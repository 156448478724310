import React, { useContext } from 'react';

import { Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { useAPI, APIRoute, ErrorCode } from 'Client';
import { AppContext } from 'Contexts/AppContext';
import {
    FindPasswordModal,
    RegisterModal,
    useModal,
    FindIdModal,
} from 'Components/modals';

export const MainView = () => {
    const history = useHistory();
    const { dispatchers } = useContext(AppContext);

    const registerModal = useModal();
    const findPasswordModal = useModal();
    const findIdModal = useModal();

    const { register, handleSubmit, errors: formErrors } = useForm();

    const loginAPI = useAPI(APIRoute.Common.Auth.Login, {
        callbacks: {
            onSuccess: result => {
                const {
                    researchAssistantProfile,
                    adminProfile,
                    staffProfile,
                    ...user
                } = result;

                dispatchers.user.set(user);
                if (researchAssistantProfile) {
                    dispatchers.researchAssistant.setProfile(
                        researchAssistantProfile,
                    );
                } else if (adminProfile) {
                    dispatchers.admin.setProfile(adminProfile);
                } else if (staffProfile) {
                    dispatchers.staff.setProfile(staffProfile);
                }
            },
        },
    });

    const onSubmit = data => {
        loginAPI.send({
            username: data.username,
            password: data.password,
        });
    };

    return (
        <>
            <section>
                <div className="main-banner-container">
                    <img src={require('../assets/img/main-banner.png')} />
                </div>
                <div className="login-wrapper">
                    <div className="login-image-container">
                        <div className="login-image-text">
                            집에서 편하게 작업하고
                            <br />
                            일한 만큼 받아가세요
                            <div
                                className="login-image-color cursor-pointer"
                                onClick={() => history.push('/signup')}
                            >
                                회원가입 후 작업 시작하기 &nbsp;&#xE001;
                            </div>
                        </div>
                        <img
                            src={require('../assets/img/main-image.png')}
                            className="login-img"
                        />
                    </div>
                    <div className="login-container">
                        <Form
                            onSubmit={handleSubmit(onSubmit)}
                            className="login-form border p-3 h-100"
                        >
                            <div className="row">
                                <div className="col-12 pt-2 pb-2 login-label">
                                    <label>아이디</label>
                                </div>
                                <div className="col-12">
                                    <input
                                        name="username"
                                        className="input w-100 pt-3 pb-3 rounded-0 login-input"
                                        ref={register}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 pt-2 pb-2 login-label">
                                    <label>비밀번호</label>
                                </div>
                                <div className="col-12">
                                    <input
                                        name="password"
                                        type="password"
                                        className="input w-100 pt-3 pb-3 rounded-0 login-input"
                                        ref={register}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 pt-2 pb-2">
                                    <button
                                        type="submit"
                                        className="btn-main-color w-100 pt-3 pb-3 rounded-0"
                                    >
                                        로그인
                                    </button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 pt-2 pb-2">
                                    <div className="d-flex  flex-column w-100 align-items-end justify-content-end">
                                        <a
                                            id="find-id-btn"
                                            className="pt-1 finding-label"
                                            href="#"
                                            onClick={findIdModal.open}
                                        >
                                            <label className="cursor-pointer">
                                                아이디 찾기
                                            </label>
                                        </a>
                                    </div>
                                    <div className="d-flex  flex-column w-100 align-items-end justify-content-end">
                                        <a
                                            id="find-password-btn"
                                            className="pt-1 finding-label"
                                            href="#"
                                            onClick={findPasswordModal.open}
                                        >
                                            <label className="cursor-pointer">
                                                비밀번호 찾기
                                            </label>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="line border-bottom"></div>
                            <div className="row">
                                <div className="col-12 pt-4">
                                    <div className="d-flex w-100 align-items-center justify-content-center pt-3">
                                        <a
                                            id="register-btn"
                                            className="register-label pt-1"
                                            onClick={() =>
                                                history.push('/signup')
                                            }
                                        >
                                            <label className="cursor-pointer">
                                                무료 회원가입
                                            </label>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
                <div className="main-step-container">
                    <div className="main-step-text">
                        5단계로 알아보는 LEAN-AI 작업
                    </div>
                    <img
                        src={require('../assets/img/main-step.png')}
                        className="main-step-img"
                    />
                </div>
                <div className="main-qna-container">
                    <img
                        src={require('../assets/img/main-qna.png')}
                        className="main-qna-img"
                    />
                    <div className="main-qna-text">
                        <div className="main-qna-text-bold">
                            궁금하신 점이 있으신가요?
                        </div>
                        우측 하단의 채널톡 아이콘을 클릭해서 문의를
                        진행해주세요!
                        <br />
                        최대한 빠른 시간 안에 답변 드리겠습니다.
                        <div className="main-qna-text-light">
                            상담 가능 시간 (평일 오전 10시 ~ 오후 5시)
                        </div>
                    </div>
                </div>
            </section>
            <RegisterModal {...registerModal} />
            <FindPasswordModal {...findPasswordModal} />
            <FindIdModal {...findIdModal} />
        </>
    );
};
