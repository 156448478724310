import lodash from 'lodash';

import React, { useRef, useState, useEffect } from 'react';

import { useForm, useFieldArray } from 'react-hook-form';

import { BasicConceptForm, LatexEditor } from 'Components';
import { TagFeedback } from 'Components/admin';
import { useAPI, APIRoute } from 'Client';
import { TagWizard } from 'Components';

import { useHistory } from 'react-router-dom';

export const AdminMissionReportStudySubjectPatchView = ({
    entity: {
        id,
        feedbacks,
        username,
        major,
        previousVersion,
        studySubject: { concept, keywords, tags, ...studySubject },
    },
}) => {
    const history = useHistory();

    const form = useForm({
        defaultValues: {
            ...studySubject,

            searchKeywords: keywords.filter(({ type }) => type === 'search'),
            exampleKeywords: keywords.filter(({ type }) => type === 'example'),
            relevantKeywords: keywords.filter(
                ({ type }) => type === 'relevant',
            ),

            conceptId: concept.id,
            conceptName: concept.name,
            conceptNameInEnglish: concept.nameInEnglish,
            conceptDescription: concept.description,

            relatedConcepts: concept.relatedConcepts.map(value => ({ value })),
            relatedConceptsInEnglish: concept.relatedConceptsInEnglish.map(
                value => ({ value }),
            ),

            feedbackForTask: feedbacks.task || '',
            feedbackForKeywords: feedbacks.keywords || '',
            feedbacksForTags: feedbacks.tags
                ? feedbacks.tags.map(value => ({ value }))
                : tags.map(() => ({
                      value: '',
                  })),

            recommendedBooks: studySubject.recommendedBooks.map(isbn => ({
                value: isbn,
            })),
        },
    });
    const relatedConcepts = useFieldArray({
        control: form.control,
        name: 'relatedConcepts',
    });
    const relatedConceptsInEnglish = useFieldArray({
        control: form.control,
        name: 'relatedConceptsInEnglish',
    });

    const searchKeywords = useFieldArray({
        control: form.control,
        name: 'searchKeywords',
    });
    const exampleKeywords = useFieldArray({
        control: form.control,
        name: 'exampleKeywords',
    });
    const relevantKeywords = useFieldArray({
        control: form.control,
        name: 'relevantKeywords',
    });
    const feedbacksForTags = useFieldArray({
        control: form.control,
        name: 'feedbacksForTags',
    });
    const recommendedBooks = useFieldArray({
        control: form.control,
        name: 'recommendedBooks',
    });

    const [myTags, setMyTags] = useState(
        tags.length
            ? tags
            : [
                  {
                      tagUUid: '',
                      learningElements: [],
                      majorCategory: 0,
                      schoolSubjectUnits: [],
                      schoolSubjects: [],
                  },
              ],
    );
    const [finished, setFinished] = useState(false);
    const [onGoing, setOnGoing] = useState(false);
    const [tagFixing, setTagFixing] = useState(false);

    const acceptButtonRef = useRef();
    const rejectButtonRef = useRef();
    const saveButtonRef = useRef();

    const missionReportPatchAPI = useAPI(APIRoute.Admin.MissionReport.Patch, {
        callbacks: {
            onSuccess: () => {
                alert('성공');
                history.push({
                    pathname: `/admin/main/mission-reports/`,
                });
            },
        },
    });

    const missionReportIssuedPatchAPI = useAPI(
        APIRoute.Admin.MissionReport.Patch,
        {
            callbacks: {
                onSuccess: () => {
                    alert('성공');
                    // history.push({
                    //     pathname: `/admin/mission-reports/`,
                    // });
                },
            },
        },
    );

    const missionReportDeleteAPI = useAPI(APIRoute.Admin.MissionReport.Delete, {
        callbacks: {
            onSuccess: () => {
                alert('삭제 성공');
                history.push({
                    pathname: `/admin/main/mission-reports/`,
                });
            },
        },
    });

    const missionReportBookAPI = useAPI(APIRoute.Admin.MissionReport.Book, {
        callbacks: {
            onSuccess: res => {
                if (res.code.title) {
                    alert(
                        `제목: ${res.code.title}\n출판사: ${res.code.publisher}\n저자: ${res.code.author}\n책표지 사진: ${res.code.cover}`,
                    );
                } else {
                    alert('도서 정보 불러오기 실패!');
                }
            },
        },
    });

    const deleteMissionReportAPI = () => {
        missionReportDeleteAPI.send({}, { additionalPath: id });
    };

    form.handleSubmit = form.handleSubmit(
        (data, { nativeEvent: { submitter } }) => {
            const isInspection = submitter !== saveButtonRef.current;
            const isAccepted = submitter === acceptButtonRef.current;
            const isRejected = submitter === rejectButtonRef.current;

            if (isAccepted | isRejected) {
                missionReportIssuedPatchAPI.send(
                    {
                        feedbacks: {
                            task: data.feedbackForTask,
                            keywords: data.feedbackForKeywords,
                            tags: tagFixing
                                ? ''
                                : data.feedbacksForTags.map(
                                      ({ value }) => value,
                                  ),
                        },
                        studySubject: {
                            id: studySubject.id,
                            concept: data.conceptId,
                            task: data.task,
                            keywords: [
                                ...data.searchKeywords.map(element => ({
                                    type: 'search',
                                    ...element,
                                })),
                                ...data.exampleKeywords.map(element => ({
                                    type: 'example',
                                    ...element,
                                })),
                                ...data.relevantKeywords.map(element => ({
                                    type: 'relevant',
                                    ...element,
                                })),
                            ],
                            recommendedBooks: data.recommendedBooks.map(
                                ({ value }) => value,
                            ),
                        },
                        inspectionStatus: isInspection
                            ? isAccepted
                                ? 'ACCEPTED'
                                : 'REJECTED'
                            : null,
                    },
                    {
                        additionalPath: id,
                    },
                );
            } else if (!isInspection) {
                missionReportPatchAPI.send(
                    {
                        feedbacks: {
                            task: data.feedbackForTask,
                            keywords: data.feedbackForKeywords,
                            tags: tagFixing
                                ? ''
                                : data.feedbacksForTags.map(
                                      ({ value }) => value,
                                  ),
                        },
                        studySubject: {
                            id: studySubject.id,
                            concept: data.conceptId,
                            task: data.task,
                            keywords: [
                                ...data.searchKeywords.map(element => ({
                                    type: 'search',
                                    ...element,
                                })),
                                ...data.exampleKeywords.map(element => ({
                                    type: 'example',
                                    ...element,
                                })),
                                ...data.relevantKeywords.map(element => ({
                                    type: 'relevant',
                                    ...element,
                                })),
                            ],
                            recommendedBooks: data.recommendedBooks.map(
                                ({ value }) => value,
                            ),
                            tags: myTags,
                        },
                    },
                    {
                        additionalPath: id,
                    },
                );
            }
        },
    );

    useEffect(() => {
        setMyTags(tags);
    }, []);

    return (
        <div className="study-subject-container">
            <div className="mt-3">
                <div
                    className="admin-back-btn cursor-pointer"
                    onClick={() => history.goBack()}
                >
                    &#xE000;&nbsp; 뒤로가기
                </div>
                {concept.isAccepted ? (
                    <div
                        className="admin-back-btn cursor-pointer"
                        onClick={() =>
                            history.push({
                                pathname: `/admin/main/concepts/${concept.id}/`,
                                state: { entity: concept },
                            })
                        }
                    >
                        &nbsp; 이론으로
                    </div>
                ) : (
                    <div className="admin-back-btn cursor-pointer btn-danger">
                        신규 이론
                    </div>
                )}
            </div>
            <div className="admin-study-subject-header-container">
                <div className="admin-study-subject-header">
                    <span>주제ID:</span> {id}
                </div>
                {previousVersion && (
                    <div className="admin-study-subject-header">
                        (이 주제는 재제출된 주제입니다. 이전에 제출된 레포트:{' '}
                        <button
                            className="btn btn-border-main"
                            onClick={() =>
                                window.open(
                                    `/admin/main/mission-reports/${previousVersion}/study-subject/`,
                                )
                            }
                        >
                            {previousVersion}
                        </button>
                        )
                    </div>
                )}
                <div className="admin-study-subject-header">
                    <span>작성자(ID): {username}</span>
                </div>
                <div className="admin-study-subject-header">
                    <span>작성자 대학/학과: {major && major.join(', ')}</span>
                </div>
            </div>
            <form onSubmit={form.handleSubmit}>
                <BasicConceptForm
                    form={form}
                    names={{
                        id: 'conceptId',
                        name: 'conceptName',
                        nameInEnglish: 'conceptNameInEnglish',
                        description: 'conceptDescription',
                    }}
                    showResetButton={false}
                />
                <div className="form-row mt-4">
                    <div className="col-2">
                        <div className="h-100 d-flex justify-content-center align-items-center">
                            <h5 className="text-middle text-center mb-0">
                                유사개념
                            </h5>
                        </div>
                    </div>
                    {relatedConcepts.fields.map((relatedConcept, index) => (
                        <div key={relatedConcept.id} className="col-2">
                            <input
                                disabled={true}
                                key={relatedConcept.id}
                                ref={form.register}
                                name={`relatedConcepts[${index}].value`}
                                defaultValue={relatedConcept.value}
                                className="form-control rounded-0 border-dark"
                            />
                        </div>
                    ))}
                </div>
                <div className="form-row mt-4">
                    <div className="col-2">
                        <div className="h-100 d-flex justify-content-center align-items-center">
                            <h5 className="text-middle text-center mb-0">
                                원어명
                            </h5>
                        </div>
                    </div>
                    {relatedConceptsInEnglish.fields.map(
                        (relatedConceptInEnglish, index) => (
                            <div
                                key={relatedConceptInEnglish.id}
                                className="col-2"
                            >
                                <input
                                    disabled={true}
                                    key={relatedConceptInEnglish.id}
                                    ref={form.register}
                                    name={`relatedConceptsInEnglish[${index}].value`}
                                    defaultValue={relatedConceptInEnglish.value}
                                    className="form-control rounded-0 border-dark"
                                />
                            </div>
                        ),
                    )}
                </div>
                <div className="row mt-4 border-bottom pb-3">
                    <div className="col-12 mb-3">
                        <h4 className="d-inline-block">과제</h4>
                        <span className="ml-3">(공백 포함 300-350자)</span>
                    </div>
                    <div className="col-6">
                        <div className="position-relative h-100">
                            <LatexEditor name="task" formRef={form} />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="border border-dark feedback-box">
                            <textarea
                                name="feedbackForTask"
                                className="d-flex w-100 h-100"
                                placeholder="피드백을 적어주세요."
                                ref={form.register}
                            ></textarea>
                        </div>
                    </div>
                    {/* 
                    <div className="col-6 d-flex justify-content-center pt-3">
                        <button
                            type="button"
                            className="btn btn-primary btn-lg rounded-0"
                        >
                            유사도 검사 진행
                    </button>
                    </div>
                    */}
                </div>
                <div className="row mt-4 mb-4 border-bottom pb-3">
                    <div className="col-6">
                        <div className="row">
                            <div className="col-12">
                                <div className="row h-50">
                                    <h5>검색 키워드(필수)</h5>
                                </div>
                                <div className="row h-50"></div>
                            </div>
                            <div className="col-12 p-0">
                                <div className="row">
                                    <div className="col-6 p-1">
                                        <input
                                            id={searchKeywords.fields[0].id}
                                            defaultValue={
                                                searchKeywords.fields[0].keyword
                                            }
                                            name="searchKeywords[0].keyword"
                                            className="form-control rounded-0 h-100 w-100 p-3"
                                            ref={form.register()}
                                        />
                                    </div>
                                    <div className="col-6 p-1">
                                        <input
                                            id={searchKeywords.fields[1].id}
                                            defaultValue={
                                                searchKeywords.fields[1].keyword
                                            }
                                            name="searchKeywords[1].keyword"
                                            className="form-control rounded-0 h-100 w-100 p-3"
                                            ref={form.register()}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6 p-1">
                                        <input
                                            id={searchKeywords.fields[2].id}
                                            defaultValue={
                                                searchKeywords.fields[2].keyword
                                            }
                                            name="searchKeywords[2].keyword"
                                            className="form-control rounded-0 h-100 w-100 p-3"
                                            ref={form.register()}
                                        />
                                    </div>
                                    <div className="col-6 p-1">
                                        <input
                                            id={searchKeywords.fields[3].id}
                                            defaultValue={
                                                searchKeywords.fields[3].keyword
                                            }
                                            name="searchKeywords[3].keyword"
                                            className="form-control rounded-0 h-100 w-100 p-3"
                                            ref={form.register()}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-12">
                                <div className="row h-50">
                                    <h5>사례 키워드(옵션)</h5>
                                </div>
                            </div>
                            <div className="col-12 p-0">
                                <div className="row">
                                    <div className="col-6 p-1">
                                        <input
                                            id={exampleKeywords.fields[0].id}
                                            defaultValue={
                                                exampleKeywords.fields[0]
                                                    .keyword
                                            }
                                            name="exampleKeywords[0].keyword"
                                            className="form-control rounded-0 h-100 w-100 p-3"
                                            ref={form.register()}
                                        />
                                    </div>
                                    <div className="col-6 p-1">
                                        <input
                                            id={exampleKeywords.fields[1].id}
                                            defaultValue={
                                                exampleKeywords.fields[1]
                                                    .keyword
                                            }
                                            name="exampleKeywords[1].keyword"
                                            className="form-control rounded-0 h-100 w-100 p-3"
                                            ref={form.register()}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6 p-1">
                                        <input
                                            id={exampleKeywords.fields[2].id}
                                            defaultValue={
                                                exampleKeywords.fields[2]
                                                    .keyword
                                            }
                                            name="exampleKeywords[2].keyword"
                                            className="form-control rounded-0 h-100 w-100 p-3"
                                            ref={form.register()}
                                        />
                                    </div>
                                    <div className="col-6 p-1">
                                        <input
                                            id={exampleKeywords.fields[3].id}
                                            defaultValue={
                                                exampleKeywords.fields[3]
                                                    .keyword
                                            }
                                            name="exampleKeywords[3].keyword"
                                            className="form-control rounded-0 h-100 w-100 p-3"
                                            ref={form.register()}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-12">
                                <div className="row h-50">
                                    <h5>관련 키워드(옵션)</h5>
                                </div>
                            </div>
                            <div className="col-12 p-0">
                                <div className="row">
                                    <div className="col-6 p-1">
                                        <input
                                            id={relevantKeywords.fields[0].id}
                                            defaultValue={
                                                relevantKeywords.fields[0]
                                                    .keyword
                                            }
                                            name="relevantKeywords[0].keyword"
                                            className="form-control rounded-0 h-100 w-100 p-3"
                                            ref={form.register()}
                                        />
                                    </div>
                                    <div className="col-6 p-1">
                                        <input
                                            id={relevantKeywords.fields[1].id}
                                            defaultValue={
                                                relevantKeywords.fields[1]
                                                    .keyword
                                            }
                                            name="relevantKeywords[1].keyword"
                                            className="form-control rounded-0 h-100 w-100 p-3"
                                            ref={form.register()}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6 p-1">
                                        <input
                                            id={relevantKeywords.fields[2].id}
                                            defaultValue={
                                                relevantKeywords.fields[2]
                                                    .keyword
                                            }
                                            name="relevantKeywords[2].keyword"
                                            className="form-control rounded-0 h-100 w-100 p-3"
                                            ref={form.register()}
                                        />
                                    </div>
                                    <div className="col-6 p-1">
                                        <input
                                            id={relevantKeywords.fields[3].id}
                                            defaultValue={
                                                relevantKeywords.fields[3]
                                                    .keyword
                                            }
                                            name="relevantKeywords[3].keyword"
                                            className="form-control rounded-0 h-100 w-100 p-3"
                                            ref={form.register()}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="border border-dark h-100">
                            <textarea
                                name="feedbackForKeywords"
                                className="d-flex w-100 h-100"
                                placeholder="피드백을 적어주세요."
                                ref={form.register}
                            ></textarea>
                        </div>
                    </div>
                </div>
                <div className="form-row mt-3">
                    <div className="col-2">
                        <div className="h-100 d-flex justify-content-center align-items-center">
                            <h6 className="text-middle text-center mb-0">
                                관련 도서(ISBN)
                            </h6>
                        </div>
                    </div>
                    {recommendedBooks.fields.map(
                        (recommendedBookField, index) => (
                            <div
                                key={recommendedBookField.id}
                                className="col-2"
                                onClick={() =>
                                    missionReportBookAPI.send({
                                        book: recommendedBookField.value,
                                    })
                                }
                            >
                                <input
                                    key={recommendedBookField.id}
                                    ref={form.register()}
                                    name={`recommendedBooks[${index}].value`}
                                    defaultValue={recommendedBookField.value}
                                    className="form-control rounded-0"
                                />
                            </div>
                        ),
                    )}
                </div>
                <div className="form-row mt-3">
                    <div className="col-2">
                        <div className="h-100 d-flex justify-content-center align-items-center">
                            <h6 className="text-middle text-center mb-0">
                                관련 과학동아 URL
                            </h6>
                        </div>
                    </div>
                </div>
                <hr className="border-dark mt-4 thick" />
                {tagFixing ? (
                    <div className="mt-4">
                        <TagWizard
                            isLoaded={myTags.length > 0}
                            tags={myTags}
                            setTags={setMyTags}
                            onComplete={myTags =>
                                form.setValue('recommendedMajors', myTags)
                            }
                            setFinished={setFinished}
                            setOnGoing={setOnGoing}
                        />
                    </div>
                ) : (
                    <TagFeedback
                        form={form}
                        tags={tags}
                        feedbacks={{ feedbacksForTags }}
                    />
                )}
                {!tagFixing && (
                    <div className="col-4 justify-content-center align-items-center d-flex mt-3 pl-5">
                        <button
                            type="button"
                            onClick={() => setTagFixing(true)}
                            className="btn-border-main btn-lg btn-block"
                        >
                            관련 과목 수정하기
                        </button>
                    </div>
                )}
                <div className="row justify-content-center mt-3">
                    <div className="col-3 justify-content-center align-items-center d-flex">
                        <button
                            type="button"
                            onClick={() => deleteMissionReportAPI()}
                            className="btn-danger btn-lg btn-block"
                        >
                            삭제하기
                        </button>
                    </div>
                    <div className="offset-1 col-3 justify-content-center align-items-center d-flex">
                        <button
                            type="submit"
                            ref={saveButtonRef}
                            className="btn-border-main btn-lg btn-block"
                        >
                            저장하기
                        </button>
                    </div>
                </div>
                <hr className="border-dark mt-4 thick" />
                <div className="row mt-5 pb-3 mb-5 justify-content-center">
                    <div className="col-3 justify-content-center align-items-center d-flex">
                        <button
                            type="submit"
                            ref={rejectButtonRef}
                            className="btn-cancel btn-lg btn-block"
                        >
                            반려하기
                        </button>
                    </div>
                    <div className="offset-1 col-3 justify-content-center align-items-center d-flex">
                        <button
                            type="submit"
                            ref={acceptButtonRef}
                            className="btn-main-color btn-lg rounded-0 btn-block"
                        >
                            승인하기
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};
