import React, { useState, useContext, useEffect } from 'react';

import lodash from 'lodash';

import { generateUuid } from 'Utils';
import { AppContext } from 'Contexts/';

import { TagListDisplay } from './TagListDisplay';
import { SchoolSubjectUnitSelector } from './SchoolSubjectUnitSelector';
import { RecommendSchoolSubject } from './RecommendSchoolSubject';
import { AddSubjectUnitModalWrapper } from './AddSubjectUnitModalWrapper';
import { Navigator } from './Navigator';

const deepUuidClone = (tag, majorCategory) => {
    const copied = lodash.cloneDeep(tag);
    copied.majorCategory = majorCategory;
    copied.tagUuid = generateUuid();

    const keys = {
        schoolSubjects: 'schoolSubjectUuid',
        schoolSubjectUnits: 'schoolSubjectUnitUuid',
        learningElements: 'learningElements',
    };

    Object.entries(keys).forEach(([key, uuidKey]) => {
        copied[key].forEach(element => {
            const oldUuid = element[uuidKey];
            const newUuid = generateUuid();

            Object.keys(keys).map(k => {
                copied[k] = copied[k].map(e => {
                    if (e[uuidKey] === oldUuid) {
                        e[uuidKey] = newUuid;
                    }
                    return e;
                });
            });
        });
    });

    return copied;
};

export const TagList = ({
    tags,
    setTags,
    back,
    next,
    currentStep,
    ...props
}) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentSubject, setCurrentSubject] = useState('');
    const [currentSubjectName, setCurrentSubjectName] = useState('');
    const [currentSubjectUnit, setCurrentSubjectUnit] = useState('');
    const [relatedSubjectUnitArr, setRelatedSubjectUnitArr] = useState([]);
    const {
        getters: { getSchoolSubject, getSchoolSubjectUnit },
    } = useContext(AppContext);

    const getCopyHandler = tagIndex => () => {
        if (tagIndex === 0) {
            return;
        }

        setTags([
            ...tags.map((tag, index) =>
                index === tagIndex
                    ? deepUuidClone(tags[index - 1], tag.majorCategory)
                    : tag,
            ),
        ]);
    };
    const clickRecommendUnit = unit => {
        let subject = getSchoolSubject(unit.schoolSubject, 'name');
        const { name, id } = subject;
        addSchoolSubject(currentIndex)({
            schoolSubjectUuid: generateUuid(),
            id,
        });
        setCurrentSubjectUnit(unit);
    };
    const checkSubjectExists = (tag, subjectId) => {
        for (let subject of tag.schoolSubjects) {
            if (subject.id == subjectId) return subject.schoolSubjectUuid;
        }
        return false;
    };
    const addSchoolSubject = tagIndex => schoolSubject => {
        setTags([
            ...tags.map((tag, index) => {
                if (index === tagIndex) {
                    let existId = checkSubjectExists(tag, schoolSubject.id);
                    if (!existId) {
                        tag.schoolSubjects = [
                            ...tag.schoolSubjects,
                            { ...schoolSubject },
                        ];
                    }
                    setCurrentSubject(
                        existId || schoolSubject.schoolSubjectUuid,
                    );
                }
                return tag;
            }),
        ]);
        let name = getSchoolSubject(schoolSubject.id).name;
        setCurrentSubjectName(name);
    };
    const checkUnitExists = tagIndex => unitId => {
        for (let unit of tags[tagIndex].schoolSubjectUnits) {
            if (unit.id == unitId) return unit.schoolSubjectUnitUuid;
        }
        return false;
    };
    const addSchoolSubjectUnit = (
        tagIndex,
        schoolSubjectUuid,
    ) => schoolSubjectUnit => {
        setTags([
            ...tags.map((tag, index) => {
                if (index === tagIndex) {
                    let existId = checkUnitExists(index)(schoolSubjectUnit.id);
                    if (!existId) {
                        tag.schoolSubjectUnits = [
                            ...tag.schoolSubjectUnits,
                            {
                                schoolSubjectUuid,
                                ...schoolSubjectUnit,
                            },
                        ];
                    }
                }
                return tag;
            }),
        ]);
        let subjectUnit = getSchoolSubjectUnit(schoolSubjectUnit.id);
        setRelatedSubjectUnitArr(
            subjectUnit.relatedSchoolSubjectUnits
                .concat(relatedSubjectUnitArr)
                .slice(0, 12),
        );
    };
    const checkElementExists = tagIndex => (unitUuid, elementName) => {
        // 중복 여부 체크
        for (let element of tags[tagIndex].learningElements) {
            if (
                element.schoolSubjectUnitUuid == unitUuid &&
                element.name == elementName
            )
                return true;
        }
        return false;
    };
    const addLearningElements = (tagIndex, schoolSubjectUuid) => (
        schoolSubjectUnitUuid,
        learningElements,
    ) => {
        setTags([
            ...tags.map((tag, index) => {
                if (index === tagIndex) {
                    tag.learningElements = [
                        ...tag.learningElements,
                        ...learningElements.map(learningElement => ({
                            schoolSubjectUuid,
                            schoolSubjectUnitUuid,
                            ...learningElement,
                        })),
                    ];
                }
                return tag;
            }),
        ]);
    };
    const subjectExistsWhenCacel = tagIndex => subjectUuid => {
        // 해당 단원, 학과 남아있는지 체크
        for (let i = 0; i < tags[tagIndex].learningElements.length; i++) {
            if (
                tags[tagIndex].learningElements[i].schoolSubjectUuid ===
                subjectUuid
            ) {
                return;
            }
        }
        for (let i = 0; i < tags[tagIndex].schoolSubjects.length; i++) {
            if (
                tags[tagIndex].schoolSubjects[i].schoolSubjectUuid ===
                subjectUuid
            ) {
                tags[tagIndex].schoolSubjects.splice(i, 1);
                break;
            }
        }
        return;
    };
    const checkElementsExists = (tag, unitId, subjectId) => {
        // 해당 단원, 학과 남아있는지 체크
        let unitExist = false;
        let subjectExist = false;
        for (let i = 0; i < tag.learningElements.length; i++) {
            if (
                unitId &&
                tag.learningElements[i].schoolSubjectUnitUuid === unitId
            ) {
                unitExist = true;
            }
            if (
                subjectId &&
                tag.learningElements[i].schoolSubjectUuid === subjectId
            ) {
                subjectExist = true;
            }
            if (unitExist && subjectExist) break;
        }
        return [unitExist, subjectExist];
    };
    const removeVacantElements = (tag, unitId, subjectId) => {
        if (unitId) {
            for (let i = 0; i < tag.schoolSubjectUnits.length; i++) {
                if (
                    tag.schoolSubjectUnits[i].schoolSubjectUnitUuid === unitId
                ) {
                    tag.schoolSubjectUnits.splice(i, 1);
                    break;
                }
            }
        }
        if (subjectId) {
            for (let i = 0; i < tag.schoolSubjects.length; i++) {
                if (tag.schoolSubjects[i].schoolSubjectUuid === subjectId) {
                    tag.schoolSubjects.splice(i, 1);
                    break;
                }
            }
        }
    };
    const deleteLearningElements = (tagIndex, learningElementUuid) => {
        setTags([
            ...tags.map((tag, index) => {
                if (index === tagIndex) {
                    let unitId = 0;
                    let subjectId = 0;
                    for (let i = 0; i < tag.learningElements.length; i++) {
                        if (
                            tag.learningElements[i].learningElementUuid ===
                            learningElementUuid
                        ) {
                            unitId =
                                tag.learningElements[i].schoolSubjectUnitUuid;
                            subjectId =
                                tag.learningElements[i].schoolSubjectUuid;
                            tag.learningElements.splice(i, 1);
                            break;
                        }
                    }
                    let [unitExist, subjectExist] = checkElementsExists(
                        tag,
                        unitId,
                        subjectId,
                    );
                    removeVacantElements(
                        tag,
                        !unitExist && unitId,
                        !subjectExist && subjectId,
                    );
                }
                return tag;
            }),
        ]);
    };

    return (
        <>
            <div className="mt-5 border-light-color p-4">
                <h5 style={{ fontWeight: 800 }}>6. 과목/단원/학습요소 태깅</h5>
                <div className="ra-mission-caution text-muted mt-3">
                    - '탐구과제'와 ① <bold>밀접하게 관련</bold>되는
                    과목/단원/학습요소를 ② <bold>모두</bold> 태그해주세요.
                </div>
                <div className="ra-mission-caution text-muted mt-3">
                    - 과목/단원/학습요소 <bold>각각 3가지 이상</bold> 태깅을
                    권장합니다. (특수한 경우 예외)
                </div>
            </div>
            <hr className="border-dark mt-3 thick" />
            <div className="d-flex w-100 justify-content-start">
                <h4 className="bold-font">단원 및 학습요소 선택</h4>
            </div>
            <div>
                <h6 className="text-muted">
                    관련되는 단원+학습요소를{' '}
                    <span style={{ color: 'red', fontWeight: 'bold' }}>
                        모두
                    </span>{' '}
                    태그해주세요.
                </h6>
                <h6 className="text-muted">
                    (예체능, 제2외국어 의 경우, 오른쪽 '단원이
                    제공되지않습니다.' 선택한 후, 왼쪽 '없음(클릭필수)'를
                    선택하시면 됩니다.)
                </h6>
            </div>
            {tags &&
                tags.map(
                    (tag, index) =>
                        index == 0 && (
                            <div key={index} className="mt-3">
                                <TagListDisplay
                                    key={`${tag}-${index}`}
                                    tags={tags}
                                    deleteLearningElements={
                                        deleteLearningElements
                                    }
                                    index={index}
                                    currentStep={currentStep}
                                />
                                {currentSubject && currentSubjectName && (
                                    <>
                                        <h5 className="pl-5 mt-3">
                                            {currentSubjectName}
                                        </h5>
                                        <SchoolSubjectUnitSelector
                                            subjectExists={subjectExistsWhenCacel(
                                                index,
                                            )}
                                            checkUnitExists={checkUnitExists(
                                                index,
                                            )}
                                            checkElementExists={checkElementExists(
                                                index,
                                            )}
                                            addLearningElements={addLearningElements(
                                                index,
                                                currentSubject,
                                            )}
                                            addSchoolSubjectUnit={addSchoolSubjectUnit(
                                                index,
                                                currentSubject,
                                            )}
                                            schoolSubjectUuid={currentSubject}
                                            schoolSubjectName={
                                                currentSubjectName
                                            }
                                            recommendedSchoolSubjectUnit={
                                                currentSubjectUnit
                                            }
                                            toggle={() => {
                                                setCurrentSubject('');
                                                setCurrentSubjectName('');
                                            }}
                                        />
                                    </>
                                )}
                                {!currentSubjectName &&
                                    currentStep !== 'Complete' && (
                                        <RecommendSchoolSubject
                                            relatedUnits={relatedSubjectUnitArr}
                                            clickRecommendUnit={
                                                clickRecommendUnit
                                            }
                                        />
                                    )}
                                {!currentSubjectName &&
                                    currentStep !== 'Complete' && (
                                        <div className="mt-2">
                                            <div className="form-row mt-3">
                                                <div className="col-6">
                                                    <AddSubjectUnitModalWrapper
                                                        first={
                                                            tags[index]
                                                                .learningElements
                                                                .length === 0
                                                        }
                                                        tagIndex={index}
                                                        handleCopy={getCopyHandler(
                                                            index,
                                                        )}
                                                        addSchoolSubject={addSchoolSubject(
                                                            index,
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                            </div>
                        ),
                )}
            <Navigator
                visible={!currentSubjectName}
                back={() => back()}
                next={() => next(tags)}
                tagLength={tags.length}
                currentIndex={currentIndex}
                setCurrentIndex={setCurrentIndex}
                currentStep={currentStep}
            />
        </>
    );
};
