import React from 'react';
import { useHistory } from 'react-router-dom';

import { APIRoute } from 'Client';
import { RemoteDataTable, useRemoteDataTable } from 'Components/table/base';

const tableProperty = [
    {
        key: 'id',
        name: '번호',
    },
    {
        key: 'title',
        name: '제목',
    },
    {
        key: 'createdAt',
        name: '작성일자',
    },
];

export const NoticeTable = props => {
    const history = useHistory();
    const apiRoute = APIRoute.Admin.Announcement.List;
    const { form, ...remoteDataTable } = useRemoteDataTable({
        apiRoute,
    });

    const clickHandler = (columnData, notice) => {
        history.push(`/ra/mission-working-info/detail?id=${notice.id}`);
    };

    tableProperty.forEach(e => {
        e.clickHandler = clickHandler;
    });

    return (
        <div className="col-12 mt-3 p-0">
            <RemoteDataTable
                tableProperty={tableProperty}
                {...remoteDataTable}
            />
        </div>
    );
};
