import React from 'react';

import { NavLink } from 'react-router-dom';
import { Navbar, Nav, NavItem } from 'react-bootstrap';

import { StaffRoutes } from 'Router';

const navData = {
    leanAi: {
        pathNamePattern: /staff\/main/,
        data: [
            ['/staff/main/mission-reports', '주제 검수'],
            ['/staff/main/study-subjects/', '주제 목록'],
            ['/staff/main/concepts/', '이론 목록'],
        ],
    },
};

export const StaffSubmenuView = props => {
    const {
        location: { pathname },
    } = props;

    return (
        <div className="content">
            <div className="content-wrapper">
                <div className="content-menu">
                    <Navbar className="w-100">
                        <Nav defaultActiveKey="/" className="flex-column">
                            {Object.entries(navData).map(
                                ([navName, { pathNamePattern, data }]) => {
                                    if (pathname.match(pathNamePattern)) {
                                        return data.map(
                                            ([baseUrl, name], index) => (
                                                <NavItem key={index}>
                                                    <NavLink
                                                        activeClassName="active"
                                                        className="admin-menu-item"
                                                        to={baseUrl}
                                                    >
                                                        {name}
                                                    </NavLink>
                                                </NavItem>
                                            ),
                                        );
                                    }
                                },
                            )}
                        </Nav>
                    </Navbar>
                </div>
                <div className="content-content">
                    <StaffRoutes />
                </div>
            </div>
        </div>
    );
};
