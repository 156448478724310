import React, { useEffect, useState, useContext } from 'react';
import { Container, Row, Col, Modal, Form, Button } from 'react-bootstrap';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';

import { useHistory } from 'react-router-dom';
import { Pen } from 'react-bootstrap-icons';

import { AppContext } from 'Contexts/AppContext';
import { useAPI, APIRoute } from 'Client';
import Select from 'react-select';

export const StudentWaitingView = ({ location, ...props }) => {
    const history = useHistory();

    const { user } = useContext(AppContext);
    const [email, setEmail] = useState(user.email);
    const [rejected, setRejected] = useState(false);

    const userPatchAPI = useAPI(APIRoute.Common.User.Patch, {
        callbacks: {
            onSuccess: res => {
                alert('변경 성공');
            },
        },
    });

    const testSubmitCheckAPI = useAPI(
        APIRoute.ResearchAssistant.TestReport.Check,
        {
            callbacks: {
                onSuccess: res => {
                    if (res.code === 'REJECTED') {
                        setRejected(true);
                    }
                },
                onError: err => {
                    history.push('/ra/test/validation/');
                },
            },
        },
    );

    useEffect(() => {
        testSubmitCheckAPI.send();
    }, []);

    return (
        <div className="ra-mission-container">
            <div className="ra-mission-title">Mission</div>
            <div className="ra-mission-category">
                전공 탐색을 위한 탐구과제 생성
            </div>
            <div className="ra-test-description">
                모든 과정을 진행해주셔서 감사합니다.
            </div>
            <hr />
            {rejected ? (
                <section>
                    <h5>테스트 결과: 반려 (REJECTED)</h5>
                    <div>아쉽지만 테스트에 통과하지 못하셨습니다.</div>
                    <div>
                        탐구과제 생성 미션에 대한 테스트 재작성은 불가능합니다.
                    </div>
                    <div>
                        다른 미션이 생길 경우, 메일로 알려드리도록 하겠습니다.
                        감사합니다.
                    </div>
                </section>
            ) : (
                <>
                    <section>
                        <h5>결과 통보 안내</h5>
                        <div>
                            결과 통보 및 계약서를 등록하신 이메일로
                            안내해드리겠습니다.
                        </div>
                        <div>
                            이메일 변경이 필요한 경우, 아래 다시 작성해주세요.
                        </div>
                        <div className="row align-items-center mt-3">
                            <input
                                className="ra-test-input"
                                placeholder="이메일을 입력해주세요."
                                onChange={e => setEmail(e.target.value)}
                                maxLengt={30}
                                value={email}
                            />
                            <div className="col-2 mt-1">
                                <Button
                                    onClick={() =>
                                        userPatchAPI.send(
                                            { email: email },
                                            {
                                                additionalPath: user.id,
                                            },
                                        )
                                    }
                                    className="w-100 pt-2 pb-2 btn-main-color"
                                >
                                    확인
                                </Button>
                            </div>
                        </div>
                    </section>

                    <Row className="justify-content-center mt-5">
                        <Col md={8} className="pt-2 pb-2">
                            <Button
                                type="submit"
                                variant={'primary'}
                                className="w-100 pt-3 pb-3 btn-border-main"
                            >
                                심사 중입니다.
                            </Button>
                        </Col>
                    </Row>
                </>
            )}
        </div>
    );
};
