import React, { useState, useEffect, useContext } from 'react';
import { Form } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import { getParamsFromQuery, getQueryFromParams } from 'Utils';

import { APIRoute } from 'Client';
import moment from 'Moment';
import {
    AdminDirectlyManagedStudySubjectModal,
    SearchRelatedMajorsModal,
    SearchRelatedSchoolSubjectsModal,
    SearchRelatedSchoolSubjectUnitsModal,
    useModal,
} from 'Components/modals';
import { AppContext } from 'Contexts';
import { getSchoolCode } from 'Helpers';

import {
    RemoteDataTable,
    RemoteDataBlockTable,
    useRemoteDataTable,
} from './base';

import { SelectOptions } from './constants';

const formatResult = results => {
    return (results = results.map(result => {
        return {
            ...result,
            updated: result.updated
                ? moment(result.updated).format('YY-MM-DD')
                : '-',
            conceptName: result.concept.name,
            conceptFullName: result.concept.nameInEnglish
                ? `${result.concept.name}
                (${result.concept.nameInEnglish})`
                : result.concept.name,
            tootlp: [
                {
                    key: 'task',
                    content: result.task,
                },
            ],
            issued: result.studentProfileMaps.length
                ? result.studentProfileMaps.length
                : '-',
        };
    }));
};

const tableProperty = [
    {
        key: 'id',
        name: '번호',
    },
    {
        key: 'conceptName',
        name: '이론명',
    },
    {
        key: 'task',
        name: '과제',
        template: (columnData, row) => {
            return (
                <>
                    <span data-for={`study-subject-task-${row.id}`} data-tip>
                        {columnData.slice(0, 35)}
                    </span>
                    <ReactTooltip
                        className="tool-tip-container"
                        id={`study-subject-task-${row.id}`}
                        place="bottom"
                        type="info"
                        effect="solid"
                        getContent={() => columnData}
                    />
                </>
            );
        },
    },
    {
        key: 'issuedStudySubjectCount',
        name: '발급횟수',
    },
    {
        key: 'updated',
        name: '수정일자',
    },
];

const blockProperty = {
    key: 'conceptFullName',
};

export const StudySubjectTable = ({
    blockTable,
    school,
    searchField,
    searchValue,
    ...props
}) => {
    // start block table related
    const [selectedStudySubject, setSelectedStudySubject] = useState();
    const adminDirectlyManagedStudySubjectModal = useModal();
    blockProperty.clickHandler = (name, datum) => {
        setSelectedStudySubject(datum);
        adminDirectlyManagedStudySubjectModal.open();
    };
    // end block table related

    const schoolCode = getSchoolCode(school || {});
    const location = useLocation();
    const history = useHistory();
    const params = getParamsFromQuery(location.search);

    const {
        researchAssistant,
        getters: { getMajorCategory, getSchoolSubject, getSchoolSubjectUnit },
    } = useContext(AppContext);

    const [schoolSubject, setSchoolSubject] = useState(params['schoolSubject']);
    const [schoolSubjectUnit, setSchoolSubjectUnit] = useState(
        params['schoolSubjectUnit'],
    );
    const [majorCategory, setMajorCategory] = useState(params['majorCategory']);

    const searchRelatedSchoolSubjectsModal = useModal();
    const searchRelatedSchoolSubjectUnitsModal = useModal();
    const searchRelatedMajorsModal = useModal();

    const { admin, staff } = useContext(AppContext);
    const apiRoute = admin.profile.id
        ? APIRoute.Admin.StudySubject.List
        : staff.profile.id
        ? APIRoute.Staff.StudySubject.List
        : null;
    // const apiRoute = APIRoute.Admin.StudySubject.List;
    const formatData = data => {
        return formatResult(data);
    };

    const { form, reload, ...remoteDataTable } = useRemoteDataTable({
        apiRoute,
        formatData,
        defaultValues: {
            search_field: searchField,
            search_value: searchValue,
            schoolCode,
        },
    });

    const clickHandler = (_, { id }) => {
        const baseUrl = admin.profile.id
            ? '/admin/main/study-subjects/'
            : staff.profile.id
            ? '/staff/main/study-subjects/'
            : null;
        history.push({
            pathname: `${baseUrl}${id}/`,
        });
    };

    tableProperty.forEach(e => {
        e.clickHandler =
            typeof props.clickHandler === 'function'
                ? (columnData, row) =>
                      props.clickHandler(columnData, row, form.getValues())
                : clickHandler;
    });

    const makeClickHandler = (fieldName, getter, setter) => {
        return {
            onClick: (item, init) => {
                const id = item.id || item;
                form.setValue(fieldName, id);
                setter(id);
                if (!init) {
                    reload();
                }
            },
            onResetClick: item => {
                form.setValue(fieldName, '');
                setter();
                reload();
            },
        };
    };

    const {
        onClick: handleSchoolSubjectUnitClick,
        onResetClick: handleSchoolSubjectUnitResetClick,
    } = makeClickHandler(
        'schoolSubjectUnit',
        getSchoolSubjectUnit,
        setSchoolSubjectUnit,
    );

    const {
        onClick: handleSchoolSubjectClick,
        onResetClick: handleSchoolSubjectResetClick,
    } = makeClickHandler('schoolSubject', getSchoolSubject, id => {
        if (id) {
            setSchoolSubject(id);
        } else {
            setSchoolSubject();
            handleSchoolSubjectUnitResetClick();
        }
    });

    const {
        onClick: handleMajorCategoryClick,
        onResetClick: handleMajorCategoryResetClick,
    } = makeClickHandler('majorCategory', getMajorCategory, setMajorCategory);

    useEffect(() => {
        if (researchAssistant.loading) {
            return;
        }

        const params = getParamsFromQuery(location.search);
        const formFields = {
            schoolSubject: handleSchoolSubjectClick,
            schoolSubjectUnit: handleSchoolSubjectUnitClick,
            majorCategory: handleMajorCategoryClick,
        };

        Object.entries(formFields).forEach(([fieldName, setter]) => {
            let initialValue = params[fieldName];
            if (!initialValue) {
                if (fieldName in params) {
                    return;
                } else {
                    initialValue = props[fieldName];
                    if (!initialValue) {
                        return;
                    }
                }
            }

            setter(
                {
                    id: +initialValue,
                },
                true,
            );
        });
    }, [researchAssistant.loading]);

    return (
        <>
            <div className=" pt-3">
                <Form className="ss-filter-container">
                    <input
                        ref={form.register}
                        type="hidden"
                        name="schoolSubject"
                        value={schoolSubject}
                    />
                    <input
                        ref={form.register}
                        type="hidden"
                        name="schoolSubjectUnit"
                        value={schoolSubjectUnit}
                    />
                    <input
                        ref={form.register}
                        type="hidden"
                        name="majorCategory"
                        value={majorCategory}
                    />
                    <input
                        ref={form.register}
                        type="hidden"
                        name="schoolCode"
                        value={schoolCode}
                    />

                    <div className="ss-filter-box p-0">
                        {schoolSubject && !Number.isNaN(schoolSubject) ? (
                            <>
                                <button
                                    type="button"
                                    className="btn border active w-80 h-100 rounded-0 ss-filter-btn"
                                >
                                    {getSchoolSubject(+schoolSubject)?.name}
                                </button>
                                <button
                                    type="button"
                                    onClick={() =>
                                        handleSchoolSubjectResetClick()
                                    }
                                    className="btn border w-20 h-100 rounded-0 text-main-color"
                                >
                                    초기화
                                </button>
                            </>
                        ) : (
                            <button
                                onClick={() =>
                                    searchRelatedSchoolSubjectsModal.open()
                                }
                                type="button"
                                className="btn border w-100 h-100 ss-filter-btn"
                            >
                                과목 선택
                            </button>
                        )}
                    </div>
                    <div className="ss-filter-box p-0">
                        {schoolSubjectUnit &&
                        !Number.isNaN(schoolSubjectUnit) ? (
                            <>
                                <button
                                    type="button"
                                    className="btn border w-80 h-100 rounded-0 ss-filter-btn active"
                                >
                                    {
                                        getSchoolSubjectUnit(+schoolSubjectUnit)
                                            ?.name
                                    }
                                </button>
                                <button
                                    onClick={() =>
                                        handleSchoolSubjectUnitResetClick()
                                    }
                                    type="button"
                                    className="btn border w-20 h-100 rounded-0 text-main-color"
                                >
                                    초기화
                                </button>
                            </>
                        ) : (
                            <>
                                <button
                                    type="button"
                                    disabled={!schoolSubject}
                                    onClick={() =>
                                        searchRelatedSchoolSubjectUnitsModal.open()
                                    }
                                    className="btn border w-100 h-100 ss-filter-btn"
                                >
                                    단원 선택
                                </button>
                            </>
                        )}
                    </div>
                    <div className="ss-filter-box p-0">
                        {majorCategory && !Number.isNaN(majorCategory) ? (
                            <>
                                <button
                                    type="button"
                                    className="btn border w-80 h-100 rounded-0 ss-filter-btn active"
                                >
                                    {
                                        getMajorCategory(+majorCategory)
                                            ?.subcategoryName
                                    }
                                </button>
                                <button
                                    onClick={() =>
                                        handleMajorCategoryResetClick()
                                    }
                                    type="button"
                                    className="btn border w-20 h-100 rounded-0 text-main-color"
                                >
                                    초기화
                                </button>
                            </>
                        ) : (
                            <>
                                <button
                                    onClick={() =>
                                        searchRelatedMajorsModal.open()
                                    }
                                    type="button"
                                    className="btn border w-100 h-100 ss-filter-btn"
                                >
                                    학과 선택
                                </button>
                            </>
                        )}
                    </div>
                    <div className="ss-filter-box mt-3">
                        <div className="input-group h-100">
                            <select ref={form.register} name="search_field">
                                {SelectOptions.StudySubject.map(
                                    ({ value, label }, index) => (
                                        <option key={index} value={value}>
                                            {label}
                                        </option>
                                    ),
                                )}
                            </select>
                            <input
                                name="search_value"
                                ref={form.register}
                                onKeyPress={e => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                        reload();
                                    }
                                }}
                                type="text"
                                className="form-control border-right-0 h-100 rounded-0"
                            />
                            <div className="input-group-append rounded-0">
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={reload}
                                >
                                    검색
                                </button>
                            </div>
                        </div>
                    </div>
                </Form>
            </div>
            <div className="col-12 mt-5">
                {blockTable ? (
                    <>
                        <RemoteDataBlockTable
                            blockProperty={blockProperty}
                            {...remoteDataTable}
                        />
                        <AdminDirectlyManagedStudySubjectModal
                            studySubject={selectedStudySubject}
                            setStudySubject={setSelectedStudySubject}
                            {...adminDirectlyManagedStudySubjectModal}
                        />
                    </>
                ) : (
                    <RemoteDataTable
                        tableProperty={tableProperty}
                        {...remoteDataTable}
                    />
                )}
            </div>

            <SearchRelatedMajorsModal
                handleClick={handleMajorCategoryClick}
                {...searchRelatedMajorsModal}
            />
            <SearchRelatedSchoolSubjectsModal
                handleClick={handleSchoolSubjectClick}
                {...searchRelatedSchoolSubjectsModal}
            />
            <SearchRelatedSchoolSubjectUnitsModal
                // TODO replace key 'name' to id from backend side
                schoolSubject={getSchoolSubject(schoolSubject)?.name}
                handleClick={handleSchoolSubjectUnitClick}
                {...searchRelatedSchoolSubjectUnitsModal}
            />
        </>
    );
};
