import React from 'react';

import { useHistory } from 'react-router-dom';

import { NoticeTable } from 'Components/admin/table';

export const AdminMissionDetailView = props => {
    const history = useHistory();

    const handleMake = () => {
        history.push({
            pathname: '/admin/management/missions/:id/create/',
        });
    };

    return (
        <div className="ra-mission-container">
            <div className="ra-mission-title">Mission</div>
            <div className="ra-mission-category">
                전공 탐색을 위한 탐구과제 생성
            </div>
            <hr />
            <div className="ra-mission-tabel-title-container">
                <div className="ra-mission-tabel-title">공지사항 관리</div>
            </div>
            <div
                className="btn btn-main-color btn-large mt-3"
                onClick={handleMake}
            >
                새로 작성하기
            </div>
            <div className="col-12 mt-3 p-0">
                <NoticeTable />
            </div>
        </div>
    );
};
