import React, { useEffect, useState, useContext } from 'react';

import { useHistory } from 'react-router-dom';

import { useAPI, APIRoute } from 'Client';
import { AppContext } from 'Contexts/AppContext';

import classNames from 'classnames';

const mapMissionType = type => {
    switch (type) {
        case 'CONCEPT':
            return '탐구이론';
        case 'CONCEPT_RFP':
            return '탐구이론 TEST';
        case 'STUDY_SUBJECT':
            return '[교육] 진로탐색을 위한 탐구과제 생성';
        case 'STUDY_SUBJECT_RFP':
            return '탐구문제 TEST';
        default:
            return type;
    }
};

export const MissionListView = ({ location, ...props }) => {
    const [missions, setMissions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [submitted, setSubmitted] = useState(false)
    const [rejected, setRejected] = useState(false)
    const history = useHistory();

    const { researchAssistant } = useContext(AppContext);

    const testSubmitAPI = useAPI(APIRoute.ResearchAssistant.TestReport.Create, {
        callbacks: {
            onSuccess: res => {
                alert('인증 요청이 전송되었습니다.');
                setSubmitted(true)
            },
        },
    });

    const testSubmitCheckAPI = useAPI(
        APIRoute.ResearchAssistant.TestReport.Check,
        {
            callbacks: {
                onSuccess: res => {
                    setSubmitted(true)
                    if (res.code === 'REJECTED') {
                        setRejected(true);
                    }
                }
            },
        },
    );

    const missionListAPI = useAPI(APIRoute.ResearchAssistant.Mission.List, {
        callbacks: {
            onSuccess: data => {
                if (data.length >= 1) setMissions([data.pop()]);
                else history.push('/ra/missions/ing');
                setLoading(false);
            },
        },
    });
    const missionPermittedAPI = useAPI(
        APIRoute.ResearchAssistant.Mission.Permitted,
        {
            callbacks: {
                onSuccess: data => {
                    setMissions(data);
                    setLoading(false);
                },
            },
        },
    );

    const gotoMissionDetail = () =>
        history.push({
            pathname: `/ra/mission-working/`,
        });

    const submitTest = () => {
        testSubmitAPI.send({
            testReportExample: 1,
            submittedSentence: [
                { value: '' },
                { value: '' },
                { value: '' },
                { value: '' },
                { value: '' },
                { value: '' },
                { value: '' },
            ],
            terms: '',
            summary: '',
            task: '',
            keywords: [
                { value: '' },
                { value: '' },
                { value: '' },
                { value: '' },
            ],
        })
    }

    useEffect(() => {
        testSubmitCheckAPI.send();
    }, []);

    useEffect(() => {
        if (window.location.pathname.match(/ing/)) {
            setLoading(true);
            setMissions([]);
            missionPermittedAPI.send();
            // missionListAPI.send();
        } else {
            setLoading(true);
            setMissions([]);
            missionListAPI.send();
        }
    }, [window.location.pathname]);

    return (
        <div className="container">
            <div className="row mr-0 ml-0 h-40 p-0 mt-4">
                {loading ? (
                    <div>
                        <span>로딩 중입니다...</span>
                    </div>
                ) : missions.length === 0 ||
                    researchAssistant.profile.expulsionExpireAt ? (
                    <span>
                        {window.location.pathname.match(/ing/)
                            ? '참여 중인 미션이 없습니다.'
                            : '참여 가능한 미션이 없습니다.'}
                    </span>
                ) : (
                    missions.map(mission => (
                        <div
                            className={classNames(
                                "ra-list-mission-container",
                                { 'join-page': window.location.pathname.match(/ing/) }
                            )}
                            key={mission.id}
                            onClick={() =>
                                window.location.pathname.match(/ing/) && gotoMissionDetail(mission)
                            }
                        >
                            <div className={classNames(
                                "ra-list-mission-box",
                                { 'join-page': window.location.pathname.match(/ing/) }
                            )}>
                                <div className="ra-list-mission-img-container">
                                    <img
                                        src={require('../../assets/img/mission-img.png')}
                                        className="ra-list-mission-img"
                                    />
                                </div>
                                <div className="ra-list-mission-body">
                                    <div className="ra-list-mission-body-title">
                                        {mapMissionType(mission.type)}
                                    </div>
                                    <div className="ra-list-mission-body-bottom">
                                        <div className="ra-list-mission-body-tag" style={window.location.pathname.match(
                                            /ing/,
                                        ) && { marginTop: 0 }}>
                                            무기한
                                        </div>
                                        <div className="ra-list-mission-body-bottom-right">
                                            <div>기한 미정</div>
                                            {window.location.pathname.match(
                                                /ing/,
                                            ) &&
                                                researchAssistant.profile
                                                    .isSettlementIncluded && (
                                                    <div>
                                                        / 원리 2,000P ~
                                                        <div>
                                                            과제 4,000P ~
                                                            과목에 따른 인센티브
                                                        </div>
                                                    </div>
                                                )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="ra-list-mission-tail">
                                {window.location.pathname.match(/ing/) ? (
                                    <div className="ra-list-mission-tail-ing">
                                        <div className="ra-list-mission-tail-content">
                                            작업
                                            <span>
                                                {
                                                    researchAssistant.profile
                                                        .count.studySubject
                                                }
                                            </span>{' '}
                                            건
                                        </div>
                                        <div className="ra-list-mission-tail-content main-color">
                                            승인
                                            <span>
                                                {
                                                    researchAssistant.profile
                                                        .count.accepted
                                                }
                                            </span>{' '}
                                            건
                                        </div>
                                    </div>
                                ) : (
                                    <div className="ra-list-mission-tail-ing-container">
                                        <div className="ra-list-mission-tail-ing">
                                            <div className="ra-list-mission-tail-content">
                                                원리 <span>2,000P</span> ~
                                            </div>
                                            <div className="ra-list-mission-tail-content">
                                                과제 <span>4,000P</span> ~
                                            </div>

                                        </div>
                                        {
                                            submitted ?
                                                <div className="ra-list-mission-tail-btn-container disabled">
                                                    {
                                                        rejected ?
                                                            '인증 실패'
                                                            :
                                                            '심사 중입니다.'
                                                    }

                                                </div>
                                                :
                                                <div className="ra-list-mission-tail-btn-container" onClick={() => submitTest()}>
                                                    인증 신청
                                                </div>
                                        }
                                    </div>
                                )}
                            </div>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
};
