import React, { useContext } from 'react';
import { SearchConceptsModal, useModal } from 'Components/modals';
import { AppContext } from 'Contexts';
import { LatexEditor } from './LatexEditor';

export const BasicConceptForm = ({
    form,
    names = {
        id: 'id',
        name: 'name',
        nameInEnglish: 'nameInEnglish',
        description: 'description',
    },
    disabled = false,
    isSearchEnabled = true,
    showResetButton = true,
    example = false,
    handleExample = () => {},
}) => {
    const searchConceptsModal = useModal();
    const reset = () => {
        form.setValue(names.id, null);
        form.setValue(names.name, '');
        form.setValue(names.nameInEnglish, '');
        form.setValue(names.description, '');
    };
    disabled = disabled || (isSearchEnabled && form.watch(names.id));
    return (
        <>
            <div className="form-row mt-3">
                <div className="col-6">
                    <div className="d-inline-block justify-content-center align-items-center">
                        <h6 className="text-middle text-center mb-0">원리</h6>
                    </div>
                    <input type="hidden" name={names.id} ref={form.register} />
                    <input
                        type="text"
                        disabled={disabled}
                        name={names.name}
                        ref={form.register}
                        className="ml-3 d-inline-block w-50 rounded-0 cocept-input"
                    />
                    {isSearchEnabled && (
                        <>
                            <button
                                id="search-concepts-btn search"
                                type="button"
                                className="w-20 btn btn-main-color"
                                onClick={searchConceptsModal.open}
                            >
                                검색
                            </button>
                            {showResetButton && (
                                <button
                                    id="search-concepts-btn init"
                                    type="button"
                                    className="w-20 btn btn-warning ml-1"
                                    onClick={reset}
                                >
                                    초기화
                                </button>
                            )}
                        </>
                    )}
                </div>
                <div className="col-6">
                    <div className="w-20 d-inline-block justify-content-center align-items-center">
                        <h6 className="text-middle text-center mb-0">
                            영문명(원어)
                        </h6>
                    </div>
                    <input
                        type="text"
                        name={names.nameInEnglish}
                        disabled={disabled}
                        className="d-inline-block w-60 cocept-input rounded-0"
                        ref={form.register}
                    />
                </div>
                <SearchConceptsModal
                    form={form}
                    names={names}
                    {...searchConceptsModal}
                />
            </div>
            <div className="form-line mt-4">
                <div className="form-group w-100">
                    <div className="row justify-content-between align-items-center mb-2">
                        <div>
                            <h6 className="mr-2 d-inline-block">설명</h6>
                            <span className="light-font">
                                (공백 포함 300-350 글자)
                            </span>
                        </div>
                        {example && (
                            <div
                                className="study-subject-ex-btn"
                                onClick={() => handleExample(1)}
                            >
                                예시 확인
                            </div>
                        )}
                    </div>
                    <div className="position-relative">
                        <LatexEditor
                            className="concept-description d-block w-100 p-4 rounded-0 border-light-color"
                            maxLength="350"
                            placeholder={'~ 원리(이)란?'}
                            name={names.description}
                            disabled={disabled}
                            formRef={form}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};
