import React, { useState, useEffect } from 'react';

import { SchoolSubjectUnitTable } from 'Components/admin/table';

export const AdminSchoolSubjectUnitListView = props => {
    return (
        <div id="school-subject-unit-view">
            <SchoolSubjectUnitTable />
        </div>
    );
};
