import React from 'react';

import { Modal, Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import { useAPI, APIRoute } from 'Client';

export const AdminSchoolSubjectCreatePatchDeleteModal = ({
    schoolSubject,
    isOpen = false,
    onSuccess = () => {},
    open = () => {},
    close = () => {},
}) => {
    const form = useForm({
        defaultValues: {
            ...schoolSubject,
        },
    });

    const deleteSchoolSubjectAPI = useAPI(APIRoute.Admin.SchoolSubject.Delete, {
        callbacks: {
            onSuccess: res => {
                alert('과목을 삭제하였습니다.');
                onSuccess(res);
                close();
            },
        },
    });

    const patchSchoolSubjectAPI = useAPI(APIRoute.Admin.SchoolSubject.Patch, {
        callbacks: {
            onSuccess: res => {
                alert('과목을 수정하였습니다.');
                onSuccess(res);
                close();
            },
        },
    });

    const createSchoolSubjectAPI = useAPI(APIRoute.Admin.SchoolSubject.Create, {
        callbacks: {
            onSuccess: res => {
                alert('과목을 생성하였습니다.');
                onSuccess(res);
                close();
            },
        },
    });

    const handleDeleteScholSubjectClick = () => {
        if (
            !confirm(
                '이 과목과 연결된 단원 데이터가 모두 사라집니다.\n이 과목을 삭제하시겠습니까?',
            )
        ) {
            return;
        }

        deleteSchoolSubjectAPI.send(null, {
            additionalPath: schoolSubject.id,
        });
    };

    const handlePatchScholSubjectClick = () => {
        if (!confirm('이 과목을 수정하시겠습니까?')) {
            return;
        }

        patchSchoolSubjectAPI.send(form.getValues(), {
            additionalPath: schoolSubject.id,
        });
    };

    const handleCreateScholSubjectClick = () => {
        if (!confirm('이 과목을 생성하시겠습니까?')) {
            return;
        }

        createSchoolSubjectAPI.send({...form.getValues(), relatedSchoolSubjects: []});
    };

    const onSubmit = () => {};

    return (
        <Modal
            id="admin-learning-element-modify-modal"
            className="modal-narrow"
            show={isOpen}
        >
            <Modal.Header className="d-block">
                <div className="row w-100">
                    <Button className="close" onClick={close}>
                        <span aria-hidden="true">&times;</span>
                    </Button>
                </div>
                <div className="row w-100 justify-content-center align-items-center">
                    <h4>과목 {schoolSubject ? '수정' : '생성'}</h4>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="container">
                    <Form onSubmit={form.handleSubmit(onSubmit)}>
                        <div className="form-row mt-3">
                            <h2>과목명</h2>
                        </div>
                        <div className="form-row mt-3 d-flex justify-content-end">
                            <div className="col-12">
                                <input
                                    name="name"
                                    defaultValue={
                                        schoolSubject && schoolSubject.name
                                    }
                                    className="password-modal-input input w-100 pt-3 pb-3 rounded-0"
                                    ref={form.register}
                                />
                            </div>
                        </div>
                        <div className="form-row mt-3">
                            <h2>분류</h2>
                        </div>
                        <div className="form-row mt-3 d-flex justify-content-end">
                            <div className="col-12">
                                <input
                                    name="category"
                                    defaultValue={
                                        schoolSubject && schoolSubject.category
                                    }
                                    className="password-modal-input input w-100 pt-3 pb-3 rounded-0"
                                    ref={form.register}
                                />
                            </div>
                        </div>
                        <div className="form-row mt-3">
                            <h2>보상</h2>
                        </div>
                        <div className="form-row mt-3 d-flex justify-content-end">
                            <div className="col-12">
                                <input
                                    name="reward"
                                    defaultValue={
                                        schoolSubject && schoolSubject.reward
                                    }
                                    className="password-modal-input input w-100 pt-3 pb-3 rounded-0"
                                    ref={form.register}
                                />
                            </div>
                        </div>
                        <div className="row mt-5 d-flex justify-content-end">
                            <button
                                onClick={() => form.reset()}
                                className="btn btn-danger ml-3"
                            >
                                초기화
                            </button>
                            <button
                                onClick={handleDeleteScholSubjectClick}
                                className="btn btn-danger ml-3"
                            >
                                이 과목 삭제하기
                            </button>
                            <button
                                onClick={() => {
                                    schoolSubject
                                        ? handlePatchScholSubjectClick()
                                        : handleCreateScholSubjectClick();
                                }}
                                className="ml-3 btn btn-primary"
                            >
                                {schoolSubject ? '수정' : '생성'}하기
                            </button>
                        </div>
                    </Form>
                </div>
            </Modal.Body>
        </Modal>
    );
};
