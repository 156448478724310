import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import moment from 'Moment';

import { APIRoute } from 'Client';
import {
    ResearchAssistantProfileModal,
    PointsWithdrawalModal,
    ResearchAssistantNoticeListModal,
    useModal,
} from 'Components/modals';

import { RemoteDataTable, useRemoteDataTable } from 'Components/table/base';

const formatResult = results => {
    return (results = results.map(result => {
        return {
            ...result,
            created: moment(result.created).format('YY-MM-DD'),
            tooltip: [
                {
                    key: 'name',
                    content:
                        (result.majorName && result.majorName.join(', ')) ||
                        '-',
                },
            ],
            conceptCount:
                result.test === 'REJECTED'
                    ? '-'
                    : result.expulsionExpireAt
                    ? '제명'
                    : result.count.concept,
            studySubjectCount:
                result.test === 'REJECTED'
                    ? '-'
                    : result.expulsionExpireAt
                    ? '제명'
                    : result.count.studySubject,
            mapRate:
                result.test === 'REJECTED'
                    ? '-'
                    : result.expulsionExpireAt
                    ? '-'
                    : result.count.concept
                    ? (
                          result.count.studySubject / result.count.concept
                      ).toFixed(2)
                    : '-',
        };
    }));
};

const tableProperty = [
    {
        key: 'id',
        name: '번호',
    },
    {
        key: 'name',
        name: '이름',
    },
    {
        key: 'username',
        name: '아이디',
    },
    {
        key: 'conceptCount',
        name: '이론개수',
    },
    {
        key: 'studySubjectCount',
        name: '주제개수',
    },
    {
        key: 'mapRate',
        name: '주제 / 이론',
    },
    {
        key: 'created',
        name: '가입일자',
    },
];

export const ResearchAssistantTable = props => {
    const researchAssistantProfileModal = useModal();
    const pointsWithdrawalModal = useModal();
    const researchAssistantNoticeListModal = useModal();

    const [researchAssistantProfile, setResearchAssistantProfile] = useState();

    const apiRoute = APIRoute.Admin.ResearchAssistant.List;
    const formatData = data => {
        return formatResult(data);
    };

    const { form, reload, ...remoteDataTable } = useRemoteDataTable({
        apiRoute,
        formatData,
        defaultValues: {
            order: 'created',
        },
    });

    const clickHandler = (columnData, researchAssistantProfile) => {
        setResearchAssistantProfile(researchAssistantProfile);
        researchAssistantProfileModal.open();
    };

    tableProperty.forEach(e => {
        e.clickHandler = clickHandler;
    });

    return (
        <>
            <Form>
                <div className="row p-3">
                    <div className="col-3 form-check d-flex align-items-center">
                        <input
                            ref={form.register}
                            type="radio"
                            name="order"
                            value="created"
                            className="position-relative mr-2 form-check-input mt-0"
                        />
                        <label className="form-check-label">가입 일자 순</label>
                    </div>
                    <div className="col-3 form-check d-flex align-items-center">
                        <input
                            ref={form.register}
                            type="radio"
                            name="order"
                            value="mission_completed"
                            className="position-relative mr-2 form-check-input mt-0"
                        />
                        <label className="form-check-label">
                            작업량 많은 순
                        </label>
                    </div>
                    <div className="col-3 form-check d-flex align-items-center">
                        <input
                            ref={form.register}
                            type="radio"
                            name="order"
                            value="mission_completed_rev"
                            className="position-relative mr-2 form-check-input mt-0"
                        />
                        <label className="form-check-label">
                            작업량 적은 순
                        </label>
                    </div>
                    <div className="col-3 form-check d-flex align-items-center">
                        <input
                            ref={form.register}
                            type="radio"
                            name="order"
                            value="name_kr"
                            className="position-relative mr-2 form-check-input mt-0"
                        />
                        <label className="form-check-label">가나다순</label>
                    </div>
                </div>
                <div className="row justify-content-end">
                    <div className="input-group col-4 p-0">
                        <input
                            name="username"
                            ref={form.register}
                            type="text"
                            className="form-control border-right-0 h-100 rounded-0"
                        />
                        <div className="input-group-append rounded-0">
                            <button type="button" onClick={reload}>
                                <svg
                                    width="1rem"
                                    height="1rem"
                                    viewBox="0 0 16 16"
                                    className="bi bi-search"
                                    fill="currentColor"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"
                                    />
                                    <path
                                        fillRule="evenodd"
                                        d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </Form>
            <div className="col-12 mt-5">
                <RemoteDataTable
                    tableProperty={tableProperty}
                    {...remoteDataTable}
                />
            </div>
            <ResearchAssistantProfileModal
                researchAssistantProfile={researchAssistantProfile}
                onClickButton={() => pointsWithdrawalModal.open()}
                onClickNotice={() => researchAssistantNoticeListModal.open()}
                {...researchAssistantProfileModal}
            />
            <PointsWithdrawalModal
                researchAssistantProfile={researchAssistantProfile}
                {...pointsWithdrawalModal}
            />
            <ResearchAssistantNoticeListModal
                researchAssistantProfile={researchAssistantProfile}
                {...researchAssistantNoticeListModal}
            />
        </>
    );
};
