import React, { useState, useEffect, useContext } from 'react';

import { useForm, useFieldArray } from 'react-hook-form';
import { Container, Row, Col, Modal, Form, Button } from 'react-bootstrap';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { useAPI, APIRoute } from 'Client';
import { formatMoney } from 'Utils';

import Moment from 'react-moment';
import moment from 'moment';
import { ko } from 'date-fns/esm/locale';
import DatePicker from 'react-datepicker';
import { Table } from 'react-bootstrap';
import Select from 'react-select';

import { BaseTable, Pagination, usePageBlock } from '../table/base';
import { AppContext } from 'Contexts/AppContext';

const columnNames = [
    {
        key: 'id',
        name: '고유번호',
    },
    {
        key: 'concept',
        name: '이론명',
    },
    {
        key: 'created',
        name: '작성일자',
    },
    {
        key: 'inspectionStatus',
        name: '상태',
    },
];

const LIMIT_PER_PAGE = 10;
const BLOCK_SIZE = 10;

export const RAReportTable = props => {
    const history = useHistory();

    const { researchAssistant } = useContext(AppContext);

    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [status, setStatus] = useState('PENDING');
    const [filter, setFilter] = useState('');

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const [tableData, setTableData] = useState([]);

    const { pageBlock, pageParams, setTotal, before, next } = usePageBlock({
        blockSize: BLOCK_SIZE,
        limitPerPage: LIMIT_PER_PAGE,
        initialTotal: (data && data.total) || 0,
        page,
    });

    const missionReportListAPI = useAPI(
        APIRoute.ResearchAssistant.MissionReport.List,
        {
            callbacks: {
                onSuccess: res => {
                    res = res.map(result => {
                        result.inspectionStatus = result.submitted
                            ? result.inspectionStatus === 'REJECTED'
                                ? '반려'
                                : '-'
                            : '임시저장';
                        return result;
                    });
                    setTableData(res);
                },
            },
        },
    );

    const gotoMissionReportDetail = missionReport => {
        const { submitted, inspectionStatus } = missionReport;

        let url;
        url =
            inspectionStatus || submitted
                ? `/study-subjects/${missionReport.id}/`
                : `/study-subjects/${missionReport.id}/patch/`;

        history.push({
            pathname: url,
            state: { entity: missionReport },
        });
    };

    useEffect(() => {
        missionReportListAPI.send();
    }, []);

    return (
        <div className="ra-mission-container">
            <div className="ra-mission-title">Mission</div>
            <div className="ra-mission-category">
                진로 탐색을 위한 탐구과제 생성
            </div>
            <div className="ra-mission-status-container">
                <div className="ra-mission-status-tr">
                    <div className="ra-mission-status-title">총 작업</div>
                    <div className="ra-mission-status-content normal">
                        <span>
                            {researchAssistant.profile.count.studySubject}{' '}
                        </span>
                        건
                        <div className="help-tip-home">
                            <p className="short-p">
                                총 제출한 미션레포트 개수(재제출 미션레포트
                                포함)
                            </p>
                        </div>
                    </div>
                </div>
                <div className="ra-mission-status-tr">
                    <div className="ra-mission-status-title">승인</div>
                    <div className="ra-mission-status-content main-color">
                        <span>{researchAssistant.profile.count.accepted} </span>
                        건
                        <div className="help-tip-home">
                            <p>
                                총 승인된 미션레포트 개수(재제출 미션레포트
                                포함, 탐구과제 기준)
                            </p>
                        </div>
                    </div>
                </div>
                <div className="ra-mission-status-tr">
                    <div className="ra-mission-status-title">반려</div>
                    <div className="ra-mission-status-content danger-color">
                        <span>{researchAssistant.profile.count.rejected} </span>
                        건
                        <div className="help-tip-home">
                            <p>
                                총 반려된 미션레포트 개수(재제출 여부와 무관한
                                반려 횟수)
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-4 p-0">
                <Button
                    onClick={() => {
                        history.push('/study-subjects/create/');
                    }}
                    className="w-100 pt-3 pb-3 btn-main-color"
                >
                    새로운 미션 작성하기
                </Button>
            </div>
            <div className="ra-mission-tabel-title-container">
                <div className="ra-mission-tabel-title">미션 리스트</div>
                <div className="ra-mission-tabel-select-container">
                    <Select
                        options={[
                            {
                                value: 'REJECTED',
                                label: '반려',
                            },
                            {
                                value: 'NOT_SUBMITTED',
                                label: '임시저장',
                            },
                        ]}
                        placeholder="전체"
                        onChange={v => setFilter(v)}
                    />
                </div>
            </div>
            <div className="col-12 mt-3 p-0">
                <BaseTable
                    columnNames={columnNames}
                    data={tableData}
                    onClick={gotoMissionReportDetail}
                />
                <Pagination
                    before={before}
                    next={next}
                    pageBlock={pageBlock}
                    page={page}
                    setPage={setPage}
                />
            </div>
        </div>
    );
};
