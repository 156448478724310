import React, { useState, useEffect } from 'react';

import { useForm, useFieldArray } from 'react-hook-form';

import { useAPI, APIRoute } from 'Client';
import { formatMoney } from 'Utils';

import { BaseTable, Pagination, usePageBlock } from 'Components/table/base';

const columnNames = [
    {
        key: 'id',
        name: '번호',
    },
    {
        key: 'name',
        name: '이름',
    },
    {
        key: 'username',
        name: '아이디',
    },
    {
        key: 'points',
        name: '포인트',
    },
    {
        key: 'created',
        name: '마지막 정산일자',
    },
];

const LIMIT_PER_PAGE = 10;
const BLOCK_SIZE = 10;

export const PointsWithdrawalTable = props => {
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [status, setStatus] = useState('PENDING');

    const { pageBlock, pageParams, setTotal, before, next } = usePageBlock({
        blockSize: BLOCK_SIZE,
        limitPerPage: LIMIT_PER_PAGE,
        initialTotal: (data && data.total) || 0,
        page,
    });

    const getDefaultSelected = () =>
        [...Array(10).keys()].map(() => ({
            value: false,
        }));
    const form = useForm({
        defaultValues: {
            selected: getDefaultSelected(),
        },
    });
    const selected = useFieldArray({
        control: form.control,
        name: 'selected',
    });

    const pointsLogListAPI = useAPI(APIRoute.Admin.PointLog.List, {
        callbacks: {
            onSuccess: ({ results, count }) => {
                setData(
                    results.map((result, index) => {
                        result.points = formatMoney(result.points);
                        return result;
                    }),
                );
                setTotal(count);
            },
        },
    });

    const pointsWithdrawalListAPI = useAPI(
        APIRoute.Admin.PointsWithdrawal.List,
        {
            callbacks: {
                onSuccess: ({ results, count }) => {
                    setData(
                        results.map((result, index) => {
                            result.created = result.createdTime;
                            result.points = formatMoney(result.pointAmount);
                            return result;
                        }),
                    );
                    setTotal(count);
                },
            },
        },
    );
    const pointsWithdrawalsSettleAPI = useAPI(
        APIRoute.Admin.PointsWithdrawal.Settle,
        {
            callbacks: {
                onSuccess: () => alert('성공'),
            },
        },
    );

    form.handleSubmit = form.handleSubmit(async data => {
        try {
            if (status === 'PENDING') {
                await pointsLogListAPI.send({
                    ...data,
                    ...pageParams,
                    status,
                });
            } else {
                await pointsWithdrawalListAPI.send({
                    ...data,
                    ...pageParams,
                    status,
                });
            }
        } catch (e) {
            console.error(e);
        }
    });

    useEffect(() => {
        form.reset({
            ...form.watch(),
            selected: getDefaultSelected(),
        });
        form.handleSubmit(form.getValues());
    }, [page, status]);

    const settleSelectedPointsWithdrawals = () => {
        const selectedPointsWithdrawalIds = form
            .watch('selected')
            .map((row, index) => (row.value ? data[index].id : null))
            .filter(id => id);
        pointsWithdrawalsSettleAPI.send({
            pointsWithdrawals: selectedPointsWithdrawalIds,
        });
    };

    return (
        <>
            <div className="col-12 mt-5">
                <BaseTable columnNames={columnNames} data={data} />
                <Pagination
                    before={before}
                    next={next}
                    pageBlock={pageBlock}
                    page={page}
                    setPage={setPage}
                />
            </div>

            {status === 'PENDING' ? (
                <div className="mt-5 row justify-content-center">
                    <div className="col-8 d-flex justify-content-center">
                        <a href="https://lean-ai.com/api/admin/points-withdrawals/excel/">
                            <button
                                type="button"
                                className="btn btn-lg btn-main-color"
                            >
                                정산 파일
                            </button>
                        </a>
                        <button
                            type="button"
                            onClick={settleSelectedPointsWithdrawals}
                            className="btn btn-lg btn-main-color"
                            style={{ marginLeft: 5 }}
                        >
                            정산하기
                        </button>
                    </div>
                    <div className="col-4 d-flex justify-content-end">
                        <button
                            onClick={() => setStatus('PROCESSED')}
                            className="btn btn-secondary"
                        >
                            완료 목록 조회
                        </button>
                    </div>
                </div>
            ) : (
                <div className="mt-5 row justify-content-end">
                    <button
                        onClick={() => setStatus('PENDING')}
                        className="btn btn-secondary"
                    >
                        미정산 목록 조회
                    </button>
                </div>
            )}
        </>
    );
};
