import React, { useState, useEffect } from 'react';

import { Modal, Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import { useAPI, APIRoute } from 'Client';
import { useModal, SearchRelatedSchoolSubjectsModal } from 'Components/modals';

export const AdminSchoolSubjectUnitCreateModal = ({
    isOpen = false,
    onSuccess = () => {},
    open = () => {},
    close = () => {},
}) => {
    const [selectedSchoolSubject, setSelectedSchoolSubject] = useState();

    const searchRelatedSchoolSubjectModal = useModal();
    const form = useForm();

    const createSchoolSubjectUnitAPI = useAPI(
        APIRoute.Admin.SchoolSubjectUnit.Create,
        {
            callbacks: {
                onSuccess: res => {
                    alert('단원을 생성하였습니다.');
                    onSuccess(res);
                    close();
                },
            },
        },
    );

    const handleCreateScholSubjectUnitButtonClick = () => {
        if (!confirm('단원을 생성하시겠습니까?')) {
            return;
        }

        createSchoolSubjectUnitAPI.send({
            ...form.getValues(),
            schoolSubject: selectedSchoolSubject.id,
        });
    };

    useEffect(() => {
        setSelectedSchoolSubject();
    }, [isOpen]);

    return (
        <Modal
            id="admin-learning-element-modify-modal"
            className="modal-narrow"
            show={isOpen}
        >
            <Modal.Header className="d-block">
                <div className="row w-100">
                    <Button className="close" onClick={close}>
                        <span aria-hidden="true">&times;</span>
                    </Button>
                </div>
                <div className="row w-100 justify-content-center align-items-center">
                    <h4>단원 생성</h4>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="container">
                    <Form>
                        <div className="form-row mt-3">
                            <h2>상위 과목</h2>
                        </div>
                        <div className="form-row mt-3 d-flex justify-content-end">
                            <div>
                                {selectedSchoolSubject
                                    ? selectedSchoolSubject.name
                                    : '선택 필요'}
                            </div>

                            <button
                                type="button"
                                onClick={() =>
                                    searchRelatedSchoolSubjectModal.open()
                                }
                                className="btn btn-primary"
                            >
                                과목 선택
                            </button>
                            <SearchRelatedSchoolSubjectsModal
                                handleClick={setSelectedSchoolSubject}
                                {...searchRelatedSchoolSubjectModal}
                            />
                        </div>
                        <div className="form-row mt-3">
                            <h2>단원명</h2>
                        </div>
                        <div className="form-row mt-3 d-flex justify-content-end">
                            <div className="col-12">
                                <input
                                    name="name"
                                    className="password-modal-input input w-100 pt-3 pb-3 rounded-0"
                                    ref={form.register}
                                />
                            </div>
                        </div>
                        <div className="form-row mt-3">
                            <h2>단원 코드</h2>
                        </div>
                        <div className="form-row mt-3 d-flex justify-content-end">
                            <div className="col-12">
                                <input
                                    name="code"
                                    className="password-modal-input input w-100 pt-3 pb-3 rounded-0"
                                    ref={form.register}
                                />
                            </div>
                        </div>
                        <div className="row mt-5 d-flex justify-content-end">
                            <button
                                type="button"
                                onClick={() =>
                                    handleCreateScholSubjectUnitButtonClick()
                                }
                                className="ml-3 btn btn-primary"
                            >
                                생성하기
                            </button>
                        </div>
                    </Form>
                </div>
            </Modal.Body>
        </Modal>
    );
};
