import React, { useState, useEffect } from 'react';

import { SchoolSubjectTable } from 'Components/admin/table';

export const AdminSchoolSubjectListView = props => {
    return (
        <div id="school-subject-view">
            <div className="row">
                <SchoolSubjectTable />
            </div>
        </div>
    );
};
