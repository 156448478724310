import React, { useRef } from 'react';

import { useForm, useFieldArray } from 'react-hook-form';

import { TagFeedback } from 'Components/admin';

import { useHistory } from 'react-router-dom';

import moment from 'Moment';

const formatDate = date => {
    return moment(date).format('YYYY-MM-DD HH:mm');
};

export const MissionDongaReportView = ({ entity }) => {
    const form = useForm({
        defaultValues: {
            serialNumber: entity.dongaScience.serialNumber,
        },
    });

    const feedbacksForTags = useFieldArray({
        control: form.control,
        name: 'feedbacksForTags',
    });

    return (
        <>
            <form onSubmit={form.handleSubmit}>
                <input
                    className="input border rounded-0 h-100 w-100 p-3 border-dark"
                    defaultValue={`작성일자:${formatDate(entity.created)}`}
                    disabled
                />
                <input
                    className="input border rounded-0 h-100 w-100 p-3 border-dark"
                    defaultValue={entity.dongaScience.serialNumber}
                    disabled
                />
                <hr className="border-dark mt-4 thick" />
                <TagFeedback
                    form={form}
                    tags={entity.dongaScience.tags}
                    feedbacks={{ feedbacksForTags }}
                />
            </form>
        </>
    );
};
