import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { LatexDisplay } from 'Components';

import { useHistory } from 'react-router-dom';

export const AdminDirectlyManagedStudySubjectModal = ({
    studySubject,
    setStudySubject,
    isOpen = false,
    close,
}) => {
    const history = useHistory();
    const [conceptSelected, setConceptSelected] = useState(false)

    const handleCloseButtonClick = () => {
        setStudySubject();
        setConceptSelected(false)
        close();
    };

    const handleSelectButtonClick = () => {
        history.push(`/admin/main/study-subjects/${studySubject.id}/`);
    };

    return (
        <Modal
            id="search-concepts-modal"
            className="modal-narrow"
            show={isOpen}
            onHide={handleCloseButtonClick}
        >
            {studySubject && (
                <>
                    <Modal.Header closeButton>
                        <Modal.Title>{studySubject.concept.name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="container">
                            {
                                !conceptSelected ?
                                    <div className="row">
                                        <div className="w-100 justify-content-center mb-4">
                                            아래 원리를 활용한 <span className="bold-font">전공생이 제안하는 세특 과제</span>를 보고 싶다면 "과제 보기" 버튼을 클릭하세요.
                                        </div>
                                        <div className="mb-3">원리: {studySubject.concept.name}</div>
                                        <div className="p-4 border">
                                            <LatexDisplay latex={studySubject.concept.description} />
                                        </div>
                                        <div className="row w-100 mt-5">
                                            <div className="col-6">
                                                <button
                                                    type="button"
                                                    onClick={handleCloseButtonClick}
                                                    className="btn btn-secondary w-100 rounded-0"
                                                >
                                                    다시 선택
                                                </button>
                                            </div>
                                            <div className="col-6">
                                                <button
                                                    type="button"
                                                    onClick={() => setConceptSelected(true)}
                                                    className="btn btn-primary w-100 rounded-0"
                                                >
                                                    과제 보기
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className="row">
                                        <div className="w-100 justify-content-center mb-4">
                                            <span className="bold-font">전공생이 제안하는 세특 과제</span>에 대한 설명이에요.
                                            이 세특 과제로 발급을 희망하면 "선택하기" 버튼을 클릭하세요.
                                        </div>
                                        <div className="mb-3">과제</div>
                                        <div className="p-4 border">
                                            <LatexDisplay latex={studySubject.task} />
                                        </div>
                                        <div className="row w-100 mt-5">
                                            <div className="col-6">
                                                <button
                                                    type="button"
                                                    onClick={handleCloseButtonClick}
                                                    className="btn btn-secondary w-100 rounded-0"
                                                >
                                                    다시선택
                                                </button>
                                            </div>
                                            <div className="col-6">
                                                <button
                                                    type="button"
                                                    onClick={handleSelectButtonClick}
                                                    className="btn btn-primary w-100 rounded-0"
                                                >
                                                    선택하기
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                            }

                        </div>
                    </Modal.Body>
                </>
            )}
        </Modal>
    );
};
