import React, { useContext, useEffect } from 'react';

import {
    BrowserRouter as Router,
    withRouter,
    Switch,
    Route,
    Redirect,
    useLocation,
} from 'react-router-dom';

import * as Views from 'Views';
import { AppContext } from 'Contexts';

import { AuthenticatedOnlyRoute } from './AuthenticatedOnlyRoute';
import { EntityPrefetchRoute } from './EntityPrefetchRoute';

const StaffRoutesWithRouter = withRouter(router => {
    const { user } = useContext(AppContext);
    const isAuthenticated = !!user.id;

    return (
        <Switch>
            {/* concepts */}
            <AuthenticatedOnlyRoute
                exact
                routeComponent={EntityPrefetchRoute}
                component={Views.StaffConceptDetailView}
                isAuthenticated={isAuthenticated}
                path="/staff/main/concepts/:id/"
            />
            <AuthenticatedOnlyRoute
                exact
                component={Views.StaffConceptListView}
                isAuthenticated={isAuthenticated}
                path="/staff/main/concepts/"
            />
            {/* mission reports */}
            <AuthenticatedOnlyRoute
                routeComponent={EntityPrefetchRoute}
                component={Views.StaffMissionReportStudySubjectPatchView}
                isAuthenticated={isAuthenticated}
                path="/staff/main/mission-reports/:id/study-subject/"
            />
            <AuthenticatedOnlyRoute
                component={Views.StaffMissionReportListView}
                isAuthenticated={isAuthenticated}
                path="/staff/main/mission-reports/"
            />
            {/* study-subjects */}
            <AuthenticatedOnlyRoute
                routeComponent={EntityPrefetchRoute}
                component={Views.StaffStudySubjectDetailView}
                isAuthenticated={isAuthenticated}
                path="/staff/main/study-subjects/:id/"
            />
            <AuthenticatedOnlyRoute
                exact
                component={Views.StaffStudySubjectListView}
                isAuthenticated={isAuthenticated}
                path="/staff/main/study-subjects/"
            />

            <Route exact component={Views.StaffAuthView} path="/Staff/auth/" />
            <Redirect to="/staff/main/study-subjects/" />
        </Switch>
    );
});

export const StaffRoutes = props => {
    return <StaffRoutesWithRouter {...props} />;
};
