import React from 'react';
import { Form } from 'react-bootstrap';
import { useForm, useFieldArray } from 'react-hook-form';
import { useAPI, APIRoute } from 'Client';
import { getFieldArrayValues } from 'Utils';
import { useHistory } from 'react-router-dom';

export const ConceptDetailView = ({ entity }) => {
    const history = useHistory();

    const {
        id,
        inspectionStatus,
        concept,
        feedbacks = {},
        ...missionReport
    } = entity;

    feedbacks.name = feedbacks.name || '';
    feedbacks.description = feedbacks.description || '';
    feedbacks.relatedFields = feedbacks.relatedFields || '';

    const defaultArrayFields = {};
    Object.entries(entity).forEach(([key, value]) => {
        if (key.startsWith('related') && Array.isArray(value)) {
            defaultArrayFields[key] =
                value.length === 4
                    ? value.map(_ => ({ value: _ }))
                    : [...Array(4).keys()].map(() => ({ value: '' }));
        }
    });

    const form = useForm({
        defaultValues: {
            ...concept,
            ...defaultArrayFields,
        },
    });

    const watchDescription = form.watch('description');

    const relatedConcepts = useFieldArray({
        control: form.control,
        name: 'relatedConcepts',
    });
    const relatedConceptsInEnglish = useFieldArray({
        control: form.control,
        name: 'relatedConceptsInEnglish',
    });
    const relatedMajors = useFieldArray({
        control: form.control,
        name: 'relatedMajors',
    });
    const relatedSchoolSubjects = useFieldArray({
        control: form.control,
        name: 'relatedSchoolSubjects',
    });

    const handleResubmitButtonClick = () =>
        history.push({
            pathname: `/concepts/create/`,
            state: { entity, resubmit: true },
        });

    return (
        <Form>
            <div className="row mt-3 border-bottom pb-3">
                <div className="col-6">
                    <div className="row">
                        <div className="col-3 d-flex align-items-center">
                            <h5>원리</h5>
                        </div>
                        <div className="col-9">
                            <input
                                ref={form.register}
                                disabled
                                name="name"
                                defaultValue={concept.name}
                                className="input border rounded-0 h-100 w-100 p-3 border-dark"
                            />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-3 d-flex align-items-center">
                            <h5>영문명(원어)</h5>
                        </div>
                        <div className="col-9">
                            <input
                                ref={form.register}
                                disabled
                                name="nameInEnglish"
                                defaultValue={concept.nameInEnglish}
                                className="input border rounded-0 h-100 w-100 p-3 border-dark"
                            />
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="h-100 border border-dark">
                        <span className="text-muted d-flex w-100 h-100 justify-content-center align-items-center">
                            {feedbacks && feedbacks.name}
                        </span>
                    </div>
                </div>
            </div>
            <div className="row mt-4 border-bottom pb-3">
                <div className="col-12 mb-3">
                    <h4 className="d-inline-block">설명</h4>
                    <span className="ml-3">(공백 포함 350-350자)</span>
                </div>
                <div className="col-6">
                    <div className="position-relative h-100">
                        <textarea
                            ref={form.register}
                            disabled
                            name="description"
                            defaultValue={concept.description}
                            className="concept-description d-block h-100 w-100 p-3 rounded-0"
                        ></textarea>
                        <div className="position-absolute fixed-bottom w-100 d-flex justify-content-end p-3 text-muted">
                            <span>
                                {watchDescription && watchDescription.length}
                                /350
                            </span>
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="border border-dark feedbacks-box ">
                        <span className="text-muted d-flex w-100 h-100 justify-content-center align-items-center">
                            {feedbacks.description}
                        </span>
                    </div>
                </div>
            </div>
            <div className="row mt-4 mb-4 border-bottom pb-3">
                <div className="col-6">
                    <div className="row">
                        <div className="col-3">
                            <div className="row justify-content-center align-items-center h-50">
                                <h4>유사개념</h4>
                            </div>
                            <div className="row h-50"></div>
                        </div>
                        <div className="col-9">
                            <div className="row">
                                {relatedConcepts.fields.map(
                                    (relatedConcept, index) => (
                                        <div
                                            key={relatedConcept.id}
                                            className="col-6"
                                        >
                                            <input
                                                key={relatedConcept.id}
                                                disabled
                                                ref={form.register}
                                                name={`relatedConcepts[${index}].value`}
                                                defaultValue={
                                                    relatedConcept.value
                                                }
                                                className="form-control rounded-0 border-dark"
                                            />
                                        </div>
                                    ),
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-3">
                            <div className="row justify-content-center align-items-center h-50">
                                <h4>원어명</h4>
                            </div>
                            <div className="row h-50"></div>
                        </div>
                        <div className="col-9">
                            <div className="row">
                                {relatedConceptsInEnglish.fields.map(
                                    (relatedConceptInEnglish, index) => (
                                        <div
                                            key={relatedConceptInEnglish.id}
                                            className="col-6"
                                        >
                                            <input
                                                key={relatedConceptInEnglish.id}
                                                disabled
                                                ref={form.register}
                                                name={`relatedConceptsInEnglish[${index}].value`}
                                                defaultValue={
                                                    relatedConceptInEnglish.value
                                                }
                                                className="form-control rounded-0 border-dark"
                                            />
                                        </div>
                                    ),
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-3">
                            <div className="row justify-content-center align-items-center h-50">
                                <h4>긴밀학과</h4>
                            </div>
                            <div className="row h-50"></div>
                        </div>
                        <div className="col-9">
                            <div className="row">
                                {relatedMajors.fields.map(
                                    (relatedMajor, index) => (
                                        <div
                                            key={relatedMajor.id}
                                            className="col-6"
                                        >
                                            <input
                                                key={relatedMajor.id}
                                                disabled
                                                ref={form.register}
                                                name={`relatedMajors[${index}].value`}
                                                defaultValue={
                                                    relatedMajor.value
                                                }
                                                className="form-control rounded-0 border-dark"
                                            />
                                        </div>
                                    ),
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-3">
                            <div className="row justify-content-center align-items-center h-50">
                                <h4>긴밀과목</h4>
                            </div>
                            <div className="row h-50"></div>
                        </div>
                        <div className="col-9">
                            <div className="row">
                                {relatedSchoolSubjects.fields.map(
                                    (relatedSchoolSubject, index) => (
                                        <div
                                            key={relatedSchoolSubject.id}
                                            className="col-6"
                                        >
                                            <input
                                                key={relatedSchoolSubject.id}
                                                disabled
                                                ref={form.register}
                                                name={`relatedSchoolSubjects[${index}].value`}
                                                defaultValue={
                                                    relatedSchoolSubject.value
                                                }
                                                className="form-control rounded-0 border-dark"
                                            />
                                        </div>
                                    ),
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="border border-dark h-100">
                        <span className="text-muted d-flex w-100 h-100 justify-content-center align-items-center">
                            {feedbacks.relatedFields}
                        </span>
                    </div>
                </div>
            </div>
            {/* <div className="row mt-3 pb-3 mb-5">
                <div className="offset-4 col-3 justify-content-center align-items-center d-flex">
                    <button
                        onClick={handleResubmitButtonClick}
                        type="button"
                        className="btn btn-primary btn-lg rounded-0 btn-block"
                    >
                        다시 제출하기
                    </button>
                </div>
            </div> */}
        </Form>
    );
};
