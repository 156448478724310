import React, { useState, useEffect, useContext } from 'react';

import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import { useAPI, APIRoute } from 'Client';
import { StudySubjectTable } from 'Components/table';
import { AppContext } from 'Contexts';

import { MajorCurriculumDisplay, StudySubjectOrderDisplay } from 'Components';

export const AdminStudySubjectOrderDetailView = props => {
    const history = useHistory();
    const params = useParams();
    const { id } = params;

    const {
        dispatchers,
        getters: { getMajorCategory, getSchoolSubject, getSchoolSubjectUnit },
    } = useContext(AppContext);

    const [order, setOrder] = useState(null);

    const [majorCategoryId, setMajorCategoryId] = useState(null);
    const [schoolSubjectId, setSchoolSubjectId] = useState(null);
    const [major, setMajor] = useState(null);

    const orderRetrieveAPI = useAPI(APIRoute.Admin.StudySubjectOrder.Retrieve, {
        callbacks: {
            onSuccess: order => {
                if (!order) {
                    return;
                }
                const {
                    data: {
                        desiredMajor,
                    },
                } = order;
                setMajor(desiredMajor);
                setOrder(order);
            },
        },
    });

    const handleRowClick = (_, studySubject, formValues) => {
        history.push({
            pathname: `/admin/main/issued-study-subjects/inspect/${studySubject.id}/`,
            state: {
                studySubjectOrder: order,
            },
        });
    };

    useEffect(() => {
        orderRetrieveAPI.send(
            { isForDetail: true },
            {
                additionalPath: id,
            },
        );

        return () => {
            orderRetrieveAPI.cancel();
        };
    }, [id]);

    useEffect(() => {
        if (!order) {
            return;
        }

        const {
            desiredMajor: { categories },
            schoolSubject,
        } = order;
        const [majorCategoryId] = categories;
        setMajorCategoryId(majorCategoryId);
        setSchoolSubjectId(schoolSubject.id);
    }, [order]);

    return (
        <>
            <div
                className="admin-back-btn cursor-pointer mt-3 ml-3"
                onClick={() => history.goBack()}
            >
                &#xE000;&nbsp; 뒤로가기
            </div>
            {order && (
                <>
                    <StudySubjectOrderDisplay studySubjectOrder={order} />
                    {major && (
                        <MajorCurriculumDisplay
                            major={major}
                        />
                    )}
                    <div>
                        {majorCategoryId && schoolSubjectId && (
                            <StudySubjectTable
                                majorCategory={majorCategoryId}
                                schoolSubject={schoolSubjectId}
                                school={order && order.school}
                                clickHandler={handleRowClick}
                            />
                        )}
                    </div>
                </>
            )}
            <div className="form-row mt-3 mb-5">
                <div className="offset-4 col-3 justify-content-center align-items-center d-flex">
                    <button type="button" className="btn btn-danger btn-block">
                        삭제하기
                    </button>
                </div>
            </div>
        </>
    );
};
