import React, {
    useEffect,
    useLayoutEffect,
    useState,
    useCallback,
} from 'react';

import _ from 'lodash';

import { useForm } from 'react-hook-form';
import { useLocation, useHistory } from 'react-router-dom';
import { useAPI } from 'Client';
import { getParamsFromQuery, getQueryFromParams } from 'Utils';

const LIMIT_PER_PAGE = 10;
const BLOCK_SIZE = 10;
const DEFAULT_PAGE = 1;

export const usePageBlock = ({
    blockSize,
    limitPerPage,
    page,
    initialTotal,
}) => {
    const [pageBlock, setPageBlock] = useState([]);

    const [before, setBefore] = useState();
    const [next, setNext] = useState();
    const [lastPage, setLastPage] = useState();

    const [total, setTotal] = useState(initialTotal);
    const pageParams = {
        limit: limitPerPage,
        offset: (page - 1) * limitPerPage,
    };

    const getPageBlock = () => {
        let next;
        let before;

        const moduled =
            page % blockSize === 0
                ? Math.floor(page / blockSize)
                : Math.floor(page / blockSize) + 1;

        const startPageNumber = (moduled - 1) * blockSize + 1;
        before = startPageNumber - 1 !== 0 ? startPageNumber - 1 : null;

        const block = [];
        for (
            let currentPage = startPageNumber;
            currentPage < startPageNumber + blockSize;
            currentPage++
        ) {
            const accumulatedPostNumber = currentPage * limitPerPage;
            if (accumulatedPostNumber - total >= limitPerPage) {
                next = null;
                break;
            }

            block.push(currentPage);

            next = currentPage + 1;
            if (next * limitPerPage >= total + limitPerPage) {
                next = null;
            }
        }

        if (block.length === 0) {
            block.push(1);
            before = null;
            next = null;
        }

        setNext(next);
        setBefore(before);
        setPageBlock(block);
    };

    useEffect(() => {
        getPageBlock();
    }, []);

    useEffect(() => {
        getPageBlock();
    }, [blockSize, limitPerPage, page, total]);

    return { pageBlock, pageParams, setTotal, before, next };
};

export const useRemoteDataTable = ({
    apiRoute,
    formatData = e => e,
    defaultValues,
    blockSize = BLOCK_SIZE,
    limitPerPage = LIMIT_PER_PAGE,
}) => {
    const history = useHistory();
    const location = useLocation();

    const form = useForm({
        defaultValues: {
            ...defaultValues,
            ...getParamsFromQuery(location.search),
        },
    });
    const watchFields = form.watch();
    const [lastFormData, setLastFormData] = useState(watchFields);
    const [locationKeys, setLocationKeys] = useState([]);

    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState();
    const [lastPage, setLastPage] = useState();
    const [data, setData] = useState([]);
    const [lastSentData, setLastSentData] = useState({});

    const { pageBlock, pageParams, setTotal, before, next } = usePageBlock({
        blockSize,
        limitPerPage,
        initialTotal: (data && data.total) || 0,
        page,
    });

    const getDefaultPageFromQueryParams = query => {
        let { page: defaultPage, limit, offset } = getParamsFromQuery(query);

        if (!defaultPage && !Number.isNaN(+offset) && !Number.isNaN(+limit)) {
            defaultPage = +offset / (+limit || limitPerPage) + 1;
        } else {
            defaultPage = 1;
        }
        return defaultPage;
    };

    const api = useAPI(apiRoute, {
        callbacks: {
            onSuccess: ({ results, count }) => {
                setData(formatData(results));
                setTotal(count);
                setLastPage(parseInt(count / limitPerPage + 1));
            },
        },
    });

    const init = async ({ data, callbacks = {}, options = {} }) => {
        if (_.isEqual(data, lastSentData)) {
            return;
        }

        setLoading(true);
        try {
            await api.send(data);
            if (typeof callbacks.onSuccess === 'function') {
                callbacks.onSuccess();
            }

            if (options.historyPush) {
                const f = _.isEmpty(getParamsFromQuery(location.search))
                    ? history.replace
                    : history.push;

                f({ search: getQueryFromParams(data) });
            }
        } catch (e) {
            console.error(e);
            if (typeof callbacks.onError === 'function') {
                callbacks.onError();
            }
        } finally {
            setLoading(false);
            setLastSentData(data);

            if (typeof callbacks.onFinally === 'function') {
                callbacks.onFinally();
            }
        }
    };

    const reload = useCallback(() => {
        const { page, offset, ...paramsToUpdate } = getParamsFromQuery(
            location.search,
        );

        const data = form.getValues();
        Object.entries(data).forEach(([k, v]) => {
            if (!v) {
                delete paramsToUpdate[k];
            }
        });

        setPage(1);
        init({
            data: {
                ...paramsToUpdate,
                ...data,
                offset: 0,
            },
            options: {
                historyPush: true,
            },
        });
    }, [watchFields]);

    useEffect(() => {
        setPage(getDefaultPageFromQueryParams(location.search));
        return () => {
            api.cancel();
        };
    }, []);

    useEffect(() => {
        if (!page) {
            return;
        }

        const paramsToUpdate = getParamsFromQuery(location.search);
        const params = {
            ...paramsToUpdate,
            ...form.getValues(),
            limit: limitPerPage,
            offset: (page - 1) * limitPerPage,
        };
        init({
            data: params,
            options: {
                historyPush: true,
            },
        });

        return () => {
            api.cancel();
        };
    }, [page]);

    useEffect(() => {
        return history.listen(location => {
            if (history.action === 'PUSH') {
                setLocationKeys([location.key]);
            }

            if (history.action === 'POP') {
                if (locationKeys[1] === location.key) {
                    setLocationKeys(([_, ...keys]) => keys);
                } else {
                    setLocationKeys(keys => [location.key, ...keys]);
                }

                init({
                    data: getParamsFromQuery(location.search),
                    options: {
                        historyPush: false,
                    },
                    callbacks: {
                        onFinally: () => {
                            setPage(
                                getDefaultPageFromQueryParams(location.search),
                            );
                        },
                    },
                });
            }
        });
    }, [locationKeys]);

    return {
        form,
        init,
        reload,
        loading,
        data,
        paginationData: {
            pageBlock,
            before,
            next,
            setPage,
            page,
            lastPage,
        },
    };
};
