import React, { useEffect, useRef, useState, useContext } from 'react';
import { Container, Row, Col, Modal, Form, Button } from 'react-bootstrap';
import { useForm, useFieldArray } from 'react-hook-form';

import { useHistory } from 'react-router-dom';
import { AppContext } from 'Contexts/AppContext';

import { useAPI, APIRoute } from 'Client';

const wrong_sentence = [
    '할머니께서 오래오래 장수하시기를 바란다.',
    '어제 개봉한 영화의 특징은 길이가 길지만 인상은 강하다.',
    '그의 전사 소식은 장군에게 여간한 슬픔이었다.',
    '새로 개발된 자동차는 유해 물질과 에너지 효율이 높다.',
    '한국은 한국 전쟁 이후 약 70년 동안 기술의 발달과 그에 따라 사회의 큰 변동을 겪었다.',
    '그는 사고 이후 친구 이름의 혼동이나 과거 사건을 잘 기억하지 못했다.',
    '그는 외투를 벗고 의자에 앉아 서류를 넣었다.',
];
const right_sentence = [
    '할머니께서 장수하시기를 바란다. / 할머니께서 오래오래 사시기를 바란다.',
    '어제 개봉한 영화의 특징은 길이가 길지만 인상은 강하다는 것이다.',
    '그의 전사 소식은 장군에게 여간한 슬픔이 아니었다.',
    '새로 개발된 자동차는 유해 물질 배출이 적고, 에너지 효율이 높다.',
    '한국은 한국 전쟁 이후 약 70년 동안, 기술이 발달하면서 사회의 큰 변동을 겪었다.',
    '그는 사고 이후 친구 이름을 혼동하거나 과거 사건을 잘 기억하지 못했다.',
    '그는 외투를 벗고 의자에 앉아서 가방에 서류를 넣었다.',
];

const mission_term = [
    '마타도어',
    '온택트',
    '공유경제',
    '상호문화주의',
    '에코프렌들리',
    'RNA',
    '인공신경망',
    '치환적분법',
    '헤스법칙',
    '고분자물질',
];

export const AdminTestReportDetailView = ({ location, ...props }) => {
    const acceptButtonRef = useRef();
    const rejectButtonRef = useRef();
    const history = useHistory();

    const [selectedTerm, setSelectedTerm] = useState(false);
    const [name, setName] = useState('');
    const [username, setUsername] = useState('');

    const form = useForm({
        // only for fields to watch
        defaultValues: {
            researchAssistanceSentence: [
                { value: '' },
                { value: '' },
                { value: '' },
                { value: '' },
                { value: '' },
                { value: '' },
                { value: '' },
            ],
            task: '',
            conceptDescription: '',
            relevantKeywords: [
                { value: '' },
                { value: '' },
                { value: '' },
                { value: '' },
            ],
        },
    });

    const relevantKeywords = useFieldArray({
        control: form.control,
        name: 'relevantKeywords',
    });
    const researchAssistanceSentence = useFieldArray({
        control: form.control,
        name: 'researchAssistanceSentence',
    });

    const testReportPatchAPI = useAPI(APIRoute.Admin.TestReport.Patch, {
        callbacks: {
            onSuccess: res => {
                alert('성공!');
            },
        },
    });

    const testReportGetAPI = useAPI(APIRoute.Admin.TestReport.List, {
        callbacks: {
            onSuccess: res => {
                form.reset({
                    selectedTerm: res.terms,
                    conceptDescription: res.summary,
                    task: res.task,
                    relevantKeywords: res.keywords,
                });
                setSelectedTerm(res.terms);
                setName(res.name);
                setUsername(res.username);
            },
        },
    });

    form.handleSubmit = form.handleSubmit((data, event) => {
        const isAccepted =
            event.nativeEvent.submitter === acceptButtonRef.current;

        testReportPatchAPI.send(
            { inspectionStatus: isAccepted ? 'ACCEPTED' : 'REJECTED' },
            {
                additionalPath: props.match.params.id,
            },
        );
    });

    useEffect(() => {
        testReportGetAPI.send(
            {},
            {
                additionalPath: props.match.params.id,
            },
        );
    }, []);

    return (
        <div className="study-subject-container mt-3">
            <div className="ra-mission-title">Mission</div>
            <div className="ra-mission-category">
                전공 탐색을 위한 탐구과제 생성
            </div>
            {name && (
                <div className="ra-mission-category">
                    {name}({username})
                </div>
            )}
            <hr />
            <Form onSubmit={form.handleSubmit} className="w-100">
                <section className="mt-4">
                    <h4>TEST 과제 작성</h4>
                    <h5>1. 원리 작성</h5>
                    <div>
                        아래의 용어 중 하나를 택하여 작성해 주세요.
                        (클릭해주세요)
                    </div>
                    <div className="mt-2 row mb-3">
                        <div className={`rounded btn btn-main-color`}>
                            {selectedTerm}
                        </div>
                    </div>
                    <div className="form-line">
                        <div className="form-group w-100">
                            <label>
                                <h6 className="mr-2 d-inline-block">요약</h6>
                                <span className="light-font">
                                    (공백 포함 300-350 글자)
                                </span>
                            </label>
                            <div className="position-relative">
                                <textarea
                                    maxLength="350"
                                    name="conceptDescription"
                                    ref={form.register}
                                    disabled
                                    className="concept-description d-block w-100 p-4 rounded-0 border-light-color"
                                ></textarea>
                                <div className="position-absolute fixed-bottom w-100 d-flex justify-content-end p-4 text-muted">
                                    {
                                        <span>
                                            {
                                                form.watch('conceptDescription')
                                                    .length
                                            }
                                            /350
                                        </span>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <h5>2. 탐구 과제 작성</h5>
                    <div>
                        위에서 작성한 요약과 관련되는 탐구과제를 제시해주세요.
                        (300-350자 분량)
                    </div>
                    <div className="form-line">
                        <div className="form-group w-100">
                            <label>
                                <h6 className="mr-2 d-inline-block">과제</h6>
                                <span className="light-font">
                                    (공백 포함 300-350 글자)
                                </span>
                            </label>
                            <div className="position-relative">
                                <textarea
                                    maxLength="350"
                                    name="task"
                                    ref={form.register}
                                    disabled
                                    className="concept-description d-block w-100 p-4 rounded-0 border-light-color"
                                ></textarea>
                                <div className="position-absolute fixed-bottom w-100 d-flex justify-content-end p-4 text-muted">
                                    {
                                        <span>
                                            {form.watch('task').length}
                                            /350
                                        </span>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <h5>3. 키워드 작성</h5>
                    <div>
                        위에서 작성한 탐구과제와 관련된 키워드를 달아주세요.
                    </div>
                    <div className="form-row mt-3">
                        <div className="col-12">
                            <div className="h-100 d-flex">
                                <h6 className="mb-2">관련 키워드</h6>
                            </div>
                        </div>
                        {relevantKeywords.fields.map(
                            (relevantKeywordField, index) => (
                                <div
                                    key={relevantKeywordField.id}
                                    className="col-3"
                                >
                                    <input
                                        key={relevantKeywordField.id}
                                        ref={form.register()}
                                        name={`relevantKeywords[${index}].value`}
                                        defaultValue={
                                            relevantKeywordField.value
                                        }
                                        disabled
                                        className="form-control rounded-0"
                                    />
                                </div>
                            ),
                        )}
                    </div>
                </section>
                <br />
                <br />
                <br />
                <div className="row mt-3 pb-3 mb-5">
                    <div className="offset-2 col-3 justify-content-center align-items-center d-flex">
                        <button
                            type="submit"
                            className="btn btn-danger btn-lg btn-block"
                            ref={rejectButtonRef}
                        >
                            반려하기
                        </button>
                    </div>
                    <div className="offset-1 col-3 justify-content-center align-items-center d-flex">
                        <button
                            type="submit"
                            className="btn btn-main-color btn-lg btn-block"
                            ref={acceptButtonRef}
                        >
                            승인하기
                        </button>
                    </div>
                </div>
            </Form>
        </div>
    );
};
