export const SelectOptions = {
    Concept: [
        {
            value: 'ALL',
            label: '전체',
        },
        {
            value: 'id',
            label: '번호',
        },
        {
            value: 'name',
            label: '이론명',
        },
        {
            value: 'name_in_english',
            label: '이론명(영문)',
        },
        {
            value: 'description',
            label: '이론 내용',
        },
    ],
    StudySubject: [
        {
            value: 'ALL',
            label: '전체',
        },
        {
            value: 'id',
            label: '번호',
        },
        {
            value: 'concept__name',
            label: '이론명',
        },
        {
            value: 'concept__description',
            label: '이론 내용',
        },
        {
            value: 'task',
            label: '과제 내용',
        },
        {
            value: 'keywords',
            label: '키워드',
        },
    ],
};
