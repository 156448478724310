import React from 'react';
import { NavLink } from 'react-router-dom';
import { Navbar, Nav, NavItem } from 'react-bootstrap';

export const NavLinks = props => {
    const { navData } = props;
    return (
        <div className="row mt-3">
            <Navbar className="w-100">
                <Nav className="nav nav-tabs nav-fill w-100">
                    <NavItem className="nav-item" href="/">
                        <NavLink
                            exact
                            activeClassName="active"
                            className="nav-link"
                            to={navData[0][0]}
                        >
                            {navData[0][1]}
                        </NavLink>
                    </NavItem>
                    {navData.slice(1).map(data => (
                        <NavItem>
                            <NavLink
                                exact
                                activeClassName="active"
                                className="nav-link"
                                to={data[0]}
                            >
                                {data[1]}
                            </NavLink>
                        </NavItem>
                    ))}
                </Nav>
            </Navbar>
        </div>
    );
};
