import React, { useEffect, useState } from 'react';

import { generateUuid } from 'Utils';

import { MajorSelector } from './MajorSelector';
import { TagList } from './TagList';
import { TagResultDisplay } from './TagResultDisplay';

const TagProcess = {
    MajorCategory: 'SetMajorCategory',
    SchoolSubject: 'SetSchoolSubject',
    Complete: 'Complete',
};

const getCurrentStep = step => {
    switch (step) {
        case TagProcess.MajorCategory:
            return '학과 계열 선택';
        case TagProcess.SchoolSubject:
            return '단원 및 학습 요소 선택';
        case TagProcess.Complete:
            return '완료';
    }
};

export const TagWizard = ({
    isLoaded,
    onComplete,
    setTags,
    tags,
    setFinished,
    setOnGoing,
    ...props
}) => {
    const [tagFlushed, setTagFlushed] = useState(false);
    const [currentStep, setCurrentStep] = useState(TagProcess.MajorCategory);
    const [majorCategories, setMajorCategories] = useState([]);

    const changeStep = to => {
        setCurrentStep(to);
    };

    const backwardToMajorCategorySelector = () => {
        changeStep(TagProcess.MajorCategory);
        setOnGoing(false);
        setFinished(false);
    };

    const forwardToSchoolSubjectSelector = majorCategories => {
        setMajorCategories(majorCategories);
    };

    const forwardToComplete = tags => {
        if (majorCategories.length < 2)
            alert('해당 소계열을 하나이상 선택해주세요!');
        else if (tags[0].learningElements.length == 0)
            alert('학습요소를 한개이상 선택해주세요!');
        else {
            setTags(
                majorCategories.map(major => {
                    let tag = { ...tags[0] };
                    tag.majorCategory = major;
                    tag.tagUuid = generateUuid();
                    return tag;
                }),
            );

            if (typeof onComplete === 'function') {
                onComplete(
                    [...majorCategories].map(major => {
                        tags[0].majorCategory = major;
                        return tags[0];
                    }),
                );
            }

            if (typeof setOnGoing === 'function') {
                setOnGoing(false);
            }

            if (typeof setFinished === 'function') {
                setFinished(true);
            }

            if (typeof setCurrentStep === 'function') {
                setCurrentStep(TagProcess.Complete);
            }

            // changeStep(TagProcess.Complete);
        }
    };

    useEffect(() => {
        if (isLoaded) {
            const majorCategories = tags.map(tag => tag.majorCategory);
            setMajorCategories(majorCategories);
            setCurrentStep(TagProcess.SchoolSubject);
        }
        if (tags) {
            setMajorCategories(tags.map(tag => tag.majorCategory));
        } else {
            setTags([
                {
                    tagUuid: generateUuid(),
                    // majorCategory,
                    schoolSubjects: [],
                    schoolSubjectUnits: [],
                    learningElements: [],
                },
            ]);
        }
    }, []);

    return (
        <>
            <div className="d-flex w-100 justify-content-start">
                <h4 className="bold-font">학과 계열 선택</h4>
            </div>
            <MajorSelector
                next={forwardToSchoolSubjectSelector}
                currentStep={currentStep}
                tags={tags}
            />
            <TagList
                tags={tags}
                setTags={setTags}
                back={backwardToMajorCategorySelector}
                next={forwardToComplete}
                currentStep={currentStep}
            />
        </>
    );
};
