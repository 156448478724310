import React, { useEffect, useState, useContext } from 'react';

import { AppContext } from 'Contexts';
import { useAPI, APIRoute } from 'Client';

import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';

export const TagAchievementsIssue = props => {
    const { close, learningElements } = props;
    const {
        admin,
        user,
        getters: { getSchoolSubjectUnit, getSchoolLearningElements },
        dispatchers,
    } = useContext(AppContext);

    const [achievements, setAchivements] = useState([]);
    const [selectedAchievements, setSelectedAchievements] = useState([]);

    const toggleAchievement = achievement => {
        setAchivements(
            achievements.map(e => {
                return {
                    ...e,
                    selected:
                        e.code === achievement.code ? !e.selected : e.selected,
                };
            }),
        );
    };

    const handleAllButtonClick = () => {
        setAchivements(achievements.map(e => ({ ...e, selected: true })));
    };

    useEffect(() => {
        if (!admin.selectedSchoolSubjectUnit) {
            return;
        }

        const selectedSchoolSubjectUnit = getSchoolSubjectUnit(
            admin.selectedSchoolSubjectUnit,
        );
        if (
            (selectedSchoolSubjectUnit === '예체능') |
            (selectedSchoolSubjectUnit === '실과') |
            (selectedSchoolSubjectUnit === '언어')
        ) {
            setAchivements([
                {
                    code: selectedSchoolSubjectUnit,
                    achievement: `${selectedSchoolSubjectUnit} 과목은 성취기준이 제공되지 않습니다.`,
                    selected: true,
                },
            ]);
        } else {
            const achievements = [];

            selectedSchoolSubjectUnit.learningElements.forEach(
                ({ name, achievementStandards }) => {
                    achievementStandards.forEach((a, index) => {
                        if (index > 0) {
                            return;
                        }

                        if (achievements.find(x => x.code === a.code)) {
                            return;
                        }

                        achievements.push({
                            ...a,
                            selected: learningElements.find(
                                e => e.name === name,
                            )
                                ? true
                                : false,
                        });
                    });
                },
            );

            setAchivements(achievements);
        }
    }, [admin.selectedSchoolSubjectUnit]);

    useEffect(() => {
        dispatchers.admin.setSelectedAchievements(
            achievements
                .filter(e => e.selected)
                .map(e => {
                    const a = { ...e };
                    delete a['selected'];
                    return a;
                }),
        );

        return () => {
            dispatchers.admin.setSelectedAchievements([]);
        };
    }, [achievements]);

    return (
        <>
            {
                <div>
                    {admin.selectedSchoolSubjectUnit && (
                        <>
                            {achievements.map((achievement, idx) => (
                                <React.Fragment key={idx}>
                                    <span
                                        onClick={() =>
                                            toggleAchievement(achievement)
                                        }
                                        style={{ color: '#0099d4' }}
                                    >
                                        {achievement.selected ? (
                                            <CircleCheckedFilled
                                                fontSize="small"
                                                className="cursor-pointer"
                                            />
                                        ) : (
                                            <CircleUnchecked
                                                fontSize="small"
                                                className="cursor-pointer"
                                            />
                                        )}
                                    </span>
                                    <p>
                                        [{achievement.code}]{' '}
                                        {achievement.description}
                                    </p>
                                </React.Fragment>
                            ))}
                            <hr />
                            <div>
                                <span
                                    onClick={() => handleAllButtonClick()}
                                    style={{ color: '#0099d4' }}
                                >
                                    {achievements.find(e => !e.selected) ? (
                                        <CircleUnchecked
                                            fontSize="small"
                                            className="cursor-pointer"
                                        />
                                    ) : (
                                        <CircleCheckedFilled
                                            fontSize="small"
                                            className="cursor-pointer"
                                        />
                                    )}
                                </span>
                                <p>전체선택</p>
                            </div>
                        </>
                    )}
                </div>
            }
        </>
    );
};
