import React, { useEffect, useContext } from 'react';

import { Route, Redirect, useLocation } from 'react-router-dom';

import { AppContext } from 'Contexts';
import { useAPI, APIRoute } from 'Client';

const mapPathToAPIRouteName = (userType, path) => {
    const { ResearchAssistant, Admin, Staff } = APIRoute;

    if (userType === 'RESEARCH_ASSISTANT') {
        if (path.match(/missions\/[0-9]?/)) {
            return ResearchAssistant.Mission.List;
        } else if (path.startsWith('/study-subjects/:id/')) {
            return ResearchAssistant.MissionReport.Retrieve;
        } else if (path.startsWith('/donga-sciences/:id/')) {
            return ResearchAssistant.DongaScience.Retrieve;
        }
    } else if (userType === 'STAFF') {
        if (path.startsWith('/staff/main/mission-reports/:id/')) {
            return Staff.MissionReport.Retrieve;
        } else if (path.startsWith('/staff/main/concepts/:id/')) {
            return Staff.Concept.Retrieve;
        } else if (path.match(/\/staff\/main\/study-subjects\/:id/)) {
            return Staff.StudySubject.Retrieve;
        }
    } else if (userType === 'ADMIN') {
        if (path.startsWith('/admin/main/mission-reports/:id/')) {
            return Admin.MissionReport.Retrieve;
        } else if (path.startsWith('/admin/main/concepts/:id/')) {
            return Admin.Concept.Retrieve;
        } else if (path.match(/\/admin\/main\/issued-study-subjects\/:id/)) {
            return Admin.IssuedStudySubject.Retrieve;
        } else if (path.match(/\/admin\/management\/test-reports\/:id/)) {
            return Admin.TestReport.Retrieve;
        } else if (path.match(/\/admin\/main\/study-subject-orders\/:id/)) {
            return Admin.StudySubject.Retrieve;
        } else if (path.match(/\/admin\/main\/study-subjects\/:id/)) {
            return Admin.StudySubject.Retrieve;
        } else if (path.match(/\/admin\/main\/study-subjects\/:id/)) {
            return Admin.StudySubject.Retrieve;
        } else if (
            path.match(/\/admin\/main\/issued-study-subjects\/inspect\/:id/)
        ) {
            return Admin.StudySubject.Retrieve;
        } else if (path.match(/\/admin\/donga-reports\/:id/)) {
            return Admin.DongaReport.Retrieve;
        } else if (
            path.match(
                /\/admin\/management\/base-data\/school-subject-units\/:id/,
            )
        ) {
            return Admin.SchoolSubjectUnit.Retrieve;
        } else {
            console.error('url not matched', path);
            return;
        }
    }
};

export const EntityPrefetchRoute = ({
    routeComponent: RouteComponent = Route,
    component: Component,
    render,
    path,
    ...outerProps
}) => {
    const {
        computedMatch: {
            params: { id = null },
        },
    } = outerProps;

    const {
        user: { type: userType },
    } = useContext(AppContext);
    const location = useLocation();
    const { result, error, send: sendAPI } = useAPI(
        mapPathToAPIRouteName(userType, path),
    );

    const entity = (location.state && location.state.entity) || result;
    const resubmit = location.state && location.state.resubmit;

    useEffect(() => {
        if (entity || !id) {
            return;
        }

        sendAPI(null, { additionalPath: id });
    }, []);

    return (
        <RouteComponent
            {...outerProps}
            render={routeProps =>
                entity ? (
                    render ? (
                        render({
                            ...routeProps,
                            ...outerProps,
                            entity,
                            resubmit,
                        })
                    ) : (
                        <Component
                            {...{
                                ...routeProps,
                                ...outerProps,
                                entity,
                                resubmit,
                            }}
                        />
                    )
                ) : error ? (
                    <Redirect
                        to={{
                            pathname: '/',
                            state: { error, from: routeProps.location },
                        }}
                    />
                ) : (
                    <div>loading ...</div>
                )
            }
        />
    );
};
