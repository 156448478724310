import React from 'react';
import { useHistory } from 'react-router-dom';
import { Form } from 'react-bootstrap';

import moment from 'Moment';
import { APIRoute } from 'Client';
import { RemoteDataTable, useRemoteDataTable } from 'Components/table/base';

const selectOptions = [
    {
        value: 'ALL',
        label: '전체',
    },
    {
        value: 'id',
        label: '번호',
    },
    {
        value: 'remote_order_id',
        label: '주문 번호',
    },
    {
        value: 'study_subject__concept__id',
        label: '이론 번호',
    },
    {
        value: 'study_subject__concept__name',
        label: '이론명',
    },
    {
        value: 'study_subject__concept__description',
        label: '이론 내용',
    },
    {
        value: 'study_subject__id',
        label: '발표 주제 번호',
    },
    {
        value: 'study_subject__task',
        label: '발표 주제 내용',
    },
    {
        value: 'study_subject__keywords',
        label: '발표 주제 키워드',
    },
];

const tableProperty = [
    {
        key: 'id',
        name: '번호',
    },
    {
        key: 'remoteOrderId',
        name: '주문 고유 번호',
    },
    {
        key: 'concept__id',
        name: '이론 번호',
    },
    {
        key: 'studySubjectPdfCount',
        name: '발급 PDF',
    },
    {
        key: 'dongaSciencePdfCount',
        name: '발급 동아 PDF',
    },
    {
        key: 'studySubject__id',
        name: '발표 주제 번호',
    },
    {
        key: 'createdAt',
        name: '발급일자',
    },
];

export const IssuedStudySubjectTable = props => {
    const history = useHistory();
    const apiRoute = APIRoute.Admin.IssuedStudySubject.List;

    const formatData = data => {
        return data.map(datum => {
            return {
                ...datum,
                concept__id: datum.studySubject.concept.id,
                studySubject__id: datum.studySubject.id,
                createdAt: moment(datum.createdAt).format('YYYY-MM-DD hh:mm'),
                tooltip: {
                    key: 'description',
                    content: datum.description,
                },
            };
        });
    };

    const { form, reload, ...remoteDataTable } = useRemoteDataTable({
        apiRoute,
        formatData,
    });

    const clickHandler = (columnData, row) => {
        history.push({
            pathname: `/admin/main/issued-study-subjects/${row.id}/`,
        });
    };

    tableProperty.forEach(e => {
        e.clickHandler = clickHandler;
    });

    return (
        <>
            <div className="row justify-content-center">
                <Form className="w-100">
                    <div className="admin-select-container">
                        <div className="ss-filter-box mt-3">
                            <div className="input-group h-100">
                                <select ref={form.register} name="search_field">
                                    {selectOptions.map(
                                        ({ value, label }, index) => (
                                            <option key={index} value={value}>
                                                {label}
                                            </option>
                                        ),
                                    )}
                                </select>
                                <input
                                    name="search_value"
                                    ref={form.register}
                                    onKeyPress={e => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                            reload();
                                        }
                                    }}
                                    type="text"
                                    className="form-control border-right-0 h-100 rounded-0"
                                />
                                <div className="input-group-append rounded-0">
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={reload}
                                    >
                                        검색
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
                <div className="col-12 mt-5">
                    <RemoteDataTable
                        tableProperty={tableProperty}
                        {...remoteDataTable}
                    />
                </div>
            </div>
        </>
    );
};
