import { useState, useEffect } from 'react';

import qs from 'qs';
import axios from 'axios';

import { BACKEND_API_SERVER_URL } from 'Constants/config';
import { keysToCamel, keysToSnake } from 'Utils';
import { raiseSwalError } from 'Helpers';

import _ from 'lodash';

// axios 인스턴스 생성
// https://xn--xy1bk56a.run/axios/guide/api.html#%EC%9D%B8%EC%8A%A4%ED%84%B4%EC%8A%A4-%EC%83%9D%EC%84%B1
export const apiClient = axios.create({
    baseURL: BACKEND_API_SERVER_URL,
    withCredentials: true,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
    transformRequest: [
        (data, headers) => {
            if (data instanceof FormData) {
                const copy = new FormData();
                for (let [key, value] of data.entries()) {
                    copy.append(_.snakeCase(key), value);
                }
                data = copy;
                headers['Content-Type'] = 'multipart/form-data';
            }
            return data;
        },

        (data, headers) => {
            if (headers['Content-Type'] === 'application/json') {
                data = keysToSnake(data);
                return JSON.stringify(data);
            }
            return data;
        },
    ],
    transformResponse: [
        data => {
            try {
                return keysToCamel(JSON.parse(data));
            } catch {
                return data;
            }
        },
    ],
    paramsSerializer: params =>
        qs.stringify(keysToSnake(params), { arrayFormat: 'repeat' }),
});

const _callAPI = async ({ url, method = 'get' }, dataOrParams, options) => {
    const { additionalPath = '', ...config } = options;
    if (additionalPath) {
        url = `${url}${additionalPath}/`;
    }

    if (['get', 'options', 'head', 'delete'].includes(method)) {
        return await apiClient[method](url, {
            ...config,
            params: dataOrParams,
        });
    } else {
        return await apiClient[method](url, dataOrParams, config);
    }
};

export const useAPI = (
    apiRoute,
    options = {
        callbacks: {},
        validators: {},
    },
) => {
    const { callbacks = {}, validators = {} } = options;
    const [result, setResult] = useState(null);
    const [error, setError] = useState(null);
    const [response, setResponse] = useState(null);
    const source = axios.CancelToken.source();

    const send = async (dataOrParams = {}, requestConfig = {}) => {
        setResult(null);
        setError(null);
        setResponse(null);

        let isValid = true;
        if (dataOrParams) {
            Object.entries(dataOrParams).forEach(([key, value]) => {
                if (!isValid) {
                    return;
                }

                if (Array.isArray(validators[key])) {
                    validators[key].forEach(validator => {
                        if (!isValid) {
                            return;
                        }

                        try {
                            validator(value);
                        } catch (e) {
                            isValid = false;
                            setError(e);
                        }
                    });
                }
            });
        }

        if (!isValid) {
            return;
        }

        try {
            const api = await _callAPI(apiRoute, dataOrParams, {
                ...requestConfig,
                cancelToken: source.token,
            });
            setResponse(api);
            setResult(api.data || true);
        } catch (error) {
            if (!error.response) {
                throw error;
            }

            console.error(error);
            raiseSwalError(error.response.data);
            setResponse(error.response);
            setError(error.response.data);
        }
    };

    useEffect(() => {
        if (!result && !error) {
            return;
        }

        if (result && callbacks.onSuccess) {
            callbacks.onSuccess(result, response);
        } else if (error && callbacks.onError) {
            callbacks.onError(error, response);
        }

        if (callbacks.finally) {
            callbacks.finally(response);
        }
    }, [result, error]);

    return {
        response,
        result,
        error,
        send,
        cancel: () => {
            if (!result && !error) {
                source.cancel();
            }
        },
    };
};
