import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form } from 'react-bootstrap';

import { APIRoute } from 'Client';

import { RemoteDataTable, useRemoteDataTable } from 'Components/table/base';
import {
    AdminSchoolSubjectCreatePatchDeleteModal,
    useModal,
} from 'Components/modals';

const tableProperty = [
    {
        key: 'id',
        name: '번호',
    },
    {
        key: 'name',
        name: '과목명',
    },
    {
        key: 'category',
        name: '분류',
    },
    {
        key: 'reward',
        name: '보상',
    },
];

export const SchoolSubjectTable = props => {
    const history = useHistory();
    const apiRoute = APIRoute.Admin.SchoolSubject.List;
    const [selectedSchoolSubject, setSelectedSchoolSubject] = useState();
    const adminSchoolSubjectCreatePatchDeleteModal = useModal();

    const formatData = data => {
        return data.map(datum => {
            return {
                ...datum,
            };
        });
    };

    const { form, reload, ...remoteDataTable } = useRemoteDataTable({
        apiRoute,
        formatData,
    });

    tableProperty.forEach(e => {
        e.clickHandler = (columnData, row) => {
            setSelectedSchoolSubject(row);
            adminSchoolSubjectCreatePatchDeleteModal.open();
        };
    });

    return (
        <>
            <Form className="w-100">
                <div className="admin-select-container">
                    <div className="input-group col-6 p-0">
                        <select name="search_field" ref={form.register}>
                            <option value="">선택</option>
                            <option value="name">과목명</option>
                            <option value="category">분류</option>
                        </select>
                        <input
                            name="search_value"
                            ref={form.register}
                            type="text"
                            className="form-control border-right-0 h-100 rounded-0"
                        />
                        <div className="input-group-append rounded-0">
                            <button
                                type="button"
                                className="admin-search-btn-container"
                                onClick={reload}
                            >
                                <svg
                                    width="1rem"
                                    height="1rem"
                                    viewBox="0 0 16 16"
                                    className="bi bi-search"
                                    fill="currentColor"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"
                                    />
                                    <path
                                        fillRule="evenodd"
                                        d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"
                                    />
                                </svg>
                            </button>
                        </div>
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => {
                                setSelectedSchoolSubject();
                                adminSchoolSubjectCreatePatchDeleteModal.open();
                            }}
                        >
                            생성하기
                        </button>
                    </div>
                </div>
            </Form>
            <div className="col-12 mt-4">
                <RemoteDataTable
                    tableProperty={tableProperty}
                    {...remoteDataTable}
                />
                <AdminSchoolSubjectCreatePatchDeleteModal
                    schoolSubject={selectedSchoolSubject}
                    onSuccess={reload}
                    {...adminSchoolSubjectCreatePatchDeleteModal}
                />
            </div>
        </>
    );
};
