import Swal from 'sweetalert2';

const defaultConfirmOption = {
    icon: 'question',
    showCancelButton: true,
    confirmButtonText: '확인',
    cancelButtonText: '닫기',
};

export const SwalOption = {
    Confirm: {
        Admin: {
            StudySubject: {
                title: 'test',
                ...defaultConfirmOption,
            },
        },
    },
    Alert: {},
};

export const wrapWithSwalConfirm = ({ option, handler, pre }) => async (
    ...args
) => {
    if (typeof pre === 'function' && !pre(...args)) {
        return;
    }

    const confirm = await Swal.fire(
        typeof option === 'function' ? option(...args) : option,
    );
    if (!confirm.value) {
        return;
    }

    return handler(...args);
};

export const raiseSwalError = async errorData => {
    let {
        class: errorClass,
        content,
        content: { message: text, code: title },
    } = errorData;
    let doFireSwal = true;

    switch (errorClass) {
        case 'ValidationError':
            Object.entries(content).forEach(([fieldName, errors]) => {
                title = fieldName;
                errors.forEach(({ message }) => {
                    text = message;
                });
            });
            break;
        case 'errorClassToSkip':
            doFireSwal = false;
            break;
    }

    if (!doFireSwal) {
        return;
    }

    await Swal.fire({
        icon: 'error',
        title,
        text,
    });
};
