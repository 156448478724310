import React, { useState } from 'react';

import { Modal, Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import { useAPI, APIRoute } from 'Client';

export const FindPasswordModal = ({
    isOpen = false,
    open = () => {},
    close = () => {},
}) => {
    const { register, watch, handleSubmit, errors: formErrors } = useForm();
    const findPasswordAPI = useAPI(APIRoute.Common.Auth.FindPassword, {
        callbacks: {
            onSuccess: data => {
                alert(data.message);
                close();
            },
        },
    });

    const onSubmit = data => {
        if (!data.username) {
            alert('아이디를 입력해주세요!');
        } else if (!data.phoneNumber) {
            alert('가입하신 휴대폰 번호를 입력해주세요!');
        } else {
            findPasswordAPI.send({
                username: data.username,
                phoneNumber: data.phoneNumber,
            });
        }
    };

    return (
        <Modal id="find-password-modal" className="modal-narrow" show={isOpen}>
            <Modal.Header className="d-block">
                <div className="row w-100">
                    <Button className="close" onClick={close}>
                        <span aria-hidden="true">&times;</span>
                    </Button>
                </div>
                <div className="row w-100 justify-content-center align-items-center">
                    <h4>비밀번호 찾기</h4>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="container">
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="w-100">
                                <div className="row">
                                    <div className="col-12 pt-2 pb-2">
                                        <label>아이디</label>
                                    </div>
                                    <div className="col-12">
                                        <input
                                            name="username"
                                            className="password-modal-input input w-100 pt-3 pb-3 rounded-0"
                                            ref={register}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 pt-2 pb-2">
                                        <label>휴대폰 번호</label>
                                    </div>
                                    <div className="col-12">
                                        <input
                                            name="phoneNumber"
                                            className="password-modal-input input w-100 pt-3 pb-3 rounded-0"
                                            ref={register}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-5">
                                    <div className="col-12 pt-2 pb-2">
                                        <button className="btn-main-color w-100 pt-3 pb-3 rounded-0">
                                            임시 비밀번호 발급
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </Modal.Body>
        </Modal>
    );
};
