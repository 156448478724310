import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';

import { APIRoute } from 'Client';
import moment from 'Moment';
import { AdminDriectlyManagedConceptModal, useModal } from 'Components/modals';
import { AppContext } from 'Contexts';

import {
    RemoteDataTable,
    RemoteDataBlockTable,
    useRemoteDataTable,
} from './base';

import { SelectOptions } from './constants';

const tableProperty = [
    {
        key: 'id',
        name: '번호',
    },
    {
        key: 'name',
        name: '원어명',
    },
    {
        key: 'description',
        name: '내용',
        template: (columnData, row) => {
            return (
                <React.Fragment>
                    <span data-for={`study-subject-task-${row.id}`} data-tip>
                        {columnData.slice(0, 40)}
                    </span>
                    <ReactTooltip
                        className="tool-tip-container"
                        id={`study-subject-task-${row.id}`}
                        place="bottom"
                        type="info"
                        effect="solid"
                        getContent={() => columnData}
                    />
                </React.Fragment>
            );
        },
    },
    {
        key: 'created',
        name: '작성일자',
    },
    {
        key: 'updated',
        name: '수정일자',
    },
];

const blockProperty = {
    key: 'fullName',
};

export const ConceptTable = ({ blockTable, props }) => {
    const history = useHistory();

    // start block table related
    const [selectedConcept, setSelectedConcept] = useState();
    const adminDirectlyManagedConceptModal = useModal();
    blockProperty.clickHandler = (name, datum) => {
        setSelectedConcept(datum);
        adminDirectlyManagedConceptModal.open();
    };
    // end block table related
    const { admin, staff } = useContext(AppContext);

    const apiRoute = admin.profile.id
        ? APIRoute.Admin.Concept.List
        : staff.profile.id
        ? APIRoute.Staff.Concept.List
        : null;
    // const apiRoute = APIRoute.Admin.Concept.List;
    const formatData = data => {
        return data.map(datum => {
            return {
                ...datum,
                created: moment(datum.created).format('YY-MM-DD'),
                updated: datum.updated
                    ? moment(datum.updated).format('YY-MM-DD')
                    : '-',
                fullName: datum.nameInEnglish
                    ? `${datum.name} (${datum.nameInEnglish})`
                    : datum.name,
                tooltip: [
                    {
                        key: 'description',
                        content: datum.description,
                    },
                ],
            };
        });
    };

    const { form, reload, ...remoteDataTable } = useRemoteDataTable({
        apiRoute,
        formatData,
    });

    const clickHandler = (columnData, row) => {
        const baseUrl = admin.profile.id
            ? '/admin/main/concepts/'
            : staff.profile.id
            ? '/staff/main/concepts/'
            : null;
        history.push({
            pathname: `${baseUrl}${row.id}/`,
        });
    };

    tableProperty.forEach(e => {
        e.clickHandler = clickHandler;
    });

    return (
        <>
            <div className=" pt-3">
                <Form className="ss-filter-container">
                    <div className="ss-filter-box mt-3">
                        <div className="input-group h-100">
                            <select ref={form.register} name="search_field">
                                {SelectOptions.Concept.map(
                                    ({ value, label }, index) => (
                                        <option key={index} value={value}>
                                            {label}
                                        </option>
                                    ),
                                )}
                            </select>
                            <input
                                name="search_value"
                                ref={form.register}
                                onKeyPress={e => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                        reload();
                                    }
                                }}
                                type="text"
                                className="form-control border-right-0 h-100 rounded-0"
                            />
                            <div className="input-group-append rounded-0">
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={reload}
                                >
                                    검색
                                </button>
                            </div>
                        </div>
                    </div>
                </Form>
                <div className="col-12 mt-5">
                    {blockTable ? (
                        <>
                            <RemoteDataBlockTable
                                blockProperty={blockProperty}
                                {...remoteDataTable}
                            />
                            <AdminDriectlyManagedConceptModal
                                concept={selectedConcept}
                                setConcept={setSelectedConcept}
                                {...adminDirectlyManagedConceptModal}
                            />
                        </>
                    ) : (
                        <RemoteDataTable
                            tableProperty={tableProperty}
                            {...remoteDataTable}
                        />
                    )}
                </div>
            </div>
        </>
    );
};
