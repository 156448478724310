import React, { useState, useEffect } from 'react';

import { Link, useHistory, useLocation } from 'react-router-dom';

import { useAPI, APIRoute } from 'Client';

import { BaseTable, usePageBlock, Pagination } from '../table/base';

const columnNames = [
    {
        key: 'noticeTitle',
        name: '제목',
    },
    {
        key: 'createdAt',
        name: '작성일자',
    },
];

const LIMIT_PER_PAGE = 10;
const BLOCK_SIZE = 10;

export const RANoticeTable = props => {
    const history = useHistory();

    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);

    const { pageBlock, pageParams, setTotal, before, next } = usePageBlock({
        blockSize: BLOCK_SIZE,
        limitPerPage: LIMIT_PER_PAGE,
        initialTotal: (data && data.total) || 0,
        page,
    });

    const AnnouncementSendAPI = useAPI(
        APIRoute.ResearchAssistant.Announcement.List,
        {
            callbacks: {
                onSuccess: ({ results, count }) => {
                    results.map(result => {
                        result.noticeTitle = result.title;
                    });
                    setData(results);
                    setTotal(count);
                },
            },
        },
    );

    const gotoDetail = notice => {
        history.push(`/ra/mission-working-info/detail?id=${notice.id}`);
    };

    useEffect(() => {
        AnnouncementSendAPI.send();
    }, []);

    useEffect(() => {
        AnnouncementSendAPI.send({ ...pageParams });
    }, [page]);

    return (
        <div className="ra-mission-container">
            <div className="ra-mission-title">Mission</div>
            <div className="ra-mission-category">
                진로 탐색을 위한 탐구과제 생성
            </div>
            <div className="ra-notice-line"></div>
            <div className="ra-mission-tabel-title-container">
                <div className="ra-mission-tabel-title">공지사항</div>
            </div>
            <div className="col-12 mt-3 p-0">
                <BaseTable
                    columnNames={columnNames}
                    data={data}
                    onClick={gotoDetail}
                />
                <Pagination
                    before={before}
                    next={next}
                    pageBlock={pageBlock}
                    page={page}
                    setPage={setPage}
                />
            </div>
        </div>
    );
};
