import React, { useState, useEffect } from 'react';
import moment from 'Moment';
import ReactTooltip from 'react-tooltip'

const formatDate = date => {
  return moment(date).format('YYYY-MM-DD HH:mm');
};

export const TooltipBaseTable = ({
  columnNames = [],
  data = [],
  onClick = () => { },
  ...props
}) => {
  return (
    <>
      <table className="table table-hover text-center">
        <thead>
          <tr>
            {columnNames.map((column, headIndex) => (
              <th key={headIndex} scope="col">
                {column.name}
              </th>
            ))}
          </tr>
        </thead>
        {
          !props.loading &&
          <tbody>
            {data.length ? data.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {columnNames.map((column, colIndex) => {
                  const { key } = column;
                  const item = row[key];
                  let tooltip = '' 
                  row.tooltip.map(tip => { if (tip.key === key) tooltip = tip.content })
                  return (
                    <td
                      key={colIndex}
                      onClick={() => onClick(row)}
                      className="pt-4 pb-4"
                    >
                      <a data-for={`getContent${rowIndex}`} data-tip={tooltip}>
                        {key === 'created'
                          ? formatDate(item)
                          : key === 'description' | key === 'task' ? item && item.slice(0, 20)
                          : key === 'inspectionStatus' ?
                            <span className={item === '반려' ? 'text-danger-color' : item === '재제출' && 'text-green-color'}>
                              {item}
                            </span>
                            :
                              item
                        }
                      </a>
                    </td>
                  );
                })}
                <ReactTooltip className="tool-tip-container" id={`getContent${rowIndex}`} place="bottom" type="info" effect="solid" />
              </tr>
            )) : <tr>검색 기준에 부합하는 주제가 없습니다.</tr>}
          </tbody>
        }
      </table>
      {
        props.loading &&
        <div className="table-loading-container">
          <div className="table-loading-text">
            데이터를 불러오고 있습니다...
              </div>
          <div className="loader">
            Loading
              </div>
        </div>
      }
    </>
  );
};
