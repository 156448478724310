import React, { useContext, useState } from 'react';
import { AppContext } from 'Contexts';
import { TagAchievements } from 'Components/modals';

export const TagFeedback = ({ form, tags, feedbacks, ...props }) => {
    const {
        getters: { getMajorCategory, getSchoolSubject, getSchoolSubjectUnit },
        user,
    } = useContext(AppContext);

    const [element, setElement] = useState('')
    const [unit, setUnit] = useState('')
    const [feedbacksName, feedbacksValues] = Object.entries(feedbacks)[0];

    return (
        <>
            {tags.map(
                (
                    {
                        majorCategory: majorCategoryId,
                        tagUuid,
                        schoolSubjects,
                        schoolSubjectUnits,
                        learningElements,
                    },
                    tagIndex,
                ) => {
                    const majorCategory = getMajorCategory(majorCategoryId);
                    return (
                        <div key={tagIndex} className="mt-4 pr-5 pl-5">
                            <div className="row">
                                <div className="col-6 border border-dark p-5">
                                    <h4>{majorCategory && majorCategory.subcategoryName}</h4>
                                    <div>
                                        {schoolSubjects && schoolSubjects.map(
                                            ({ schoolSubjectUuid, id }) => {
                                                const schoolSubject = getSchoolSubject(
                                                    id,
                                                );
                                                return (
                                                    <div
                                                        key={schoolSubjectUuid}
                                                        className="pl-5"
                                                    >
                                                        <h5>
                                                            {schoolSubject && schoolSubject.name}
                                                        </h5>
                                                        <div className="pl-5">
                                                            {schoolSubjectUnits && schoolSubjectUnits
                                                                .filter(
                                                                    ({
                                                                        schoolSubjectUuid: _,
                                                                    }) =>
                                                                        _ ===
                                                                        schoolSubjectUuid,
                                                                )
                                                                .map(
                                                                    ({
                                                                        schoolSubjectUnitUuid,
                                                                        name,
                                                                        code
                                                                    }) => {
                                                                        return (
                                                                            <div
                                                                                key={
                                                                                    schoolSubjectUnitUuid
                                                                                }
                                                                                className="pl-5"
                                                                            >
                                                                                <span>
                                                                                    {
                                                                                        name.replace(/_/g, ' ')
                                                                                    }
                                                                                </span>
                                                                                <div className="pl-5">
                                                                                    {learningElements
                                                                                        .filter(
                                                                                            ({
                                                                                                schoolSubjectUnitUuid: _schoolSubjectUnitUuid,
                                                                                                schoolSubjectUuid: _schoolSubjectUuid,
                                                                                            }) =>
                                                                                                _schoolSubjectUnitUuid ===
                                                                                                schoolSubjectUnitUuid &&
                                                                                                _schoolSubjectUuid ===
                                                                                                schoolSubjectUuid,
                                                                                        )
                                                                                        .map(
                                                                                            ({
                                                                                                name,
                                                                                            }) => {
                                                                                                return (
                                                                                                    <div
                                                                                                        key={
                                                                                                            name
                                                                                                        }
                                                                                                        onClick={() => { setElement(name); setUnit(code); }}
                                                                                                        disabled={user.type === "RESEACH_ASSISTANT"}>
                                                                                                        {
                                                                                                            name
                                                                                                        }
                                                                                                    </div>
                                                                                                );
                                                                                            },
                                                                                        )}
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    },
                                                                )}
                                                        </div>
                                                    </div>
                                                );
                                            },
                                        )}
                                    </div>
                                </div>
                                {feedbacksValues.fields[tagIndex] && <div className="col-6">
                                    <div className="h-100 border border-dark">
                                        <textarea
                                            key={
                                                feedbacksValues.fields[tagIndex]
                                                    .id
                                            }
                                            name={`${feedbacksName}[${tagIndex}].value`}
                                            defaultValue={
                                                feedbacksValues.fields[tagIndex]
                                                    .value
                                            }
                                            className="d-flex w-100 h-100"
                                            placeholder={user.type == "RESEARCH_ASSISTANT" ? "" : "피드백을 적어주세요."}
                                            ref={form.register}
                                            disabled={user.type == "RESEARCH_ASSISTANT"}
                                        ></textarea>
                                    </div>
                                </div>}
                            </div>
                        </div>
                    );
                },
            )}
        </>
    );
};
