import React, { useState, useEffect } from 'react';

import { Modal, Button, Form } from 'react-bootstrap';
import { useForm, useFieldArray } from 'react-hook-form';

import { useAPI, APIRoute } from 'Client';

export const AdminLearningElementModifyModal = ({
    schoolSubjectUnitId,
    learningElement,
    setLearningElements,
    isOpen = false,
    open = () => {},
    close = () => {},
}) => {
    const deleteLearningElementAPI = useAPI(
        APIRoute.Admin.SchoolSubjectUnit.DeleteLearningElement,
        {
            callbacks: {
                onSuccess: ({ learningElements }) => {
                    alert('성취 기준을 삭제하였습니다.');
                    setLearningElements(learningElements);
                    close();
                },
            },
        },
    );

    const patchLearningElementAPI = useAPI(
        APIRoute.Admin.SchoolSubjectUnit.PatchLearningElement,
        {
            callbacks: {
                onSuccess: ({ learningElements }) => {
                    alert('성취 기준을 삭제하였습니다.');
                    setLearningElements(learningElements);
                    close();
                },
            },
        },
    );

    const form = useForm({
        defaultValues: {
            ...learningElement,
        },
    });

    const achievementStandards = useFieldArray({
        control: form.control,
        name: 'achievementStandards',
    });

    const handleDeleteLearningElementClick = () => {
        if (!confirm('이 성취 기준을 삭제하시겠습니까?')) {
            return;
        }

        deleteLearningElementAPI.send(
            {
                learningElementIndex: learningElement.index,
            },
            {
                additionalPath: `${schoolSubjectUnitId}/delete_learning_element`,
            },
        );
    };

    const handlePatchLearningElementClick = () => {
        if (!confirm('이 성취 기준을 수정하시겠습니까?')) {
            return;
        }

        patchLearningElementAPI.send(
            {
                learningElementIndex: learningElement.index,
                achievementStandards: [],
                ...form.getValues(),
            },
            {
                additionalPath: `${schoolSubjectUnitId}/patch_learning_element`,
            },
        );
    };

    const onSubmit = data => {};

    const initializeForm = () => {
        form.reset();
        initializeFormArrayFields();
    };

    const initializeFormArrayFields = () => {
        achievementStandards.remove();
        learningElement.achievementStandards.forEach((e, index) => {
            achievementStandards.append({
                id: index,
                ...e,
            });
        });
    };

    useEffect(() => {
        if (!learningElement) {
            return;
        }
        initializeFormArrayFields();
    }, [learningElement]);

    return (
        <Modal
            id="admin-learning-element-modify-modal"
            className="modal-narrow"
            show={isOpen}
        >
            <Modal.Header className="d-block">
                <div className="row w-100">
                    <Button className="close" onClick={close}>
                        <span aria-hidden="true">&times;</span>
                    </Button>
                </div>
                <div className="row w-100 justify-content-center align-items-center">
                    <h4>성취 기준 수정</h4>
                </div>
            </Modal.Header>
            <Modal.Body>
                {learningElement && (
                    <div className="container">
                        <Form onSubmit={form.handleSubmit(onSubmit)}>
                            <div className="row">
                                <div className="w-100">
                                    <div className="row">
                                        <div className="col-12 pt-2 pb-2">
                                            <label>성취 기준명</label>
                                        </div>
                                        <div className="col-12">
                                            <input
                                                name="name"
                                                defaultValue={
                                                    learningElement.name
                                                }
                                                className="password-modal-input input w-100 pt-3 pb-3 rounded-0"
                                                ref={form.register}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-row mt-3">
                                <h2>성취 요소</h2>
                            </div>
                            <div className="form-row mt-3 d-flex justify-content-end">
                                <button
                                    className="btn btn-info"
                                    onClick={() =>
                                        achievementStandards.append({
                                            code: '',
                                            description: '',
                                        })
                                    }
                                >
                                    성취요소 추가
                                </button>
                            </div>
                            <div className="form-row mt-3">
                                {achievementStandards.fields.map(
                                    (achievementStandardField, index) => (
                                        <div
                                            className="col-12 mt-3"
                                            key={
                                                achievementStandardField.code +
                                                index
                                            }
                                        >
                                            <div className="row">
                                                <div className="mt-2 col-2">
                                                    <input
                                                        ref={form.register()}
                                                        className="form-control rounded-0"
                                                        name={`achievementStandards[${index}].code`}
                                                        defaultValue={
                                                            achievementStandardField.code
                                                        }
                                                    />
                                                </div>
                                                <div className="mt-2 col-8">
                                                    <textarea
                                                        ref={form.register()}
                                                        className="form-control rounded-0"
                                                        name={`achievementStandards[${index}].description`}
                                                        defaultValue={
                                                            achievementStandardField.description
                                                        }
                                                    />
                                                </div>
                                                <button
                                                    className="btn btn-danger col-2 pl-1 pl-1"
                                                    onClick={() => {
                                                        achievementStandards.remove(
                                                            index,
                                                        );
                                                    }}
                                                >
                                                    삭제
                                                </button>
                                            </div>
                                        </div>
                                    ),
                                )}
                            </div>
                            <div className="row mt-5 d-flex justify-content-end">
                                <button
                                    onClick={initializeForm}
                                    className="btn btn-danger ml-3"
                                >
                                    초기화
                                </button>
                                <button
                                    onClick={() =>
                                        handleDeleteLearningElementClick()
                                    }
                                    className="btn btn-danger ml-3"
                                >
                                    이 학습요소 삭제하기
                                </button>
                                <button
                                    onClick={() =>
                                        handlePatchLearningElementClick()
                                    }
                                    className="ml-3 btn btn-primary"
                                >
                                    저장하기
                                </button>
                            </div>
                        </Form>
                    </div>
                )}
            </Modal.Body>
        </Modal>
    );
};
