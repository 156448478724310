import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useForm, useFieldArray } from 'react-hook-form';
import { BasicConceptForm } from 'Components';
import { getFieldArrayValues } from 'Utils';
import { useAPI, APIRoute } from 'Client';
import { useHistory } from 'react-router-dom';

export const AdminConceptDetailView = ({ entity: concept, ...props }) => {
    const history = useHistory();

    const [isModifying, setIsModifying] = useState(false);
    const conceptPatchAPI = useAPI(APIRoute.Admin.Concept.Patch, {
        callbacks: {
            onSuccess: () => {
                alert('성공');
                // window.location.reload(false)
                history.push({
                    pathname: `/admin/concepts/`,
                });
            },
        },
    });
    const conceptDeleteAPI = useAPI(APIRoute.Admin.Concept.Delete, {
        callbacks: {
            onSuccess: () => {
                alert('성공');
                history.push({
                    pathname: `/admin/concepts/`,
                });
            },
        },
    });
    const deleteConcept = () => {
        conceptDeleteAPI.send({}, { additionalPath: concept.id });
    };

    const defaultArrayFields = {};
    Object.entries(concept).forEach(([key, value]) => {
        if (key.startsWith('related') && Array.isArray(value)) {
            defaultArrayFields[key] =
                value.length === 4
                    ? value.map(_ => ({ value: _ }))
                    : [...Array(4).keys()].map(() => ({ value: '' }));
        }
    });

    const form = useForm({
        defaultValues: {
            ...concept,
            ...defaultArrayFields,
        },
    });

    const relatedConcepts = useFieldArray({
        control: form.control,
        name: 'relatedConcepts',
    });
    const relatedConceptsInEnglish = useFieldArray({
        control: form.control,
        name: 'relatedConceptsInEnglish',
    });
    // const relatedMajors = useFieldArray({
    //     control: form.control,
    //     name: 'relatedMajors',
    // });
    // const relatedSchoolSubjects = useFieldArray({
    //     control: form.control,
    //     name: 'relatedSchoolSubjects',
    // });

    form.handleSubmit = form.handleSubmit(data => {
        const {
            name,
            nameInEnglish,
            description,
            relatedConcepts,
            relatedConceptsInEnglish,
            // relatedMajors,
            // relatedSchoolSubjects,
        } = data;

        conceptPatchAPI.send(
            {
                name,
                nameInEnglish,
                description,
                relatedConcepts: getFieldArrayValues(relatedConcepts),
                relatedConceptsInEnglish: getFieldArrayValues(
                    relatedConceptsInEnglish,
                ),
                // relatedMajors: getFieldArrayValues(relatedMajors),
                // relatedSchoolSubjects: getFieldArrayValues(
                //     relatedSchoolSubjects,
                // ),
            },
            {
                additionalPath: concept.id,
            },
        );
    });

    useEffect(() => {
        if (!isModifying) {
            form.reset();
        }
    }, [isModifying]);

    return (
        <>
            <div className="study-subject-container mt-3">
                <div
                    className="admin-back-btn cursor-pointer"
                    onClick={() => history.goBack()}
                >
                    &#xE000;&nbsp; 뒤로가기
                </div>
                <div className="row">
                    <div className="w-100">
                        <Form onSubmit={form.handleSubmit} className="w-100">
                            <BasicConceptForm
                                form={form}
                                disabled={!isModifying}
                                isSearchEnabled={false}
                            />
                            <div className="form-row mt-4">
                                <div className="col-2">
                                    <div className="h-100 d-flex justify-content-center align-items-center">
                                        <h5 className="text-middle text-center mb-0">
                                            유사개념
                                        </h5>
                                    </div>
                                </div>
                                {relatedConcepts.fields.map(
                                    (relatedConcept, index) => (
                                        <div
                                            key={relatedConcept.id}
                                            className="col-2"
                                        >
                                            <input
                                                disabled={!isModifying}
                                                key={relatedConcept.id}
                                                ref={form.register}
                                                name={`relatedConcepts[${index}].value`}
                                                defaultValue={
                                                    relatedConcept.value
                                                }
                                                className="form-control rounded-0 border-dark"
                                            />
                                        </div>
                                    ),
                                )}
                            </div>
                            <div className="form-row mt-4">
                                <div className="col-2">
                                    <div className="h-100 d-flex justify-content-center align-items-center">
                                        <h5 className="text-middle text-center mb-0">
                                            원어명
                                        </h5>
                                    </div>
                                </div>
                                {relatedConceptsInEnglish.fields.map(
                                    (relatedConceptInEnglish, index) => (
                                        <div
                                            key={relatedConceptInEnglish.id}
                                            className="col-2"
                                        >
                                            <input
                                                disabled={!isModifying}
                                                key={relatedConceptInEnglish.id}
                                                ref={form.register}
                                                name={`relatedConceptsInEnglish[${index}].value`}
                                                defaultValue={
                                                    relatedConceptInEnglish.value
                                                }
                                                className="form-control rounded-0 border-dark"
                                            />
                                        </div>
                                    ),
                                )}
                            </div>
                            {/* <div className="form-row mt-4">
                                <div className="col-2">
                                    <div className="h-100 d-flex justify-content-center align-items-center">
                                        <h5 className="text-middle text-center mb-0">
                                            긴밀학과
                                        </h5>
                                    </div>
                                </div>
                                {relatedMajors.fields.map(
                                    (relatedMajor, index) => (
                                        <div
                                            key={relatedMajor.id}
                                            className="col-2"
                                        >
                                            <input
                                                disabled={!isModifying}
                                                key={relatedMajor.id}
                                                ref={form.register}
                                                name={`relatedMajors[${index}].value`}
                                                defaultValue={
                                                    relatedMajor.value
                                                }
                                                className="form-control rounded-0 border-dark"
                                            />
                                        </div>
                                    ),
                                )}
                            </div>
                            <div className="form-row mt-3">
                                <div className="col-2">
                                    <div className="h-100 d-flex justify-content-center align-items-center">
                                        <h5 className="text-middle text-center mb-0">
                                            긴밀과목
                                        </h5>
                                    </div>
                                </div>
                                {relatedSchoolSubjects.fields.map(
                                    (relatedSchoolSubject, index) => (
                                        <div
                                            key={relatedSchoolSubject.id}
                                            className="col-2"
                                        >
                                            <input
                                                disabled={!isModifying}
                                                key={relatedSchoolSubject.id}
                                                ref={form.register}
                                                name={`relatedSchoolSubjects[${index}].value`}
                                                defaultValue={
                                                    relatedSchoolSubject.value
                                                }
                                                className="form-control rounded-0 border-dark"
                                            />
                                        </div>
                                    ),
                                )}
                            </div> */}
                            {isModifying ? (
                                <>
                                    <div className="form-row mt-5">
                                        <div className="offset-3 col-3 justify-content-center align-items-center d-flex">
                                            <button
                                                type="button"
                                                onClick={() =>
                                                    setIsModifying(false)
                                                }
                                                className="btn btn-border-main btn-block"
                                            >
                                                취소
                                            </button>
                                        </div>
                                        <div className="offset-1 col-3 justify-content-center align-items-center d-flex">
                                            <button
                                                type="submit"
                                                className="btn btn-main-color btn-block"
                                            >
                                                확인
                                            </button>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="form-row mt-5">
                                        <div className="offset-4 col-3 justify-content-center align-items-center d-flex">
                                            <button
                                                onClick={() =>
                                                    setIsModifying(true)
                                                }
                                                type="button"
                                                className="btn btn-main-color btn-block"
                                            >
                                                수정하기
                                            </button>
                                        </div>
                                    </div>
                                </>
                            )}
                        </Form>
                        <div className="form-row mt-3 mb-5">
                            <div className="offset-4 col-3 justify-content-center align-items-center d-flex">
                                <button
                                    onClick={() => deleteConcept()}
                                    type="button"
                                    className="btn btn-danger btn-block"
                                >
                                    삭제하기
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
