import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

import { useAPI, APIRoute } from 'Client';

export const StudentProfileModal = ({
    studentProfile,
    handleClick = () => { },
    isOpen = false,
    close,
}) => {
    return (
        <Modal
            id="search-concepts-modal"
            className="modal"
            show={isOpen}
            onHide={close}
        >
            <Modal.Header className="d-block">
                <div className="row w-100">
                    <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={close}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="row w-100 justify-content-center align-items-center">
                    <h4>학생 정보</h4>
                </div>
            </Modal.Header>
            <Modal.Body>
                {studentProfile && (
                    <div className="container">
                        <div className="row mt-3">
                            <div className="col-6">
                                <div className="mb-1">
                                    <span>이름</span>
                                </div>
                                <div>
                                    <input
                                        disabled
                                        defaultValue={studentProfile.name}
                                        className="form-control rounded-0 border-dark"
                                    />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="mb-1">
                                    <span>학교</span>
                                </div>
                                <div>
                                    <input
                                        disabled
                                        defaultValue={
                                            studentProfile.school &&
                                            studentProfile.school.name
                                        }
                                        className="form-control rounded-0 border-dark"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-6">
                                <div className="mb-1">
                                    <span>학년</span>
                                </div>
                                <div>
                                    <input
                                        disabled
                                        defaultValue={studentProfile.grade}
                                        className="form-control rounded-0 border-dark"
                                    />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="mb-1">
                                    <span>발급횟수</span>
                                </div>
                                <div>
                                    <input
                                        disabled
                                        defaultValue={
                                            studentProfile.studySubjectMaps.length || ''
                                        }
                                        className="form-control rounded-0 border-dark"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <table className="w-100 text-center">
                                <thead>
                                    <tr>
                                        <td className="pt-3 pb-3 border-bottom border-dark">
                                            이론
                                        </td>
                                        <td className="pt-3 pb-3 border-bottom border-dark">
                                            과제
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {studentProfile.studySubjectMaps &&
                                        studentProfile.studySubjectMaps.map(
                                            (studySubjectMap, rowIndex) => (
                                                <>
                                                    <tr key={rowIndex}>
                                                        <td className="pt-3 pb-3">
                                                            {
                                                                studySubjectMap.conceptName
                                                            }
                                                        </td>
                                                        <td className="pt-3 pb-3">
                                                            {studySubjectMap.studySubjectTask.slice(
                                                                0,
                                                                20,
                                                            )}
                                                        </td>
                                                    </tr>
                                                </>
                                            ),
                                        )}
                                </tbody>
                            </table>
                        </div>
                        <div className="row mt-3 text-center">
                            <button
                                onClick={close}
                                className="btn btn-primary rounded-0 btn-block"
                            >
                                확인
                            </button>
                        </div>
                    </div>
                )}
            </Modal.Body>
        </Modal>
    );
};
