import React from 'react';

import { Pagination } from './Pagination';

export const RemoteDataTable = ({
    tableProperty,
    data,
    loading,
    paginationData: { isPaginated = true, ...paginationData },
    ...props
}) => {
    return (
        <>
            <table className="table table-hover text-center">
                <thead>
                    <tr>
                        {tableProperty.map((column, headIndex) => (
                            <th key={headIndex} scope="col">
                                {column.name}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {loading && (
                        <tr className="table-loading-container">
                            <td colSpan={tableProperty.length}>
                                <div className="table-loading-text">
                                    데이터를 불러오고 있습니다...
                                </div>
                                <div className="loader">Loading</div>
                            </td>
                        </tr>
                    )}
                    {!loading && (
                        <>
                            {data.length === 0 && (
                                <tr>
                                    <td colSpan={tableProperty.length}>
                                        <span>데이터가 없습니다.</span>
                                    </td>
                                </tr>
                            )}
                            {data.map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    {tableProperty.map(
                                        (
                                            { key, template, clickHandler },
                                            colIndex,
                                        ) => {
                                            return (
                                                <td
                                                    key={colIndex}
                                                    onClick={() =>
                                                        typeof clickHandler ===
                                                        'function'
                                                            ? clickHandler(
                                                                  row[key],
                                                                  row,
                                                              )
                                                            : ''
                                                    }
                                                    className="pt-4 pb-4"
                                                >
                                                    {template &&
                                                    typeof template ===
                                                        'function' ? (
                                                        template(
                                                            row[key],
                                                            row,
                                                            clickHandler,
                                                        )
                                                    ) : (
                                                        <span>{row[key]}</span>
                                                    )}
                                                </td>
                                            );
                                        },
                                    )}
                                </tr>
                            ))}
                        </>
                    )}
                </tbody>
            </table>
            {isPaginated && <Pagination {...paginationData} />}
        </>
    );
};
