import React from 'react';

import { NavLink } from 'react-router-dom';
import { Navbar, Nav, NavItem } from 'react-bootstrap';

import { AdminRoutes } from 'Router';

const navData = {
    leanAi: {
        pathNamePattern: /admin\/main/,
        data: [
            ['/admin/main/study-subject-orders/', '주제 발급'],
            ['/admin/main/issued-study-subjects/', 'PDF 발급'],
            ['/admin/main/mission-reports/', '주제 검수'],
            ['/admin/main/study-subjects/', '주제 목록'],
            ['/admin/main/concepts/', '이론 목록'],
        ],
    },
    management: {
        pathNamePattern: /admin\/management/,
        data: [
            ['/admin/management/research-assistants/', 'RA'],
            ['/admin/management/points-withdrawals/', '포인트 정산'],
            ['/admin/management/missions/', '미션'],
            ['/admin/management/test-reports/', '테스트 레포트'],
            ['/admin/management/base-data/school-subjects/', '과목 관리'],
            ['/admin/management/base-data/school-subject-units/', '단원 관리'],
        ],
    },
    statistics: {
        pathNamePattern: /admin\/statistics/,
        data: [
            [
                '/admin/statistics/study-subject-supply-demand/',
                '발표 주제 수요/공급',
            ],
        ],
    },
    directlyManaged: {
        pathNamePattern: /admin\/directly-managed/,
        data: [['/admin/directly-managed/study-subjects/', '맞춤형 이론']],
    },
};

export const AdminSubmenuView = props => {
    const {
        location: { pathname },
    } = props;

    return (
        <div className="content">
            <div className="content-wrapper">
                <div className="content-menu">
                    <Navbar className="w-100">
                        <Nav defaultActiveKey="/" className="flex-column">
                            {Object.entries(navData).map(
                                ([navName, { pathNamePattern, data }]) => {
                                    if (pathname.match(pathNamePattern)) {
                                        return data.map(
                                            ([baseUrl, name], index) => (
                                                <NavItem key={index}>
                                                    <NavLink
                                                        activeClassName="active"
                                                        className="admin-menu-item"
                                                        to={baseUrl}
                                                    >
                                                        {name}
                                                    </NavLink>
                                                </NavItem>
                                            ),
                                        );
                                    }
                                },
                            )}
                        </Nav>
                    </Navbar>
                </div>
                <div className="content-content">
                    <AdminRoutes />
                </div>
            </div>
        </div>
    );
};
