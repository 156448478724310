import React, { useContext, useState, useEffect } from 'react';

import { Container, Row, Col, Modal, Form, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';

import { useAPI, APIRoute, ErrorCode } from 'Client';
import { v1 as uuidv1 } from 'uuid';
import { AppContext } from 'Contexts/AppContext';

export const SignUpView = () => {
    const history = useHistory();
    const { dispatchers } = useContext(AppContext);
    const { register, watch, errors: formErrors } = useForm();
    const [userData, setUserData] = useState('');
    // 사용자 입력 정보
    const [username, setUsername] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneNumberVerificationId, setPhoneNumberVerficationId] = useState('');
    const [phoneNumberVerificationCode, setPhoneNumberVerificationCode] = useState('');
    const [phoneNumberVerificationCompleted, setPhoneNumberVerificationCompleted] = useState(false);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordCheck, setPasswordCheck] = useState('');
    const [english, setEnglish] = useState('');
    const [japanese, setJapanese] = useState('');
    const [chinese, setChinese] = useState('');
    const [extra, setExtra] = useState('');
    const queryObj = queryString.parse(location.search);
    const watchFields = watch(['username', 'password', 'passwordConfirmation']);

    const [checkValidation, setCheckValidation] = useState(false);
    const validateUsernameAPI = useAPI(APIRoute.Common.Auth.ValidateUsername);
    const preparePhoneNumberVerificationAPI = useAPI(APIRoute.Common.Auth.PhoneNumberVerification.Prepare);
    const verifyPhoneNumberAPI = useAPI(APIRoute.Common.Auth.PhoneNumberVerification.Verify);

    const [step, setStep] = useState(1);

    const registerAPI = useAPI(APIRoute.ResearchAssistant.Auth.Register, {
        callbacks: {
            onSuccess: data => {
                alert('회원가입에 성공했습니다!');
                history.push('/');
            },
        },
    });

    const onSubmit = () => {
        if (!username || validateUsernameAPI.error) {
            alert('아이디를 확인해주세요!');
        } else if (!email) {
            alert('이메일 입력을 확인해주세요.');
        } else if (!password) {
            alert('비밀번호를 입력해주세요!');
        } else if (password != passwordCheck) {
            alert('비밀번호가 일치한지 확인해주세요!!');
        } else if (!phoneNumberVerificationCompleted) {
            alert('핸드폰 인증을 완료해주세요!');
        } else {
            registerAPI.send({
                user: {
                    name: userData.name,
                    username: username,
                    password: passwordCheck,
                    phone: userData.mobileno,
                    email: email,
                    phoneNumberVerification: phoneNumberVerificationId,
                },
                certificate: [
                    {
                        english: english,
                        japanese: japanese,
                        chinese: chinese,
                        extra: extra,
                    },
                ],
            });
        }
    };
    const handleUsernameValidationButtonClick = async e => {
        e.preventDefault();
        if (username) {
            await validateUsernameAPI.send({ username: username });
            setCheckValidation(true);
        } else {
            alert('아이디를 입력해주세요!');
        }
    };

    const preparePhoneNumberVerification = async e => {
        await preparePhoneNumberVerificationAPI.send({ phoneNumber: phoneNumber });
    }

    const verifyPhoneNumber = async e => {
        await verifyPhoneNumberAPI.send({ code: phoneNumberVerificationCode, phoneNumberVerification: phoneNumberVerificationId, verificationCode: phoneNumberVerificationCode });
    }

    const handlePreparePhoneNumberVerificationButtonClick = async e => {
        e.preventDefault();
        if (phoneNumber) {
            if (phoneNumberVerificationId) {
                await verifyPhoneNumber();
            } else {
                await preparePhoneNumberVerification();
            }
        } else {
            alert('핸드폰 번호를 입력해주세요!');
        }
    };

    useEffect(function updatePhoneNumberVerificationId() {
        if (!preparePhoneNumberVerificationAPI.result) {
            return;
        }

        const { code, message: phoneNumberVerificationId } = preparePhoneNumberVerificationAPI.result;
        if (code === 'SMS_MESSAGE_SENT') {
            alert('인증번호를 전송하였습니다.');
            setPhoneNumberVerficationId(phoneNumberVerificationId);
        }
    }, [preparePhoneNumberVerificationAPI.result])

    useEffect(function finalizePhoneNumberVerification() {
        if (!verifyPhoneNumberAPI.result) {
            return;
        }

        const { code } = verifyPhoneNumberAPI.result;
        if (code === 'SUCCESS') {
            alert('핸드폰 인증을 완료하였습니다.');
            setPhoneNumberVerificationCompleted(true);
        }
    }, [verifyPhoneNumberAPI.result])

    return (
        <>
            <section className="signup-wrapper">
                <div className="signup-container">
                    <div className="signup-title">회원가입</div>
                    <div className="signup-step-container">
                        <div
                            className={`signup-step-circle ${step === 1 &&
                                'active'}`}
                        >
                            1
                        </div>
                        <div className="signup-step-line"></div>
                        <div
                            className={`signup-step-circle ${step === 2 &&
                                'active'}`}
                        >
                            2
                        </div>
                    </div>
                    {step === 1 && (
                        <div>
                            <div className="signup-step-title">
                                약관에 동의해주세요
                            </div>
                            <div className="signup-step-box">
                                본인은 [개인정보 보호법] 제15조 및 제17조에 따라
                                아래의 내용으로 개인정보를 수집, 이용 및
                                제공하는데 동의합니다.
                                <br />
                                <br />
                                - 개인정보의 수집 및 이용에 관한 사항
                                <br />
                                <br />
                                1) 수집하는 개인정보 항목: 휴대폰번호
                                <br />
                                2) 개인정보의 이용 목적: 수집 된 개인정보를 본인
                                확인 및 미션 진행과 관련된 알람과 공지사항 전달
                                목적으로 활용하며, 목적 외의 용도로는 사용하지
                                않습니다.
                            </div>
                            <div className="signup-step-btn-container">
                                <div
                                    className="btn btn-main-color signup-step-btn"
                                    onClick={() => setStep(2)}
                                >
                                    약관에 동의합니다.
                                </div>
                            </div>
                        </div>
                    )}
                    {step === 2 && (
                        <div>
                            <section>
                                <div className="signup-step-title">
                                    기본 정보를 입력해주세요.
                                </div>
                                <div className="signup-input-container">
                                    <div className="register-modal-label col-12 pt-2 pb-2">
                                        <label>*아이디</label>
                                    </div>
                                    <div className="col-8">
                                        <input
                                            value={username}
                                            onChange={e =>
                                                setUsername(e.target.value)
                                            }
                                            name="username"
                                            className="register-modal-input input w-100 pt-3 pb-3 rounded-0"
                                        />
                                        {checkValidation ? (
                                            validateUsernameAPI.error ? (
                                                <small className="form-text text-danger">
                                                    이 아이디는 사용하실 수
                                                    없습니다.
                                                </small>
                                            ) : (
                                                <small className="form-text">
                                                    사용가능한 아이디입니다.
                                                </small>
                                            )
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                    <div className="col-4">
                                        <Button
                                            onClick={
                                                handleUsernameValidationButtonClick
                                            }
                                            variant={'warning'}
                                            className="w-100 pt-2 pb-2 btn-main-color"
                                        >
                                            중복확인
                                        </Button>
                                    </div>
                                    <div className="register-modal-label col-12 pt-2 pb-2">
                                        <label>*핸드폰 번호</label>
                                    </div>
                                    <div className="col-8 mt-3">
                                        <input
                                            value={phoneNumber}
                                            onChange={e =>
                                                setPhoneNumber(e.target.value)
                                            }
                                            name="phone_number"
                                            disabled={phoneNumberVerificationId}
                                            className="register-modal-input input w-100 pt-3 pb-3 rounded-0"
                                        />
                                    </div>
                                    {
                                        (phoneNumberVerificationId && !phoneNumberVerificationCompleted) && (
                                            <div className="col-8 mt-3">
                                                <input
                                                    value={phoneNumberVerificationCode}
                                                    onChange={e =>
                                                        setPhoneNumberVerificationCode(e.target.value)
                                                    }
                                                    placeholder="인증번호 입력"
                                                    name="phone_number_verification_code"
                                                    className="register-modal-input input w-100 pt-3 pb-3 rounded-0"
                                                />
                                            </div>
                                        )
                                    }
                                    <div className="col-4 mt-3">
                                        <Button
                                            onClick={
                                                handlePreparePhoneNumberVerificationButtonClick
                                            }
                                            disabled={phoneNumberVerificationCompleted}
                                            variant={phoneNumberVerificationCompleted ? 'secondary' : 'warning'}
                                            className="w-100 pt-2 pb-2 btn-main-color"
                                        >
                                            {
                                                !phoneNumberVerificationId && (
                                                    '인증번호 요청'
                                                )
                                            }
                                            {
                                                phoneNumberVerificationId && (
                                                    phoneNumberVerificationCompleted ? '인증 완료' : '인증번호 확인'
                                                )
                                            }
                                        </Button>
                                    </div>
                                    <div className="register-modal-label col-12 pt-2 pb-2">
                                        <label>이메일</label>
                                    </div>
                                    <div className="col-12">
                                        <input
                                            value={email}
                                            onChange={e =>
                                                setEmail(e.target.value)
                                            }
                                            name="email"
                                            className="register-modal-input input w-100 pt-3 pb-3 rounded-0"
                                        />
                                    </div>
                                    <div className="register-modal-label col-12 pt-2 pb-2">
                                        <label>*비밀번호</label>
                                    </div>
                                    <div className="col-12">
                                        <input
                                            value={password}
                                            onChange={e =>
                                                setPassword(e.target.value)
                                            }
                                            name="password"
                                            type="password"
                                            className="register-modal-input input w-100 pt-3 pb-3 rounded-0"
                                        />
                                    </div>
                                    <div className="register-modal-label col-12 pt-2 pb-2">
                                        <label>*비밀번호 확인</label>
                                    </div>
                                    <div className="col-12">
                                        <input
                                            value={passwordCheck}
                                            onChange={e =>
                                                setPasswordCheck(e.target.value)
                                            }
                                            name="passwordConfirmation"
                                            type="password"
                                            className="register-modal-input input w-100 pt-3 pb-3 rounded-0"
                                        />
                                        {watchFields.passwordConfirmation &&
                                            watchFields.password !==
                                            watchFields.passwordConfirmation && (
                                                <small className="form-text text-danger">
                                                    비밀번호가 일치하지
                                                    않습니다.
                                                </small>
                                            )}
                                    </div>
                                </div>
                                <div className="signup-step-title mt-3">
                                    추가 정보를 입력해주세요. (선택사항)
                                </div>
                                <div className="signip-input-subtitle">
                                    보유 자격증 입력
                                </div>
                                <div className="signup-input-container">
                                    <div className="register-modal-label col-12 pt-2 pb-2">
                                        <label>영어</label>
                                    </div>
                                    <div className="col-12">
                                        <input
                                            value={english}
                                            onChange={e =>
                                                setEnglish(e.target.value)
                                            }
                                            placeholder="토익점수 포함 자격증 입력"
                                            className="register-modal-input input w-100 pt-3 pb-3 rounded-0"
                                        />
                                    </div>
                                    <div className="register-modal-label col-12 pt-2 pb-2">
                                        <label>일본어</label>
                                    </div>
                                    <div className="col-12">
                                        <input
                                            value={japanese}
                                            onChange={e =>
                                                setJapanese(e.target.value)
                                            }
                                            placeholder="JPT점수 포함 자격증 입력"
                                            className="register-modal-input input w-100 pt-3 pb-3 rounded-0"
                                        />
                                    </div>
                                    <div className="register-modal-label col-12 pt-2 pb-2">
                                        <label>중국어(간체)</label>
                                    </div>
                                    <div className="col-12">
                                        <input
                                            value={chinese}
                                            onChange={e =>
                                                setChinese(e.target.value)
                                            }
                                            placeholder="HSK점수 포함 자격증 입력"
                                            className="register-modal-input input w-100 pt-3 pb-3 rounded-0"
                                        />
                                    </div>
                                    <div className="register-modal-label col-12 pt-2 pb-2">
                                        <label>기타</label>
                                    </div>
                                    <div className="col-12">
                                        <input
                                            value={extra}
                                            onChange={e =>
                                                setExtra(e.target.value)
                                            }
                                            placeholder="기타 자격증 입력"
                                            className="register-modal-input input w-100 pt-3 pb-3 rounded-0"
                                        />
                                    </div>
                                </div>
                                <Row className="justify-content-center mt-5">
                                    <Col md={8} className="pt-2 pb-2">
                                        <Button
                                            type="submit"
                                            variant={'primary'}
                                            className="w-100 pt-3 pb-3 btn-main-color"
                                            onClick={() => onSubmit()}
                                        >
                                            회원가입 완료
                                        </Button>
                                    </Col>
                                </Row>
                            </section>
                        </div>
                    )}
                </div>
            </section>
        </>
    );
};
