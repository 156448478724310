import React, { useContext } from 'react';

import { AppContext } from 'Contexts/AppContext';
import { useHistory } from 'react-router-dom';

import { useAPI, APIRoute } from 'Client';
import { ModifyPasswordModal, useModal } from 'Components/modals';

export const MyPageView = props => {
  const history = useHistory();
  const modifyPasswordModal = useModal();

  const { user, admin, researchAssistant, dispatchers } = useContext(
    AppContext,
  );

  const dropoutAPI = useAPI(APIRoute.Common.Auth.Dropout, {
    callbacks: {
      onSuccess: () => {
        dispatchers.clear();
        history.push('/');
      },
    },
  });

  const dropoutButtonClick = () => {
    if (researchAssistant.profile.points) alert('정산해야할 포인트가 남아있습니다. 다음 정산 이후, 포인트가 0일때 다시 시도해주세요!')
    else {
      alert('회원탈퇴 후에는 더 이상 같은 핸드폰 번호로 회원가입할 수 없습니다.')
      if (confirm('확인버튼을 누르면 회원정보가 비활성화됩니다.\n확인버튼을 신중히 눌러주세요.\n탈퇴를 하기 원하지 않으시면 취소 버튼을 눌러주세요.\n(회원탈퇴 후에는 더 이상 같은 핸드폰 번호로 회원가입할 수 없습니다.)')) {
        dropoutAPI.send()
      }
    }
  }

  return (
    <div className="ra-pofile-container">
      <div className="ra-profile-title">마이페이지</div>
      <div className="ra-pofile-wrapper">
        <div className="admin-ra-profile-modal-table ra-pofile-wrapper">
          <div className="admin-ra-profile-modal-tr">
            <div className="admin-ra-profile-modal-title">대학(원)</div>
            <div className="admin-ra-profile-modal-content">{researchAssistant.profile.universityName}</div>
          </div>
          <div className="admin-ra-profile-modal-tr">
            <div className="admin-ra-profile-modal-title">학과(전공)</div>
            <div className="admin-ra-profile-modal-content">
              {
                researchAssistant.profile.majorName &&
                <span>
                  {researchAssistant.profile.majorName.join(', ')}
                </span>
              }
            </div>
          </div>
          <div className="admin-ra-profile-modal-tr">
            <div className="admin-ra-profile-modal-title">입학년도</div>
            <div className="admin-ra-profile-modal-content">{researchAssistant.profile.universityEntranceYear}</div>
          </div>
          <div className="admin-ra-profile-modal-tr">
            <div className="admin-ra-profile-modal-title">졸업여부</div>
            <div className="admin-ra-profile-modal-content">{researchAssistant.profile.universityAttendStatus === 'GRADUATION' ? '졸업' : '재학'}</div>
          </div>
          <div className="admin-ra-profile-modal-tr">
            <div className="admin-ra-profile-modal-title">학생부종합전형 겸험 여부</div>
            <div className="admin-ra-profile-modal-content">{researchAssistant.profile.studentRecordAcceptance ? 'YES' : 'NO'}</div>
          </div>
          <div className="admin-ra-profile-modal-tr">
            <div className="admin-ra-profile-modal-title">학생부종합전형 합격 여부</div>
            <div className="admin-ra-profile-modal-content">{researchAssistant.profile.studentRecordAcceptance ? 'YES' : 'NO'}</div>
          </div>
          <div className="admin-ra-profile-modal-tr">
            <div className="admin-ra-profile-modal-title">휴대폰 번호</div>
            <div className="admin-ra-profile-modal-content">{user.phone}</div>
          </div>
          <div className="admin-ra-profile-modal-tr">
            <div className="admin-ra-profile-modal-title">메일 주소</div>
            <div className="admin-ra-profile-modal-content">[가입 메일] {user.email}<br />[자격 메일] {researchAssistant.profile.universityEmail}</div>
          </div>
        </div>
      </div>
      <div>
        <div className="btn btn-outline-secondary cursor-pointer" onClick={() => dropoutButtonClick()}>회원 탈퇴</div>
        <div className="btn btn-outline-secondary cursor-pointer" onClick={() => modifyPasswordModal.open()} >비밀번호 변경</div>
      </div>
      <ModifyPasswordModal
        {...modifyPasswordModal} />
    </div >
  );
};
