import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import ReactTooltip from 'react-tooltip';

import { useAPI, APIRoute } from 'Client';
import { BaseTable } from 'Components/table/base';
import {
    useModal,
    AdminLearningElementModifyModal,
    SearchRelatedSchoolSubjectsModal,
} from 'Components';

import { AppContext } from 'Contexts';

const columnNames = [
    {
        key: 'index',
        name: '번호',
    },
    {
        key: 'name',
        name: '성취기준명',
    },
    {
        key: 'achievementStandardsCount',
        name: '학습요소 수',
        template: (columnData, row) => {
            return (
                <>
                    <a data-for={`achievement-standards-${row.index}`} data-tip>
                        {columnData}
                    </a>
                    <ReactTooltip
                        className="tool-tip-container"
                        id={`achievement-standards-${row.index}`}
                        place="bottom"
                        type="info"
                        effect="solid"
                        getContent={() =>
                            row.learningElements.reduce((prev, cur, index) => {
                                return `${prev} <\br> ${cur}`;
                            }, '')
                        }
                    />
                </>
            );
        },
    },
];

export const AdminSchoolSubjectUnitDetailView = props => {
    const { entity: schoolSubjectUnit } = props;
    if (!schoolSubjectUnit) {
        return <></>;
    }

    const {
        getters: { getSchoolSubject: getSchoolSubjectFromContext },
    } = useContext(AppContext);

    const history = useHistory();

    const [schoolSubject, setSchoolSubject] = useState([]);

    const [selectedLearningElement, setSelectedLearningElement] = useState();
    const [learningElements, setLearningElements] = useState([]);

    const learningElementModal = useModal();
    const searchRelatedSchoolSubjectsModal = useModal();

    const form = useForm({
        defaultValues: {
            ...schoolSubjectUnit,
        },
    });

    const addLearningElementAPI = useAPI(
        APIRoute.Admin.SchoolSubjectUnit.CreateLearningElement,
        {
            callbacks: {
                onSuccess: ({ learningElements }) => {
                    setLearningElements(learningElements);
                    alert('성취 기준을 생성하였습니다.');
                },
            },
        },
    );

    const patchSchoolSubjectUnitAPI = useAPI(
        APIRoute.Admin.SchoolSubjectUnit.Patch,
        {
            callbacks: {
                onSuccess: () => {
                    alert('저장하였습니다.');
                },
            },
        },
    );

    const deleteSchoolSubjectUnitAPI = useAPI(
        APIRoute.Admin.SchoolSubjectUnit.Delete,
        {
            callbacks: {
                onSuccess: () => {
                    alert('삭제하였습니다.');
                    history.push(
                        'admin/management/base-data/school-subject-units/',
                    );
                },
            },
        },
    );

    const handleDeleteSchoolSubjectUnitButtonClick = () => {
        if (!confirm('정말로 삭제하시겠습니까?')) {
            return;
        }

        deleteSchoolSubjectUnitAPI.send(null, {
            additionalPath: schoolSubjectUnit.id,
        });
    };

    const handleSubmitButtonClick = () => {
        patchSchoolSubjectUnitAPI.send(
            { ...form.getValues(), schoolSubject: schoolSubject.id },
            {
                additionalPath: schoolSubjectUnit.id,
            },
        );
    };

    const handleAddLearningElementButtonClick = () => {
        if (!confirm('추가하시겠습니까?')) {
            return;
        }

        addLearningElementAPI.send(null, {
            additionalPath: `${schoolSubjectUnit.id}/create_learning_element`,
        });
    };

    const tableRowClickHandler = row => {
        setSelectedLearningElement(row);
        learningElementModal.open();
    };

    const relatedSchoolSubjectClickHandler = schoolSubject => {
        setSchoolSubject(schoolSubject);
    };

    const initializeForm = () => {
        const { schoolSubject: schoolSubjectName } = schoolSubjectUnit;

        const schoolSubject = getSchoolSubjectFromContext(
            schoolSubjectName,
            'name',
        );
        setSchoolSubject(schoolSubject);

        form.reset();
    };

    useEffect(() => {
        initializeForm();
    }, [schoolSubjectUnit]);

    useEffect(() => {
        setLearningElements(schoolSubjectUnit.learningElements);
    }, [schoolSubjectUnit]);

    return (
        <div id="admin-school-subject-unit-detail-view">
            <div>단원 관리</div>
            <div>
                <Form>
                    <div className="form-line mt-3">
                        <div className="form-group w-100">
                            <div className="row justify-content-between align-items-center mb-3">
                                <div>
                                    <h6 className="mr-2 d-inline-block">
                                        상위 과목명
                                    </h6>
                                </div>
                                <div className="position-relative">
                                    {schoolSubject && schoolSubject.name}
                                </div>
                                <div>
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={
                                            searchRelatedSchoolSubjectsModal.open
                                        }
                                    >
                                        변경
                                    </button>
                                    <SearchRelatedSchoolSubjectsModal
                                        handleClick={
                                            relatedSchoolSubjectClickHandler
                                        }
                                        {...searchRelatedSchoolSubjectsModal}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-line mt-3">
                        <div className="form-group w-100">
                            <div className="row justify-content-between align-items-center mb-3">
                                <div>
                                    <h6 className="mr-2 d-inline-block">
                                        단원명
                                    </h6>
                                </div>
                            </div>
                            <div className="position-relative">
                                <input
                                    name="name"
                                    defaultValue={schoolSubjectUnit.name}
                                    ref={form.register}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-line mt-3">
                        <div className="form-group w-100">
                            <div className="row justify-content-between align-items-center mb-3">
                                <div>
                                    <h6 className="mr-2 d-inline-block">
                                        단원코드
                                    </h6>
                                </div>
                            </div>
                            <div className="position-relative">
                                <input
                                    name="code"
                                    defaultValue={schoolSubjectUnit.code}
                                    ref={form.register}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-line mt-3">
                        <div className="form-group w-100">
                            <div className="row justify-content-end align-items-center mb-3">
                                <button
                                    type="button"
                                    onClick={() =>
                                        handleDeleteSchoolSubjectUnitButtonClick()
                                    }
                                    className="btn btn-warning"
                                >
                                    삭제
                                </button>
                                <button
                                    type="button"
                                    onClick={() => initializeForm()}
                                    className="ml-3 btn btn-danger"
                                >
                                    초기화
                                </button>
                                <button
                                    onClick={() => handleSubmitButtonClick()}
                                    type="button"
                                    className="ml-3 btn btn-primary"
                                >
                                    저장
                                </button>
                            </div>
                        </div>
                    </div>
                </Form>
                <div>
                    <div className="d-flex justify-content-between w-100">
                        <div className="d-flex">
                            <h2>성취 기준</h2>
                        </div>
                        <div className="d-flex">
                            <button
                                onClick={handleAddLearningElementButtonClick}
                                className="btn btn-danger"
                            >
                                추가
                            </button>
                        </div>
                    </div>
                    <BaseTable
                        columnNames={columnNames}
                        onClick={tableRowClickHandler}
                        data={learningElements.map((e, index) => {
                            return {
                                ...e,
                                index,
                                achievementStandardsCount:
                                    e.achievementStandards.length,
                            };
                        })}
                    />
                    <AdminLearningElementModifyModal
                        schoolSubjectUnitId={schoolSubjectUnit.id || -1}
                        learningElement={selectedLearningElement}
                        setLearningElements={setLearningElements}
                        {...learningElementModal}
                    />
                </div>
            </div>
        </div>
    );
};
