import React from 'react';
import { Modal } from 'react-bootstrap';
import { LatexDisplay } from 'Components';

import { useHistory } from 'react-router-dom';

export const AdminDriectlyManagedConceptModal = ({
    concept,
    setConcept,
    isOpen = false,
    close,
}) => {
    const history = useHistory();

    const handleCloseButtonClick = () => {
        setConcept();
        close();
    };

    const handleSelectButtonClick = () => {
        history.push({
            pathname: `/admin/directly-managed/study-subjects/`,
            state: {
                searchField: 'concept__name',
                searchValue: concept.name,
            },
        });
    };

    return (
        <Modal
            id="search-concepts-modal"
            className="modal-narrow"
            show={isOpen}
            onHide={close}
        >
            {concept && (
                <>
                    <Modal.Header className="d-block">
                        <bold>{concept.name}</bold>에 대한 설명이에요.
                        <br /><br />
                        <bold>{concept.name}</bold>를 활용한
                        전공생이 제안하는 세특 과제를 보고 싶다면
                        <br />
                        "과제 보기" 버튼을 클릭하세요
                    </Modal.Header>
                    <Modal.Body>
                        <div className="container">
                            <div className="row justify-content-center">
                                <br />
                                원리: {concept.name}
                                <br /><br />
                                <LatexDisplay latex={concept.description} />
                                <div className="row w-100 mt-5">
                                    <div className="col-6">
                                        <button
                                            type="button"
                                            onClick={handleCloseButtonClick}
                                            className="btn btn-secondary w-100 rounded-0"
                                        >
                                            다시 선택
                                        </button>
                                    </div>
                                    <div className="col-6">
                                        <button
                                            type="button"
                                            onClick={handleSelectButtonClick}
                                            className="btn btn-primary w-100 rounded-0"
                                        >
                                            과제 보기
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </>
            )}
        </Modal>
    );
};
