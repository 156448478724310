import React, { useContext, useEffect } from 'react';

import {
    BrowserRouter as Router,
    withRouter,
    Switch,
    Route,
    Redirect,
    useLocation,
} from 'react-router-dom';

import * as Views from 'Views';
import { AppContext } from 'Contexts';

import { AuthenticatedOnlyRoute } from './AuthenticatedOnlyRoute';
import { EntityPrefetchRoute } from './EntityPrefetchRoute';

const AdminRoutesWithRouter = withRouter(router => {
    const { user } = useContext(AppContext);
    const isAuthenticated = !!user.id;

    return (
        <Switch>
            {/* research assistants */}
            <AuthenticatedOnlyRoute
                exact
                routeComponent={EntityPrefetchRoute}
                component={Views.AdminResearchAssistantDetailView}
                isAuthenticated={isAuthenticated}
                path="/admin/management/research-assistants/:id/"
            />
            <AuthenticatedOnlyRoute
                component={Views.AdminResearchAssistantListView}
                isAuthenticated={isAuthenticated}
                path="/admin/management/research-assistants/"
            />
            {/* missions */}
            <AuthenticatedOnlyRoute
                exact
                component={Views.AdminMissionNoticeCreateView}
                isAuthenticated={isAuthenticated}
                path="/admin/management/missions/:id/create/"
            />
            <AuthenticatedOnlyRoute
                exact
                component={Views.AdminMissionDetailView}
                isAuthenticated={isAuthenticated}
                path="/admin/management/missions/:id/"
            />
            <AuthenticatedOnlyRoute
                component={Views.AdminMissionListView}
                isAuthenticated={isAuthenticated}
                path="/admin/management/missions/"
            />
            {/* points withdrawals */}
            <AuthenticatedOnlyRoute
                component={Views.AdminPointsWithdrawalListView}
                isAuthenticated={isAuthenticated}
                path="/admin/management/points-withdrawals/"
            />
            {/* test reports */}
            <AuthenticatedOnlyRoute
                exact
                component={Views.AdminTestReportDetailView}
                isAuthenticated={isAuthenticated}
                path="/admin/management/test-reports/:id/"
            />
            <AuthenticatedOnlyRoute
                component={Views.AdminTestReportListView}
                isAuthenticated={isAuthenticated}
                path="/admin/management/test-reports/"
            />

            {/* base data */}
            <AuthenticatedOnlyRoute
                exact
                routeComponent={EntityPrefetchRoute}
                component={Views.AdminSchoolSubjectUnitDetailView}
                isAuthenticated={isAuthenticated}
                path="/admin/management/base-data/school-subject-units/:id/"
            />
            <AuthenticatedOnlyRoute
                component={Views.AdminSchoolSubjectUnitListView}
                isAuthenticated={isAuthenticated}
                path="/admin/management/base-data/school-subject-units/"
            />
            <AuthenticatedOnlyRoute
                component={Views.AdminSchoolSubjectListView}
                isAuthenticated={isAuthenticated}
                path="/admin/management/base-data/school-subjects/"
            />

            {/* concepts */}
            <AuthenticatedOnlyRoute
                exact
                routeComponent={EntityPrefetchRoute}
                component={Views.AdminConceptDetailView}
                isAuthenticated={isAuthenticated}
                path="/admin/main/concepts/:id/"
            />
            <AuthenticatedOnlyRoute
                exact
                component={Views.AdminConceptListView}
                isAuthenticated={isAuthenticated}
                path="/admin/main/concepts/"
            />
            {/* mission reports */}
            <AuthenticatedOnlyRoute
                routeComponent={EntityPrefetchRoute}
                component={Views.AdminMissionReportStudySubjectPatchView}
                isAuthenticated={isAuthenticated}
                path="/admin/main/mission-reports/:id/study-subject/"
            />
            <AuthenticatedOnlyRoute
                routeComponent={EntityPrefetchRoute}
                component={Views.AdminMissionReportConceptPatchView}
                isAuthenticated={isAuthenticated}
                path="/admin/main/mission-reports/:id/concept/"
            />
            <AuthenticatedOnlyRoute
                component={Views.AdminMissionReportListView}
                isAuthenticated={isAuthenticated}
                path="/admin/main/mission-reports/"
            />
            {/* issued study subjects */}
            <AuthenticatedOnlyRoute
                exact
                routeComponent={EntityPrefetchRoute}
                component={Views.AdminIssuedStudySubjectInspectView}
                isAuthenticated={isAuthenticated}
                path="/admin/main/issued-study-subjects/inspect/:id/"
            />
            <AuthenticatedOnlyRoute
                exact
                component={Views.AdminIssuedStudySubjectCreateView}
                isAuthenticated={isAuthenticated}
                path="/admin/main/issued-study-subjects/create/"
            />
            <AuthenticatedOnlyRoute
                exact
                routeComponent={EntityPrefetchRoute}
                component={Views.AdminIssuedStudySubjectDetailView}
                isAuthenticated={isAuthenticated}
                path="/admin/main/issued-study-subjects/:id/"
            />
            <AuthenticatedOnlyRoute
                component={Views.AdminIssuedStudySubjectListView}
                isAuthenticated={isAuthenticated}
                path="/admin/main/issued-study-subjects/"
            />
            {/* study-subjects */}
            <AuthenticatedOnlyRoute
                routeComponent={EntityPrefetchRoute}
                component={Views.AdminStudySubjectIssueView}
                isAuthenticated={isAuthenticated}
                path="/admin/main/study-subjects/:id/issue/"
            />
            <AuthenticatedOnlyRoute
                routeComponent={EntityPrefetchRoute}
                component={Views.AdminStudySubjectDetailView}
                isAuthenticated={isAuthenticated}
                path="/admin/main/study-subjects/:id/"
            />
            <AuthenticatedOnlyRoute
                component={Views.AdminStudySubjectListView}
                isAuthenticated={isAuthenticated}
                path="/admin/main/study-subjects/"
            />
            {/* study subject orders */}
            <AuthenticatedOnlyRoute
                exact
                component={Views.AdminStudySubjectOrderDetailView}
                isAuthenticated={isAuthenticated}
                path="/admin/main/study-subject-orders/:id/"
            />
            <AuthenticatedOnlyRoute
                component={Views.AdminStudySubjectOrderListView}
                isAuthenticated={isAuthenticated}
                path="/admin/main/study-subject-orders/"
            />
            {/* statistics */}
            <AuthenticatedOnlyRoute
                component={Views.AdminStudySubjectDemandSupplyStatisticView}
                isAuthenticated={isAuthenticated}
                path="/admin/statistics/study-subject-supply-demand/"
            />

            {/* directly managed */}
            <AuthenticatedOnlyRoute
                component={Views.AdminDirectlyManagedStudySubjectListView}
                isAuthenticated={isAuthenticated}
                path="/admin/directly-managed/study-subjects/"
            />
            <AuthenticatedOnlyRoute
                component={Views.AdminIssuedStudySubjectDetailView}
                isAuthenticated={isAuthenticated}
                path="/admin/directly-managed/study-subjects/:id/"
            />

            <Route exact component={Views.AdminAuthView} path="/admin/auth/" />
            <Redirect to="/admin/main/study-subject-orders/" />
        </Switch>
    );
});

export const AdminRoutes = props => {
    return <AdminRoutesWithRouter {...props} />;
};
