import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import styled from 'styled-components';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { useAPI, APIRoute } from 'Client';
import { AppContext } from 'Contexts';

import queryString from 'query-string';

const IntroduceContent = styled.div`
    position: relative;
    border: 0.0625rem solid #d7e2eb;
    border-radius: 0.75rem;
    overflow: hidden;
    padding: 1.5rem;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 4rem;
    margin-top: 1rem;
`;

export const RANoticeDetail = props => {
    const history = useHistory();

    const { user } = useContext(AppContext);

    const [title, setTitle] = useState('');
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    const announcementSendAPI = useAPI(
        APIRoute.ResearchAssistant.Announcement.List,
        {
            callbacks: {
                onSuccess: res => {
                    setTitle(res.title);
                    const blocksFromHtml = htmlToDraft(res.context);
                    if (blocksFromHtml) {
                        const { contentBlocks, entityMap } = blocksFromHtml;
                        // https://draftjs.org/docs/api-reference-content-state/#createfromblockarray
                        const contentState = ContentState.createFromBlockArray(
                            contentBlocks,
                            entityMap,
                        );
                        // ContentState를 EditorState기반으로 새 개체를 반환.
                        // https://draftjs.org/docs/api-reference-editor-state/#createwithcontent
                        const editorState = EditorState.createWithContent(
                            contentState,
                        );
                        setEditorState(editorState);
                    }
                },
            },
        },
    );

    const announcementAdminSendAPI = useAPI(APIRoute.Admin.Announcement.List, {
        callbacks: {
            onSuccess: res => {
                setTitle(res.title);
                const blocksFromHtml = htmlToDraft(res.context);
                if (blocksFromHtml) {
                    const { contentBlocks, entityMap } = blocksFromHtml;
                    // https://draftjs.org/docs/api-reference-content-state/#createfromblockarray
                    const contentState = ContentState.createFromBlockArray(
                        contentBlocks,
                        entityMap,
                    );
                    // ContentState를 EditorState기반으로 새 개체를 반환.
                    // https://draftjs.org/docs/api-reference-editor-state/#createwithcontent
                    const editorState = EditorState.createWithContent(
                        contentState,
                    );
                    setEditorState(editorState);
                }
            },
        },
    });

    const announcementAdminDeleteAPI = useAPI(
        APIRoute.Admin.Announcement.Delete,
        {
            callbacks: {
                onSuccess: res => {
                    history.push('/admin/management/mission/');
                },
            },
        },
    );

    const editorToHtml = draftToHtml(
        convertToRaw(editorState.getCurrentContent()),
    );

    const queryObj = queryString.parse(location.search);
    const { id } = queryObj;

    useEffect(() => {
        if (user.type === 'ADMIN')
            announcementAdminSendAPI.send(null, { additionalPath: id });
        else announcementSendAPI.send(null, { additionalPath: id });
    }, []);

    return (
        <div className="ra-mission-container">
            <div className="ra-mission-title">Mission</div>
            <div className="ra-mission-category">
                진로 탐색을 위한 탐구과제 생성
            </div>
            <div className="ra-notice-line"></div>
            <div
                className="admin-back-btn cursor-pointer mt-3"
                onClick={() => history.goBack()}
            >
                &#xE000;&nbsp; 뒤로가기
            </div>
            <div className="ra-mission-tabel-title-container">
                <div className="ra-mission-tabel-title">제목 : {title}</div>
            </div>
            <IntroduceContent
                dangerouslySetInnerHTML={{ __html: editorToHtml }}
            />
            {user.type === 'ADMIN' && (
                <div className="offset-4 col-3 justify-content-center align-items-center d-flex">
                    <button
                        onClick={() =>
                            announcementAdminDeleteAPI.send(null, {
                                additionalPath: id,
                            })
                        }
                        type="button"
                        className="btn btn-danger btn-block"
                    >
                        삭제하기
                    </button>
                </div>
            )}
        </div>
    );
};
