import React from 'react';
import { useLocation } from 'react-router-dom';

import { StudySubjectTable } from 'Components/table';

export const AdminDirectlyManagedStudySubjectListView = props => {
    const location = useLocation();
    const { searchField, searchValue } = location.state || {};
    return (
        <StudySubjectTable
            searchField={searchField}
            searchValue={searchValue}
            blockTable={true}
        />
    );
};
