import React from 'react';

import error from '../assets/img/404.png'
export class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            isErrorDetailShown: false,
            error: null,
            errorInfo: null,
        };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        console.error(error);
        this.setState({
            error,
            errorInfo,
        });
    }

    handleReloadButtonClick() {
        location.href = '/';
    }

    handleShowErrorDetailButtonClick() {
        this.setState({
            isErrorDetailShown: !this.state.isErrorDetailShown,
        });
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className="row w-100 h-100 justify-content-center">
                    <div className="error-view-img-container">
                        <img src={error} className="error-view-img" />
                    </div>
                    <div className="error-view-container">
                        <div className="info">
                            <h5>페이지 처리에 문제가 발생하였습니다.</h5>
                            <h5>
                                아래 새로고침을 클릭하여 다시 접속시도하십시오.
                            </h5>
                            <h5>
                                에러가 지속적으로 반복될 경우 관리자에게
                                문의해주세요.
                            </h5>
                        </div>
                        <div className="button-group mt-3">
                            <button
                                className="btn btn-main-color"
                                onClick={() => this.handleReloadButtonClick()}
                                variant="dark"
                            >
                                새로고침
                            </button>
                            <button
                                className="btn btn-border-main ml-5"
                                onClick={() =>
                                    this.handleShowErrorDetailButtonClick()
                                }
                                variant="danger"
                            >
                                {this.state.isErrorDetailShown
                                    ? '닫기'
                                    : '자세히'}
                            </button>
                        </div>
                        {this.state.isErrorDetailShown && (
                            <div className="error-log">
                                <p>
                                    {this.state.error.toString()}
                                    <br />
                                    <br />
                                    {this.state.error.stack}
                                    <br />
                                    <br />
                                    {JSON.stringify(this.state.errorInfo)}
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            );
        }

        return this.props.children;
    }
}
