import React, { useEffect, useState, useContext } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import { MajorSelector } from '../../components/tag-wizard/MajorSelector';

import { useHistory } from 'react-router-dom';
import { AppContext } from 'Contexts/AppContext';

import { useAPI, APIRoute } from 'Client';
import Select from 'react-select';

export const univEmail = [
    { value: '서울대학교', label: 'snu.ac.kr' },
    { value: '연세대학교', label: 'yonsei.ac.kr' },
    { value: '고려대학교', label: 'korea.ac.kr' },
    { value: 'KAIST', label: 'kaist.ac.kr' },
    { value: 'POSTECH', label: 'postech.ac.kr' },
    { value: '서강대학교', label: 'sogang.ac.kr' },
    { value: '성균관대학교', label: 'skku.edu' },
    { value: '성균관대학교', label: 'skku.ac.kr' },
    { value: '성균관대학교', label: 'g.skku.edu' },
    { value: '이화여자대학교', label: 'ewha.ac.kr' },
    { value: '이화여자대학교', label: 'ewhain.net' },
    { value: '중앙대학교', label: 'cau.ac.kr' },
    { value: '경희대학교', label: 'khu.ac.kr' },
    { value: '한국외국어대학교', label: 'hufs.ac.kr' },
    { value: '서울시립대학교', label: 'uos.ac.kr' },
];

export const gradeArr = [
    { value: 2021, label: '2021학번' },
    { value: 2020, label: '2020학번' },
    { value: 2019, label: '2019학번' },
    { value: 2018, label: '2018학번' },
    { value: 2017, label: '2017학번' },
    { value: 2016, label: '2016학번' },
    { value: 2015, label: '2015학번' },
    { value: 2014, label: '2014학번' },
    { value: 2013, label: '2013학번' },
    { value: 2012, label: '2012학번' },
    { value: 2011, label: '2011학번' },
    { value: 2010, label: '2010학번' },
    { value: 2009, label: '2009학번' },
];

export const StudentValidationView = ({ location, ...props }) => {
    const history = useHistory();
    const { researchAssistant } = useContext(AppContext);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [code, setCode] = useState('');
    const [extraMajor, setExtraMajor] = useState('');
    const [graduation, setGraduation] = useState(false);
    const [university, setUniversity] = useState('');
    const [major, setMajor] = useState('');
    const [grade, setGrade] = useState('');
    const [emailSend, setEmailSend] = useState(false);
    const [emailValidated, setEmailValidated] = useState(false);
    const [studentRecordPreparation, setStudentRecordPreparation] = useState(
        false,
    );
    const [studentRecordAcceptance, setStudentRecordAcceptance] = useState(
        false,
    );

    const [tags] = useState([]);

    const emailSubmitAPI = useAPI(
        APIRoute.ResearchAssistant.TestReport.EmailValidation,
        {
            callbacks: {
                onSuccess: res => {
                    alert('인증메일 발송이 완료되었습니다.');
                    setEmailSend(true);
                },
            },
        },
    );

    const validationSubmitAPI = useAPI(
        {
            url: `research-assistant/profiles/${researchAssistant.profile.id}/`,
            method: 'patch',
        },
        {
            callbacks: {
                onSuccess: res => {
                    history.push('/ra/test/agreement/');
                },
            },
        },
    );

    const emailValidationStatusAPI = useAPI(
        APIRoute.ResearchAssistant.TestReport.EmailValidationStatus,
        {
            callbacks: {
                onSuccess: res => {
                    setEmailSend(true);
                    setEmailValidated(true);
                    setUniversity(res.message);
                },
            },
        },
    );

    const emailCodeCheckAPI = useAPI(
        APIRoute.ResearchAssistant.TestReport.EmailCodeCheck,
        {
            callbacks: {
                onSuccess: res => {
                    alert('인증완료!');
                    setEmailValidated(true);
                },
            },
        },
    );

    const checkAllChecked = () => {
        return emailValidated && major && grade;
    };

    useEffect(() => {
        emailValidationStatusAPI.send();
        if (researchAssistant.profile.major.length)
            history.push('/ra/test/agreement/');
    }, []);

    return (
        <div className="ra-mission-container">
            <div className="ra-mission-title">Mission</div>
            <div className="ra-mission-category">
                전공 탐색을 위한 탐구과제 생성
            </div>
            <div className="ra-test-description">
                이 미션은 자격 검증이 필요한 미션입니다. 아래의 내용을
                입력해주세요.
            </div>
            <hr />
            {/* 대학 */}
            <section>
                <h5>1. 대학 인증하기</h5>
                <div className="ra-test-email-descripton">
                    <span>
                        서울대, 연세대, 고려대, KAIST, POSTECH, 서강대,
                        성균관대, 이화여대, 중앙대, 경희대, 외대, 시립대
                    </span>{' '}
                    학생을 대상으로 진행하는 미션입니다.
                </div>
                <div className="ra-test-email-container">
                    <input
                        className="ra-test-input email"
                        placeholder="대학 이메일 주소를 입력해주세요."
                        onChange={e => setName(e.target.value)}
                        maxLength={30}
                        value={name}
                        disabled={emailSend}
                    />
                    <div className="ml-2 mr-2">@</div>
                    <div className="ra-test-select-container">
                        <Select
                            options={univEmail}
                            placeholder="email"
                            onChange={v => setEmail(v)}
                        />
                    </div>
                    <div className="ra-test-select-btn">
                        <Button
                            onClick={() => {
                                if (!name || !email)
                                    alert('이름과 이메일을 입력해주세요!');
                                else {
                                    emailSubmitAPI.send({
                                        universityEmail: `${name}@${email.label}`,
                                    });
                                }
                            }}
                            className="w-100 pt-2 pb-2 btn-main-color"
                            disabled={emailSend}
                        >
                            인증코드 전송
                        </Button>
                    </div>
                </div>
                {emailSend && (
                    <div className="ra-test-email-container">
                        <input
                            className="ra-test-input left"
                            placeholder="인증코드를 입력하세요"
                            onChange={e => setCode(e.target.value)}
                            maxLength={30}
                            value={emailValidated ? '인증완료' : code}
                            disabled={emailValidated}
                        />
                        <div className="ra-test-select-btn">
                            <Button
                                onClick={() => {
                                    if (!code)
                                        alert('인증코드를 입력해주세요!');
                                    else {
                                        emailCodeCheckAPI.send({ code: code });
                                    }
                                }}
                                className="w-100 pt-2 pb-2 btn-main-color"
                                disabled={emailValidated}
                            >
                                확인
                            </Button>
                        </div>
                    </div>
                )}
            </section>
            {/* 전공, 부전공 */}
            <section className="mt-4">
                <h5>
                    2. 학과(전공)(부전공, 복수전공, 이중전공, 연계전공, 연합전공
                    포함)
                </h5>
                <div className="home-select-container">
                    <input
                        className="ra-test-input left"
                        placeholder="대학교"
                        maxLength={30}
                        value={email ? email.value : university}
                        disabled={true}
                    />
                </div>
                <div className="home-select-container m-0">
                    <MajorSelector
                        next={setMajor}
                        currentStep={100}
                        tags={tags}
                    />
                </div>
            </section>
            {/* 학년 */}
            <section className="mt-4 row align-items-center">
                <h5 className="mb-0">4. 입학년도</h5>
                <div className="ra-test-grade-select-container">
                    <Select
                        defaultValue={grade[0]}
                        options={gradeArr}
                        onChange={v => setGrade(v.value)}
                        placeholder="학번"
                    />
                </div>
            </section>
            {/* 학생부 종합전형 준비 */}
            <section className="mt-4 row align-items-center ">
                <h5 className="mb-0">5. 졸업여부</h5>
                <div className="ml-3">
                    {graduation ? (
                        <CircleCheckedFilled
                            fontSize="large"
                            className="cursor-pointer text-main-color"
                        />
                    ) : (
                        <CircleUnchecked
                            onClick={() => setGraduation(true)}
                            fontSize="large"
                            className="cursor-pointer text-border-color"
                        />
                    )}{' '}
                    예
                </div>
                <div className="ml-3">
                    {!graduation ? (
                        <CircleCheckedFilled
                            fontSize="large"
                            className="cursor-pointer text-main-color"
                        />
                    ) : (
                        <CircleUnchecked
                            onClick={() => setGraduation(false)}
                            fontSize="large"
                            className="cursor-pointer text-border-color"
                        />
                    )}{' '}
                    아니오
                </div>
            </section>
            {/* 학생부 종합전형 준비 */}
            <section className="mt-4 row align-items-center ">
                <h5 className="mb-0">
                    6. 학생부종합전형을 준비해본 적이 있으신가요?
                </h5>
                <div className="ml-3">
                    {studentRecordPreparation ? (
                        <CircleCheckedFilled
                            fontSize="large"
                            className="cursor-pointer text-main-color"
                        />
                    ) : (
                        <CircleUnchecked
                            onClick={() => setStudentRecordPreparation(true)}
                            fontSize="large"
                            className="cursor-pointer text-border-color"
                        />
                    )}{' '}
                    예
                </div>
                <div className="ml-3">
                    {!studentRecordPreparation ? (
                        <CircleCheckedFilled
                            fontSize="large"
                            className="cursor-pointer text-main-color"
                        />
                    ) : (
                        <CircleUnchecked
                            onClick={() => setStudentRecordPreparation(false)}
                            fontSize="large"
                            className="cursor-pointer text-border-color"
                        />
                    )}{' '}
                    아니오
                </div>
            </section>
            <section className="mt-4 row">
                <h5 className="mb-0">
                    7. 학생부종합전형에 합격한 적이 있으신가요?
                </h5>
                <div className="ml-3">
                    {studentRecordAcceptance ? (
                        <CircleCheckedFilled
                            fontSize="large"
                            className="cursor-pointer text-main-color"
                        />
                    ) : (
                        <CircleUnchecked
                            onClick={() => setStudentRecordAcceptance(true)}
                            fontSize="large"
                            className="cursor-pointer text-border-color"
                        />
                    )}{' '}
                    예
                </div>
                <div className="ml-3">
                    {!studentRecordAcceptance ? (
                        <CircleCheckedFilled
                            fontSize="large"
                            className="cursor-pointer text-main-color"
                        />
                    ) : (
                        <CircleUnchecked
                            onClick={() => setStudentRecordAcceptance(false)}
                            fontSize="large"
                            className="cursor-pointer text-border-color"
                        />
                    )}{' '}
                    아니오
                </div>
            </section>
            <Row className="justify-content-center mt-5">
                <Col md={8} className="pt-2 pb-2">
                    <Button
                        type="submit"
                        variant={'primary'}
                        disabled={!checkAllChecked()}
                        onClick={() =>
                            validationSubmitAPI.send({
                                major: major,
                                universityEntranceYear: grade,
                                extraMajor: extraMajor,
                                studentRecordPreparation: studentRecordPreparation,
                                universityAttendStatus: graduation
                                    ? 'GRADUATION'
                                    : 'IN_SCHOOL',
                                studentRecordAcceptance: studentRecordAcceptance,
                            })
                        }
                        className="w-100 pt-3 pb-3 btn-main-color"
                    >
                        {checkAllChecked()
                            ? '제출하기'
                            : '모든 항목을 입력해주세요.'}
                    </Button>
                </Col>
            </Row>
        </div>
    );
};
