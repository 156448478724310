import React, { useState, useContext, useEffect } from 'react';
import { AppContext } from 'Contexts';
import { generateUuid } from 'Utils';

import { LearningElementsSelector } from './LearningElementsSelector';

export const SchoolSubjectUnitSelector = ({
    subjectExists,
    checkUnitExists,
    checkElementExists,
    addLearningElements,
    addSchoolSubjectUnit,
    schoolSubjectUuid,
    schoolSubjectName,
    recommendedSchoolSubjectUnit,
    toggle,
    ...props
}) => {
    const {
        researchAssistant,
        getters: { getSchoolSubjectUnit, getSchoolLearningElements },
    } = useContext(AppContext);
    const [schoolSubjectUnit, setSchoolSubjectUnit] = useState(null);
    const [learningElements, setLearningElements] = useState([]);
    const [selectedLearningElements, setSelectedLearningElements] = useState(
        [],
    );

    const schoolSubjectUnits = researchAssistant.schoolSubjectUnits.filter(
        ({ schoolSubject: _ }) => _.replace(/_/g, ' ') === schoolSubjectName,
    );

    const handleSchoolSubjectUnitClick = ({ id: schoolSubjectUnitId }) => {
        const schoolSubjectUnit = getSchoolSubjectUnit(schoolSubjectUnitId);
        const { learningElements } = schoolSubjectUnit;
        setSelectedLearningElements([]);
        setLearningElements(learningElements);
        setSchoolSubjectUnit(schoolSubjectUnit);
    };

    const handleCancleButtonClick = e => {
        subjectExists(schoolSubjectUuid);
        toggle();
    };

    const handleConfirmButtonClick = e => {
        if (selectedLearningElements.length == 0)
            alert('학습요소를 1개 이상 선택해주세요!\n(복수선택가능)');
        else {
            let existId = checkUnitExists(schoolSubjectUnit.id);
            const schoolSubjectUnitUuid = existId || generateUuid();
            if (!existId) {
                addSchoolSubjectUnit({
                    ...schoolSubjectUnit,
                    schoolSubjectUnitUuid,
                });
            }
            let notOverlappedLearningElements = [];
            for (let element of selectedLearningElements) {
                let existElement = checkElementExists(
                    schoolSubjectUnitUuid,
                    element.name,
                );
                if (!existElement) {
                    notOverlappedLearningElements.push(element);
                }
            }
            addLearningElements(
                schoolSubjectUnitUuid,
                notOverlappedLearningElements,
            );
            toggle();
        }
    };

    useEffect(() => {
        if (recommendedSchoolSubjectUnit) {
            const { learningElements } = recommendedSchoolSubjectUnit;
            setSelectedLearningElements([]);
            setLearningElements(learningElements);
            setSchoolSubjectUnit(recommendedSchoolSubjectUnit);
        }
    }, []);

    return (
        <div className="mt-4 pr-5 pl-5">
            <div className="row">
                <div className="col-6 scroll border-dark-color p-5">
                    {schoolSubjectUnits.map((_schoolSubjectUnit, index) => {
                        console.log(schoolSubjectUnit);
                        console.log;
                        const innerSchoolSubjectUnitName = _schoolSubjectUnit?.name.replace(
                            /_/g,
                            ' ',
                        );
                        return (
                            <div
                                key={index}
                                onClick={() =>
                                    handleSchoolSubjectUnitClick(
                                        _schoolSubjectUnit,
                                    )
                                }
                                className="mt-3 mb-3"
                            >
                                <h6
                                    className={`${
                                        schoolSubjectUnit &&
                                        schoolSubjectUnit.name ===
                                            innerSchoolSubjectUnitName
                                            ? 'text-primary cursor-pointer'
                                            : 'text-muted cursor-pointer'
                                    }`}
                                >
                                    {innerSchoolSubjectUnitName}
                                </h6>
                            </div>
                        );
                    })}
                </div>

                <LearningElementsSelector
                    learningElements={learningElements}
                    selectedLearningElements={selectedLearningElements}
                    setSelectedLearningElements={setSelectedLearningElements}
                    schoolSubjectUnit={schoolSubjectUnit}
                />

                <div className="col-12 d-flex justify-content-end mt-3">
                    <button
                        type="button"
                        style={{ backgroundColor: 'gray' }}
                        onClick={handleCancleButtonClick}
                        className="btn btn-main-color btn-lg rounded-0 mr-2"
                    >
                        취소
                    </button>
                    <button
                        type="button"
                        onClick={handleConfirmButtonClick}
                        className="btn btn-main-color btn-lg rounded-0 "
                    >
                        확인
                    </button>
                </div>
            </div>
        </div>
    );
};
