import React, { useContext, useEffect } from 'react';

import {
    BrowserRouter as Router,
    withRouter,
    Switch,
    Route,
    useLocation,
} from 'react-router-dom';

import * as Views from 'Views';
import { AppContext } from 'Contexts';
import { Header } from 'Components/Header';
import { Footer } from 'Components/Footer';

import { AnonymousOnlyRoute } from './AnonymousOnlyRoute';
import { AuthenticatedOnlyRoute } from './AuthenticatedOnlyRoute';
import { EntityPrefetchRoute } from './EntityPrefetchRoute';
import { ViewStreamSharp } from '@material-ui/icons';

const MainRoutesWithRouter = withRouter(router => {
    const { user, researchAssistant, admin, staff } = useContext(AppContext);
    const isAuthenticated = !!user.id;

    return (
        <Switch>
            <AnonymousOnlyRoute
                isAuthenticated={isAuthenticated}
                userType={user.type}
                component={Views.MainView}
                exact
                path="/"
            />
            <AnonymousOnlyRoute
                isAuthenticated={isAuthenticated}
                userType={user.type}
                component={Views.SignUpView}
                exact
                path="/signup/"
            />
            <AuthenticatedOnlyRoute
                component={Views.MyPageView}
                exact
                isAuthenticated={isAuthenticated}
                path="/mypage/"
            />
            <AuthenticatedOnlyRoute
                component={Views.MissionTutorialView}
                exact
                isAuthenticated={isAuthenticated}
                path="/missions/tutorial/"
            />
            <AuthenticatedOnlyRoute
                component={Views.MissionDetailView}
                routeComponent={EntityPrefetchRoute}
                exact
                isAuthenticated={isAuthenticated}
                path="/missions/:id/"
            />
            {/* <AuthenticatedOnlyRoute
                component={Views.MissionListView}
                routeComponent={EntityPrefetchRoute}
                exact
                isAuthenticated={isAuthenticated}
                path="/missions/"
            /> */}
            <AuthenticatedOnlyRoute
                component={Views.PointsWithdrawalCreateView}
                exact
                isAuthenticated={isAuthenticated}
                path="/points-withdrawals/create/"
            />
            <AuthenticatedOnlyRoute
                component={Views.StudySubjectCreateSuccessView}
                exact
                isAuthenticated={isAuthenticated}
                beforeRouteGuard={!!researchAssistant.currentMissionId}
                path="/study-subjects/complete/"
            />
            <AuthenticatedOnlyRoute
                component={Views.StudySubjectCreatePatchView}
                exact
                isAuthenticated={true}
                beforeRouteGuard={!!researchAssistant.currentMissionId}
                path="/study-subjects/create/"
            />
            <AuthenticatedOnlyRoute
                routeComponent={EntityPrefetchRoute}
                component={Views.StudySubjectDetailView}
                exact
                isAuthenticated={isAuthenticated}
                path="/study-subjects/:id/"
            />
            <AuthenticatedOnlyRoute
                routeComponent={EntityPrefetchRoute}
                component={Views.StudySubjectCreatePatchView}
                exact
                isAuthenticated={isAuthenticated}
                path="/study-subjects/:id/patch/"
            />
            <AuthenticatedOnlyRoute
                component={Views.DongaScienceCreateView}
                exact
                isAuthenticated={isAuthenticated}
                beforeRouteGuard={!!researchAssistant.currentMissionId}
                path="/donga-sciences/create/"
            />
            <AuthenticatedOnlyRoute
                component={Views.MissionDongaReportView}
                exact
                isAuthenticated={isAuthenticated}
                routeComponent={EntityPrefetchRoute}
                path="/donga-sciences/:id/"
            />
            <AuthenticatedOnlyRoute
                component={Views.ConceptCreateView}
                exact
                isAuthenticated={isAuthenticated}
                beforeRouteGuard={!!researchAssistant.currentMissionId}
                path="/concepts/create/"
            />
            <AuthenticatedOnlyRoute
                component={Views.ConceptDetailView}
                routeComponent={EntityPrefetchRoute}
                exact
                isAuthenticated={isAuthenticated}
                path="/concepts/:id/"
            />

            <Route component={Views.AdminSubmenuView} path="/admin/" />
            <Route component={Views.RAListView} path="/ra/" />
            <Route component={Views.StaffSubmenuView} path="/staff/" />
        </Switch>
    );
});
function _ScrollToTop(props) {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return props.children;
}

const ScrollToTop = withRouter(_ScrollToTop);

export const MainRoutes = () => {
    return (
        <Router>
            <ScrollToTop>
                <div className="router-wrapper">
                    <Header />
                    <div className="home-wrapper">
                        <MainRoutesWithRouter />
                    </div>
                    <Footer />
                </div>
            </ScrollToTop>
        </Router>
    );
};
