import React, { useContext, useState, useEffect } from 'react';
import { AppContext } from 'Contexts';
import { TagAchievementsIssue } from 'Components/modals';

export const TagResultDisplay = ({ tags, back, ...props }) => {
    const {
        getters: { getMajorCategory, getSchoolSubject, getSchoolSubjectUnit },
        dispatchers,
        admin,
    } = useContext(AppContext);

    const [learningElements, setLearningElements] = useState([]);

    const handleSchoolSubjectUnitClick = (
        schoolSubjectUnitId,
        schoolSubjectId,
        learningElements,
    ) => {
        dispatchers.admin.setSelectedSchoolSubject(schoolSubjectId);
        dispatchers.admin.setSelectedSchoolSubjectUnit(schoolSubjectUnitId);

        setLearningElements(learningElements);
    };

    useEffect(() => {
        return () => {
            dispatchers.admin.setSelectedSchoolSubject();
            dispatchers.admin.setSelectedSchoolSubjectUnit();
        };
    }, []);

    return (
        <>
            <div className="row">
                {tags.map(({ majorCategory: majorCategoryId }, index) => {
                    const majorCategory = getMajorCategory(majorCategoryId);
                    if (!majorCategory) {
                        return;
                    }
                    return (
                        <div key={index} className="pr-2">
                            {majorCategory.subcategoryName}
                        </div>
                    );
                })}
            </div>
            {tags
                .filter(e => e.majorCategory !== 0)
                .map((tag, tagIndex) => {
                    const {
                        majorCategory: majorCategoryId,
                        schoolSubjects,
                        schoolSubjectUnits,
                        learningElements,
                    } = tag;

                    if (tags.length > 1 && tagIndex > 0) {
                        return;
                    }

                    const majorCategory = getMajorCategory(majorCategoryId);
                    if (!majorCategory) {
                        return;
                    }

                    return (
                        <React.Fragment key={tagIndex}>
                            <div key={tagIndex} className="mt-4 pr-5 pl-5">
                                <div className="row">
                                    <div className="col-12 scroll border border-dark p-5">
                                        <div>
                                            {schoolSubjects.map(
                                                ({
                                                    isAutoTagged,
                                                    schoolSubjectUuid,
                                                    id: schoolSubjectId,
                                                }) => {
                                                    const schoolSubject = getSchoolSubject(
                                                        schoolSubjectId,
                                                    );
                                                    return (
                                                        <div
                                                            key={
                                                                schoolSubjectUuid
                                                            }
                                                            className="pl-5"
                                                        >
                                                            <h6
                                                                className={`${
                                                                    admin.selectedSchoolSubject &&
                                                                    admin.selectedSchoolSubject ===
                                                                        schoolSubjectId
                                                                        ? 'text-main-color'
                                                                        : 'cursor-pointer'
                                                                }`}
                                                            >
                                                                {schoolSubject &&
                                                                    `${
                                                                        isAutoTagged
                                                                            ? '(자동 태깅 추천) '
                                                                            : ''
                                                                    }${schoolSubject.name.replace(
                                                                        /_/g,
                                                                        ' ',
                                                                    )}`}
                                                            </h6>
                                                            <div className="pl-5">
                                                                {schoolSubjectUnits
                                                                    .filter(
                                                                        ({
                                                                            schoolSubjectUuid: _,
                                                                        }) =>
                                                                            _ ===
                                                                            schoolSubjectUuid,
                                                                    )
                                                                    .map(
                                                                        ({
                                                                            schoolSubjectUnitUuid,
                                                                            name,
                                                                            id: schoolSubjectUnitId,
                                                                        }) => {
                                                                            const innerLearningElements = learningElements.filter(
                                                                                ({
                                                                                    schoolSubjectUnitUuid: _schoolSubjectUnitUuid,
                                                                                    schoolSubjectUuid: _schoolSubjectUuid,
                                                                                }) =>
                                                                                    _schoolSubjectUnitUuid ===
                                                                                        schoolSubjectUnitUuid &&
                                                                                    _schoolSubjectUuid ===
                                                                                        schoolSubjectUuid,
                                                                            );
                                                                            return (
                                                                                <div
                                                                                    key={
                                                                                        schoolSubjectUnitUuid
                                                                                    }
                                                                                    className={`${
                                                                                        admin.selectedSchoolSubjectUnit &&
                                                                                        admin.selectedSchoolSubjectUnit ===
                                                                                            schoolSubjectUnitId
                                                                                            ? 'mt-2 pl-5 text-main-color'
                                                                                            : 'mt-2 pl-5 cursor-pointer'
                                                                                    }`}
                                                                                    onClick={() => {
                                                                                        handleSchoolSubjectUnitClick(
                                                                                            schoolSubjectUnitId,
                                                                                            schoolSubjectId,
                                                                                            innerLearningElements,
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    <span>
                                                                                        {name.replace(
                                                                                            /_/g,
                                                                                            ' ',
                                                                                        )}
                                                                                    </span>
                                                                                    <div className="pl-5 mt-3 mb-3">
                                                                                        {innerLearningElements.map(
                                                                                            ({
                                                                                                name,
                                                                                            }) => {
                                                                                                return (
                                                                                                    <div
                                                                                                        key={
                                                                                                            name
                                                                                                        }
                                                                                                    >
                                                                                                        <span>
                                                                                                            {name.replace(
                                                                                                                /_/g,
                                                                                                                ' ',
                                                                                                            )}
                                                                                                        </span>
                                                                                                    </div>
                                                                                                );
                                                                                            },
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        },
                                                                    )}
                                                            </div>
                                                        </div>
                                                    );
                                                },
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    );
                })}
            <TagAchievementsIssue learningElements={learningElements} />
        </>
    );
};
