import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import styled from 'styled-components';
import { convertToRaw, EditorState } from 'draft-js';
import { useAPI, APIRoute } from 'Client';

const MyBlock = styled.div`
    .wrapper-class {
        width: 100%;
        margin: 0 auto;
        margin-bottom: 2rem;
        margin-top: 1rem;
    }
    .editor {
        height: 500px !important;
        border: 1px solid #f1f1f1 !important;
        padding: 5px !important;
        border-radius: 2px !important;
    }
`;

export const AdminMissionNoticeCreateView = props => {
    const history = useHistory();

    const [title, setTitle] = useState('');

    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    const announcementSendAPI = useAPI(APIRoute.Admin.Announcement.Create, {
        callbacks: {
            onSuccess: res => {
                alert('성공');
                history.push('/admin/management/mission/');
            },
        },
    });

    const onClickButton = () => {
        const value = draftToHtml(
            convertToRaw(editorState.getCurrentContent()),
        );
        announcementSendAPI.send({ title: title, context: value });
    };

    return (
        <div className="ra-mission-container">
            <div className="ra-mission-title">Mission</div>
            <div className="ra-mission-category">
                진로 탐색을 위한 탐구과제 생성
            </div>
            <div className="ra-notice-line"></div>
            <div className="ra-mission-tabel-title-container">
                <div className="ra-mission-tabel-title">공지사항 생성</div>
            </div>
            <div className="mt-3 row align-items-center">
                <label style={{ marginBottom: 10 }}>제목</label>
                <div className="col-6">
                    <input
                        className="register-modal-input w-100 input pt-3 pb-3 rounded-0"
                        placeholder="제목을 입력해주세요."
                        value={title}
                        onChange={e => setTitle(e.target.value)}
                    />
                </div>
            </div>
            <MyBlock>
                <Editor
                    // 에디터와 툴바 모두에 적용되는 클래스
                    wrapperClassName="wrapper-class"
                    // 에디터 주변에 적용된 클래스
                    editorClassName="editor"
                    // 툴바 주위에 적용된 클래스
                    toolbarClassName="toolbar-class"
                    // 툴바 설정
                    toolbar={{
                        // inDropdown: 해당 항목과 관련된 항목을 드롭다운으로 나타낼것인지
                        list: { inDropdown: true },
                        textAlign: { inDropdown: true },
                        link: { inDropdown: true },
                        history: { inDropdown: false },
                    }}
                    placeholder="내용을 작성해주세요."
                    // 한국어 설정
                    localization={{
                        locale: 'ko',
                    }}
                    // 초기값 설정
                    editorState={editorState}
                    // 에디터의 값이 변경될 때마다 onEditorStateChange 호출
                    onEditorStateChange={state => setEditorState(state)}
                />
            </MyBlock>
            <div className="form-row mt-5">
                <div className="offset-3 col-5 justify-content-center align-items-center d-flex">
                    <button
                        type="submit"
                        className="btn btn-primary rounded-0 btn-block"
                        onClick={() => onClickButton()}
                    >
                        저장
                    </button>
                </div>
            </div>
        </div>
    );
};
