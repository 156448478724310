import React, { useState, useEffect } from 'react';
import useLocalStorage from '../hooks/useLocalStorage';

export const TutorialBox = props => {
    const { setEnd, guideIndex } = props;

    const [allConfirmed, setAllConfirmed] = useState(false);
    const [selected, setSelected] = useState([
        {
            id: 0,
            selected: false,
        },
        {
            id: 1,
            selected: false,
        },
        {
            id: 2,
            selected: false,
        },
        {
            id: 3,
            selected: false,
        },
        {
            id: 4,
            selected: false,
        },
        {
            id: 5,
            selected: false,
        },
    ]);
    const [checked, setChecked] = useLocalStorage('tutorialCheck', [
        {
            id: 0,
            checked: false,
        },
        {
            id: 1,
            checked: false,
        },
        {
            id: 2,
            checked: false,
        },
        {
            id: 3,
            checked: false,
        },
        {
            id: 4,
            checked: false,
        },
        {
            id: 5,
            checked: false,
        },
    ]);

    const handleCheck = index => {
        if (!checked[index].checked) {
            setChecked(
                checked.map(item =>
                    item.id === index ? { ...item, checked: true } : item,
                ),
            );
            if (index !== 5) {
                setSelected(
                    selected.map(item =>
                        item.id === index
                            ? { ...item, selected: false }
                            : item.id === index + 1
                                ? { ...item, selected: true }
                                : item,
                    ),
                );
            }
        } else {
            setChecked(
                checked.map(item =>
                    item.id === index ? { ...item, checked: false } : item,
                ),
            );
        }
    };
    const handleIndex = index => {
        setSelected(
            selected.map(item =>
                item.id === index
                    ? { ...item, selected: true }
                    : item.selected
                        ? { ...item, selected: false }
                        : item,
            ),
        );
    };
    const handleEnd = () => {
        if (allConfirmed) {
            setEnd();
        } else {
            alert('모든 가이드를 확인해주세요!');
        }
    };

    useEffect(() => {
        if (
            checked[0].checked &&
            checked[1].checked &&
            checked[2].checked &&
            checked[3].checked &&
            checked[4].checked &&
            checked[5].checked
        ) {
            setAllConfirmed(true);
        }
    }, [checked]);

    useEffect(() => {
        setSelected(
            selected.map(item =>
                item.id === guideIndex
                    ? { ...item, selected: true }
                    : item.selected
                        ? { ...item, selected: false }
                        : item,
            ),
        );
    }, []);

    return (
        <div className="tutorial-wrapper">
            <div className="tutorial-box"></div>
            <div className="tutorial-container">
                <div className="tutorial-title-container">
                    <div
                        className={
                            'btn mb-3' +
                            (allConfirmed
                                ? ' btn-main-color'
                                : ' btn-border-main')
                        }
                        onClick={() => handleEnd()}
                    >
                        가이드 닫기
                    </div>
                    <div
                        className={
                            selected[0].selected ? 'active' : 'cursor-pointer'
                        }
                        onClick={() => handleIndex(0)}
                    >
                        0. 미션 개요
                    </div>
                    <div
                        className={
                            selected[1].selected ? 'active' : 'cursor-pointer'
                        }
                        onClick={() => handleIndex(1)}
                    >
                        1. 원리 작성
                    </div>
                    <div
                        className={
                            selected[2].selected ? 'active' : 'cursor-pointer'
                        }
                        onClick={() => handleIndex(2)}
                    >
                        2. 탐구 과제 작성
                    </div>
                    <div
                        className={
                            selected[3].selected ? 'active' : 'cursor-pointer'
                        }
                        onClick={() => handleIndex(3)}
                    >
                        3. 키워드 작성
                    </div>
                    <div
                        className={
                            selected[4].selected ? 'active' : 'cursor-pointer'
                        }
                        onClick={() => handleIndex(4)}
                    >
                        4. 도서(ISBN) 태깅
                    </div>
                    <div
                        className={
                            selected[5].selected ? 'active' : 'cursor-pointer'
                        }
                        onClick={() => handleIndex(5)}
                    >
                        5. 학과/과목 태깅
                    </div>
                </div>
                <div className="tutorial-content-container">
                    {selected[0].selected && (
                        <div>
                            <div className="tutorial-content-title">
                                0. 미션 개요
                            </div>
                            <hr />
                            <div className="tutorial-content mb-3">
                                - 본 미션은 고등학생들의 전공탐색활동을 위해
                                마련되었습니다. 혹은 관련 지식이 풍부한
                                내용으로 작성 부탁드립니다. 원리/과제에 오류가
                                발견될 경우 반려될 수 있습니다.
                            </div>
                            <div className="tutorial-content mb-4">
                                - 학생들은 본 미션에서 생성된 자료를 바탕으로
                                수업 중 발표, 보고서 작성 등의 활동을 하게 됩니다.
                                궁극적으로 학생들의 대학 입시에 도움이 되는 양질의
                                과제를 생성하고자 본 미션을 실시하오니 학생들의
                                입장을 고려한 미션 수행을 부탁드립니다.
                            </div>
                            <div className="tutorila-content-input-container">
                                <input
                                    type="checkbox"
                                    checked={checked[0].checked}
                                    onChange={() => handleCheck(0)}
                                />{' '}
                                네, 확인했습니다.
                            </div>
                        </div>
                    )}
                    {selected[1].selected && (
                        <div>
                            <div className="tutorial-content-title">
                                1. 원리 작성
                            </div>
                            <hr />
                            <div className="tutorial-content mb-3">
                                - 원리는 학생들이 과제를 탐구하기 전 습득/이해해야 할
                                개념에 해당합니다. 고등학교 학습 내용의
                                심화~대학 전공 지식의 기초에 해당하는 개념을
                                선정해주세요. 하나의 의미를 담고 있는 간결한
                                용어로 제시해주세요.
                            </div>
                            <div className="tutorial-content mb-3">
                                - 필요한 원리가{' '}
                                <bold>
                                    기존 DB에 있는지 반드시 검색하여 확인
                                </bold>
                                해 주세요. 새로 작성하셨더라도 해당 원리가 기존
                                DB에 있는 경우 적립이 불가합니다.
                            </div>
                            <div className="tutorial-content mb-3">
                                - 원리에 해당하는 영문명 혹은 원어명을
                                작성해주세요.
                            </div>
                            <div className="tutorial-content mb-3">
                                - 원리에 대한 설명은 반드시{' '}
                                <red>300자 이상 </red>작성해주세요. 고등학교
                                학생이 이해할 수 있도록 교과서와 관련된 내용을
                                기반으로 하여 명확히 직관적으로 설명해주시기 바랍니다.
                            </div>
                            <div className="tutorial-content mb-4">
                                - 관련된 유사 개념이 있다면 반드시 작성해
                                주세요. (≠관련 개념)
                                <br />
                                ex) ‘황색 언론’의 유사개념으로 ‘옐로 저널리즘’, ‘황색 저널리즘’, ‘옐로 페이퍼’ (O)
                                <br />
                                ex) ‘적분’의 유사개념으로 ‘미분’(X)

                            </div>
                            <div className="tutorial-content-ex-title mb-3">
                                작업예시
                            </div>
                            <div className="row mb-3">
                                <div className="col-6 p-0">
                                    <div className="d-inline-block ">원리</div>
                                    <div className="ml-3 d-inline-block w-50 rounded-0 cocept-input">
                                        젠트리피케이션
                                    </div>
                                </div>
                                <div className="col-6 p-0">
                                    <div className="d-inline-block ">
                                        원어명
                                    </div>
                                    <div className="ml-3 d-inline-block w-50 rounded-0 cocept-input">
                                        Gentrification
                                    </div>
                                </div>
                            </div>
                            <div className="ra-test-tutorial-ex-container d-block w-100 p-4 rounded-0 border-light-color">
                                젠트리피케이션이란 낙후됐던 구도심이 번성해
                                중산층 이상의 사람들이 몰리면서, 임대료가 오르고
                                원주민이 내몰리는 현상을 의미한다. 재건축 등으로
                                인해 도시 환경이 변하면서 중·상류층이 낙후됐던
                                구도심의 주거지로 유입되고, 이에 따라 주거비용이
                                상승하면서 비싼 월세 등을 감당할 수 없는
                                원주민들이 다른 곳으로 밀려나는 현상을 이른다.
                                본래 신사 계급을 뜻하는 ‘젠트리’에서 파생된 말로
                                본래는 낙후 지역에 외부인이 들어와 지역이 다시
                                활성화되는 현상을 뜻했지만, 최근에는 외부인이
                                유입되면서 본래 거주하던 원주민이 밀려나는
                                부정적인 의미로 많이 쓰이고 있다.
                            </div>
                            <div className="row align-items-center">
                                <div className="col-2">유사개념</div>
                                <div className="col-2 form-control rounded-0 mr-2">
                                    둥지내몰림
                                </div>
                                <div className="col-2 form-control rounded-0 mr-2"></div>
                                <div className="col-2 form-control rounded-0 mr-2"></div>
                                <div className="col-2 form-control rounded-0"></div>
                            </div>
                            <div className="row align-items-center mt-2">
                                <div className="col-2">원어명</div>
                                <div className="col-2 form-control rounded-0 mr-2"></div>
                                <div className="col-2 form-control rounded-0 mr-2"></div>
                                <div className="col-2 form-control rounded-0 mr-2"></div>
                                <div className="col-2 form-control rounded-0"></div>
                            </div>
                            <div className="tutorila-content-input-container mt-3">
                                <input
                                    type="checkbox"
                                    checked={checked[1].checked}
                                    onChange={() => handleCheck(1)}
                                />{' '}
                                네, 확인했습니다.
                            </div>
                        </div>
                    )}
                    {selected[2].selected && (
                        <div>
                            <div className="tutorial-content-title">
                                2. 탐구 과제 작성
                            </div>
                            <hr />
                            <div className="tutorial-content mb-3">
                                - 원리를 바탕으로 생각을 확장하여 '탐구'할 수
                                있는 과제를 제시해주세요. 단순히 이론에 대해 더
                                알아보거나, 학습 내용을 정리하는 수준의 과제가
                                아닙니다.{' '}
                                <bold>
                                    실생활에서의 현상 및 사례, 사회문제, 연구
                                    중인 과학 기술
                                </bold>{' '}
                                등 원리에서 더 나아간 과제를 제시해주세요.
                            </div>
                            <div className="tutorial-content mb-3">
                                - "~서술하시오. ~정리하시오."와 같이 서술형,
                                논술형 문제를 내는 것이 아닙니다. 단순히 옳고
                                그름을 판단하는 내용이 아니라 깊고 풍부하게{' '}
                                <bold>조사하고, 탐구할 수 있는 과제</bold>를
                                제안해주세요. 최종적으로{' '}
                                <bold>"탐구해 보자."</bold>의 형태로 끝나도록
                                작성해주시기 바랍니다.
                            </div>
                            <div className="tutorial-content mb-3">
                                - 고등학생이 자신이 목표하는 학과에 대하여
                                전공적합성 및 학업역량을 보여줄 수 있는 과제를
                                제안해주시기 바랍니다. 너무 쉽거나, 너무 어려운
                                과제는 지양해주세요.
                            </div>
                            <div className="tutorial-content mb-4">
                                - 한 원리에 대하여 서로 관련되는 탐구과제를
                                2-3가지 정도 작성하셔도 괜찮습니다. 그러나
                                탐구과제끼리 내용이 지나치게 겹치는 경우, 혹은
                                원리와 탐구과제 간의 관련성이 떨어지는 경우
                                반려될 수 있습니다.
                                <br />
                                (ex: 350자 안에 서로 관련성 높은 탐구 과제 2-3개 (O))
                            </div>
                            <div className="tutorial-content-ex-title mb-3">
                                작업예시
                            </div>
                            <div className="ra-test-tutorial-ex-container d-block w-100 p-4 rounded-0 border-light-color">
                                2,000년대 이후 서울의 경우 종로구 서촌을 비롯해
                                홍익대 인근, 망원동, 상수동, 경리단길, 삼청동,
                                신사동 가로수길 등에서 젠트리피케이션 현상이
                                벌어지고 있다. 현재 우리나라에서 발생하는
                                젠트리피케이션의 특징을 조사해보자. 또한,
                                젠트리피케이션은 임대료의 상승과 밀접한 관련이
                                있기에 사회문제라고 볼 수 있다. ‘상가건물
                                임대차보호법’ 등을 통하여 소상공인들을 보호하고
                                있는데, 이 이외에 현재 우리나라에서 시행되고
                                있는 소상공인을 보호하기 위한 법과 정책을
                                탐구하고 정부가 소상공인을 보호하기 위해 시장에
                                개입하는 것이 타당한지 탐구해 보자.
                            </div>
                            <div className="tutorila-content-input-container">
                                <input
                                    type="checkbox"
                                    checked={checked[2].checked}
                                    onChange={() => handleCheck(2)}
                                />{' '}
                                네, 확인했습니다.
                            </div>
                        </div>
                    )}
                    {selected[3].selected && (
                        <div>
                            <div className="tutorial-content-title">
                                3. 키워드 작성
                            </div>
                            <hr />
                            <div className="tutorial-content mb-3">
                                - 검색 키워드는 과제에 대한 직접적인 관련이 있는
                                단어,{' '}
                                <red>검색에 실질적으로 도움이 되는 단어</red>를
                                작성해주시기 바랍니다. 해당 과제와 관련된 내용이
                                검색되지 않는 광범위한 키워드(ex. 정치, 경제)는
                                배제해주시기 바랍니다.
                            </div>
                            <div className="tutorial-content mb-3">
                                - 해시태그와 마찬가지로 띄어쓰기는 언더바(_)를
                                활용해 검색 가능한 키워드를 조합하여
                                작성해주세요.
                            </div>
                            <div className="tutorial-content mb-4">
                                - 키워드는 검색키워드(4개), 사례키워드,
                                관련키워드(사례, 관련키워드 합쳐서 4개) {' '}
                                <red>모두 합쳐 최소 8개</red>를 필수로
                                작성해주시기 바랍니다.
                            </div>
                            <div className="tutorial-content-ex-title mb-3">
                                작업예시
                            </div>
                            <div className="row align-items-center">
                                <div className="col-3 p-0">
                                    검색 키워드(필수)
                                </div>
                                <div className="col-2 form-control rounded-0 mr-2 tutorial-keyword-box">
                                    젠트리피케이션
                                </div>
                                <div className="col-2 form-control rounded-0 mr-2 tutorial-keyword-box">
                                    임대차보호법
                                </div>
                                <div className="col-2 form-control rounded-0 mr-2 tutorial-keyword-box">
                                    소상공인_보호
                                </div>
                                <div className="col-2 form-control rounded-0 tutorial-keyword-box">
                                    젠트리
                                </div>
                            </div>
                            <div className="row align-items-center mt-2">
                                <div className="col-3 p-0">
                                    사례 키워드(선택)
                                </div>
                                <div className="col-2 form-control rounded-0 mr-2 tutorial-keyword-box">
                                    경리단길
                                </div>
                                <div className="col-2 form-control rounded-0 mr-2 tutorial-keyword-box">
                                    가로수길
                                </div>
                                <div className="col-2 form-control rounded-0 mr-2 tutorial-keyword-box">
                                    경주_황리단길
                                </div>
                                <div className="col-2 form-control rounded-0 tutorial-keyword-box">
                                    도시화
                                </div>
                            </div>
                            <div className="row align-items-center mt-2">
                                <div className="col-3 p-0">
                                    관련 키워드(선택)
                                </div>
                                <div className="col-2 form-control rounded-0 mr-2 tutorial-keyword-box">
                                    구도심
                                </div>
                                <div className="col-2 form-control rounded-0 mr-2 tutorial-keyword-box">
                                    신도심
                                </div>
                                <div className="col-2 form-control rounded-0 mr-2 tutorial-keyword-box">
                                    익선동
                                </div>
                                <div className="col-2 form-control rounded-0 tutorial-keyword-box">
                                    게토화
                                </div>
                            </div>
                            <div className="tutorila-content-input-container mt-3">
                                <input
                                    type="checkbox"
                                    checked={checked[3].checked}
                                    onChange={() => handleCheck(3)}
                                />{' '}
                                네, 확인했습니다.
                            </div>
                        </div>
                    )}
                    {selected[4].selected && (
                        <div>
                            <div className="tutorial-content-title">
                                4. 도서(ISBN) 태깅
                            </div>
                            <hr />
                            <div className="tutorial-content mb-3">
                                - 관련 도서는 탐구과제와 밀접한 관련이 있는
                                도서를 추천해주세요.{' '}
                                <bold>
                                    도서 제목 혹은 목차에서 관련 내용이
                                    확인되어야 합니다.
                                </bold>{' '}
                                네이버 책(
                                <a
                                    href="https://book.naver.com/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    book.naver.com
                                </a>
                                )을 활용하시면 쉽게 관련 도서를 검색하실 수
                                있습니다.
                            </div>
                            <div className="tutorial-content mb-3">
                                - 용어사전, 백과사전, 수험용 도서,
                                대학전공서적 등은 제외해주시기 바랍니다.
                            </div>
                            <div className="tutorial-content mb-3">
                                - ISBN(13자리)은 반드시 네이버 책에서 검색이 되어야
                                합니다. 네이버 책에서 검색이 되는지 확인하신 후
                                정확히 입력해주시기 바랍니다.
                            </div>
                            <div className="tutorial-content mb-4">
                                - 띄어쓰기 없이 입력해주세요.
                            </div>
                            <div className="tutorila-content-input-container">
                                <input
                                    type="checkbox"
                                    checked={checked[4].checked}
                                    onChange={() => handleCheck(4)}
                                />{' '}
                                네, 확인했습니다.
                            </div>
                        </div>
                    )}
                    {selected[5].selected && (
                        <div>
                            <div className="tutorial-content-title">
                                5. 학과/과목 태깅
                            </div>
                            <hr />
                            <div className="tutorial-content mb-4">
                                - 해당 탐구 과제를 통해 전공적합성을 보여줄 수
                                있는 모든 학과를 태깅해주세요. 특히
                                유사계열/학과는 빠짐없이 태깅해주시기 바랍니다.
                            </div>
                            <div className="tutorial-content mb-4">
                                - 탐구 주제와 관련있는 과목/단원 및 학습요소를 태깅해주세요.
                                다만, 단순히 분량을 채우기 위해 관련성이 없는
                                과목이나 단원 및 학습요소를 태깅하는 것은
                                용인되지 않습니다. <bold>각각 3가지 이상</bold>
                                의 과목/단원/학습요소 태깅을 권장합니다.
                            </div>
                            <div className="tutorial-content mb-4">
                                - 추천단원을 반드시 확인하시고, 해당되는
                                과목/단원을 모두 태깅해주세요.
                            </div>
                            <div className="tutorial-content mb-4">
                                <a
                                    href="https://docs.google.com/spreadsheets/d/1A9OfLQllpsTvyl34WpfV6KOyfmQ1HifC7n3Akg_zW_g/edit?usp=sharing"
                                    target="_blank"
                                    rel="noreferrer"
                                >- 학습 요소 열람 링크
                                </a>

                            </div>
                            <div className="tutorila-content-input-container">
                                <input
                                    type="checkbox"
                                    checked={checked[5].checked}
                                    onChange={() => handleCheck(5)}
                                />{' '}
                                네, 확인했습니다.
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
