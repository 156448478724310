import React, { useEffect, useState, useContext } from 'react';
import { Modal } from 'react-bootstrap';

import { useAPI, APIRoute } from 'Client';
import { usePageBlock, Pagination, BaseTable } from '../table/base';

import { AppContext } from 'Contexts';

import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import styled from 'styled-components';
import { convertToRaw, EditorState } from 'draft-js';

const MyBlock = styled.div`
    .wrapper-class {
        width: 100%;
        margin: 0 auto;
        margin-bottom: 2rem;
        margin-top: 1rem;
    }
    .editor {
        height: 300px !important;
        border: 1px solid #f1f1f1 !important;
        padding: 5px !important;
        border-radius: 2px !important;
    }
`;
const IntroduceContent = styled.div`
    position: relative;
    border: 0.0625rem solid #d7e2eb;
    border-radius: 0.75rem;
    overflow: hidden;
    padding: 1.5rem;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 4rem;
    margin-top: 1rem;
`;

const data = [
    {
        check: false,
        noticeTitle: '승진하셨습니다.',
        createdAt: '2021-03-15T15:39:21.922008+09:00',
    },
];

const columnNames = [
    {
        key: 'check',
        name: '조회',
    },
    {
        key: 'noticeTitle',
        name: '제목',
    },
    {
        key: 'created',
        name: '날짜',
    },
];

const LIMIT_PER_PAGE = 10;
const BLOCK_SIZE = 10;

export const ResearchAssistantNoticeListModal = ({
    researchAssistantProfile,
    handleClick = () => {},
    isOpen = false,
    close,
}) => {
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const editorToHtml = draftToHtml(
        convertToRaw(editorState.getCurrentContent()),
    );

    const [page, setPage] = useState(1);
    const [detail, setDetail] = useState(false);
    const [create, setCreate] = useState(false);
    const [title, setTitle] = useState('');

    const { pageBlock, pageParams, setTotal, before, next } = usePageBlock({
        blockSize: BLOCK_SIZE,
        limitPerPage: LIMIT_PER_PAGE,
    });

    const { user } = useContext(AppContext);

    const createNotice = () => {
        // 알림 작성 API 호출
    };

    const goToDetail = () => {
        // 테이블에서 key를 받아와서 해당 key의 알림 사항을 불러오는 API 호출, editorState 초기화
        setDetail(true);
    };

    useEffect(() => {}, []);

    return (
        <Modal
            id="search-concepts-modal"
            className="modal"
            show={isOpen}
            onHide={close}
        >
            <Modal.Header className="d-block">
                <div className="row w-100">
                    <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={close}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="row w-100 justify-content-center align-items-center">
                    <h4>알림 및 공지사항</h4>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="mt-3 notice-modal-container">
                    {user.type === 'ADMIN' && !create && (
                        <div className="row mb-3">
                            <div className="col-4 justify-content-center d-flex">
                                <button
                                    className="btn-main-color btn-lg btn-block"
                                    onClick={() => setCreate(true)}
                                >
                                    새로운 알림 작성
                                </button>
                            </div>
                        </div>
                    )}
                    {create ? (
                        <div>
                            <div className="ra-mission-tabel-title-container">
                                <div className="ra-mission-tabel-title">
                                    새로운 알림 작성
                                </div>
                            </div>
                            <div className="mt-3 row align-items-center">
                                <label style={{ marginBottom: 10 }}>제목</label>
                                <div className="col-6">
                                    <input
                                        className="register-modal-input w-100 input pt-3 pb-3 rounded-0"
                                        placeholder="제목을 입력해주세요."
                                        value={title}
                                        onChange={e => setTitle(e.target.value)}
                                    />
                                </div>
                            </div>
                            <MyBlock>
                                <Editor
                                    // 에디터와 툴바 모두에 적용되는 클래스
                                    wrapperClassName="wrapper-class"
                                    // 에디터 주변에 적용된 클래스
                                    editorClassName="editor"
                                    // 툴바 주위에 적용된 클래스
                                    toolbarClassName="toolbar-class"
                                    // 툴바 설정
                                    toolbar={{
                                        // inDropdown: 해당 항목과 관련된 항목을 드롭다운으로 나타낼것인지
                                        list: { inDropdown: true },
                                        textAlign: { inDropdown: true },
                                        link: { inDropdown: true },
                                        history: { inDropdown: false },
                                    }}
                                    placeholder="내용을 작성해주세요."
                                    // 한국어 설정
                                    localization={{
                                        locale: 'ko',
                                    }}
                                    // 초기값 설정
                                    editorState={editorState}
                                    // 에디터의 값이 변경될 때마다 onEditorStateChange 호출
                                    onEditorStateChange={state =>
                                        setEditorState(state)
                                    }
                                />
                            </MyBlock>
                            <div className="form-row mt-5 justify-content-center">
                                <div className="col-3 justify-content-center align-items-center d-flex">
                                    <button
                                        type="submit"
                                        className="btn btn-border-main btn-block"
                                        onClick={() => setCreate(false)}
                                    >
                                        취소
                                    </button>
                                </div>
                                <div className="offset-1 col-3 justify-content-center align-items-center d-flex">
                                    <button
                                        type="submit"
                                        className="btn btn-main-color btn-block"
                                        onClick={() => createNotice()}
                                    >
                                        저장
                                    </button>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div>
                            {detail ? (
                                <div>
                                    <div
                                        className="admin-back-btn cursor-pointer mt-3"
                                        onClick={() => setDetail(false)}
                                    >
                                        &#xE000;&nbsp; 뒤로가기
                                    </div>
                                    <div className="ra-mission-tabel-title-container">
                                        <div className="ra-mission-tabel-title">
                                            제목 : {title}
                                        </div>
                                    </div>
                                    <IntroduceContent
                                        dangerouslySetInnerHTML={{
                                            __html: editorToHtml,
                                        }}
                                    />
                                </div>
                            ) : (
                                <div>
                                    <BaseTable
                                        columnNames={columnNames}
                                        data={data}
                                        onClick={() => goToDetail()}
                                    />
                                    <Pagination
                                        before={before}
                                        next={next}
                                        pageBlock={pageBlock}
                                        page={page}
                                        setPage={setPage}
                                    />
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </Modal.Body>
        </Modal>
    );
};
