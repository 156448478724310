import React, { useRef } from 'react';

import { useForm } from 'react-hook-form';

import { useAPI, APIRoute } from 'Client';

export const AdminMissionReportConceptPatchView = ({
    entity: { id, concept, feedbacks },
}) => {
    const form = useForm({
        defaultValues: {
            feedbackForName: feedbacks.name || '',
            feedbackForDescription: feedbacks.description || '',
            feedbackForTags: feedbacks.tags || '',
        },
    });
    const acceptButtonRef = useRef();
    const rejectButtonRef = useRef();

    const missionReportPatchAPI = useAPI(APIRoute.Admin.MissionReport.Patch, {
        callbacks: {
            onSuccess: () => alert('성공'),
        },
    });

    form.handleSubmit = form.handleSubmit((data, event) => {
        const isAccepted =
            event.nativeEvent.submitter === acceptButtonRef.current;

        missionReportPatchAPI.send(
            {
                inspectionStatus: isAccepted ? 'ACCEPTED' : 'REJECTED',
                feedbacks: {
                    name: data.feedbackForName,
                    description: data.feedbackForDescription,
                    tags: data.feedbackForTags,
                },
            },
            {
                additionalPath: id,
            },
        );
    });

    return (
        <form onSubmit={form.handleSubmit}>
            <div className="row mt-5 border-bottom pb-3">
                <div className="col-6">
                    <div className="row">
                        <div className="col-3 d-flex align-items-center">
                            <h5>원리</h5>
                        </div>
                        <div className="col-9">
                            <input
                                className="input border rounded-0 h-100 w-100 p-3 border-dark"
                                defaultValue={concept.name}
                                disabled
                            />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-3 d-flex align-items-center">
                            <h5>영문명(원어)</h5>
                        </div>
                        <div className="col-9">
                            <input
                                className="input border rounded-0 h-100 w-100 p-3 border-dark"
                                defaultValue={concept.nameInEnglish}
                                disabled
                            />
                        </div>
                    </div>
                </div>

                <div className="col-6">
                    <div className="h-100 border border-dark">
                        <textarea
                            name="feedbackForName"
                            className="d-flex w-100 h-100"
                            placeholder="피드백을 적어주세요."
                            ref={form.register}
                        ></textarea>
                    </div>
                </div>
            </div>
            <div className="row mt-4 border-bottom pb-3">
                <div className="col-12 mb-3">
                    <h4 className="d-inline-block">설명</h4>
                    <span className="ml-3">(공백 포함 300-350자)</span>
                </div>
                <div className="col-6">
                    <div className="position-relative h-100">
                        <textarea
                            className="concept-description d-block h-100 w-100 p-3 rounded-0"
                            defaultValue={concept.description}
                            disabled
                        ></textarea>
                        <div className="position-absolute fixed-bottom w-100 d-flex justify-content-end p-3 text-muted">
                            <span> {concept.description.length}/350</span>
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="border border-dark feedback-box ">
                        <textarea
                            name="feedbackForDescription"
                            className="d-flex w-100 h-100"
                            placeholder="피드백을 적어주세요."
                            ref={form.register}
                        ></textarea>
                    </div>
                </div>
            </div>
            <div className="row mt-4 mb-4 pb-3">
                <div className="col-6">
                    <div className="row">
                        <div className="col-3">
                            <div className="row justify-content-center align-items-center h-50">
                                <h4>유사개념</h4>
                            </div>
                            <div className="row h-50"></div>
                        </div>
                        <div className="col-9">
                            <div className="row">
                                <div className="col-6 p-1">
                                    <input
                                        className="input border rounded-0 h-100 w-100 p-3 border-dark"
                                        defaultValue={
                                            concept.relatedConcepts[0]
                                        }
                                        disabled
                                    />
                                </div>
                                <div className="col-6 p-1">
                                    <input
                                        className="input border rounded-0 h-100 w-100 p-3 border-dark"
                                        defaultValue={
                                            concept.relatedConcepts[1]
                                        }
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 p-1">
                                    <input
                                        className="input border rounded-0 h-100 w-100 p-3 border-dark"
                                        defaultValue={
                                            concept.relatedConcepts[2]
                                        }
                                        disabled
                                    />
                                </div>
                                <div className="col-6 p-1">
                                    <input
                                        className="input border rounded-0 h-100 w-100 p-3 border-dark"
                                        defaultValue={
                                            concept.relatedConcepts[3]
                                        }
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-3">
                            <div className="row justify-content-center align-items-center h-50">
                                <h4>원어명</h4>
                            </div>
                            <div className="row h-50"></div>
                        </div>
                        <div className="col-9">
                            <div className="row">
                                <div className="col-6 p-1">
                                    <input
                                        className="input border rounded-0 h-100 w-100 p-3 border-dark"
                                        defaultValue={
                                            concept.relatedConceptsInEnglish[0]
                                        }
                                        disabled
                                    />
                                </div>
                                <div className="col-6 p-1">
                                    <input
                                        className="input border rounded-0 h-100 w-100 p-3 border-dark"
                                        defaultValue={
                                            concept.relatedConceptsInEnglish[1]
                                        }
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 p-1">
                                    <input
                                        className="input border rounded-0 h-100 w-100 p-3 border-dark"
                                        defaultValue={
                                            concept.relatedConceptsInEnglish[2]
                                        }
                                        disabled
                                    />
                                </div>
                                <div className="col-6 p-1">
                                    <input
                                        className="input border rounded-0 h-100 w-100 p-3 border-dark"
                                        defaultValue={
                                            concept.relatedConceptsInEnglish[3]
                                        }
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="row mt-2">
                        <div className="col-3">
                            <div className="row justify-content-center align-items-center h-50">
                                <h4>긴밀학과</h4>
                            </div>
                            <div className="row h-50"></div>
                        </div>
                        <div className="col-9">
                            <div className="row">
                                <div className="col-6 p-1">
                                    <input
                                        className="input border rounded-0 h-100 w-100 p-3 border-dark"
                                        defaultValue={concept.relatedMajors[0]}
                                        disabled
                                    />
                                </div>
                                <div className="col-6 p-1">
                                    <input
                                        className="input border rounded-0 h-100 w-100 p-3 border-dark"
                                        defaultValue={concept.relatedMajors[1]}
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 p-1">
                                    <input
                                        className="input border rounded-0 h-100 w-100 p-3 border-dark"
                                        defaultValue={concept.relatedMajors[2]}
                                        disabled
                                    />
                                </div>
                                <div className="col-6 p-1">
                                    <input
                                        className="input border rounded-0 h-100 w-100 p-3 border-dark"
                                        defaultValue={concept.relatedMajors[3]}
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-3">
                            <div className="row justify-content-center align-items-center h-50">
                                <h4>긴밀과목</h4>
                            </div>
                            <div className="row h-50"></div>
                        </div>
                        <div className="col-9">
                            <div className="row">
                                <div className="col-6 p-1">
                                    <input
                                        className="input border rounded-0 h-100 w-100 p-3 border-dark"
                                        defaultValue={
                                            concept.relatedSchoolSubjects[0]
                                        }
                                        disabled
                                    />
                                </div>
                                <div className="col-6 p-1">
                                    <input
                                        className="input border rounded-0 h-100 w-100 p-3 border-dark"
                                        defaultValue={
                                            concept.relatedSchoolSubjects[1]
                                        }
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 p-1">
                                    <input
                                        className="input border rounded-0 h-100 w-100 p-3 border-dark"
                                        defaultValue={
                                            concept.relatedSchoolSubjects[2]
                                        }
                                        disabled
                                    />
                                </div>
                                <div className="col-6 p-1">
                                    <input
                                        className="input border rounded-0 h-100 w-100 p-3 border-dark"
                                        defaultValue={
                                            concept.relatedSchoolSubjects[3]
                                        }
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
                <div className="col-6">
                    <div className="border border-dark h-100">
                        <textarea
                            name="feedbackForTags"
                            className="d-flex w-100 h-100"
                            placeholder="피드백을 적어주세요."
                            ref={form.register}
                        ></textarea>
                    </div>
                </div>
            </div>
            <div className="row mt-3 pb-3 mb-5">
                <div className="offset-2 col-3 justify-content-center align-items-center d-flex">
                    <button
                        type="submit"
                        className="btn btn-danger btn-lg rounded-0 btn-block"
                        ref={rejectButtonRef}
                    >
                        반려하기
                    </button>
                </div>
                <div className="offset-1 col-3 justify-content-center align-items-center d-flex">
                    <button
                        type="submit"
                        className="btn btn-primary btn-lg rounded-0 btn-block"
                        ref={acceptButtonRef}
                    >
                        승인하기
                    </button>
                </div>
            </div>
        </form>
    );
};
