import React from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { TagFeedback } from 'Components/admin';
import { LatexDisplay } from 'Components';
import { useHistory } from 'react-router-dom';

export const StudySubjectDetailView = ({ entity }) => {
    const { id, feedbacks, studySubject, submitted, inspectionStatus } = entity;
    const { concept, keywords, tags } = studySubject;

    const history = useHistory();
    const form = useForm({
        defaultValues: {
            feedbackForTask: feedbacks.task || '',
            feedbackForKeywords: feedbacks.keywords || '',
            feedbacksForTags: feedbacks.tags
                ? feedbacks.tags.map(value => ({ value }))
                : tags.map(() => ({
                    value: '',
                })),
        },
    });

    const feedbacksForTags = useFieldArray({
        control: form.control,
        name: 'feedbacksForTags',
    });

    const searchKeywords = keywords.filter(({ type }) => type === 'search');
    const exampleKeywords = keywords.filter(({ type }) => type === 'example');
    const relevantKeywords = keywords.filter(({ type }) => type === 'relevant');

    const handleResubmitButtonClick = () =>
        history.push({
            pathname: `/study-subjects/${entity.id}/patch/`,
            state: { entity, resubmit: true },
        });

    return (
        <form onSubmit={form.handleSubmit} className="ra-mission-contaienr">
            <div
                className="admin-back-btn cursor-pointer mt-4"
                onClick={() => history.goBack()}
            >
                &#xE000;&nbsp; 뒤로가기
                        </div>
            <div className="row mt-3 border-bottom pb-3">
                <div className="col-6 p-0">
                    <div className="row">
                        <div className="col-2 d-flex align-items-center">
                            <h4 className="d-inline-block">이론</h4>
                        </div>
                        <div className="col-10">
                            <input
                                className="input border rounded-0 h-100 w-100 p-3 border-dark"
                                defaultValue={concept.name}
                                disabled
                            />
                        </div>
                    </div>
                </div>
                <div className="col-12 mb-3 mt-3">
                    <h4 className="d-inline-block">설명</h4>
                    <span className="ml-3">(공백 포함 300-350 글자)</span>
                </div>
                <div className="col-6">
                    <div className="position-relative feedback-box border">
                        <div className="concept-description d-block h-100 w-100 p-3 rounded-0">
                            <LatexDisplay latex={concept.description} />
                        </div>
                        <div className="position-absolute fixed-bottom w-100 d-flex justify-content-end p-3 text-muted">
                            <span>{concept.description.length}/350</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-4 mb-4 ml-3">
                <div className="row">
                    <div className="col-3">
                        <div className="row justify-content-center align-items-center h-50">
                            <h4>유사개념</h4>
                        </div>
                        <div className="row h-50"></div>
                    </div>
                    <div className="col-9">
                        <div className="d-flex row">
                            {concept.relatedConcepts &&
                                concept.relatedConcepts.map(
                                    (relatedConcept, index) => (
                                        <div
                                            key={relatedConcept.id}
                                            className="col-3"
                                        >
                                            <input
                                                key={relatedConcept.id}
                                                disabled
                                                defaultValue={relatedConcept}
                                                className="form-control rounded-0 border-dark"
                                            />
                                        </div>
                                    ),
                                )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-3">
                    <div className="row justify-content-center align-items-center h-50">
                        <h4>원어명</h4>
                    </div>
                    <div className="row h-50"></div>
                </div>
                <div className="col-9">
                    <div className="d-flex row">
                        {concept.relatedConceptsInEnglish &&
                            concept.relatedConceptsInEnglish.map(
                                (relatedConceptInEnglish, index) => (
                                    <div
                                        key={relatedConceptInEnglish.id}
                                        className="col-3"
                                    >
                                        <input
                                            key={relatedConceptInEnglish.id}
                                            disabled
                                            defaultValue={
                                                relatedConceptInEnglish
                                            }
                                            className="form-control rounded-0 border-dark"
                                        />
                                    </div>
                                ),
                            )}
                    </div>
                </div>
            </div>
            <div className="row mt-4 border-bottom pb-3">
                <div className="col-12 mb-3">
                    <h4 className="d-inline-block">과제</h4>
                    <span className="ml-3">(공백 포함 300-350자)</span>
                </div>
                <div className="col-6">
                    <div className="position-relative h-100 border">
                        <div className="concept-description d-block h-100 w-100 p-3 rounded-0">
                            <LatexDisplay latex={studySubject.task} />
                        </div>
                        <div className="position-absolute fixed-bottom w-100 d-flex justify-content-end p-3 text-muted">
                            <span>{studySubject.task.length}/350</span>
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="border border-dark feedback-box">
                        <textarea
                            name="feedbackForTask"
                            className="d-flex w-100 h-100"
                            disabled
                            ref={form.register}
                        ></textarea>
                    </div>
                </div>
            </div>
            <div className="row mt-4 mb-4 border-bottom pb-3">
                <div className="col-6">
                    <div className="row">
                        <div className="col-4">
                            <div className="row justify-content-center align-items-center h-50">
                                <h4>검색 키워드(필수)</h4>
                            </div>
                            <div className="row h-50"></div>
                        </div>
                        <div className="col-8 p-0">
                            <div className="row">
                                <div className="col-6 p-1">
                                    <input
                                        className="input border rounded-0 h-100 w-100 p-3 border-dark"
                                        defaultValue={searchKeywords[0].keyword}
                                        disabled
                                    />
                                </div>
                                <div className="col-6 p-1">
                                    <input
                                        className="input border rounded-0 h-100 w-100 p-3 border-dark"
                                        defaultValue={searchKeywords[1].keyword}
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 p-1">
                                    <input
                                        className="input border rounded-0 h-100 w-100 p-3 border-dark"
                                        defaultValue={searchKeywords[2].keyword}
                                        disabled
                                    />
                                </div>
                                <div className="col-6 p-1">
                                    <input
                                        className="input border rounded-0 h-100 w-100 p-3 border-dark"
                                        defaultValue={searchKeywords[3].keyword}
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-4">
                            <div className="row justify-content-center align-items-center h-50">
                                <h4>사례 키워드(옵션)</h4>
                            </div>
                            <div className="row h-50"></div>
                        </div>
                        <div className="col-8 p-0">
                            <div className="row">
                                <div className="col-6 p-1">
                                    <input
                                        className="input border rounded-0 h-100 w-100 p-3 border-dark"
                                        defaultValue={
                                            exampleKeywords[0].keyword
                                        }
                                        disabled
                                    />
                                </div>
                                <div className="col-6 p-1">
                                    <input
                                        className="input border rounded-0 h-100 w-100 p-3 border-dark"
                                        defaultValue={
                                            exampleKeywords[1].keyword
                                        }
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 p-1">
                                    <input
                                        className="input border rounded-0 h-100 w-100 p-3 border-dark"
                                        defaultValue={
                                            exampleKeywords[2].keyword
                                        }
                                        disabled
                                    />
                                </div>
                                <div className="col-6 p-1">
                                    <input
                                        className="input border rounded-0 h-100 w-100 p-3 border-dark"
                                        defaultValue={
                                            exampleKeywords[3].keyword
                                        }
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-4">
                            <div className="row justify-content-center align-items-center h-50">
                                <h4>관련 키워드(옵션)</h4>
                            </div>
                            <div className="row h-50"></div>
                        </div>
                        <div className="col-8 p-0">
                            <div className="row">
                                <div className="col-6 p-1">
                                    <input
                                        className="input border rounded-0 h-100 w-100 p-3 border-dark"
                                        defaultValue={
                                            relevantKeywords[0].keyword
                                        }
                                        disabled
                                    />
                                </div>
                                <div className="col-6 p-1">
                                    <input
                                        className="input border rounded-0 h-100 w-100 p-3 border-dark"
                                        defaultValue={
                                            relevantKeywords[1].keyword
                                        }
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 p-1">
                                    <input
                                        className="input border rounded-0 h-100 w-100 p-3 border-dark"
                                        defaultValue={
                                            relevantKeywords[2].keyword
                                        }
                                        disabled
                                    />
                                </div>
                                <div className="col-6 p-1">
                                    <input
                                        className="input border rounded-0 h-100 w-100 p-3 border-dark"
                                        defaultValue={
                                            relevantKeywords[3].keyword
                                        }
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="border border-dark h-100">
                        <textarea
                            disabled
                            name="feedbackForKeywords"
                            className="d-flex w-100 h-100"
                            placeholder="관리자의 피드백을 기다리고 있습니다."
                            ref={form.register}
                        ></textarea>
                    </div>
                </div>
            </div>
            <hr className="border-dark mt-4 thick" />
            <TagFeedback
                form={form}
                tags={tags}
                feedbacks={{ feedbacksForTags }}
            />
            <hr className="border-dark mt-4 thick" />
            {submitted &&
                (inspectionStatus == 'REJECTED') | (inspectionStatus == '반려') ? (
                <div className="row mt-5 pb-3 mb-5">
                    <div className="offset-2 col-3 justify-content-center align-items-center d-flex">
                        <button
                            type="button"
                            onClick={handleResubmitButtonClick}
                            className="btn btn-danger btn-lg rounded-0 btn-block"
                        >
                            재제출하기
                        </button>
                    </div>
                </div>
            ) : (
                <></>
            )}
        </form>
    );
};
