import React, { useState, useEffect } from 'react';

import { MathfieldComponent } from 'react-mathlive';
import MathJax from 'react-mathjax-preview';

const EditorMode = {
    Edit: 'Edit',
    Preview: 'Preview',
};

const LatexPreset = [
    `$$\\begin{bmatrix} 0 & \\cdots & 0 \\\\ \\vdots & \\ddots & \\vdots \\\\ 0 & \\cdots & 0 \\end{bmatrix}$$`,
    `$$\\sqrt[3]{x^3+y^3 \\over 2}$$`,
    `$$\\mathrm{P}_{k}^{n}$$`,
    `$$f(x)=\\frac{\\sin (x)}{\\cos (x)}$$`,
    `$$ {P(E)={{n\\choose k}}p^k(1-p)^{n-k}} $$`,
];

export const LatexDisplay = ({ latex, ...props }) => {
    return (
        <div className="w-100 h-100 latex-wrapper">
            <MathJax math={latex} />
        </div>
    );
};

export const LatexEditor = ({ disabled, formRef, ...props }) => {
    const [editorMode, setEditorMode] = useState(EditorMode.Preview);
    const [isLatexInputShown, setIsLatexInputShow] = useState(false);
    const [isLatexPresetShown, setLatexPresetShown] = useState(false);

    const [latex, setLatex] = useState('');

    const handleLatextInputCompleteButtonClick = () => {
        const innerLatex = latex.includes('$$') ? latex : `$$${latex}$$`;
        formRef.setValue(
            props.name,
            `${formRef.getValues(props.name)} \n${innerLatex}`,
        );
        setIsLatexInputShow(false);
        setLatex();
    };

    const handleCopyPresetButtonClick = preset => {
        setLatex(preset);
        setLatexPresetShown(false);
    };

    useEffect(() => {
        if (!isLatexInputShown) {
            setLatexPresetShown(false);
            setLatex('');
        }
    }, [isLatexInputShown]);

    useEffect(() => {
        setIsLatexInputShow(false);
    }, [editorMode]);

    useEffect(() => {
        if (disabled) {
            setEditorMode(EditorMode.Preview);
        }
    }, [disabled]);

    return (
        <div className="w-100 latex-editor">
            {!disabled && (
                <div className="d-flex w-100">
                    {Object.keys(EditorMode).map((mode, key) => {
                        return (
                            <button
                                key={key}
                                type="button"
                                onClick={() => setEditorMode(mode)}
                                className={`btn w-50 ${
                                    editorMode === mode
                                        ? 'btn-primary'
                                        : 'btn-dark'
                                }`}
                            >
                                {mode}
                            </button>
                        );
                    })}
                </div>
            )}
            <div
                style={{
                    display:
                        editorMode == EditorMode.Preview ? 'block' : 'none',
                }}
                className="p-5 border w-100"
            >
                <LatexDisplay latex={formRef.watch(props.name)} />
            </div>
            <div
                style={{
                    display: editorMode == EditorMode.Edit ? 'block' : 'none',
                }}
            >
                <div className="position-relative">
                    <textarea
                        {...props}
                        disabled={disabled}
                        ref={formRef.register}
                        maxLength="350"
                        className="concept-description d-block w-100 p-4 rounded-0 border-light-color"
                    />
                    <div className="position-absolute fixed-bottom w-100 d-flex justify-content-end p-4 text-muted">
                        {
                            <span>
                                {formRef.watch(props.name).length}/
                                {props.maxLength}
                            </span>
                        }
                    </div>
                </div>
                {!disabled && (
                    <div>
                        <button
                            type="button"
                            className="btn btn-primary mb-2 mt-2 w-100"
                            onClick={() => {
                                setIsLatexInputShow(!isLatexInputShown);
                            }}
                        >
                            {isLatexInputShown ? '닫기' : '수식 입력'}
                        </button>
                        {isLatexInputShown && (
                            <>
                                <div className="row">
                                    <div className="border w-100">
                                        <MathfieldComponent
                                            latex={latex}
                                            onChange={setLatex}
                                            mathfieldConfig={{
                                                smartFence: false,
                                                defaultMode: 'math',
                                                virtualKeyboardMode: 'onfocus',
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <button
                                        type="button"
                                        onClick={() =>
                                            setLatexPresetShown(
                                                !isLatexPresetShown,
                                            )
                                        }
                                        className="mt-2 mb-2 btn btn-info w-50"
                                    >
                                        입력 프리셋
                                    </button>
                                    <button
                                        type="button"
                                        className="mt-2 mb-2 btn btn-danger w-50"
                                        onClick={
                                            handleLatextInputCompleteButtonClick
                                        }
                                    >
                                        입력 완료
                                    </button>
                                </div>
                            </>
                        )}
                        {isLatexPresetShown && (
                            <div className="mt-3 mb-3 p-2 border row">
                                {LatexPreset.map((preset, key) => (
                                    <div
                                        key={key}
                                        className="border p-2 d-flex flex-column justify-content-between col-lg-6 col-md-6 col-xs-12"
                                    >
                                        <LatexDisplay latex={preset} />
                                        <button
                                            onClick={() =>
                                                handleCopyPresetButtonClick(
                                                    preset,
                                                )
                                            }
                                            className="btn btn-warning mt-2"
                                        >
                                            복사
                                        </button>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};
