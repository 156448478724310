import React, { useEffect, useState } from 'react';

export const usePageBlock = ({
    blockSize,
    limitPerPage,
    page,
    initialTotal,
}) => {
    const [pageBlock, setPageBlock] = useState([]);

    const [before, setBefore] = useState();
    const [next, setNext] = useState();

    const [total, setTotal] = useState(initialTotal);
    const pageParams = {
        limit: limitPerPage,
        offset: (page - 1) * limitPerPage,
    };

    const getPageBlock = () => {
        let next;
        let before;

        const moduled =
            page % blockSize === 0
                ? Math.floor(page / blockSize)
                : Math.floor(page / blockSize) + 1;

        const startPageNumber = (moduled - 1) * blockSize + 1;
        before = startPageNumber - 1 !== 0 ? startPageNumber - 1 : null;

        const block = [];
        for (
            let currentPage = startPageNumber;
            currentPage < startPageNumber + blockSize;
            currentPage++
        ) {
            const accumulatedPostNumber = currentPage * limitPerPage;
            if (accumulatedPostNumber - total >= limitPerPage) {
                next = null;
                break;
            }

            block.push(currentPage);

            next = currentPage + 1;
            if (next * limitPerPage >= total + limitPerPage) {
                next = null;
            }
        }

        if (block.length === 0) {
            block.push(1);
            before = null;
            next = null;
        }

        setNext(next);
        setBefore(before);
        setPageBlock(block);
    };

    useEffect(() => {
        getPageBlock();
    }, []);

    useEffect(() => {
        getPageBlock();
    }, [blockSize, limitPerPage, page, total]);

    return { pageBlock, pageParams, setTotal, before, next };
};
