import React, { useRef, useEffect, useState, useContext } from 'react';
import { Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useForm, useFieldArray } from 'react-hook-form';

import { AppContext } from 'Contexts';
import {
    BasicConceptForm,
    TagWizard,
    StudySubjectOrderDisplay,
    LatexEditor,
} from 'Components';
import { TagResultDisplay } from 'Components/tag-wizard/TagResultDisplay';

import { useAPI, APIRoute } from 'Client';

export const AdminIssuedStudySubjectInspectView = props => {
    const {
        entity: studySubject,
        location: { state = {} },
    } = props;
    if (!studySubject || !state.studySubjectOrder) {
        return <></>;
    }

    const {
        location: {
            state: { studySubjectOrder },
        },
    } = props;
    const { concept } = studySubject;

    const history = useHistory();
    const {
        admin,
        dispatchers,
        getters: { getSchoolSubject, getSchoolSubjectUnit },
    } = useContext(AppContext);

    const [isModifyingTags, setIsModifyingTags] = useState(false);
    const [tags, setTags] = useState(studySubject.tags);

    const handleCompleteButtonClick = () => {
        if (!admin.selectedSchoolSubjectUnit) {
            alert('단원 선택 필수');
            return;
        }
        if (admin.selectedAchievements.length === 0) {
            alert('성취 요소 선택 필수');
            return;
        }

        history.push({
            pathname: '/admin/main/issued-study-subjects/create/',
            state: {
                studySubject,
                studySubjectOrder,
                selectedSchoolSubject: admin.selectedSchoolSubject,
                selectedSchoolSubjectUnit: admin.selectedSchoolSubjectUnit,
                selectedAchievements: admin.selectedAchievements,
            },
        });
    };

    const missionReportBookAPI = useAPI(APIRoute.Admin.MissionReport.Book, {
        callbacks: {
            onSuccess: res => {
                if (res.code.title) {
                    alert(
                        `제목: ${res.code.title}\n출판사: ${res.code.publisher}\n저자: ${res.code.author}\n책표지 사진: ${res.code.cover}`,
                    );
                } else {
                    alert('도서 정보 불러오기 실패!');
                }
            },
        },
    });

    const form = useForm({
        defaultValues: {
            ...concept,
            ...studySubject,
            relatedConcepts: concept.relatedConcepts.map(value => ({
                value,
            })),
            relatedConceptsInEnglish: concept.relatedConceptsInEnglish.map(
                value => ({
                    value,
                }),
            ),
            relatedMajors: concept.relatedMajors.map(value => ({
                value,
            })),
            relatedSchoolSubjects: concept.relatedSchoolSubjects.map(value => ({
                value,
            })),
            searchKeywords: studySubject.keywords
                .filter(({ type }) => type === 'search')
                .map(({ keyword }) => ({
                    value: keyword,
                })),
            exampleKeywords: studySubject.keywords
                .filter(({ type }) => type === 'example')
                .map(({ keyword }) => ({
                    value: keyword,
                })),
            relevantKeywords: studySubject.keywords
                .filter(({ type }) => type === 'relevant')
                .map(({ keyword }) => ({
                    value: keyword,
                })),
            recommendedBooks: studySubject.recommendedBooks.map(isbn => ({
                value: isbn,
            })),
        },
    });

    const relatedConcepts = useFieldArray({
        control: form.control,
        name: 'relatedConcepts',
    });
    const relatedConceptsInEnglish = useFieldArray({
        control: form.control,
        name: 'relatedConceptsInEnglish',
    });
    const relatedMajors = useFieldArray({
        control: form.control,
        name: 'relatedMajors',
    });
    const relatedSchoolSubjects = useFieldArray({
        control: form.control,
        name: 'relatedSchoolSubjects',
    });

    const searchKeywords = useFieldArray({
        control: form.control,
        name: 'searchKeywords',
    });
    const exampleKeywords = useFieldArray({
        control: form.control,
        name: 'exampleKeywords',
    });
    const relevantKeywords = useFieldArray({
        control: form.control,
        name: 'relevantKeywords',
    });
    const recommendedBooks = useFieldArray({
        control: form.control,
        name: 'recommendedBooks',
    });

    useEffect(() => {
        if (!studySubjectOrder) {
            return;
        }

        const { schoolSubject } = studySubjectOrder;

        dispatchers.admin.setSelectedSchoolSubject(schoolSubject?.id);

        return () => {
            dispatchers.admin.setSelectedSchoolSubject();
            dispatchers.admin.setSelectedSchoolSubjectUnit();
        };
    }, [studySubjectOrder]);

    return (
        <>
            <div className="study-subject-container mt-3">
                <div
                    className="admin-back-btn cursor-pointer"
                    onClick={() => history.goBack()}
                >
                    &#xE000;&nbsp; 뒤로가기
                </div>
                {studySubjectOrder && (
                    <StudySubjectOrderDisplay
                        studySubjectOrder={studySubjectOrder}
                    />
                )}
                {studySubject.caseorderMaps && (
                    <div className="mt-2">
                        <div className="col-6 mb-1">
                            <div className="d-inline-block justify-content-center align-items-center">
                                <h6 className="text-middle text-center mb-0">
                                    발급횟수
                                </h6>
                            </div>
                            <span className="d-inline-block ml-3">
                                {studySubject.issuedStudySubjectCount}
                            </span>
                        </div>
                        <div className="col-6">
                            <div className="d-inline-block justify-content-center align-items-center">
                                <h6 className="text-middle text-center mb-0">
                                    발급받은 학생
                                </h6>
                            </div>
                            <div className="d-inline-block  ml-3">
                                {studySubject.caseorderMaps.map((id, key) => (
                                    <span key={key}>[{id}] </span>
                                ))}
                            </div>
                        </div>
                    </div>
                )}
                <div className="row">
                    <div className="w-100">
                        <Form className="w-100">
                            <BasicConceptForm
                                form={form}
                                isSearchEnabled={false}
                                disabled={true}
                            />
                            <div className="form-row mt-4">
                                <div className="col-2">
                                    <div className="h-100 d-flex justify-content-center align-items-center">
                                        <h5 className="text-middle text-center mb-0">
                                            유사개념
                                        </h5>
                                    </div>
                                </div>
                                {relatedConcepts.fields.map(
                                    (relatedConcept, index) => (
                                        <div
                                            key={relatedConcept.id}
                                            className="col-2"
                                        >
                                            <input
                                                disabled
                                                key={relatedConcept.id}
                                                ref={form.register}
                                                name={`relatedConcepts[${index}].value`}
                                                defaultValue={
                                                    relatedConcept.value
                                                }
                                                className="form-control rounded-0 border-dark"
                                            />
                                        </div>
                                    ),
                                )}
                            </div>
                            <div className="form-row mt-4">
                                <div className="col-2">
                                    <div className="h-100 d-flex justify-content-center align-items-center">
                                        <h5 className="text-middle text-center mb-0">
                                            원어명
                                        </h5>
                                    </div>
                                </div>
                                {relatedConceptsInEnglish.fields.map(
                                    (relatedConceptInEnglish, index) => (
                                        <div
                                            key={relatedConceptInEnglish.id}
                                            className="col-2"
                                        >
                                            <input
                                                disabled
                                                key={relatedConceptInEnglish.id}
                                                ref={form.register}
                                                name={`relatedConceptsInEnglish[${index}].value`}
                                                defaultValue={
                                                    relatedConceptInEnglish.value
                                                }
                                                className="form-control rounded-0 border-dark"
                                            />
                                        </div>
                                    ),
                                )}
                            </div>

                            <div className="form-line mt-4">
                                <div className="form-group w-100">
                                    <label>
                                        <h5 className="mr-2 d-inline-block">
                                            과제
                                        </h5>
                                        <span>(공백 포함 300-350 글자)</span>
                                    </label>
                                    <div className="position-relative">
                                        <LatexEditor
                                            name="task"
                                            formRef={form}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-row mt-5">
                                <div className="col-2">
                                    <div className="h-100 d-flex justify-content-center align-items-center">
                                        <h5 className="text-middle text-center mb-0">
                                            검색 키워드(필수)
                                        </h5>
                                    </div>
                                </div>
                                {searchKeywords.fields.map(
                                    (searchKeyword, index) => (
                                        <div
                                            key={searchKeyword.id}
                                            className="col-2"
                                        >
                                            <input
                                                disabled
                                                key={searchKeyword.id}
                                                ref={form.register}
                                                name={`searchKeywords[${index}].value`}
                                                defaultValue={
                                                    searchKeyword.value
                                                }
                                                className="form-control rounded-0 border-dark"
                                            />
                                        </div>
                                    ),
                                )}
                            </div>
                            <div className="form-row mt-3">
                                <div className="col-2">
                                    <div className="h-100 d-flex justify-content-center align-items-center">
                                        <h5 className="text-middle text-center mb-0">
                                            사례 키워드(옵션)
                                        </h5>
                                    </div>
                                </div>
                                {exampleKeywords.fields.map(
                                    (exampleKeywordField, index) => (
                                        <div
                                            key={exampleKeywordField.id}
                                            className="col-2"
                                        >
                                            <input
                                                disabled
                                                key={exampleKeywordField.id}
                                                ref={form.register}
                                                name={`exampleKeywords[${index}].value`}
                                                defaultValue={
                                                    exampleKeywordField.value
                                                }
                                                className="form-control rounded-0 border-dark"
                                            />
                                        </div>
                                    ),
                                )}
                            </div>
                            <div className="form-row mt-4">
                                <div className="col-2">
                                    <div className="h-100 d-flex justify-content-center align-items-center">
                                        <h5 className="text-middle text-center mb-0">
                                            관련 키워드(옵션)
                                        </h5>
                                    </div>
                                </div>
                                {relevantKeywords.fields.map(
                                    (relevantKeywordField, index) => (
                                        <div
                                            key={relevantKeywordField.id}
                                            className="col-2"
                                        >
                                            <input
                                                disabled
                                                key={relevantKeywordField.id}
                                                ref={form.register}
                                                name={`relevantKeywords[${index}].value`}
                                                defaultValue={
                                                    relevantKeywordField.value
                                                }
                                                className="form-control rounded-0 border-dark"
                                            />
                                        </div>
                                    ),
                                )}
                            </div>
                            <div className="form-row mt-4">
                                <div className="col-2">
                                    <div className="h-100 d-flex justify-content-center align-items-center">
                                        <h5 className="text-middle text-center mb-0">
                                            관련 도서(ISBN)
                                        </h5>
                                    </div>
                                </div>
                                {recommendedBooks.fields.map(
                                    (recommendedBookField, index) => (
                                        <div
                                            key={recommendedBookField.id}
                                            className="col-2"
                                            onClick={() =>
                                                missionReportBookAPI.send({
                                                    book:
                                                        recommendedBookField.value,
                                                })
                                            }
                                        >
                                            <input
                                                disabled
                                                key={recommendedBookField.id}
                                                ref={form.register}
                                                name={`recommendedBooks[${index}].value`}
                                                defaultValue={
                                                    recommendedBookField.value
                                                }
                                                className="form-control rounded-0 border-dark"
                                            />
                                        </div>
                                    ),
                                )}
                            </div>
                            <hr className="border-dark mt-4 thick" />
                            <div className="mt-5">
                                <div className="d-flex justify-content-end">
                                    <button
                                        type="button"
                                        onClick={() => {
                                            setIsModifyingTags(
                                                !isModifyingTags,
                                            );
                                        }}
                                    >
                                        태그 수정
                                    </button>
                                </div>
                                <hr className="border-dark mt-5 thick" />
                                {isModifyingTags ? (
                                    <TagWizard
                                        isLoaded={tags.length > 0}
                                        tags={tags}
                                        setTags={setTags}
                                        setFinished={() => {
                                            setIsModifyingTags(false);
                                        }}
                                    />
                                ) : (
                                    <TagResultDisplay tags={tags} />
                                )}

                                <hr className="border-dark mt-5 thick" />
                                <div className="form-row mt-5 mb-5 justify-content-center">
                                    <div className="col-3 justify-content-center align-items-center d-flex">
                                        <button
                                            onClick={() => history.goBack()}
                                            id="study-subject-issue-btn"
                                            type="button"
                                            className="btn-cancel btn-lg btn-block"
                                        >
                                            발급 취소
                                        </button>
                                    </div>
                                    <div className="offset-2 col-3 justify-content-center align-items-center d-flex">
                                        <button
                                            onClick={() =>
                                                handleCompleteButtonClick()
                                            }
                                            id="study-subject-issue-btn"
                                            type="button"
                                            className="btn-main-color btn-lg btn-block"
                                        >
                                            선택 완료
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </>
    );
};
