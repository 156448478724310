import React, { useContext } from 'react';

export const Footer = () => {
    return (
        <>
            <div className="w-100 footer">
                <span className="opacity-60">(주)잡쇼퍼	| 대표: 권재우, 권기원 <br />
                    서울특별시 관악구 남부순환로 1984, 2층<br /> (봉천동, 이공빌딩) <br />
                    사업자등록번호: 315-87-00617 <br />
                    전화번호: 02-6951-1510</span>
                <div className="mt-1 mb-1">대표이메일 : ceo@jobshopper.co.kr<br /></div>
                <span className="opacity-40">© 2021. LEAN AI. All Rights Reserved.</span>
            </div>
        </>
    )

}