import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

import { useAPI, APIRoute } from 'Client';
import Select from 'react-select';

export const expelReasonOptions = [
    { value: '표절', label: '표절' },
    { value: '피드백 미반영 누적', label: '피드백 미반영 누적' },
    { value: '작업 오류 지속', label: '작업 오류 지속' },
];

export const ResearchAssistantProfileModal = ({
    researchAssistantProfile,
    handleClick = () => {},
    isOpen = false,
    close,
    onClickButton,
    onClickNotice,
}) => {
    const [showExpel, setShowExpel] = useState(false);
    const [expelReason, setExpelReason] = useState('');

    const researchAssistantExpelAPI = useAPI(
        APIRoute.Admin.ResearchAssistant.Expel,
        {
            callbacks: {
                onSuccess: res => {
                    alert('성공');
                },
            },
        },
    );

    return (
        <Modal
            id="search-concepts-modal"
            className="modal"
            show={isOpen}
            onHide={close}
        >
            <Modal.Header className="d-block">
                <div className="row w-100">
                    <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={close}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="row w-100 justify-content-center align-items-center">
                    {researchAssistantProfile && (
                        <h4>{researchAssistantProfile.user.name}의 프로필</h4>
                    )}
                </div>
            </Modal.Header>
            <Modal.Body>
                {researchAssistantProfile && (
                    <div className="container">
                        <div className="admin-ra-profile-modal-table">
                            <div className="admin-ra-profile-modal-tr">
                                <div className="admin-ra-profile-modal-title">
                                    대학(원)
                                </div>
                                <div className="admin-ra-profile-modal-content">
                                    {researchAssistantProfile.universityName}
                                </div>
                            </div>
                            <div className="admin-ra-profile-modal-tr">
                                <div className="admin-ra-profile-modal-title">
                                    학과(전공)
                                </div>
                                <div className="admin-ra-profile-modal-content">
                                    {researchAssistantProfile.majorName &&
                                        researchAssistantProfile.majorName.join(
                                            ', ',
                                        )}
                                </div>
                            </div>
                            <div className="admin-ra-profile-modal-tr">
                                <div className="admin-ra-profile-modal-title">
                                    자격증
                                </div>
                                <div className="admin-ra-profile-modal-content">
                                    {researchAssistantProfile.certificate &&
                                        researchAssistantProfile.certificate
                                            .length > 0 &&
                                        Object.entries(
                                            researchAssistantProfile
                                                .certificate[0],
                                        )
                                            .filter(x => x[1])
                                            .map(x => (
                                                <div>
                                                    {x[0]}: {x[1]} /
                                                </div>
                                            ))}
                                </div>
                            </div>
                            <div className="admin-ra-profile-modal-tr">
                                <div className="admin-ra-profile-modal-title">
                                    입학연도
                                </div>
                                <div className="admin-ra-profile-modal-content">
                                    {
                                        researchAssistantProfile.universityEntranceYear
                                    }
                                </div>
                            </div>
                            <div className="admin-ra-profile-modal-tr">
                                <div className="admin-ra-profile-modal-title">
                                    학생부종합전형 겸험 여부
                                </div>
                                <div className="admin-ra-profile-modal-content">
                                    {researchAssistantProfile.studentRecordAcceptance
                                        ? 'YES'
                                        : 'NO'}
                                </div>
                            </div>
                            <div className="admin-ra-profile-modal-tr">
                                <div className="admin-ra-profile-modal-title">
                                    학생부종합전형 합격 여부
                                </div>
                                <div className="admin-ra-profile-modal-content">
                                    {researchAssistantProfile.studentRecordPreparation
                                        ? 'YES'
                                        : 'NO'}
                                </div>
                            </div>
                            <div className="admin-ra-profile-modal-tr">
                                <div className="admin-ra-profile-modal-title">
                                    휴대폰 번호
                                </div>
                                <div className="admin-ra-profile-modal-content">
                                    {researchAssistantProfile.user.phone}
                                </div>
                            </div>
                            <div className="admin-ra-profile-modal-tr">
                                <div className="admin-ra-profile-modal-title">
                                    메일 주소
                                </div>
                                <div className="admin-ra-profile-modal-content">
                                    [가입 메일]{' '}
                                    {researchAssistantProfile.user.email}
                                    <br />
                                    [자격 메일]{' '}
                                    {researchAssistantProfile.universityEmail}
                                </div>
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="admin-ra-profile-modal-table">
                                <div className="admin-ra-profile-modal-tr">
                                    <div className="w-33">
                                        <div className="admin-ra-profile-modal-title">
                                            작업
                                        </div>
                                        <div className="admin-ra-profile-modal-content">
                                            <span>
                                                {
                                                    researchAssistantProfile
                                                        .count.studySubject
                                                }{' '}
                                            </span>
                                            건
                                        </div>
                                    </div>
                                    <div className="w-33">
                                        <div className="admin-ra-profile-modal-title">
                                            승인
                                        </div>
                                        <div className="admin-ra-profile-modal-content main-color">
                                            <span>
                                                {
                                                    researchAssistantProfile
                                                        .count.accepted
                                                }{' '}
                                            </span>
                                            건
                                        </div>
                                    </div>
                                    <div className="w-33">
                                        <div className="admin-ra-profile-modal-title">
                                            재제출
                                        </div>
                                        <div className="admin-ra-profile-modal-content">
                                            <span>
                                                {
                                                    researchAssistantProfile
                                                        .count.resubmitted
                                                }{' '}
                                            </span>
                                            건
                                        </div>
                                    </div>
                                </div>
                                <div className="admin-ra-profile-modal-tr">
                                    <div className="w-33">
                                        <div className="admin-ra-profile-modal-title">
                                            이론
                                        </div>
                                        <div className="admin-ra-profile-modal-content">
                                            <span>
                                                {
                                                    researchAssistantProfile
                                                        .count.concept
                                                }{' '}
                                            </span>
                                            건
                                        </div>
                                    </div>
                                    <div className="w-33">
                                        <div className="admin-ra-profile-modal-title">
                                            반려
                                        </div>
                                        <div className="admin-ra-profile-modal-content danger-color">
                                            <span>
                                                {
                                                    researchAssistantProfile
                                                        .count.rejected
                                                }{' '}
                                            </span>
                                            건
                                        </div>
                                    </div>
                                    <div className="w-33">
                                        <div className="admin-ra-profile-modal-title">
                                            재승인
                                        </div>
                                        <div className="admin-ra-profile-modal-content">
                                            <span>
                                                {
                                                    researchAssistantProfile
                                                        .count.resubmitAccepted
                                                }{' '}
                                            </span>
                                            건
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="admin-ra-profile-modal-table">
                                <div className="admin-ra-profile-modal-tr">
                                    <div className="w-25">
                                        <div className="admin-ra-profile-modal-title half">
                                            승인율
                                        </div>
                                        <div className="admin-ra-profile-modal-content main-color half">
                                            <span>
                                                {researchAssistantProfile.count
                                                    .studySubject &&
                                                researchAssistantProfile.count
                                                    .studySubject !== '-'
                                                    ? parseInt(
                                                          researchAssistantProfile
                                                              .count.accepted /
                                                              researchAssistantProfile
                                                                  .count
                                                                  .studySubject,
                                                      ) * 100
                                                    : '-'}
                                            </span>{' '}
                                            %
                                        </div>
                                    </div>
                                    <div className="w-25">
                                        <div className="admin-ra-profile-modal-title half">
                                            반려율
                                        </div>
                                        <div className="admin-ra-profile-modal-content danger-color half">
                                            <span>
                                                {researchAssistantProfile.count
                                                    .studySubject &&
                                                researchAssistantProfile.count
                                                    .studySubject !== '-'
                                                    ? parseInt(
                                                          researchAssistantProfile
                                                              .count.rejected /
                                                              researchAssistantProfile
                                                                  .count
                                                                  .studySubject,
                                                      ) * 100
                                                    : '-'}
                                            </span>{' '}
                                            %
                                        </div>
                                    </div>
                                    <div className="w-25">
                                        <div className="admin-ra-profile-modal-title half">
                                            재제출률
                                        </div>
                                        <div className="admin-ra-profile-modal-content half">
                                            <span>
                                                {researchAssistantProfile.count
                                                    .resubmitted
                                                    ? parseInt(
                                                          (researchAssistantProfile
                                                              .count
                                                              .resubmitAccepted /
                                                              researchAssistantProfile
                                                                  .count
                                                                  .resubmitted) *
                                                              100,
                                                      )
                                                    : '-'}
                                            </span>{' '}
                                            %
                                        </div>
                                    </div>
                                    <div className="w-25">
                                        <div className="admin-ra-profile-modal-title half">
                                            재승인율
                                        </div>
                                        <div className="admin-ra-profile-modal-content half">
                                            <span>
                                                {researchAssistantProfile.count
                                                    .resubmitted
                                                    ? parseInt(
                                                          (researchAssistantProfile
                                                              .count
                                                              .resubmitAccepted /
                                                              researchAssistantProfile
                                                                  .count
                                                                  .resubmitted) *
                                                              100,
                                                      )
                                                    : '-'}
                                            </span>{' '}
                                            %
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-3 justify-content-center">
                            <div className="col-3 justify-content-center d-flex">
                                <button
                                    onClick={onClickNotice}
                                    className="btn-border-danger btn-lg btn-block"
                                >
                                    알림
                                </button>
                            </div>
                            <div className="col-3 justify-content-center d-flex">
                                <button
                                    onClick={onClickButton}
                                    className="btn-border-main btn-lg btn-block"
                                >
                                    정산내역 확인
                                </button>
                            </div>
                            <div className="col-3 justify-content-center d-flex">
                                <button
                                    onClick={() => setShowExpel(true)}
                                    className="btn-border-main btn-lg btn-block"
                                >
                                    제명
                                </button>
                            </div>
                            <div className="offset-1 col-3 justify-content-center d-flex">
                                <button
                                    onClick={close}
                                    className="btn-main-color btn-lg btn-block"
                                >
                                    확인
                                </button>
                            </div>
                        </div>
                    </div>
                )}
                {showExpel && (
                    <div>
                        <Select
                            options={expelReasonOptions}
                            placeholder="제명 사유"
                            onChange={v => setExpelReason(v)}
                        />
                        {expelReason && (
                            <div className="col-3 justify-content-center d-flex">
                                <button
                                    onClick={() => {
                                        if (confirm('정말 제명하시겠습니까?'))
                                            researchAssistantExpelAPI.send(
                                                {
                                                    expelDurationDay: 700,
                                                    expelledReason: expelReason,
                                                },
                                                {
                                                    additionalPath: `${researchAssistantProfile.id}/expel`,
                                                },
                                            );
                                    }}
                                    className="btn-border-main btn-lg btn-block"
                                >
                                    제명하기
                                </button>
                            </div>
                        )}
                    </div>
                )}
            </Modal.Body>
        </Modal>
    );
};
