import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

import { useAPI, APIRoute } from 'Client';

export const ResearchAssistantMyProfileModal = ({
    researchAssistantProfile,
    user,
    handleClick = () => { },
    isOpen = false,
    close,
}) => {
    let acceptedMissionReportNumber = 0;
    let rejectedMissionReportNumber = 0;
    let conceptNumber = 0;
    let studySubjectNumber = 0;

    if (researchAssistantProfile) {
        const { missionReports } = researchAssistantProfile;
        // missionReports.forEach(({ status, concept, studySubject }) => {
        //     if (status === 'ACCEPTED') {
        //         acceptedMissionReportNumber += 1;
        //     } else if (status === 'REJECTED') {
        //         rejectedMissionReportNumber += 1;
        //     }

        //     if (studySubject) {
        //         studySubjectNumber += 1;
        //     }

        //     if (concept) {
        //         conceptNumber += 1;
        //     }
        // });
    }

    return (
        <Modal
            id="search-concepts-modal"
            className="modal"
            show={isOpen}
            onHide={close}
        >
            <Modal.Header className="d-block">
                <div className="row w-100">
                    <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={close}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="row w-100 justify-content-center align-items-center">
                    <h4>RA 정보</h4>
                </div>
            </Modal.Header>
            <Modal.Body>
                {researchAssistantProfile && (
                    <div className="container">
                        <div className="row mt-3">
                            <div className="col-6">
                                <div className="mb-1">
                                    <span>이름</span>
                                </div>
                                <div>
                                    <input
                                        defaultValue={
                                            user.name
                                        }
                                        className="form-control rounded-0 border-dark"
                                    />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="mb-1">
                                    <span>휴대폰번호</span>
                                </div>
                                <div>
                                    <input
                                        defaultValue={
                                            user.phone
                                        }
                                        className="form-control rounded-0 border-dark"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-6">
                                <div className="mb-1">
                                    <span>대학교</span>
                                </div>
                                <div>
                                    <input
                                        defaultValue={
                                            researchAssistantProfile.university &&
                                            researchAssistantProfile.university
                                                .name
                                        }
                                        className="form-control rounded-0 border-dark"
                                    />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="mb-1">
                                    <span>학과</span>
                                </div>
                                <div>
                                    <input
                                        defaultValue={
                                            researchAssistantProfile.major &&
                                            researchAssistantProfile.major.name
                                        }
                                        className="form-control rounded-0 border-dark"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-6">
                                <div className="mb-1">
                                    <span>재학 / 졸업 여부</span>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-6">
                                        <input
                                            name=""
                                            type="radio"

                                            defaultValue=""
                                            className="rounded-0 border-dark"
                                        />
                                        <label className="ml-1">재학</label>
                                    </div>
                                    <div className="col-6">
                                        <input
                                            name=""
                                            type="radio"

                                            defaultValue=""
                                            className="rounded-0 border-dark"
                                        />
                                        <label className="ml-1">졸업</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-12">
                                <div className="mb-1">
                                    <span>학년/학기</span>
                                </div>
                                <div className="row">
                                    <div className="col-5">
                                        <input

                                            defaultValue=""
                                            className="form-control rounded-0 border-dark"
                                        />
                                    </div>
                                    <div className="offset-1 col-5">
                                        <input

                                            defaultValue=""
                                            className="form-control rounded-0 border-dark"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-6">
                                <div className="mb-1">
                                    <span>학생부 종합전형 합격 여부</span>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-6">
                                        <input
                                            name=""
                                            type="radio"

                                            defaultValue=""
                                            className="rounded-0 border-dark"
                                        />
                                        <label className="ml-1">예</label>
                                    </div>
                                    <div className="col-6">
                                        <input
                                            name=""
                                            type="radio"

                                            defaultValue=""
                                            className="rounded-0 border-dark"
                                        />
                                        <label className="ml-1">아니오</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        {/* <div className="row mt-3">
                            <table className="w-100 text-center">
                                <thead>
                                    <tr>
                                        <td className="pt-3 pb-3 border-bottom border-dark">
                                            반려 횟수
                                        </td>
                                        <td className="pt-3 pb-3 border-bottom border-dark">
                                            완료 미션
                                        </td>
                                        <td className="pt-3 pb-3 border-bottom border-dark">
                                            탐구 이론
                                        </td>
                                        <td className="pt-3 pb-3 border-bottom border-dark">
                                            탐구 주제
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="pt-3 pb-3">
                                            {rejectedMissionReportNumber}
                                        </td>
                                        <td className="pt-3 pb-3">
                                            {acceptedMissionReportNumber}
                                        </td>
                                        <td className="pt-3 pb-3">
                                            {conceptNumber}
                                        </td>
                                        <td className="pt-3 pb-3">
                                            {studySubjectNumber}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                         */}
                        <div className="row mt-3 text-center">
                            <button
                                onClick={close}
                                className="btn btn-primary rounded-0 btn-block"
                            >
                                변경하기
                            </button>
                        </div>
                    </div>
                )}
            </Modal.Body>
        </Modal>
    );
};
