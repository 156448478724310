import React, { useEffect, useState, useContext } from 'react';

import { Modal, Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { AppContext } from 'Contexts';
import { useAPI, APIRoute } from 'Client';

export const TagAchievements = props => {
    const { element, close, unit, tags } = props;

    const {
        getters: { getSchoolSubjectUnit },
        user,
    } = useContext(AppContext);

    const [achievements, setAchivements] = useState([]);

    const getAchivementsAPI = useAPI(APIRoute.Admin.Achievements.GET, {
        callbacks: {
            onSuccess: data => {
                setAchivements(data);
            },
        },
    });

    useEffect(() => {
        if (element) getAchivementsAPI.send({ element: element, unit: unit });
    }, [element]);

    return (
        <Modal id="find-password-modal" className="modal-narrow" show={element}>
            <Modal.Header className="d-block">
                <div className="row w-100">
                    <Button className="close" onClick={close}>
                        <span aria-hidden="true">&times;</span>
                    </Button>
                </div>
                <div className="row w-100 justify-content-center align-items-center">
                    <h4>{element}</h4>
                </div>
            </Modal.Header>
            <Modal.Body>
                {achievements &&
                    achievements.map(achievement => <p>{achievement}</p>)}
            </Modal.Body>
        </Modal>
    );
};
