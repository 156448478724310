import React, { useState } from 'react';

import { useHistory } from 'react-router-dom';

import { BaseTable, usePageBlock, Pagination } from 'Components/table/base';

const columnNames = [
    {
        key: 'id',
        name: '번호',
    },
    {
        key: 'title',
        name: '미션명',
    },
];

export const MissionTable = props => {
    const history = useHistory();

    const [tableData, setTableData] = useState([
        {
            id: 1,
            title: '[교육] 진로탐색을 위한 탐구과제 생성',
        },
    ]);

    const gotoDetail = mission => {
        history.push(`/admin/management/missions/${mission.id}/`);
    };

    return (
        <div className="ra-mission-container">
            <div className="col-12 mt-3 p-0">
                <BaseTable
                    columnNames={columnNames}
                    data={tableData}
                    onClick={gotoDetail}
                />
            </div>
        </div>
    );
};
