import React, { useEffect, useRef, useState, useContext } from 'react';
import { Row, Col, Modal, Form, Button } from 'react-bootstrap';
import { useForm, useFieldArray } from 'react-hook-form';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import classNames from 'classnames';

import { useHistory } from 'react-router-dom';
import { AppContext } from 'Contexts/AppContext';

import { useAPI, APIRoute } from 'Client';

const wrong_sentence = [
    '할머니께서 오래오래 장수하시기를 바란다.',
    '어제 개봉한 영화의 특징은 길이가 길지만 인상은 강하다.',
    '그의 전사 소식은 장군에게 여간한 슬픔이었다.',
    '새로 개발된 자동차는 유해 물질과 에너지 효율이 높다.',
    '한국은 한국 전쟁 이후 약 70년 동안 기술의 발달과 그에 따라 사회의 큰 변동을 겪었다.',
    '그는 사고 이후 친구 이름의 혼동이나 과거 사건을 잘 기억하지 못했다.',
    '그는 외투를 벗고 의자에 앉아 서류를 넣었다.',
];

const mission_term_lit = [
    '마타도어',
    '온택트',
    '공유경제',
    '상호문화주의',
    '에코프렌들리',
];
const mission_term_sci = [
    'RNA',
    '인공신경망',
    '치환적분법',
    '헤스법칙',
    '고분자물질',
];

export const StudentTestView = ({ location, ...props }) => {
    const history = useHistory();
    const { researchAssistant } = useContext(AppContext);

    const [selectedTerm, setSelectedTerm] = useState(false);
    const [tutorial1C, setTutorial1C] = useState(false);
    const [tutorial2C, setTutorial2C] = useState(false);
    const [tutorial3C, setTutorial3C] = useState(false);
    const [tutorial1, setTutorial1] = useState(false);
    const [tutorial2, setTutorial2] = useState(false);
    const [tutorial3, setTutorial3] = useState(false);

    const form = useForm({
        // only for fields to watch
        defaultValues: {
            researchAssistanceSentence: [
                { value: '' },
                { value: '' },
                { value: '' },
                { value: '' },
                { value: '' },
                { value: '' },
                { value: '' },
            ],
            task: '',
            conceptDescription: '',
            relevantKeywords: [
                { value: '' },
                { value: '' },
                { value: '' },
                { value: '' },
            ],
        },
    });
    const relevantKeywords = useFieldArray({
        control: form.control,
        name: 'relevantKeywords',
    });
    const researchAssistanceSentence = useFieldArray({
        control: form.control,
        name: 'researchAssistanceSentence',
    });

    const checkAllChecked = () => {
        return (
            selectedTerm &&
            form.watch('conceptDescription').length &&
            form.watch('task').length &&
            form.watch('relevantKeywords[0].value').length &&
            form.watch('relevantKeywords[1].value').length &&
            form.watch('relevantKeywords[2].value').length &&
            form.watch('relevantKeywords[3].value').length
        );
    };

    const testSubmitAPI = useAPI(APIRoute.ResearchAssistant.TestReport.Create, {
        callbacks: {
            onSuccess: res => {
                alert('제출 성공!');
                history.push('/ra/test/waiting/');
            },
        },
    });

    const testSubmitCheckAPI = useAPI(
        APIRoute.ResearchAssistant.TestReport.Check,
        {
            callbacks: {
                onSuccess: res => {
                    history.push('/ra/test/waiting/');
                },
            },
        },
    );

    const emailValidationStatusAPI = useAPI(
        APIRoute.ResearchAssistant.TestReport.EmailValidationStatus,
        {
            callbacks: {
                onSuccess: res => {
                    if (!researchAssistant.profile.major)
                        history.push('/ra/test/validation/');
                },
                onError: err => {
                    history.push('/ra/test/validation/');
                },
            },
        },
    );

    form.handleSubmit = form.handleSubmit((data, event) => {
        const {
            researchAssistanceSentence,
            conceptDescription,
            task,
            relevantKeywords,
        } = data;

        testSubmitAPI.send({
            testReportExample: 1,
            submittedSentence: researchAssistanceSentence,
            terms: selectedTerm,
            summary: conceptDescription,
            task: task,
            keywords: relevantKeywords,
        });
    });
    const handleTutorial1 = () => {
        if (tutorial1C) {
            setTutorial1C(false);
        } else {
            setTutorial1(true);
            setTutorial1C(true);
        }
    };
    const handleTutorial2 = () => {
        if (tutorial2C) {
            setTutorial2C(false);
        } else {
            setTutorial2(true);
            setTutorial2C(true);
        }
    };
    const handleTutorial3 = () => {
        if (tutorial3C) {
            setTutorial3C(false);
        } else {
            setTutorial3(true);
            setTutorial3C(true);
        }
    };

    useEffect(() => {
        emailValidationStatusAPI.send()
        testSubmitCheckAPI.send()
    }, []);

    return (
        <div className="ra-mission-container">
            <div className="ra-mission-title">Mission</div>
            <div className="ra-mission-category">
                전공 탐색을 위한 탐구과제 생성
            </div>
            <div className="ra-test-description">
                테스트를 통과하셔야 작업에 착수하실 수 있습니다. 합격/불합격
                여부가 <span>1영업일 이내</span>에 통보됩니다.
            </div>
            <hr />
            <div className="mt-4 d-flex">
                <h6 className="text-muted" style={{ lineHeight: 1.4 }}>
                    <span style={{ color: 'red', fontWeight: 800 }}>
                        [표절 금지 안내]
                    </span>
                    <br />
                    작성 시 참고문헌이나 자료를 그대로 베끼시면 안 됩니다. 원본
                    자료를 참조하시되 작업자 본인의 언어로 가공 및 수정해주시기
                    바랍니다.{' '}
                    <span style={{ color: 'red', fontWeight: 600 }}>
                        표절이 적발될 경우 즉시 불합격 처리
                    </span>
                    됩니다.
                </h6>
            </div>
            {/* 미션가이드 */}
            <Form onSubmit={form.handleSubmit} className="w-100">
                {/* 
        <section className="mt-4">
          <h4>TEST 1. 문장 다듬기</h4>
          <div className="ra-test-description">아래의 문장들을 바르게 고쳐 주세요.</div>
          {researchAssistanceSentence.fields.map(
            (relevantKeywordField, index) => (
              <div
                key={relevantKeywordField.id}
                className="col-12 mt-3"
              >
                <div className="mb-2">{wrong_sentence[index]}</div>
                <input
                  key={relevantKeywordField.id}
                  ref={form.register()}
                  name={`researchAssistanceSentence[${index}].value`}
                  defaultValue={
                    relevantKeywordField.value
                  }
                  className="form-control rounded-0"
                />
              </div>
            ),
          )}
        </section>
        */}
                {/* 약관 동의 */}
                <section className="mt-4">
                    <h4>TEST 과제 작성</h4>
                    <h5>1. 원리 작성</h5>
                    <div>
                        아래의 용어 중 하나를 택하여 작성해 주세요.
                        (클릭해주세요)
                    </div>
                    <div className="mt-3" style={{ fontWeight: 700 }}>
                        [인문/사회계열]
                    </div>
                    <div className="row mb-3">
                        {mission_term_lit.map((term, index) => (
                            <div
                                className={`rounded btn ${selectedTerm == term
                                    ? 'btn-main-color'
                                    : 'btn-border-main'
                                    } mr-2 mt-2`}
                                active={selectedTerm == term}
                                key={`term${index}`}
                                onClick={() => {
                                    setSelectedTerm(term);
                                }}
                            >
                                {term}
                            </div>
                        ))}
                    </div>
                    <div style={{ fontWeight: 700 }}>[과학/이공계열]</div>
                    <div className="row mb-3">
                        {mission_term_sci.map((term, index) => (
                            <div
                                className={`rounded btn ${selectedTerm == term
                                    ? 'btn-main-color'
                                    : 'btn-border-main'
                                    } mr-2 mt-2`}
                                active={selectedTerm == term}
                                key={`term${index}`}
                                onClick={() => {
                                    setSelectedTerm(term);
                                }}
                            >
                                {term}
                            </div>
                        ))}
                    </div>
                    <div className="ra-test-tutorial-container">
                        <div
                            className={classNames(
                                'ra-test-tutorial-title-container',
                                { 'bg-gray': tutorial1C },
                            )}
                        >
                            <div>
                                <span>Tutorial</span>{' '}
                                {!tutorial1C && '(확인 후 작업할 수 있습니다.)'}
                            </div>
                            {tutorial1 ? (
                                <KeyboardArrowDownIcon
                                    className="cursor-pointer"
                                    onClick={() => setTutorial1(!tutorial1)}
                                />
                            ) : (
                                <KeyboardArrowUpIcon
                                    className="cursor-pointer"
                                    onClick={() => setTutorial1(!tutorial1)}
                                />
                            )}
                        </div>
                        {!tutorial1 && (
                            <div className="ra-test-tutorial-content-container">
                                <div className="ra-test-tutorial-content">
                                    - 원리는 과제를 탐구하기 전 습득/이해해야 할
                                    지식 키워드를 뜻합니다.
                                </div>
                                <div className="ra-test-tutorial-content">
                                    - 고등학교 학습 내용의 심화~대학 전공 지식의
                                    기초에 해당하는 키워드가 제시됩니다. 10개의
                                    키워드 중 하나의 키워드를 선택하여 해당
                                    키워드에 대하여 원리에 대한 설명을
                                    작성해주세요.
                                </div>
                                <div className="ra-test-tutorial-content">
                                    - 원리에 대한 설명은 반드시{' '}
                                    <span>300자</span> 이상 작성해 주세요.
                                    고등학교 학생이 이해할 수 있도록 교과서와
                                    관련된 내용을 기반으로 하여 명확히 설명해
                                    주시기 바랍니다.
                                </div>
                                <div className="ra-test-tutorial-content bold">
                                    [작업 예시]
                                </div>
                                <div className="btn-main-color btn mb-2">
                                    젠트리피케이션
                                </div>
                                <div
                                    className="ra-test-tutorial-ex-container d-block w-100 p-4 rounded-0 border-light-color"
                                    disabled
                                >
                                    젠트리피케이션이란 낙후됐던 구도심이 번성해
                                    중산층 이상의 사람들이 몰리면서, 임대료가
                                    오르고 원주민이 내몰리는 현상을 의미한다.
                                    재건축 등으로 인해 도시 환경이 변하면서
                                    중·상류층이 낙후됐던 구도심의 주거지로
                                    유입되고, 이에 따라 주거비용이 상승하면서
                                    비싼 월세 등을 감당할 수 없는 원주민들이
                                    다른 곳으로 밀려나는 현상을 이른다. 본래
                                    신사 계급을 뜻하는 ‘젠트리’에서 파생된 말로
                                    본래는 낙후 지역에 외부인이 들어와 지역이
                                    다시 활성화되는 현상을 뜻했지만, 최근에는
                                    외부인이 유입되면서 본래 거주하던 원주민이
                                    밀려나는 부정적인 의미로 많이 쓰이고 있다.
                                </div>
                                <div className="ra-test-agree-input-container">
                                    <input
                                        type="checkbox"
                                        checked={tutorial1C}
                                        onChange={handleTutorial1}
                                    />{' '}
                                    네, 확인했습니다.
                                </div>
                            </div>
                        )}
                    </div>
                    {tutorial1C && (
                        <div className="form-line">
                            <div className="form-group w-100">
                                <label>
                                    <h6 className="mr-2 d-inline-block">
                                        요약
                                    </h6>
                                    <span className="light-font">
                                        (공백 포함 300-350 글자)
                                    </span>
                                </label>
                                <div className="position-relative">
                                    <textarea
                                        maxLength="350"
                                        name="conceptDescription"
                                        ref={form.register}
                                        className="concept-description d-block w-100 p-4 rounded-0 border-light-color"
                                    ></textarea>
                                    <div className="position-absolute fixed-bottom w-100 d-flex justify-content-end p-4 text-muted">
                                        {
                                            <span>
                                                {
                                                    form.watch(
                                                        'conceptDescription',
                                                    ).length
                                                }
                                                /350
                                            </span>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </section>
                <section>
                    <h5>2. 탐구 과제 작성</h5>
                    <div>
                        위에서 작성한 요약과 관련되는 탐구과제를 제시해주세요.
                        (300-350자 분량)
                    </div>
                    <div className="ra-test-tutorial-container mt-3">
                        <div
                            className={classNames(
                                'ra-test-tutorial-title-container',
                                { 'bg-gray': tutorial2C },
                            )}
                        >
                            <div>
                                <span>Tutorial</span>{' '}
                                {!tutorial2C && '(확인 후 작업할 수 있습니다.)'}
                            </div>
                            {tutorial2 ? (
                                <KeyboardArrowDownIcon
                                    className="cursor-pointer"
                                    onClick={() => setTutorial2(!tutorial2)}
                                />
                            ) : (
                                <KeyboardArrowUpIcon
                                    className="cursor-pointer"
                                    onClick={() => setTutorial2(!tutorial2)}
                                />
                            )}
                        </div>
                        {!tutorial2 && (
                            <div className="ra-test-tutorial-content-container">
                                <div className="ra-test-tutorial-content">
                                    - 원리를 바탕으로 생각을 확장하여 '탐구'할
                                    수 있는 과제를 제시해주세요. 단순히 이론에
                                    대해 더 알아보거나, 학습 내용을 정리하는
                                    수준의 과제가 아닙니다.{' '}
                                    <bold>
                                        실생활에서의 현상 및 사례, 사회문제,
                                        연구 중인 과학 기술
                                    </bold>{' '}
                                    등 원리에서 더 나아간 과제를 제시해주세요.
                                </div>
                                <div className="ra-test-tutorial-content">
                                    - "~서술하시오. ~정리하시오."와 같이 서술형,
                                    논술형 문제를 내는 것이 아닙니다. 단순히
                                    옳고 그름을 판단하는 내용이 아니라{' '}
                                    <bold>
                                        깊고 풍부하게 조사하고, 탐구할 수 있는
                                        과제
                                    </bold>
                                    를 제안해 주세요.
                                </div>
                                <div className="ra-test-tutorial-content">
                                    - 마지막 문장은 <bold>"탐구해 보자."</bold>
                                    의 형태로 작성해 주세요.
                                </div>
                                <div className="ra-test-tutorial-content">
                                    - 고등학생이 자신이 목표하는 학과에 대하여{' '}
                                    <bold>전공적합성</bold> 및 학업역량을 보여줄
                                    수 있는 과제를 제안해주시기 바랍니다. 너무
                                    쉽거나, 너무 어려운 과제는 지양해주세요.
                                </div>
                                <div className="ra-test-tutorial-content bold">
                                    [작업 예시]
                                </div>
                                <div className="position-relative">
                                    <div
                                        maxLength="350"
                                        name="conceptDescription"
                                        className="ra-test-tutorial-ex-container d-block w-100 p-4 rounded-0 border-light-color"
                                        disabled
                                    >
                                        2,000년대 이후 서울의 경우 종로구 서촌을
                                        비롯해 홍익대 인근, 망원동, 상수동,
                                        경리단길, 삼청동, 신사동 가로수길 등에서
                                        젠트리피케이션 현상이 벌어지고 있다.
                                        현재 우리나라에서 발생하는
                                        젠트리피케이션의 특징을 조사해보자.
                                        또한, 젠트리피케이션은 임대료의 상승과
                                        밀접한 관련이 있기에 사회문제라고 볼 수
                                        있다. ‘상가건물 임대차보호법’ 등을
                                        통하여 소상공인들을 보호하고 있는데, 이
                                        이외에 현재 우리나라에서 시행되고 있는
                                        소상공인을 보호하기 위한 법과 정책을
                                        탐구하고 정부가 소상공인을 보호하기 위해
                                        시장에 개입하는 것이 타당한지 탐구해
                                        보자.
                                    </div>
                                </div>

                                <div className="ra-test-agree-input-container">
                                    <input
                                        type="checkbox"
                                        checked={tutorial2C}
                                        onChange={handleTutorial2}
                                    />{' '}
                                    네, 확인했습니다.
                                </div>
                            </div>
                        )}
                    </div>
                    {tutorial2C && (
                        <div className="form-line">
                            <div className="form-group w-100">
                                <label>
                                    <h6 className="mr-2 d-inline-block">
                                        과제
                                    </h6>
                                    <span className="light-font">
                                        (공백 포함 300-350 글자)
                                    </span>
                                </label>
                                <div className="position-relative">
                                    <textarea
                                        maxLength="350"
                                        name="task"
                                        ref={form.register}
                                        className="concept-description d-block w-100 p-4 rounded-0 border-light-color"
                                    ></textarea>
                                    <div className="position-absolute fixed-bottom w-100 d-flex justify-content-end p-4 text-muted">
                                        {
                                            <span>
                                                {form.watch('task').length}
                                                /350
                                            </span>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </section>
                <section>
                    <h5>3. 키워드 작성</h5>
                    <div>
                        위에서 작성한 탐구과제와 관련된 키워드를 달아주세요.
                    </div>
                    <div className="ra-test-tutorial-container mt-3">
                        <div
                            className={classNames(
                                'ra-test-tutorial-title-container',
                                { 'bg-gray': tutorial3C },
                            )}
                        >
                            <div>
                                <span>Tutorial</span>{' '}
                                {!tutorial3C && '(확인 후 작업할 수 있습니다.)'}
                            </div>
                            {tutorial3 ? (
                                <KeyboardArrowDownIcon
                                    className="cursor-pointer"
                                    onClick={() => setTutorial3(!tutorial3)}
                                />
                            ) : (
                                <KeyboardArrowUpIcon
                                    className="cursor-pointer"
                                    onClick={() => setTutorial3(!tutorial3)}
                                />
                            )}
                        </div>
                        {!tutorial3 && (
                            <div className="ra-test-tutorial-content-container">
                                <div className="ra-test-tutorial-content">
                                    - 검색 키워드는 과제에 대한 직접적인 관련이
                                    있는 단어,{' '}
                                    <bold>
                                        검색에 실질적으로 도움이 되는 단어
                                    </bold>
                                    를 작성해주시기 바랍니다. 해당 과제와 관련된
                                    내용이 검색되지 않는 광범위한 키워드(ex.
                                    정치, 경제)는 배제해주시기 바랍니다.
                                </div>
                                <div className="ra-test-tutorial-content bold">
                                    [작업 예시]
                                </div>
                                <div className="ra-test-mission-keyword-container">
                                    <div className="form-control rounded-0 ra-test-mission-keyword mr-3">
                                        젠트리피케이션
                                    </div>
                                    <div className="form-control rounded-0 ra-test-mission-keyword mr-3">
                                        임대차보호법
                                    </div>
                                    <div className="form-control rounded-0 ra-test-mission-keyword mr-3">
                                        소상공인 보호
                                    </div>
                                    <div className="form-control rounded-0 ra-test-mission-keyword">
                                        젠트리
                                    </div>
                                </div>
                                <div className="ra-test-agree-input-container mt-4">
                                    <input
                                        type="checkbox"
                                        checked={tutorial3C}
                                        onChange={handleTutorial3}
                                    />{' '}
                                    네, 확인했습니다.
                                </div>
                            </div>
                        )}
                    </div>
                    {tutorial3C && (
                        <div className="form-row mt-3">
                            <div className="col-12">
                                <div className="h-100 d-flex">
                                    <h6 className="mb-2">관련 키워드</h6>
                                </div>
                            </div>
                            {relevantKeywords.fields.map(
                                (relevantKeywordField, index) => (
                                    <div
                                        key={relevantKeywordField.id}
                                        className="col-3"
                                    >
                                        <input
                                            key={relevantKeywordField.id}
                                            ref={form.register()}
                                            name={`relevantKeywords[${index}].value`}
                                            defaultValue={
                                                relevantKeywordField.value
                                            }
                                            className="form-control rounded-0"
                                        />
                                    </div>
                                ),
                            )}
                        </div>
                    )}
                </section>
                <Row className="justify-content-center mt-5">
                    <Col md={8} className="pt-2 pb-2">
                        <Button
                            type="submit"
                            variant={'primary'}
                            disabled={!checkAllChecked()}
                            className="w-100 pt-3 pb-3 btn-main-color"
                        >
                            {checkAllChecked()
                                ? '제출하기'
                                : '모든 항목을 입력해주세요.'}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};
