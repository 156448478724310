import React from 'react';

import { MissionTable } from 'Components/admin/table';

export const AdminMissionListView = props => {
    return (
        <div className="ra-mission-container">
            <MissionTable />
        </div>
    );
};
