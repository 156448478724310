import React, { useState, useContext, useEffect } from 'react';
import { AppContext } from 'Contexts/';
import { SearchRelatedMajorsModal, useModal } from 'Components/modals';
import { RecommendMajor } from './RecommendMajor';

export const MajorSelector = ({ next, currentStep, tags, ...props }) => {
    const searchRelatedMajorsModal = useModal();
    const [majorCategories, setMajorCategories] = useState([]);
    const {
        getters: { getMajorCategory },
    } = useContext(AppContext);

    const addMajorCategory = majorCategory => {
        if (!majorCategories.includes(majorCategory)) {
            setMajorCategories([...majorCategories, majorCategory]);
        }
    };

    const removeMajorCategory = majorCategory => {
        setMajorCategories([
            ...majorCategories.filter(e => e != majorCategory),
        ]);
    };

    const handleClick = majorCategory => {
        addMajorCategory(majorCategory.id);
    };

    useEffect(() => {
        next(majorCategories);
    }, [majorCategories]);

    useEffect(() => {
        if (tags && tags.length > 0) {
            setMajorCategories(tags.map(tag => tag.majorCategory));
        }
    }, []);

    return (
        <div className={currentStep === 100 ? '' : 'ml-5 mr-5'}>
            {currentStep !== 100 && (
                <div className="mt-3">
                    <h6>위의 주제는</h6>
                </div>
            )}
            <div className="mt-3">
                <div className="form-row mt-3">
                    {majorCategories.map(
                        majorCategory =>
                            majorCategory !== 0 && (
                                <div
                                    key={majorCategory}
                                    className="col-12 mb-3 selected-major-category"
                                >
                                    <div className="text-center border-main-color pt-3 pb-3 col-3 rounded-lg">
                                        {getMajorCategory(majorCategory) &&
                                            getMajorCategory(majorCategory)
                                                .subcategoryName}
                                    </div>
                                    {currentStep !== 'Complete' && (
                                        <button
                                            type="button"
                                            onClick={() =>
                                                removeMajorCategory(
                                                    majorCategory,
                                                )
                                            }
                                            className="btn btn-outline-secondary selected-major-delete-btn ml-3 pt-0 pb-0"
                                        >
                                            X
                                        </button>
                                    )}
                                </div>
                            ),
                    )}
                    {currentStep !== 'Complete' && (
                        <div className="col-6">
                            <button
                                type="button"
                                onClick={searchRelatedMajorsModal.open}
                                className="w-60 btn btn-outline-secondary major-add-btn rounded-0"
                            >
                                <span>
                                    {currentStep === 100
                                        ? '학과선택'
                                        : '학과추가'}
                                </span>
                                <svg
                                    width="0.65em"
                                    height="0.65em"
                                    viewBox="0 0 16 16"
                                    className="display-4 bi bi-plus"
                                    fill="currentColor"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"
                                    />
                                </svg>
                            </button>
                            <SearchRelatedMajorsModal
                                handleClick={handleClick}
                                {...searchRelatedMajorsModal}
                            />
                        </div>
                    )}
                </div>
            </div>
            {currentStep !== 100 && (
                <div className="mt-3 mb-4">
                    <h6>
                        학과에 가고싶은 학생에게 적절해요.
                        <span className="text-muted">
                            {' '}
                            (관련되는 학과를{' '}
                            <span style={{ fontWeight: 'bold', color: 'red' }}>
                                {' '}
                                모두
                            </span>{' '}
                            태그해주세요.)
                        </span>{' '}
                    </h6>
                </div>
            )}
        </div>
    );
};
