import React, { useState } from 'react';
import { StudySubjectOrderTable } from 'Components/admin';
import {
    RAPointTable,
    RANoticeTable,
    RANoticeDetail,
    RAReportTable,
    RAWithdrawalTable,
} from 'Components/ra';
import {
    MissionListView,
    StudentValidationView,
    StudentAgreementView,
    StudentTestView,
    StudentWaitingView,
} from 'Views';

import { NavLink } from 'react-router-dom';
import { Navbar, Nav, NavItem, NavDropdown } from 'react-bootstrap';

export const mapRAPathToTableComponent = url => {
    if (url.match(/mission-ing/)) {
        // 참여 중인 미션
        return StudySubjectOrderTable;
    } else if (url.match(/missions/)) {
        // 미션 목록
        return MissionListView;
    } else if (url.match(/mission-working-info\/detail/)) {
        //공지사항 자세히보기
        return RANoticeDetail;
    } else if (url.match(/mission-working-info/)) {
        // 공지사항
        return RANoticeTable;
    } else if (url.match(/mission-working/)) {
        // 작업홈
        return RAReportTable;
        // } else if (url.match(/withdrawls/)) { // 정산 내역
        //   return ;
    } else if (url.match(/points/)) {
        // 포인트 내역
        return RAPointTable;
    } else if (url.match(/validation/)) {
        // 자격 검증
        return StudentValidationView;
    } else if (url.match(/agreement/)) {
        // 안내 및 약관 동의
        return StudentAgreementView;
    } else if (url.match(/mission/)) {
        // 테스트
        return StudentTestView;
    } else if (url.match(/waiting/)) {
        // 결과 대기
        return StudentWaitingView;
    } else {
        return () => <div></div>;
    }
};

export const RAListView = ({ location: { pathname }, ...props }) => {
    const navData = {
        missions: [
            ['/ra/missions/', '미션 목록'],
            ['/ra/missions/ing/', '참여 중인 미션'],
        ],
        'mission-working': [
            ['/ra/mission-working/', '작업 홈'],
            ['/ra/mission-working-info/', '공지사항'],
        ],

        point: [['/ra/point/points/', '포인트 내역']],
        test: [
            ['/ra/test/validation/', '1. 자격 검증'],
            ['/ra/test/agreement/', '2. 약관동의'],
            ['/ra/test/mission/', '3. 테스트'],
            ['/ra/test/waiting/', '4. 결과 대기'],
        ],
    };
    // ["/ra/point/withdrawls/", "정산 내역"],

    const TableComponent = mapRAPathToTableComponent(pathname);

    return (
        <content className="content">
            <div className="content-wrapper">
                <div className="content-menu pl-0">
                    <Navbar className="w-100 pl-0 mt-2 ra-right-menu">
                        <Nav defaultActiveKey="/" className="flex-column">
                            {pathname.match(/missions/) ? (
                                navData['missions'].map(data => (
                                    <NavItem>
                                        <NavLink
                                            exact
                                            activeClassName="active"
                                            className="admin-menu-item"
                                            to={data[0]}
                                        >
                                            {data[1]}
                                        </NavLink>
                                    </NavItem>
                                ))
                            ) : pathname.match(/mission-working/) ? (
                                <div>
                                    <NavItem className="ra-right-menu-btn-container">
                                        <NavLink
                                            exact
                                            className="ra-back-btn cursor-pointer"
                                            to={'/ra/missions/ing/'}
                                        >
                                            &#xE000;&nbsp; 목록으로
                                        </NavLink>
                                    </NavItem>
                                    {navData['mission-working'].map(data => (
                                        <NavItem>
                                            <NavLink
                                                exact
                                                isActive={() => {
                                                    if (
                                                        pathname.match(data[0])
                                                    ) {
                                                        return true;
                                                    }
                                                }}
                                                activeClassName="active"
                                                className="admin-menu-item"
                                                to={data[0]}
                                            >
                                                {data[1]}
                                            </NavLink>
                                        </NavItem>
                                    ))}
                                </div>
                            ) : pathname.match(/point/) ? (
                                navData['point'].map(data => (
                                    <NavItem>
                                        <NavLink
                                            exact
                                            activeClassName="active"
                                            className="admin-menu-item"
                                            to={data[0]}
                                        >
                                            {data[1]}
                                        </NavLink>
                                    </NavItem>
                                ))
                            ) : (
                                <div>
                                    <NavItem className="ra-right-menu-btn-container">
                                        <NavLink
                                            exact
                                            className="ra-back-btn cursor-pointer"
                                            to={'/ra/missions/ing/'}
                                        >
                                            &#xE000;&nbsp; 목록으로
                                        </NavLink>
                                    </NavItem>
                                    {navData['test'].map(data => (
                                        <NavItem>
                                            <NavLink
                                                exact
                                                activeClassName="active"
                                                className="admin-menu-item"
                                                to={data[0]}
                                            >
                                                {data[1]}
                                            </NavLink>
                                        </NavItem>
                                    ))}
                                </div>
                            )}
                        </Nav>
                    </Navbar>
                </div>
                <div className="content-content">
                    <TableComponent />
                </div>
            </div>
        </content>
    );
};
