export const ErrorCode = {
    USERNAME_NOT_VALID: 'USERNAME_NOT_VALID',
    USERNAME_WRONG: 'USERNAME_WRONG',
    USERNAME_DUPLICATED: 'USERNAME_DUPLICATED',

    PASSWORD_WRONG: 'PASSWORD_WRONG',

    USER_NOT_ACTIVE: 'USER_NOT_ACTIVE',
    NO_USER: 'NO_USER',

    NOT_AUTHORIZED: 'NOT_AUTHORIZED',

    MAIL_TRANSPORT_FAILED: 'MAIL_TRANSPORT_FAILED',

    NOT_ENOUGH_POINTS: 'NOT_ENOUGH_POINTS',
};

export const SuccessCode = {
    USERNAME_VALID: 'USERNAME_VALID',
    LOGOUT_SUCCEED: 'LOGOUT_SUCCEED',
    AUTHORIZED: 'AUTHORIZED',

    MAIL_SENT: 'MAIL_SENT',
    SMS_MESSAGE_SENT: 'SMS_MESSAGE_SENT',

    AUTH_ALIVE: 'AUTH_ALIVE',

    STUDENT_CREATED: 'STUDENT_CREATED',
    STUDENT_ADDED: 'STUDENT_ADDED',
};
