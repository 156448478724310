import React, { useState, useEffect, useRef, useContext } from 'react';
import { Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import { useAPI, APIRoute } from 'Client';
import { useHistory } from 'react-router-dom';
import { AppContext } from 'Contexts';
import { TagWizard } from 'Components';

export const DongaScienceCreateView = () => {
    const history = useHistory();

    const {
        researchAssistant: { currentMissionId },
    } = useContext(AppContext);

    const dongaScienceCreateAPI = useAPI(
        APIRoute.ResearchAssistant.DongaScience.Create,
        {
            callbacks: {
                onSuccess: () => {
                    alert('성공');
                    history.push({ pathname: `/` });
                },
            },
        },
    );
    const submitButtonRef = useRef(null);
    const [tags, setTags] = useState([
        {
            tagUUid: '',
            learningElements: [],
            majorCategory: 0,
            schoolSubjectUnits: [],
            schoolSubjects: [],
        },
    ]);
    const [finished, setFinished] = useState(false);
    const [onGoing, setOnGoing] = useState(false);

    const allTagsChecked = () => {
        for (let tag of tags) {
            if (tag && tag.learningElements.length == 0) return false;
        }
        return true;
    };

    const form = useForm({
        // only for fields to watch
        defaultValues: {
            serialNumber: '',
        },
    });

    form.handleSubmit = form.handleSubmit((data, event) => {
        const { serialNumber } = data;

        const {
            nativeEvent: { submitter },
        } = event;

        if (![submitButtonRef.current].includes(submitter)) {
            return;
        }

        if (serialNumber) {
            dongaScienceCreateAPI.send({
                serialNumber,
                tags,
            });
        } else {
            alert('과학동아 시리얼 넘버를 입력해주세요!!');
        }
    });

    useEffect(() => {
        form.setValue('recommendedMajors', tags);
    }, [tags]);

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="w-100">
                    <Form onSubmit={form.handleSubmit} className="w-100">
                        <div className="d-flex w-100 justify-content-start">
                            <h2 className="bold-font">
                                과학동아 기사 태깅 과제
                            </h2>
                        </div>
                        <hr className="border-dark mt-4 thick" />
                        <div className="d-flex w-100 justify-content-start">
                            <h4 className="bold-font">과학동아 시리얼 넘버</h4>
                        </div>
                        <input
                            maxLength="20"
                            name="serialNumber"
                            ref={form.register}
                            style={{ width: 400, height: 40, fontSize: 20 }}
                        />
                        <div className="mt-4">
                            <TagWizard
                                isLoaded={false}
                                tags={tags}
                                setTags={setTags}
                                onComplete={tags =>
                                    form.setValue('recommendedMajors', tags)
                                }
                                setFinished={setFinished}
                                setOnGoing={setOnGoing}
                            />
                        </div>
                        {finished && !allTagsChecked() && (
                            <div>
                                <div className="form-row mt-4 d-flex justify-content-end">
                                    <h6 className="text-muted">
                                        아직 태깅되지 않은 학과가 있어요!{' '}
                                        <span
                                            style={{
                                                color: 'red',
                                                fontWeight: 800,
                                            }}
                                        >
                                            8개 이상
                                        </span>
                                        작성
                                    </h6>
                                </div>
                                <div className="form-row d-flex justify-content-end">
                                    <h6 className="text-muted">
                                        다시 선택 버튼을 눌러서 학과 태깅을
                                        마무리해주세요!
                                    </h6>
                                </div>
                            </div>
                        )}
                        <hr className="border-dark mt-5 thick" />
                        <div className="row mt-5 justify-content-end">
                            <div className="d-flex align-items-center mr-5">
                                <h5>총 포인트</h5>
                            </div>
                            <div className="border-dark-color col-4 text-center p-2">
                                <h5>0</h5>
                            </div>
                        </div>
                        <div className="form-row mt-5 mb-5 justify-content-end">
                            {finished && allTagsChecked() ? (
                                <div className="offset-1 col-3 justify-content-center align-items-center d-flex">
                                    <button
                                        type="submit"
                                        ref={submitButtonRef}
                                        className="btn btn-main-color btn-lg btn-block"
                                    >
                                        제출하기
                                    </button>
                                </div>
                            ) : (
                                <div className="offset-1 col-3 justify-content-center align-items-center d-flex">
                                    <div
                                        className="btn border-main-color btn-lg btn-block"
                                        style={{ fontSize: 14 }}
                                    >
                                        학과 태깅을 완료해야
                                        <br />
                                        제출버튼을
                                        <br />
                                        누를 수 있어요!
                                    </div>
                                </div>
                            )}
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    );
};
