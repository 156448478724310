import React, { useState, useEffect } from 'react';

import { Form } from 'react-bootstrap';
import { APIRoute } from 'Client';
import { useHistory } from 'react-router-dom';

import { RemoteDataTable, useRemoteDataTable } from 'Components/table/base';
import moment from 'Moment';

const tableProperty = [
    {
        key: 'id',
        name: '번호',
    },
    {
        key: 'name',
        name: '이름',
    },
    {
        key: 'username',
        name: '아이디',
    },
    {
        key: 'createdAt',
        name: '테스트 일자',
    },
    {
        key: 'quick',
        name: '남은 시간',
    },
    {
        key: 'inspectionStatus',
        name: '결과',
    },
];

export const TestReportTable = props => {
    const history = useHistory();
    const apiRoute = APIRoute.Admin.TestReport.List;
    const formatData = data => {
        let now = new Date(Date.now()).getTime();
        return data.map(datum => {
            let created =
                '20' +
                datum.created.replace(/\//gi, '-').replace(' ', 'T') +
                ':00';
            let deadline =
                new Date(Date.parse(created)).getTime() + 1000 * 60 * 60 * 24;
            let remainingHour = parseInt((deadline - now) / (1000 * 60 * 60));
            let remainingMin = parseInt(
                (deadline - now - remainingHour * 1000 * 60 * 60) / (1000 * 60),
            );
            return {
                ...datum,
                quick: datum.quick
                    ? datum.issued
                        ? '발급완료'
                        : remainingHour + ':' + remainingMin
                    : '-',
                inspectionStatus:
                    datum.inspectionStatus === 'ACCEPTED'
                        ? '승인'
                        : datum.inspectionStatus === 'REJECTED'
                        ? '반려'
                        : '-',
                createdAt: moment(datum.createdAt).format('YY-MM-DD'),
            };
        });
    };

    const { form, reload, ...remoteDataTable } = useRemoteDataTable({
        apiRoute,
        formatData,
    });

    const clickHandler = (columnData, row) => {
        history.push(`/admin/management/test-reports/${row.id}/`);
    };

    tableProperty.forEach(e => {
        e.clickHandler = clickHandler;
    });

    return (
        <>
            <Form>
                <div className="row p-3 justify-content-end mt-3">
                    <div className="input-group col-4 p-0">
                        <input
                            name="name"
                            ref={form.register}
                            type="text"
                            className="form-control border-right-0 h-100 rounded-0"
                        />
                        <div className="input-group-append rounded-0">
                            <button type="button" onClick={reload}>
                                <svg
                                    width="1rem"
                                    height="1rem"
                                    viewBox="0 0 16 16"
                                    className="bi bi-search"
                                    fill="currentColor"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"
                                    />
                                    <path
                                        fillRule="evenodd"
                                        d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </Form>

            <div className="col-12 mt-3">
                <RemoteDataTable
                    tableProperty={tableProperty}
                    {...remoteDataTable}
                />
            </div>
        </>
    );
};
