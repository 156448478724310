import React, { useContext, useState, useEffect } from 'react';

import { Link, useHistory, useLocation } from 'react-router-dom';

import { AppContext } from 'Contexts/AppContext';
import { ModifyPasswordModal, useModal } from 'Components/modals';
import { useAPI, APIRoute } from 'Client';
import { formatMoney } from 'Utils';

import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';

const Menu = {
    ADMIN: [
        {
            label: 'HAKZZONG',
            urlPattern: /admin\/main/,
            to: '/admin/main/',
        },
        {
            label: 'MANAGEMENT',
            urlPattern: /admin\/management/,
            to: '/admin/management/research-assistants/',
        },
        {
            label: 'STATISTICS',
            urlPattern: /admin\/statistics/,
            to: '/admin/statistics/study-subject-supply-demand/',
        },
        {
            label: 'DIRECTLY MANAGED',
            urlPattern: /admin\/directly-managed/,
            to: '/admin/directly-managed/study-subjects/',
        },
    ],
    STAFF: [
        {
            label: 'HAKZZONG',
            urlPattern: /staff\/main/,
            to: '/staff/main/',
        },
    ],
    RESEARCH_ASSISTANT: [
        {
            label: '미션',
            urlPattern: /ra\/missions/,
            to: '/ra/missions/',
        },
        {
            label: '포인트 내역',
            urlPattern: /ra\/point\/points/,
            to: '/ra/point/points/',
        },
        {
            label: '마이페이지',
            urlPattern: /mypage/,
            to: '/mypage/',
        },
    ],
};

export const Header = (props = { user: {} }) => {
    const history = useHistory();
    const location = useLocation();

    const { user, staff, admin, researchAssistant, dispatchers } = useContext(
        AppContext,
    );
    const modifyPasswordModal = useModal();
    const researchAssistantNoticeListModal = useModal();

    const logoutAPI = useAPI(APIRoute.Common.Auth.Logout, {
        callbacks: {
            onSuccess: () => {
                dispatchers.clear();
                history.push('/');
            },
        },
    });

    return (
        <>
            <header className="header-wrapper">
                <div className="header-container">
                    <div className="header-left">
                        <Link to="/" className="header-left-logo">
                            <img
                                src={require('../assets/img/logo.png')}
                                className="header-logo"
                            />
                        </Link>
                        {user.id &&
                            Menu[user.type].map(
                                ({ label, urlPattern, to }, index) => {
                                    const isMatched = location.pathname.match(
                                        urlPattern,
                                    );
                                    return (
                                        <Link
                                            key={index}
                                            to={to}
                                            className={
                                                'header-left-content' +
                                                (isMatched
                                                    ? ' active'
                                                    : ' none')
                                            }
                                        >
                                            {label === '포인트 내역'
                                                ? researchAssistant.profile
                                                      .isSettlementIncluded &&
                                                  label
                                                : label}
                                            {isMatched && (
                                                <div className="header-active-bottom-line"></div>
                                            )}
                                        </Link>
                                    );
                                },
                            )}
                    </div>

                    <div className="header-right">
                        {user.id ? (
                            user.type == 'ADMIN' ? (
                                <>
                                    <div
                                        className="header-right-content"
                                        onClick={() =>
                                            modifyPasswordModal.open()
                                        }
                                    >
                                        <div className="header-mypage-content profile">
                                            비밀번호 변경
                                        </div>
                                    </div>
                                    <div
                                        className="header-right-content logout"
                                        onClick={() => logoutAPI.send()}
                                    >
                                        로그아웃
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="header-right-content">
                                        <div className="header-mypage-content profile">
                                            <span>
                                                {researchAssistant.profile
                                                    .isSettlementIncluded &&
                                                    researchAssistant.profile.points
                                                        .toString()
                                                        .replace(
                                                            /\B(?=(\d{3})+(?!\d))/g,
                                                            ',',
                                                        )}
                                            </span>
                                            P
                                        </div>
                                    </div>
                                    <div className="header-right-content mr-3">
                                        <div className="header-mypage-content profile">
                                            <span>{user.username}</span>님
                                        </div>
                                    </div>
                                    {/*
                                    <div className="header-right-content">
                                        <span>
                                            <NotificationsNoneIcon
                                                onClick={() =>
                                                    researchAssistantNoticeListModal.open()
                                                }
                                            />
                                        </span>
                                        {alert && (
                                            <div className="header-notification-alert"></div>
                                        )}
                                    </div>
                                        */}

                                    <div
                                        className="btn btn-main-color cursor-pointer"
                                        onClick={() => logoutAPI.send()}
                                    >
                                        로그아웃
                                    </div>
                                </>
                            )
                        ) : (
                            <></>
                        )}
                    </div>
                    <></>
                </div>
            </header>
            <ModifyPasswordModal {...modifyPasswordModal} />
        </>
    );
};
