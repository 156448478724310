import React, { useState, useContext } from 'react';

import { Modal, Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { AppContext } from 'Contexts/AppContext';
import { useAPI, APIRoute } from 'Client';

export const ModifyPasswordModal = ({
    isOpen = false,
    open = () => {},
    close = () => {},
}) => {
    const { register, watch, handleSubmit, errors: formErrors } = useForm();
    const watchFields = watch(['password', 'passwordConfirmation']);

    const history = useHistory();
    const { dispatchers } = useContext(AppContext);

    const modifyPasswordAPI = useAPI(APIRoute.Common.Auth.ModifyPassword, {
        callbacks: {
            onSuccess: data => {
                alert('비밀번호 변경 성공!! 다시 로그인해주세요!!');
                close();
                dispatchers.clear();
                history.push('/');
            },
        },
    });

    const onSubmit = data => {
        if (!data.now) alert('현재 비밀번호를 입력해주세요!');
        else if (!data.passwordConfirmation)
            alert('새로운 비밀번호를 입력하고 확인해주세요!');
        else if (data.password !== data.passwordConfirmation)
            alert('새로운 비밀번호 확인이 일치하지 않습니다.');
        else {
            modifyPasswordAPI.send({
                password: data.now,
                newPassword: data.password,
            });
        }
    };

    return (
        <Modal id="find-password-modal" className="modal-narrow" show={isOpen}>
            <Modal.Header className="d-block">
                <div className="row w-100">
                    <Button className="close" onClick={close}>
                        <span aria-hidden="true">&times;</span>
                    </Button>
                </div>
                <div className="row w-100 justify-content-center align-items-center">
                    <h4>비밀번호 찾기</h4>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="container">
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="w-100">
                                <Form.Row>
                                    <div className="register-modal-label col-12 pt-2 pb-2">
                                        <label>*현재 비밀번호</label>
                                    </div>
                                    <div className="col-12">
                                        <input
                                            name="now"
                                            type="password"
                                            className="register-modal-input input w-100 pt-3 pb-3 rounded-0"
                                            ref={register}
                                        />
                                    </div>
                                </Form.Row>
                                <Form.Row>
                                    <div className="register-modal-label col-12 pt-2 pb-2">
                                        <label>*새로운 비밀번호</label>
                                    </div>
                                    <div className="col-12">
                                        <input
                                            name="password"
                                            type="password"
                                            className="register-modal-input input w-100 pt-3 pb-3 rounded-0"
                                            ref={register}
                                        />
                                    </div>
                                </Form.Row>
                                <Form.Row>
                                    <div className="register-modal-label col-12 pt-2 pb-2">
                                        <label>*새로운 비밀번호 확인</label>
                                    </div>
                                    <div className="col-12">
                                        <input
                                            name="passwordConfirmation"
                                            type="password"
                                            className="register-modal-input input w-100 pt-3 pb-3 rounded-0"
                                            ref={register}
                                        />
                                        {watchFields.passwordConfirmation &&
                                            watchFields.password !==
                                                watchFields.passwordConfirmation && (
                                                <small className="form-text text-danger">
                                                    비밀번호가 일치하지
                                                    않습니다.
                                                </small>
                                            )}
                                    </div>
                                </Form.Row>
                                <div className="row mt-5">
                                    <div className="col-12 pt-2 pb-2">
                                        <button className="btn-main-color w-100 pt-3 pb-3 rounded-0">
                                            비밀번호 변경하기
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </Modal.Body>
        </Modal>
    );
};
