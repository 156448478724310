import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import { useAPI, APIRoute } from 'Client';

export const SearchStudentProfileModal = ({
    handleClick = () => {},
    isOpen = false,
    close,
}) => {
    const [studentProfiles, setStudentProfiles] = useState([]);
    const studentProfileListAPI = useAPI(APIRoute.Admin.Student.List, {
        callbacks: {
            onSuccess: ({ results }) => {
                setStudentProfiles(results);
            },
        },
    });

    const form = useForm({
        defaultValues: {
            name: '',
            schoolName: '',
            grade: '',
        },
    });

    const watchName = form.watch('name');
    const watchSchoolName = form.watch('schoolName');
    const watchGrade = form.watch('grade');

    useEffect(() => {
        if (!isOpen) {
            return;
        }

        studentProfileListAPI.send({
            name: watchName,
            schoolName: watchSchoolName,
            grade: watchGrade,
        });
    }, [isOpen, watchName, watchSchoolName, watchGrade]);

    return (
        <Modal
            id="search-concepts-modal"
            className="modal-narrow"
            show={isOpen}
            onHide={close}
        >
            <Modal.Header className="d-block">
                <div className="row w-100">
                    <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={close}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="row w-100 justify-content-center align-items-center">
                    <h4>검색 결과</h4>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="container">
                    <div className="row w-100">
                        <div className="col-12 pt-2 pb-2">
                            <label>이름</label>
                        </div>
                        <div className="col-12">
                            <input
                                name="name"
                                ref={form.register}
                                className="input w-100 pt-3 pb-3 rounded-0"
                            />
                        </div>
                    </div>
                    <div className="row w-100">
                        <div className="col-12 pt-2 pb-2">
                            <label>학교명</label>
                        </div>
                        <div className="col-12">
                            <input
                                name="schoolName"
                                ref={form.register}
                                className="input w-100 pt-3 pb-3 rounded-0"
                            />
                        </div>
                    </div>
                    <div className="row w-100">
                        <div className="col-12 pt-2 pb-2">
                            <label>학년</label>
                        </div>
                        <div className="col-12">
                            <input
                                name="grade"
                                ref={form.register}
                                className="input w-100 pt-3 pb-3 rounded-0"
                            />
                        </div>
                    </div>
                    <div className="row justify-content-center mt-3">
                        <div className="search-result-box">
                            {studentProfiles.length === 0 ? (
                                <div className="item">
                                    검색 결과가 없습니다.
                                </div>
                            ) : (
                                <table className="w-100">
                                    <thead>
                                        <tr>
                                            <th className="pt-2 pb-2">
                                                학생이름
                                            </th>
                                            <th className="pt-2 pb-2">학교</th>
                                            <th className="pt-2 pb-2">학년</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {studentProfiles.map(
                                            (studentProfile, rowIdnex) => (
                                                <tr
                                                    key={rowIdnex}
                                                    onClick={() =>
                                                        handleClick(
                                                            studentProfile,
                                                        )
                                                    }
                                                >
                                                    <td className="pt-2 pb-2">
                                                        {
                                                            studentProfile.user
                                                                .name
                                                        }
                                                    </td>
                                                    <td className="pt-2 pb-2">
                                                        {
                                                            studentProfile.schoolName
                                                        }
                                                    </td>
                                                    <td className="pt-2 pb-2">
                                                        {studentProfile.grade}
                                                    </td>
                                                </tr>
                                            ),
                                        )}
                                    </tbody>
                                </table>
                            )}
                        </div>
                        <div className="row w-100 mt-5">
                            <div className="col-6">
                                <button
                                    type="button"
                                    onClick={close}
                                    className="btn btn-secondary w-100 rounded-0"
                                >
                                    취소
                                </button>
                            </div>
                            <div className="col-6">
                                <button
                                    type="button"
                                    onClick={close}
                                    className="btn btn-primary w-100 rounded-0"
                                >
                                    확인
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};
