import React, { useRef, useEffect, useState, useContext } from 'react';
import { Form } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { useForm, useFieldArray } from 'react-hook-form';

import { AppContext } from 'Contexts';
import { BasicConceptForm, TagWizard } from 'Components';
import { TagResultDisplay } from 'Components/tag-wizard/TagResultDisplay';
import { SearchStudentProfileModal, useModal } from 'Components/modals';

import { getFieldArrayValues } from 'Utils';
import { useAPI, APIRoute } from 'Client';

import moment from 'Moment';
import queryString from 'query-string';

const formatDate = date => {
    return moment(date).format('YYYY-MM-DD HH:mm');
};

const defaultTag = {
    tagUUid: '',
    learningElements: [],
    majorCategory: 0,
    schoolSubjectUnits: [],
    schoolSubjects: [],
};

export const AdminStudySubjectIssueView = ({
    entity: studySubject,
    ...props
}) => {
    const history = useHistory();

    const temporarilySaveButtonRef = useRef();
    const submitButtonRef = useRef();

    const { admin } = useContext(AppContext);

    const queryObj = queryString.parse(location.search);
    const { uid, major, subject, unit } = queryObj;

    const [isModifyingTags, setIsModifyingTags] = useState(false);
    const [studentProfilesToIssue, setStudentProfilesToIssue] = useState([]);

    const [tags, setTags] = useState(studySubject.tags);
    const [tagsToAdd, setTagsToAdd] = useState([
        {
            ...defaultTag,
        },
    ]);

    const { concept } = studySubject;

    const searchStudentProfileModal = useModal();

    const handleTagModificationButtonClick = b => {
        setIsModifyingTags(b);
    };

    const handleStudentProfileClick = studentProfile => {
        setStudentProfilesToIssue([...studentProfilesToIssue, studentProfile]);
    };

    const handleButtonClick = () => {
        searchStudentProfileModal.open();
    };

    const handleCompleteButtonClick = () => {
        if (!admin.selectedSubjectUnit) {
            alert('단원 선택 필수');
            return;
        }
        history.push(
            `/admin/study-subject-orders?uid=${uid}&complete=true&report=${studySubject.id}`,
        );
    };

    const handleIssueButtonClick = async () => {};

    const missionReportBookAPI = useAPI(APIRoute.Admin.MissionReport.Book, {
        callbacks: {
            onSuccess: res => {
                if (res.code.title) {
                    alert(
                        `제목: ${res.code.title}\n출판사: ${res.code.publisher}\n저자: ${res.code.author}\n책표지 사진: ${res.code.cover}`,
                    );
                } else {
                    alert('도서 정보 불러오기 실패!');
                }
            },
        },
    });

    const studySubjectPatchAPI = useAPI(APIRoute.Admin.StudySubject.Patch, {
        callbacks: {
            onSuccess: () => {
                alert('성공');
            },
        },
    });

    const missionReportPostAPI = useAPI(APIRoute.Admin.MissionReport.Create, {
        callbacks: {
            onSuccess: res => {
                history.push(
                    `/admin/study-subjects/${res.message}/issue/?major=${major}&subject=${subject}&unit=${unit}&uid=${uid}`,
                );
            },
        },
    });

    const form = useForm({
        defaultValues: {
            ...concept,
            ...studySubject,
            relatedConcepts: concept.relatedConcepts.map(value => ({
                value,
            })),
            relatedConceptsInEnglish: concept.relatedConceptsInEnglish.map(
                value => ({
                    value,
                }),
            ),
            relatedMajors: concept.relatedMajors.map(value => ({
                value,
            })),
            relatedSchoolSubjects: concept.relatedSchoolSubjects.map(value => ({
                value,
            })),
            searchKeywords: studySubject.keywords
                .filter(({ type }) => type === 'search')
                .map(({ keyword }) => ({
                    value: keyword,
                })),
            exampleKeywords: studySubject.keywords
                .filter(({ type }) => type === 'example')
                .map(({ keyword }) => ({
                    value: keyword,
                })),
            relevantKeywords: studySubject.keywords
                .filter(({ type }) => type === 'relevant')
                .map(({ keyword }) => ({
                    value: keyword,
                })),
            recommendedBooks: studySubject.recommendedBooks.map(isbn => ({
                value: isbn,
            })),
        },
    });

    const relatedConcepts = useFieldArray({
        control: form.control,
        name: 'relatedConcepts',
    });
    const relatedConceptsInEnglish = useFieldArray({
        control: form.control,
        name: 'relatedConceptsInEnglish',
    });
    const relatedMajors = useFieldArray({
        control: form.control,
        name: 'relatedMajors',
    });
    const relatedSchoolSubjects = useFieldArray({
        control: form.control,
        name: 'relatedSchoolSubjects',
    });

    const searchKeywords = useFieldArray({
        control: form.control,
        name: 'searchKeywords',
    });
    const exampleKeywords = useFieldArray({
        control: form.control,
        name: 'exampleKeywords',
    });
    const relevantKeywords = useFieldArray({
        control: form.control,
        name: 'relevantKeywords',
    });
    const recommendedBooks = useFieldArray({
        control: form.control,
        name: 'recommendedBooks',
    });
    form.handleSubmit = form.handleSubmit((data, event) => {
        const {
            nativeEvent: { submitter },
        } = event;

        const { searchKeywords, exampleKeywords, relevantKeywords } = data;

        if (
            ![
                temporarilySaveButtonRef.current,
                submitButtonRef.current,
            ].includes(submitter)
        ) {
            return;
        }

        if (submitter === temporarilySaveButtonRef.current) {
            studySubjectPatchAPI.send(
                {
                    task: data.task,
                    keywords: [
                        ...searchKeywords.map(({ value }) => ({
                            type: 'search',
                            keyword: value,
                        })),
                        ...exampleKeywords.map(({ value }) => ({
                            type: 'example',
                            keyword: value,
                        })),
                        ...relevantKeywords.map(({ value }) => ({
                            type: 'relevant',
                            keyword: value,
                        })),
                    ],
                    recommendedBooks: data.recommendedBooks.map(
                        ({ value }) => value,
                    ),
                },
                {
                    additionalPath: studySubject.id,
                },
            );
        } else if (submitter === submitButtonRef.current) {
            // delete concept["id"];
            delete concept['created'];
            delete concept['writer'];
            delete concept['type'];

            delete studySubject['id'];

            missionReportPostAPI.send({
                mission: 1 || (entity && entity.mission.id) || currentMissionId,
                concept: concept['id'],
                studySubject: {
                    id: studySubject.id,
                    concept: concept['id'],
                    task: data.task,
                    keywords: [
                        ...searchKeywords.map(({ value }) => ({
                            type: 'search',
                            keyword: value,
                        })),
                        ...exampleKeywords.map(({ value }) => ({
                            type: 'example',
                            keyword: value,
                        })),
                        ...relevantKeywords.map(({ value }) => ({
                            type: 'relevant',
                            keyword: value,
                        })),
                    ],
                    recommendedBooks: data.recommendedBooks.map(
                        ({ value }) => value,
                    ),
                    tags: studySubject.tags,
                },
            });
        }
    });

    return (
        <>
            <div className="study-subject-container mt-3">
                <div
                    className="admin-back-btn cursor-pointer"
                    onClick={() => history.goBack()}
                >
                    &#xE000;&nbsp; 뒤로가기
                </div>
                {studySubject && studySubject.caseorderMaps && (
                    <div className="mt-2">
                        <div className="col-6 mb-1">
                            <div className="d-inline-block justify-content-center align-items-center">
                                <h6 className="text-middle text-center mb-0">
                                    발급횟수
                                </h6>
                            </div>
                            <span className="d-inline-block ml-3">
                                {studySubject.issuedStudySubjectCount}
                            </span>
                        </div>
                        <div className="col-6">
                            <div className="d-inline-block justify-content-center align-items-center">
                                <h6 className="text-middle text-center mb-0">
                                    발급받은 학생
                                </h6>
                            </div>
                            <div className="d-inline-block  ml-3">
                                {studySubject.caseorderMaps.map(id => (
                                    <span>[{id}] </span>
                                ))}
                            </div>
                        </div>
                    </div>
                )}
                <div className="row">
                    <div className="w-100">
                        <Form onSubmit={form.handleSubmit} className="w-100">
                            <BasicConceptForm
                                form={form}
                                isSearchEnabled={false}
                                disabled={true}
                            />
                            <div className="form-row mt-4">
                                <div className="col-2">
                                    <div className="h-100 d-flex justify-content-center align-items-center">
                                        <h5 className="text-middle text-center mb-0">
                                            유사개념
                                        </h5>
                                    </div>
                                </div>
                                {relatedConcepts.fields.map(
                                    (relatedConcept, index) => (
                                        <div
                                            key={relatedConcept.id}
                                            className="col-2"
                                        >
                                            <input
                                                disabled
                                                key={relatedConcept.id}
                                                ref={form.register}
                                                name={`relatedConcepts[${index}].value`}
                                                defaultValue={
                                                    relatedConcept.value
                                                }
                                                className="form-control rounded-0 border-dark"
                                            />
                                        </div>
                                    ),
                                )}
                            </div>
                            <div className="form-row mt-4">
                                <div className="col-2">
                                    <div className="h-100 d-flex justify-content-center align-items-center">
                                        <h5 className="text-middle text-center mb-0">
                                            원어명
                                        </h5>
                                    </div>
                                </div>
                                {relatedConceptsInEnglish.fields.map(
                                    (relatedConceptInEnglish, index) => (
                                        <div
                                            key={relatedConceptInEnglish.id}
                                            className="col-2"
                                        >
                                            <input
                                                disabled
                                                key={relatedConceptInEnglish.id}
                                                ref={form.register}
                                                name={`relatedConceptsInEnglish[${index}].value`}
                                                defaultValue={
                                                    relatedConceptInEnglish.value
                                                }
                                                className="form-control rounded-0 border-dark"
                                            />
                                        </div>
                                    ),
                                )}
                            </div>

                            <div className="form-line mt-4">
                                <div className="form-group w-100">
                                    <label>
                                        <h5 className="mr-2 d-inline-block">
                                            과제
                                        </h5>
                                        <span>(공백 포함 300-350 글자)</span>
                                    </label>
                                    <div className="position-relative">
                                        <textarea
                                            className="concept-description d-block w-100 p-3 rounded-0"
                                            defaultValue={studySubject.task}
                                            name="task"
                                            ref={form.register}
                                        ></textarea>
                                        <div className="position-absolute fixed-bottom w-100 d-flex justify-content-end p-3 text-muted">
                                            <span>
                                                {studySubject.task &&
                                                    studySubject.task.length}
                                                /350
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-row mt-5">
                                <div className="col-2">
                                    <div className="h-100 d-flex justify-content-center align-items-center">
                                        <h5 className="text-middle text-center mb-0">
                                            검색 키워드(필수)
                                        </h5>
                                    </div>
                                </div>
                                {searchKeywords.fields.map(
                                    (searchKeyword, index) => (
                                        <div
                                            key={searchKeyword.id}
                                            className="col-2"
                                        >
                                            <input
                                                key={searchKeyword.id}
                                                ref={form.register}
                                                name={`searchKeywords[${index}].value`}
                                                defaultValue={
                                                    searchKeyword.value
                                                }
                                                className="form-control rounded-0 border-dark"
                                            />
                                        </div>
                                    ),
                                )}
                            </div>
                            <div className="form-row mt-3">
                                <div className="col-2">
                                    <div className="h-100 d-flex justify-content-center align-items-center">
                                        <h5 className="text-middle text-center mb-0">
                                            사례 키워드(옵션)
                                        </h5>
                                    </div>
                                </div>
                                {exampleKeywords.fields.map(
                                    (exampleKeywordField, index) => (
                                        <div
                                            key={exampleKeywordField.id}
                                            className="col-2"
                                        >
                                            <input
                                                key={exampleKeywordField.id}
                                                ref={form.register}
                                                name={`exampleKeywords[${index}].value`}
                                                defaultValue={
                                                    exampleKeywordField.value
                                                }
                                                className="form-control rounded-0 border-dark"
                                            />
                                        </div>
                                    ),
                                )}
                            </div>
                            <div className="form-row mt-4">
                                <div className="col-2">
                                    <div className="h-100 d-flex justify-content-center align-items-center">
                                        <h5 className="text-middle text-center mb-0">
                                            관련 키워드(옵션)
                                        </h5>
                                    </div>
                                </div>
                                {relevantKeywords.fields.map(
                                    (relevantKeywordField, index) => (
                                        <div
                                            key={relevantKeywordField.id}
                                            className="col-2"
                                        >
                                            <input
                                                key={relevantKeywordField.id}
                                                ref={form.register}
                                                name={`relevantKeywords[${index}].value`}
                                                defaultValue={
                                                    relevantKeywordField.value
                                                }
                                                className="form-control rounded-0 border-dark"
                                            />
                                        </div>
                                    ),
                                )}
                            </div>
                            <div className="form-row mt-4">
                                <div className="col-2">
                                    <div className="h-100 d-flex justify-content-center align-items-center">
                                        <h5 className="text-middle text-center mb-0">
                                            관련 도서(ISBN)
                                        </h5>
                                    </div>
                                </div>
                                {recommendedBooks.fields.map(
                                    (recommendedBookField, index) => (
                                        <div
                                            key={recommendedBookField.id}
                                            className="col-2"
                                            onClick={() =>
                                                missionReportBookAPI.send({
                                                    book:
                                                        recommendedBookField.value,
                                                })
                                            }
                                        >
                                            <input
                                                key={recommendedBookField.id}
                                                ref={form.register}
                                                name={`recommendedBooks[${index}].value`}
                                                defaultValue={
                                                    recommendedBookField.value
                                                }
                                                className="form-control rounded-0 border-dark"
                                            />
                                        </div>
                                    ),
                                )}
                            </div>
                            <hr className="border-dark mt-5 thick" />
                            <div className="form-row mt-5 mb-5 justify-content-center">
                                <div className="col-3 justify-content-center align-items-center d-flex">
                                    <button
                                        type="submit"
                                        ref={temporarilySaveButtonRef}
                                        className="btn-border-main btn-lg btn-block"
                                    >
                                        수정하기
                                    </button>
                                </div>
                                <div className="offset-2 col-3 justify-content-center align-items-center d-flex">
                                    <button
                                        id="study-subject-issue-btn"
                                        type="submit"
                                        ref={submitButtonRef}
                                        className="btn-main-color btn-lg btn-block"
                                    >
                                        새로 저장
                                    </button>
                                </div>
                            </div>

                            <hr className="border-dark mt-4 thick" />
                            <div className="mt-5">
                                <div className="d-flex justify-content-end">
                                    <button
                                        onClick={() => {
                                            setIsModifyingTags(
                                                !isModifyingTags,
                                            );
                                        }}
                                    >
                                        태그 수정
                                    </button>
                                </div>
                                <hr className="border-dark mt-5 thick" />
                                {isModifyingTags ? (
                                    <TagWizard
                                        isLoaded={tags.length > 0}
                                        tags={tags}
                                        setTags={setTags}
                                        setFinished={() => {
                                            setIsModifyingTags(false);
                                        }}
                                    />
                                ) : (
                                    <TagResultDisplay tags={tags} />
                                )}

                                <hr className="border-dark mt-5 thick" />
                                {uid ? (
                                    <div className="form-row mt-5 mb-5 justify-content-center">
                                        <div className="col-3 justify-content-center align-items-center d-flex">
                                            <button
                                                onClick={() => history.goBack()}
                                                id="study-subject-issue-btn"
                                                type="button"
                                                className="btn-cancel btn-lg btn-block"
                                            >
                                                발급 취소
                                            </button>
                                        </div>
                                        <div className="offset-2 col-3 justify-content-center align-items-center d-flex">
                                            <button
                                                onClick={() =>
                                                    handleCompleteButtonClick()
                                                }
                                                id="study-subject-issue-btn"
                                                type="button"
                                                className="btn-main-color btn-lg btn-block"
                                            >
                                                선택 완료
                                            </button>
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        <button
                                            onClick={() => {}}
                                            id="study-subject-issue-btn"
                                            type="button"
                                            className="btn-cancel btn-lg btn-block"
                                        >
                                            수정하기
                                        </button>
                                    </div>
                                )}
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </>
    );
};
