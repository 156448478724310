import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

import { useAPI, APIRoute } from 'Client';
import { usePageBlock } from '../admin/table/hooks';

const LIMIT_PER_PAGE = 10;
const BLOCK_SIZE = 10;

export const PointsWithdrawalModal = ({
    researchAssistantProfile,
    handleClick = () => {},
    isOpen = false,
    close,
}) => {
    const [withdrawalHistory, setWithdrawalHistory] = useState([]);

    const { pageBlock, pageParams, setTotal, before, next } = usePageBlock({
        blockSize: BLOCK_SIZE,
        limitPerPage: LIMIT_PER_PAGE,
    });

    const researchAssistantWithdrawalHistoryAPI = useAPI(
        APIRoute.Admin.PointsWithdrawal.List,
        {
            callbacks: {
                onSuccess: res => {
                    // console.log(res)
                },
            },
        },
    );

    useEffect(() => {
        if (researchAssistantProfile) {
            researchAssistantWithdrawalHistoryAPI.send({
                researchAssistantProfile: researchAssistantProfile.id,
                ...pageParams,
            });
        }
    }, [researchAssistantProfile]);

    return (
        <Modal
            id="search-concepts-modal"
            className="modal"
            show={isOpen}
            onHide={close}
        >
            <Modal.Header className="d-block">
                <div className="row w-100">
                    <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={close}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="row w-100 justify-content-center align-items-center">
                    <h4>정산일지</h4>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="row mt-3">
                    <table className="w-100 text-center">
                        <thead>
                            <tr>
                                <td className="pt-3 pb-3 border-bottom border-dark">
                                    작업
                                </td>
                                <td className="pt-3 pb-3 border-bottom border-dark">
                                    이론
                                </td>
                                <td className="pt-3 pb-3 border-bottom border-dark">
                                    승인
                                </td>
                                <td className="pt-3 pb-3 border-bottom border-dark">
                                    반려
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="pt-3 pb-3">0</td>
                                <td className="pt-3 pb-3">0</td>
                                <td className="pt-3 pb-3">0</td>
                                <td className="pt-3 pb-3">0</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </Modal.Body>
        </Modal>
    );
};
