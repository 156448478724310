import React, { useEffect, useState, useContext } from 'react';
import { Container, Row, Col, Modal, Form, Button } from 'react-bootstrap';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';

import { useHistory } from 'react-router-dom';
import { Pen } from 'react-bootstrap-icons';

import { AppContext } from 'Contexts/AppContext';
import { useAPI, APIRoute } from 'Client';
import Select from 'react-select';

export const StudentAgreementView = ({ location, ...props }) => {
    const history = useHistory();

    const { researchAssistant } = useContext(AppContext);

    const [check1, setCheck1] = useState(false);
    const [check2, setCheck2] = useState(false);
    const [check3, setCheck3] = useState(false);

    const [check, setCheck] = useState([
        {
            check: false,
        },
        {
            check: false,
        },
        {
            check: false,
        },
        {
            check: false,
        },
        {
            check: false,
        },
    ]);

    const [allCheck, setAllCheck] = useState(false);
    const [checkedAll, setCheckedAll] = useState(false);

    const testSubmitCheckAPI = useAPI(
        APIRoute.ResearchAssistant.TestReport.Check,
        {
            callbacks: {
                onSuccess: res => {
                    history.push('/ra/test/waiting/');
                },
            },
        },
    );

    const handleAllCheck = () => {
        if (allCheck) {
            setAllCheck(false);
            setCheck1(false);
            setCheck2(false);
            setCheck3(false);
        } else {
            setAllCheck(true);
            setCheck1(true);
            setCheck2(true);
            setCheck3(true);
        }
    };

    const handleCheck = id => {
        setCheck(
            check.map((item, index) =>
                index === id ? { ...item, check: !item.check } : item,
            ),
        );
    };

    useEffect(() => {
        let allChecked = true;
        check.map(item => (allChecked = item.check && allChecked));
        if (allChecked) {
            setCheckedAll(true);
        }
    }, [check]);

    const emailValidationStatusAPI = useAPI(
        APIRoute.ResearchAssistant.TestReport.EmailValidationStatus,
        {
            callbacks: {
                onSuccess: res => {
                    if (!researchAssistant.profile.major)
                        history.push('/ra/test/validation/');
                },
                onError: err => {
                    history.push('/ra/test/validation/');
                },
            },
        },
    );

    useEffect(() => {
        emailValidationStatusAPI.send();
        testSubmitCheckAPI.send();
    }, []);

    useEffect(() => {
        if (check1 && check2 && check3) {
            setAllCheck(true);
        }
    }, [check1, check2, check3]);

    return (
        <div className="ra-mission-container">
            <div className="ra-mission-title">Mission</div>
            <div className="ra-mission-category">
                전공 탐색을 위한 탐구과제 생성
            </div>
            <div className="ra-test-description">
                테스트에 대한 약관을 확인하신 후 동의해 주세요.
            </div>
            <hr />
            <div className="ra-test-description"></div>
            <div className="ra-test-agree-container">
                <div className="ra-test-agree-line">
                    1. 본 미션은 고등학생들의 전공탐색활동을 위해
                    마련되었습니다. 따라서 작업자 본인의{' '}
                    <span>전공과 관련된 내용</span>으로 작성해 주세요. 본인의
                    전공과 전혀 무관한 경우 탈락될 수 있습니다.
                    <div className="ra-test-agree-input-container">
                        <input
                            type="checkbox"
                            checked={check1}
                            onChange={() => setCheck1(!check1)}
                        />{' '}
                        네, 확인했습니다.
                    </div>
                </div>
                <hr />
                <div className="ra-test-agree-line">
                    2. 테스트/미션 수행 시 참고문헌을 활용하실 수 있으나,
                    표절해서는 안 됩니다.{' '}
                    <span>
                        표절이 확인되는 즉시 불합격/제명되며, 추후 본 미션에
                        재도전하실 수 없습니다.
                    </span>
                    <div className="ra-test-agree-input-container">
                        <input
                            type="checkbox"
                            checked={check2}
                            onChange={() => setCheck2(!check2)}
                        />{' '}
                        네, 확인했습니다.
                    </div>
                </div>
                <hr />
                <div className="ra-test-agree-line">
                    3. 테스트는 작업자 분의 기본적인 작문 실력을 확인하기 위한
                    문항으로 구성되어 있습니다. 따라서 별도의 포인트가 적립되지
                    않습니다. 포인트는 미션에 착수하신 후 승인된 건을 기준으로
                    적립됩니다.
                    <div className="ra-test-agree-input-container">
                        <input
                            type="checkbox"
                            checked={check3}
                            onChange={() => setCheck3(!check3)}
                        />{' '}
                        네, 확인했습니다.
                    </div>
                </div>
            </div>
            <div className="ra-test-agree-input-container">
                <input
                    type="checkbox"
                    checked={allCheck}
                    onChange={() => handleAllCheck()}
                />{' '}
                모든 내용을 확인했으며 이에 동의합니다.
            </div>
            <div className="ra-test-description">
                미션에 대한 숙지사항을 확인해주세요.
            </div>
            <div className="ra-test-agree-container">
                <div className="ra-test-agree-line">
                    1. 원리 생성 건당 1000원, 탐구과제 생성 건당 3000원 기준으로
                    승인된 작업량에 해당하는 금액을 매월 둘째, 넷째주 금요일에
                    계좌로 지급합니다. (금요일이 휴일일 경우 이후 영업일에 지급)
                    <div className="ra-test-agree-input-container">
                        <input
                            type="checkbox"
                            checked={check[0].check}
                            onChange={() => handleCheck(0)}
                        />{' '}
                        이해했습니다.
                    </div>
                </div>
                <hr />
                <div className="ra-test-agree-line">
                    2. 매월 둘째, 넷째주 목요일 오후 6시까지 승인된 포인트에
                    한하여 정산합니다.
                    <div className="ra-test-agree-input-container">
                        <input
                            type="checkbox"
                            checked={check[1].check}
                            onChange={() => handleCheck(1)}
                        />{' '}
                        이해했습니다.
                    </div>
                </div>
                <hr />
                <div className="ra-test-agree-line">
                    3. 매월 둘째, 넷째주 목요일 오후 6시까지 승인된 작업량은
                    최소 10건 이상이어야 합니다. (2주에 10건 이상)
                    <div className="ra-test-agree-input-container">
                        <input
                            type="checkbox"
                            checked={check[2].check}
                            onChange={() => handleCheck(2)}
                        />{' '}
                        이해했습니다.
                    </div>
                </div>
                <hr />
                <div className="ra-test-agree-line">
                    4. 학습 자료의 표절, 인용 미표기 등으로 인한 분쟁 시, 학습
                    자료의 제작자인 본인(프리랜서 당사자)이 모든 책임을 집니다.
                    <div className="ra-test-agree-input-container">
                        <input
                            type="checkbox"
                            checked={check[3].check}
                            onChange={() => handleCheck(3)}
                        />{' '}
                        이해했습니다.
                    </div>
                </div>
                <hr />
                <div className="ra-test-agree-line">
                    5. 표절 혹은 가이드 미숙지 등으로 인한 반려가 회사의 피드백
                    이후에도 지속적으로 발생할 경우, 본인의 책임을 물어 회사는
                    본인과의 계약을 파기할 수 있습니다.
                    <div className="ra-test-agree-input-container">
                        <input
                            type="checkbox"
                            checked={check[4].check}
                            onChange={() => handleCheck(4)}
                        />{' '}
                        이해했습니다.
                    </div>
                </div>
            </div>
            <Row className="justify-content-center mt-5">
                <Col md={8} className="pt-2 pb-2">
                    <Button
                        type="submit"
                        variant={'primary'}
                        className="w-100 pt-3 pb-3 btn-main-color"
                        disabled={!check1 || !check2 || !check3 || !checkedAll}
                        onClick={() => history.push('/ra/test/mission/')}
                    >
                        {check1 && check2 && check3 && checkedAll
                            ? '다음 단계'
                            : '모든 항목에 동의해주세요.'}
                    </Button>
                </Col>
            </Row>
        </div>
    );
};
