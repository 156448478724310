import React, { useEffect, useState } from 'react';

import { useAPI, APIRoute } from 'Client';
import { RemoteDataTable } from 'Components/table/base';

import moment from 'Moment';

const UploadType = {
    IssuedStudySubject: 'STUDY_SUBJECT_PDF',
    DongaScience: 'DONGA_SICENCE_PDF',
};

const ButtonText = {
    IssuedStudySubject: 'PDF 발급',
    DongaScience: '동아 PDF 발급',
};

import { StudySubjectOrderDisplay } from 'Components';
import { useHistory } from 'react-router';

const tableProperty = [
    {
        key: 'id',
        name: '번호',
    },
    {
        key: 'type',
        name: '유형',
        template: (columnData, row) => {
            return columnData;
        },
    },
    {
        key: 'file',
        name: '파일',
        template: (columnData, row) => {
            return columnData ? (
                <a
                    href={`${columnData}`}
                    target="_blank"
                    className="btn btn-primary"
                    rel="noreferrer"
                >
                    다운로드
                </a>
            ) : (
                <button className="btn btn-danger">파일 없음</button>
            );
        },
    },
    {
        key: 'createdAt',
        name: '생성일',
        template: (columnData, row) => {
            return <span>{moment(columnData).format('YYYY-MM-DD hh:mm')}</span>;
        },
    },
];

export const AdminIssuedStudySubjectDetailView = ({
    entity: issuedStudySubject,
    ...props
}) => {
    const history = useHistory();
    const [
        createIssuedStudySubjectPdfButtonText,
        setCreateIssuedStudySubjectPdfButtonText,
    ] = useState(ButtonText.IssuedStudySubject);

    const [createDongaPdfButtonText, setCreateDongaPdfButtonText] = useState(
        ButtonText.DongaScience,
    );

    const [dongaPdfFile, setDongaPdfFile] = useState();
    const [pdfFiles, setPdfFiles] = useState();
    const [isPdfFilesFetched, setIsPdfFilesFetched] = useState(false);
    const [studySubjectOrder, setStudySubjectOrder] = useState();

    const issuedStudySubjectDeleteAPI = useAPI(
        APIRoute.Admin.IssuedStudySubject.Delete,
        {
            callbacks: {
                onSuccess: () => {
                    alert('삭제 완료하였습니다.');
                    history.push('/admin/main/issued-study-subjects/');
                },
            },
        },
    );

    const studySubjectOrderRetrieveAPI = useAPI(
        APIRoute.Admin.StudySubjectOrder.Retrieve,
        {
            callbacks: {
                onSuccess: studySubjectOrder => {
                    if (!studySubjectOrder) {
                        return;
                    }
                    setStudySubjectOrder(studySubjectOrder);
                },
            },
        },
    );

    const uploadCreateAPI = useAPI(APIRoute.Admin.Upload.Create, {
        callbacks: {
            onSuccess: res => {
                alert('성공!');
                setPdfFiles([res, ...pdfFiles]);
            },
            finally: () => {
                setCreateIssuedStudySubjectPdfButtonText(
                    ButtonText.IssuedStudySubject,
                );
                setCreateDongaPdfButtonText(ButtonText.DongaScience);
            },
        },
    });

    const uploadListAPI = useAPI(APIRoute.Admin.Upload.List, {
        callbacks: {
            onSuccess: results => {
                setPdfFiles(results);
            },
            onErorr: error => {
                alert('실패');
            },
            finally: () => {
                setIsPdfFilesFetched(true);
            },
        },
    });

    const handleIssuedStudySubjectDeleteButtonClick = () => {
        if (!confirm('삭제하시겠습니까?')) {
            return;
        }

        issuedStudySubjectDeleteAPI.send(null, {
            additionalPath: issuedStudySubject.id,
        });
    };

    const handleCreateDongaPdfButtonClick = () => {
        if (isPdfFilesFetched && pdfFiles.length > 0) {
            if (
                !confirm('이미 발급된 PDF가 있습니다. 계속 진행하시겠습니까?')
            ) {
                return;
            }
        }

        let form = new FormData();
        form.append('file', dongaPdfFile);
        form.append('type', UploadType.DongaScience);
        form.append('issuedStudySubject', issuedStudySubject.id);

        setCreateDongaPdfButtonText(<div className="loader">Loading</div>);
        uploadCreateAPI.send(form);

        alert('PDF 발급을 요청하였습니다.');
    };

    const handleCreateIssuedStudySubjectButtonClick = () => {
        if (isPdfFilesFetched && pdfFiles.length > 0) {
            if (
                !confirm('이미 발급된 PDF가 있습니다. 계속 진행하시겠습니까?')
            ) {
                return;
            }
        }

        setCreateIssuedStudySubjectPdfButtonText(
            <div className="loader">Loading</div>,
        );
        uploadCreateAPI.send({
            issuedStudySubject: issuedStudySubject.id,
            type: UploadType.IssuedStudySubject,
        });

        alert('PDF 발급을 요청하였습니다.');
    };

    const handleStudySubjectButtonClick = () => {
        history.push({
            pathname: `/admin/main/study-subjects/${issuedStudySubject.studySubject.id}/`,
            state: {
                entity: issuedStudySubject.studySubject,
            },
        });
    };

    useEffect(() => {
        uploadListAPI.send({
            issuedStudySubject: issuedStudySubject.id,
            paginate: false,
        });

        return () => {
            uploadListAPI.cancel();
        };
    }, []);

    useEffect(() => {
        studySubjectOrderRetrieveAPI.send(
            {
                isForDetail: true,
            },
            {
                additionalPath: issuedStudySubject.remoteOrderId,
            },
        );

        return () => {
            studySubjectOrderRetrieveAPI.cancel();
        };
    }, []);

    return (
        <>
            <div className="row mt-5">
                {studySubjectOrder && (
                    <StudySubjectOrderDisplay
                        studySubjectOrder={studySubjectOrder}
                    />
                )}
            </div>
            <div className="row flex-column mt-5">
                <span>발표 주제</span>
                <div className="mt-3">
                    <button
                        className="btn btn-primary"
                        onClick={() => handleStudySubjectButtonClick()}
                    >
                        발표 주제 페이지 이동
                    </button>
                </div>
            </div>
            <div className="row flex-column mt-5">
                <span>PDF 발급 내역</span>
                <div className="mt-3">
                    <RemoteDataTable
                        tableProperty={tableProperty}
                        data={pdfFiles}
                        loading={!pdfFiles}
                        paginationData={{
                            isPaginated: false,
                        }}
                    />
                </div>
            </div>
        </>
    );
};
