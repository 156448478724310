import React, { useState, useEffect } from 'react';
import moment from 'Moment';

const formatDate = date => {
    return moment(date).format('YYYY-MM-DD HH:mm');
};

export const BaseTable = ({
    columnNames = [],
    data = [],
    onClick = () => {},
    ...props
}) => {
    let now = new Date(Date.now()).getTime();

    return (
        <>
            <table className="table table-hover text-center">
                <thead>
                    <tr>
                        {columnNames.map((column, headIndex) => (
                            <th key={headIndex} scope="col">
                                {column.name}
                            </th>
                        ))}
                    </tr>
                </thead>
                {!props.loading && (
                    <tbody>
                        {data.length === 0 && (
                            <tr>
                                <td colSpan={columnNames.length}>
                                    <span>데이터가 없습니다.</span>
                                </td>
                            </tr>
                        )}
                        {data.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                                {columnNames.map((column, colIndex) => {
                                    const { key } = column;
                                    const item = row[key];
                                    return (
                                        <td
                                            key={colIndex}
                                            onClick={() => onClick(row)}
                                            className="pt-4 pb-4"
                                        >
                                            {key === 'created' ||
                                            key === 'createdAt' ? (
                                                formatDate(item)
                                            ) : key === 'quick' ? (
                                                <span
                                                    className={
                                                        row['issued'] !== 'O' &&
                                                        row['emergency']
                                                            ? 'quick-emergency'
                                                            : ''
                                                    }
                                                >
                                                    {item}
                                                </span>
                                            ) : key === 'check' ? (
                                                <span
                                                    className={
                                                        item
                                                            ? 'text-main-color'
                                                            : 'text-danger-color'
                                                    }
                                                >
                                                    {item ? '읽음' : '미확인'}
                                                </span>
                                            ) : key === 'noticeTitle' ? (
                                                <span>
                                                    {(now -
                                                        new Date(
                                                            Date.parse(
                                                                row[
                                                                    'createdAt'
                                                                ],
                                                            ),
                                                        ).getTime()) /
                                                        (1000 * 60 * 60 * 24) <
                                                        7 && (
                                                        <span className="notice-table-new">
                                                            new
                                                        </span>
                                                    )}
                                                    {item}
                                                </span>
                                            ) : (
                                                item
                                            )}
                                        </td>
                                    );
                                })}
                            </tr>
                        ))}
                    </tbody>
                )}
            </table>
            {props.loading && (
                <div className="table-loading-container">
                    <div className="table-loading-text">
                        데이터를 불러오고 있습니다...
                    </div>
                    <div className="loader">Loading</div>
                </div>
            )}
        </>
    );
};
