import React, { useEffect, useState } from 'react';
import { useAPI, APIRoute } from 'Client';
import { BaseTable } from 'Components/table/base';

const columnNames = [
    {
        key: 'key',
        name: '-',
    },
    {
        key: 'demand',
        name: '수요',
    },
    {
        key: 'supply',
        name: '공급',
    },
    {
        key: 'percentage',
        name: '비율',
    },
];

export const AdminStudySubjectDemandSupplyStatisticView = props => {
    const [statistics, setStatistics] = useState();
    const [keys, setKeys] = useState();
    const [selectedKey, setSelectedKey] = useState();
    const [isLoading, setIsLoading] = useState(true);

    const studySubjectDemandSupplyStatisticFetchAPI = useAPI(
        APIRoute.Admin.Statistic.StudySubjectDemandSupply,
        {
            callbacks: {
                onSuccess: res => {
                    setStatistics(res);
                    const keys = Object.keys(res);
                    setKeys(keys);
                    setSelectedKey(keys[0]);
                },
                finally: () => {
                    setIsLoading(false);
                },
            },
        },
    );

    useEffect(() => {
        studySubjectDemandSupplyStatisticFetchAPI.send();

        return () => {
            studySubjectDemandSupplyStatisticFetchAPI.cancel();
        };
    }, []);

    return (
        <div>
            <div className="m-2 d-flex w-100">
                {keys &&
                    keys.map(e => (
                        <button
                            className={`w-50 btn m-2 p-2 ${
                                e === selectedKey ? 'btn-primary' : 'btn-dark'
                            }`}
                            onClick={() => setSelectedKey(e)}
                        >
                            {e}
                        </button>
                    ))}
            </div>
            <div>
                <BaseTable
                    columnNames={columnNames}
                    loading={isLoading}
                    data={
                        statistics &&
                        selectedKey &&
                        statistics[selectedKey].map(({ key, value }) => ({
                            key,
                            ...value,
                        }))
                    }
                />
            </div>
        </div>
    );
};
