import React, { useState, useEffect, useRef, useContext } from 'react';
import { Form } from 'react-bootstrap';
import { useForm, useFieldArray } from 'react-hook-form';

import { useAPI, APIRoute } from 'Client';
import { getFieldArrayValues } from 'Utils';
import { useHistory } from 'react-router-dom';
import { AppContext } from 'Contexts';
import {
    BasicConceptForm,
    TagWizard,
    TutorialBox,
    LatexEditor,
} from 'Components';
import {
    SPELL_CHECKER_URL,
} from 'Constants/config';

import useLocalStorage from '../../hooks/useLocalStorage';

export const StudySubjectCreatePatchView = ({ entity, resubmit }) => {
    const history = useHistory();

    const {
        researchAssistant: { currentMissionId, profile },
    } = useContext(AppContext);

    const missionReportCreateAPI = useAPI(
        APIRoute.ResearchAssistant.MissionReport.Create,
        {
            callbacks: {
                onSuccess: () =>
                    history.push({
                        pathname: `/ra/mission-working/`,
                    }),
            },
        },
    );
    const missionReportPatchAPI = useAPI(
        APIRoute.ResearchAssistant.MissionReport.Patch,
        {
            callbacks: {
                onSuccess: () =>
                    history.push({
                        pathname: `/ra/mission-working/`,
                    }),
            },
        },
    );
    const missionReportSubmitAPI = useAPI(
        APIRoute.ResearchAssistant.MissionReport.Submit,
        {
            callbacks: {
                onSuccess: () =>
                    history.push({
                        pathname: `/ra/mission-working/`,
                    }),
            },
        },
    );

    const temporarilySaveButtonRef = useRef(null);
    const submitButtonRef = useRef(null);
    const [tags, setTags] = useState([
        {
            tagUUid: '',
            learningElements: [],
            majorCategory: 0,
            schoolSubjectUnits: [],
            schoolSubjects: [],
        },
    ]);
    const [finished, setFinished] = useState(false);
    const [onGoing, setOnGoing] = useState(false);

    //가이드 확인 여부
    const [guideFirst, setGuideFirst] = useLocalStorage('guide', true);
    const [guideIndex, setGuideIndex] = useState(0);
    const [spellCheckedMap, setSpellCheckedMap] = useState({
        concept: false,
        studySubject: false,
    });

    const isAllSpellChecked = () => {
        return Object.values(spellCheckedMap).every(e => e === true);
    }

    const handleSpellCheckButtonClick = (key) => {
        window.open(SPELL_CHECKER_URL, '_blank');
        setSpellCheckedMap({
            ...spellCheckedMap,
            [key]: true,
        });
    }


    const allTagsChecked = () => {
        for (let tag of tags) {
            if (tag && tag.learningElements.length == 0) return false;
        }
        return true;
    };

    const form = useForm({
        defaultValues: {
            relatedConcepts: [
                { value: '' },
                { value: '' },
                { value: '' },
                { value: '' },
            ],
            relatedConceptsInEnglish: [
                { value: '' },
                { value: '' },
                { value: '' },
                { value: '' },
            ],
            task: '',
            conceptDescription: '',
            searchKeywords: [
                { value: '' },
                { value: '' },
                { value: '' },
                { value: '' },
            ],
            exampleKeywords: [
                { value: '' },
                { value: '' },
                { value: '' },
                { value: '' },
            ],
            relevantKeywords: [
                { value: '' },
                { value: '' },
                { value: '' },
                { value: '' },
            ],
            recommendedBooks: [{ value: '' }, { value: '' }],
        },
    });

    const watchConceptId = form.watch('conceptId');

    const relatedConcepts = useFieldArray({
        control: form.control,
        name: 'relatedConcepts',
    });
    const relatedConceptsInEnglish = useFieldArray({
        control: form.control,
        name: 'relatedConceptsInEnglish',
    });
    const searchKeywords = useFieldArray({
        control: form.control,
        name: 'searchKeywords',
    });
    const exampleKeywords = useFieldArray({
        control: form.control,
        name: 'exampleKeywords',
    });
    const relevantKeywords = useFieldArray({
        control: form.control,
        name: 'relevantKeywords',
    });
    const recommendedBooks = useFieldArray({
        control: form.control,
        name: 'recommendedBooks',
    });

    form.handleSubmit = form.handleSubmit((data, event) => {
        const {
            task,
            searchKeywords,
            exampleKeywords,
            relevantKeywords,
            recommendedBooks,
            conceptId,
            conceptName,
            conceptNameInEnglish,
            conceptDescription,
            relatedConcepts,
            relatedConceptsInEnglish,
        } = data;

        const {
            nativeEvent: { submitter },
        } = event;

        if (
            ![
                temporarilySaveButtonRef.current,
                submitButtonRef.current,
            ].includes(submitter)
        ) {
            return;
        }

        const concept = conceptId
            ? conceptId
            : {
                id: conceptId,
                name: conceptName,
                nameInEnglish: conceptNameInEnglish,
                description: conceptDescription,
                relatedConcepts: getFieldArrayValues(relatedConcepts),
                relatedConceptsInEnglish: getFieldArrayValues(
                    relatedConceptsInEnglish,
                ),
            };

        const studySubject = {
            id: entity ? entity.studySubject.id : null,
            task,
            concept: conceptId || null,
            tags,
            keywords: [
                ...searchKeywords.map(({ value }) => ({
                    type: 'search',
                    keyword: value,
                })),
                ...exampleKeywords.map(({ value }) => ({
                    type: 'example',
                    keyword: value,
                })),
                ...relevantKeywords.map(({ value }) => ({
                    type: 'relevant',
                    keyword: value,
                })),
            ],
            recommendedBooks: recommendedBooks.map(({ value }) => value),
        };

        const checkKeywordNums = () => {
            let count = 0;
            for (let keyword of exampleKeywords) {
                if (keyword.value) count += 1;
            }
            for (let keyword of relevantKeywords) {
                if (keyword.value) count += 1;
            }
            return count;
        };

        const submitted =
            submitter === submitButtonRef.current
                ? new Date().toISOString()
                : null;

        let DataValidation = false;

        if (submitter === submitButtonRef.current) {
            // 제출 Validation
            if (!conceptId && !conceptName) {
                alert('탐구 이론(원리)을 반드시 작성해주세요!');
            } else if (!conceptId && conceptDescription.length < 300) {
                alert('탐구 이론 설명이 너무 짧습니다! (300자 이상 제출 가능)');
            } else if (task.length < 300) {
                alert('탐구 과제가 너무 짧습니다! (300자 이상 제출 가능)');
            } else if (
                !searchKeywords[0].value ||
                !searchKeywords[1].value ||
                !searchKeywords[2].value ||
                !searchKeywords[3].value
            ) {
                alert('검색 키워드 4개를 모두 입력해주세요!');
            } else if (checkKeywordNums() < 4) {
                alert('전체 키워드 8개 이상 작성해주세요!');
            } else if (
                !recommendedBooks[0].value | !recommendedBooks[1].value
            ) {
                alert('관련 도서 ISBN은 두 개 모두 입력해주세요!!');
            } else if (!tags.length || !tags[0].learningElements.length) {
                alert(
                    '학생이 주제를 발표할 수 있는\n학과(소계열)들을 선택하고(복수선택가능)\n각 학과마다 교육과정의 과목 > 단원 > 학습요소를 태깅해주세요.',
                );
            } else {
                DataValidation = true;
            }
        } else {
            if (!conceptId && !conceptName) {
                alert(
                    '탐구 이론(원리)을 반드시 작성해주세요!\n(임시저장은 탐구 이론(원리),\n탐구 이론 설명 300-350자,\n과제 1글자 이상 입력해야 가능합니다.)',
                );
            } else if (!conceptId && conceptDescription.length < 300) {
                alert(
                    '탐구 이론 설명이 너무 짧습니다! (300자 이상 제출 가능)\n(임시저장은 탐구 이론(원리),\n탐구 이론 설명 300-350자,\n과제 1글자 이상 입력해야 가능합니다.)',
                );
            } else if (!task) {
                alert(
                    '과제를 한글자 이상 입력해주세요!\n(임시저장은 탐구 이론(원리),\n탐구 이론 설명 300-350자,\n과제 1글자 이상 입력해야 가능합니다.)',
                );
            } else {
                DataValidation = true;
            }
        }
        if (DataValidation) {
            if (entity && !resubmit) {
                if (
                    confirm(
                        '탐구 이론(원리, 설명, 유사개념, 원어명)은\n임시저장 및 제출 이후 수정이 불가능합니다.\n탐구이론 작성을 완료했는지 확인해주세요!',
                    )
                ) {
                    // mission : 하드코딩(MissionId)
                    missionReportPatchAPI.send(
                        {
                            concept,
                            studySubject,
                            mission: 1 || currentMissionId || entity.mission.id,
                            submitted,
                        },
                        {
                            additionalPath: entity.id,
                        },
                    );
                }
            } else if (entity && resubmit) {
                if (
                    confirm(
                        '재제출시 이전에 제출된 탐구주제를 더 이상 볼 수 없습니다.',
                    )
                ) {
                    // mission : 하드코딩(MissionId)
                    missionReportSubmitAPI.send(
                        {
                            concept:
                                entity.studySubject.concept.description ===
                                    conceptDescription &&
                                    entity.studySubject.concept.name ===
                                    concept.name &&
                                    entity.studySubject.concept.nameInEnglish ===
                                    conceptNameInEnglish
                                    ? conceptId
                                    : {
                                        id: conceptId,
                                        name: conceptName,
                                        nameInEnglish: conceptNameInEnglish,
                                        description: conceptDescription,
                                    },
                            studySubject,
                            mission: 1 || currentMissionId || entity.mission.id,
                            submitted,
                            description: conceptDescription,
                        },
                        {
                            additionalPath: `${entity.id}/resubmit`,
                        },
                    );
                }
            } else {
                if (
                    confirm(
                        '탐구 이론(원리, 설명, 유사개념, 원어명)은\n임시저장 및 제출 이후 수정이 불가능합니다.\n탐구이론 작성을 완료했는지 확인해주세요!',
                    )
                ) {
                    // mission : 하드코딩(MissionId)
                    missionReportCreateAPI.send({
                        concept,
                        studySubject,
                        mission:
                            1 ||
                            (entity && entity.mission.id) ||
                            currentMissionId,
                        submitted,
                    });
                }
            }
        }
    });

    const handleGuide = index => {
        setGuideIndex(index);
        setGuideFirst(true);
    };

    useEffect(() => {
        form.setValue('recommendedMajors', tags);
    }, [tags]);

    // deserialize
    useEffect(() => {
        if (!entity) {
            return;
        }
        if (entity.studySubject.keywords) {
            // to fix undefined error : upload keywords 된 후..?
            const {
                studySubject: {
                    task,
                    concept,
                    keywords,
                    recommendedBooks: _recommendedBooks,
                },
            } = entity;

            const searchKeywords = keywords
                .filter(({ type }) => type === 'search')
                .map(({ keyword }) => ({ value: keyword }));
            const exampleKeywords = keywords
                .filter(({ type }) => type === 'example')
                .map(({ keyword }) => ({ value: keyword }));
            const relevantKeywords = keywords
                .filter(({ type }) => type === 'relevant')
                .map(({ keyword }) => ({ value: keyword }));
            const recommendedBooks = _recommendedBooks.map(isbn => ({
                value: isbn,
            }));

            form.reset({
                task,

                conceptId: concept.id,
                conceptName: concept.name,
                conceptNameInEnglish: concept.nameInEnglish,
                conceptDescription: concept.description,

                searchKeywords:
                    searchKeywords.length > 0
                        ? searchKeywords
                        : [
                            { value: '' },
                            { value: '' },
                            { value: '' },
                            { value: '' },
                        ],
                exampleKeywords:
                    exampleKeywords.length > 0
                        ? exampleKeywords
                        : [
                            { value: '' },
                            { value: '' },
                            { value: '' },
                            { value: '' },
                        ],
                relevantKeywords:
                    relevantKeywords.length > 0
                        ? relevantKeywords
                        : [
                            { value: '' },
                            { value: '' },
                            { value: '' },
                            { value: '' },
                        ],
                recommendedBooks:
                    recommendedBooks.length > 0
                        ? recommendedBooks
                        : [{ value: '' }, { value: '' }],
            });
        }
        if (entity.studySubject.tags.length) {
            setTags(entity.studySubject.tags);
        }
    }, []);

    useEffect(() => {
        setSpellCheckedMap({
            ...spellCheckedMap,
            concept: !!watchConceptId,
        })
    }, [watchConceptId])
    return (
        <div className="ra-mission-contaienr">
            <div className="row">
                <div className="w-100">
                    <div className="row justify-content-between">
                        <div
                            className="admin-back-btn cursor-pointer mt-4"
                            onClick={() => history.goBack()}
                        >
                            &#xE000;&nbsp; 뒤로가기
                        </div>
                        <div
                            className="admin-back-btn cursor-pointer mt-4"
                            onClick={() => setGuideFirst(true)}
                        >
                            가이드 보기
                        </div>
                    </div>
                    {guideFirst && (
                        <TutorialBox
                            setEnd={() => setGuideFirst(false)}
                            guideIndex={guideIndex}
                        />
                    )}
                    <div className="mt-4 border-light-color p-4">
                        <h5 style={{ fontWeight: 800 }}>0. 기본사항</h5>
                        <div className="ra-mission-caution text-muted mt-3">
                            - 작업자 분의 <bold>전공과 관련된 내용</bold>으로
                            작성해 주세요. 원리/과제에 오류가 발견될 경우
                            반려될 수 있습니다.
                        </div>
                        <div className="ra-mission-caution text-muted">
                            - 너무 추상적이거나 심오한 용어보다는, 되도록
                            고등학생이 이해하기에 <bold>쉬운 언어</bold>를
                            사용해 주세요.
                        </div>
                        <div className="ra-mission-caution text-muted">
                            - 원리 및 탐구과제 작성 시 참고문헌을 활용하실 수
                            있으나, 표절해서는 안 됩니다.{' '}
                            <red>
                                표절이 확인되는 즉시 제명되며, 모든 포인트
                                정산이 불가합니다.
                            </red>
                        </div>
                        {
                            profile.isSettlementIncluded &&
                            <div className="ra-mission-caution text-muted">
                                - 신규 원리는 2,000포인트, 신규 탐구과제는
                                4,000포인트가 적립됩니다.
                            </div>
                        }
                    </div>
                    <div className="mt-5 border-light-color p-4">
                        <h5 style={{ fontWeight: 800 }}>1. 원리 [300-350자]</h5>
                        <div className="ra-mission-caution text-muted mt-3">
                            - 과제를 탐구하기 전 습득/이해하면 좋은 개념입니다.
                            고등학교 학습 내용의 심화~대학 전공 지식의 기초에
                            해당하는 개념을 선정해주세요.
                        </div>
                        <div className="ra-mission-caution text-muted">
                            - 필요한 원리가{' '}
                            <bold>기존 DB에 있는지 반드시 검색하여 확인</bold>해
                            주세요. 새로 작성하셨더라도 해당 원리가 기존 DB에
                            있는 경우 적립이 불가합니다.
                        </div>
                        <div className="ra-mission-caution text-muted">
                            - 유사 개념이 있다면 꼭 추가해 주세요. (예:
                            황색언론, 옐로 저널리즘, 황색 저널리즘, 옐로페이퍼)
                        </div>
                    </div>
                    <Form onSubmit={form.handleSubmit} className="w-100">
                        <BasicConceptForm
                            form={form}
                            names={{
                                id: 'conceptId',
                                name: 'conceptName',
                                nameInEnglish: 'conceptNameInEnglish',
                                description: 'conceptDescription',
                            }}
                            isSearchEnabled={!entity}
                            disabled={
                                !!entity &&
                                profile.id !==
                                entity.studySubject.concept.writer
                            }
                            example={true}
                            handleExample={handleGuide}
                        />
                        {
                            !watchConceptId && (
                                spellCheckedMap.concept ? (
                                    <div className="form-line mt-4">
                                        <div className="form-group w-100 d-flex justify-content-end">
                                            <button type="button"
                                                className="btn btn-success">
                                                맞춤법 검사 완료
                                            </button>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="form-line mt-4">
                                        <div className="form-group w-100 d-flex justify-content-end">
                                            <button type="button"
                                                className="btn btn-danger"
                                                onClick={() => handleSpellCheckButtonClick('concept')}>
                                                맞춤법 검사
                                            </button>
                                        </div>
                                    </div>

                                )
                            )
                        }
                        {!watchConceptId && (
                            <>
                                <div className="form-row mt-4">
                                    <div className="col-2">
                                        <div className="h-100 d-flex justify-content-center align-items-center">
                                            <h6 className="text-middle text-center mb-0">
                                                유사개념
                                            </h6>
                                        </div>
                                    </div>
                                    {relatedConcepts.fields.map(
                                        (relatedConcept, index) => (
                                            <div
                                                key={relatedConcept.id}
                                                className="col-2"
                                            >
                                                <input
                                                    key={relatedConcept.id}
                                                    ref={form.register}
                                                    name={`relatedConcepts[${index}].value`}
                                                    defaultValue={
                                                        relatedConcept.value
                                                    }
                                                    className="form-control rounded-0"
                                                />
                                            </div>
                                        ),
                                    )}
                                </div>
                                <div className="form-row mt-3">
                                    <div className="col-2">
                                        <div className="h-100 d-flex justify-content-center align-items-center">
                                            <h6 className="text-middle text-center mb-0">
                                                원어명
                                            </h6>
                                        </div>
                                    </div>
                                    {relatedConceptsInEnglish.fields.map(
                                        (relatedConceptInEnglish, index) => (
                                            <div
                                                key={relatedConceptInEnglish.id}
                                                className="col-2"
                                            >
                                                <input
                                                    key={
                                                        relatedConceptInEnglish.id
                                                    }
                                                    ref={form.register}
                                                    name={`relatedConceptsInEnglish[${index}].value`}
                                                    defaultValue={
                                                        relatedConceptInEnglish.value
                                                    }
                                                    className="form-control rounded-0"
                                                />
                                            </div>
                                        ),
                                    )}
                                </div>
                            </>
                        )}
                        <div className="mt-5 border-light-color p-4">
                            <h5 style={{ fontWeight: 800 }}>
                                2. 탐구과제 [300-350자]
                            </h5>
                            <div className="ra-mission-caution text-muted mt-3">
                                - 탐구과제는 원리에 '기대어' 탐구할 수 있어야
                                합니다. 원리에서 심화/파생된 내용(
                                <bold>
                                    실생활에서의 현상 및 사례, 사회문제, 연구
                                    중인 과학 기술 등
                                </bold>
                                )으로 작성해 주세요.
                            </div>
                            <div className="ra-mission-caution text-muted">
                                - 마지막 문장은 <bold>"탐구해 보자."</bold>의
                                형태로 끝나도록 작성해 주세요.
                            </div>
                            <div className="ra-mission-caution text-muted">
                                - 원리에서 설명되지 않은 생소한 용어는 간략히
                                설명해 주세요.
                            </div>
                            <div className="ra-mission-caution text-muted">
                                - <bold>'전공 탐색'</bold>을 위한
                                탐구주제이므로, 단순히 교과서 내용을 반복해서는
                                안 됩니다. 교과서보다 심화된 내용을 탐구할 수
                                있도록 해 주세요.
                            </div>
                            <div className="ra-mission-caution text-muted">
                                - 한 원리에 대하여 서로 관련되는 탐구과제를 여러
                                개 작성하실 수 있습니다. (단, 내용이 지나치게
                                겹치는 경우, 또는 원리와 탐구과제 간의 관련성이
                                떨어지는 경우 반려됩니다.)
                            </div>
                            <div className="ra-mission-caution text-muted">
                                -{' '}
                                <undeline>
                                    작업자 본인께서{' '}
                                    <bold>
                                        학생의 입장에서 이러한 과제를 부여받으면
                                        어떠할지 한 번쯤 생각해주시면서
                                    </bold>{' '}
                                    과제를 작성해주시면 감사하겠습니다.
                                </undeline>
                            </div>
                        </div>
                        <div className="form-line mt-3">
                            <div className="form-group w-100">
                                <div className="row justify-content-between align-items-center mb-3">
                                    <div>
                                        <h6 className="mr-2 d-inline-block">
                                            과제
                                        </h6>
                                        <span className="light-font">
                                            (공백 포함 300-350 글자)
                                        </span>
                                    </div>
                                    <div
                                        className="study-subject-ex-btn"
                                        onClick={() => handleGuide(2)}
                                    >
                                        예시 확인
                                    </div>
                                </div>
                                <div className="position-relative">
                                    <LatexEditor
                                        className="concept-description d-block w-100 p-4 rounded-0 border-light-color"
                                        maxLength="350"
                                        name="task"
                                        formRef={form}
                                    />
                                </div>
                            </div>
                        </div>
                        {
                            spellCheckedMap.studySubject ? (
                                <div className="form-line mt-4">
                                    <div className="form-group w-100 d-flex justify-content-end">
                                        <button type="button"
                                            className="btn btn-success">
                                            맞춤법 검사 완료
                                        </button>
                                    </div>
                                </div>
                            ) : (
                                <div className="form-line mt-4">
                                    <div className="form-group w-100 d-flex justify-content-end">
                                        <button type="button"
                                            className="btn btn-danger"
                                            onClick={() => handleSpellCheckButtonClick('studySubject')}>
                                            맞춤법 검사
                                        </button>
                                    </div>
                                </div>

                            )
                        }
                        <div className="mt-5 border-light-color p-4">
                            <h5 style={{ fontWeight: 800 }}>
                                3. 키워드 [총 8개 이상], 도서 [2개 필수]
                            </h5>
                            <div className="ra-mission-caution text-muted mt-3">
                                - <bold>실제 검색에 유용한 키워드</bold>로
                                작성해주세요. 너무 범주가 큰 키워드(예: 정치,
                                경제)는 검색에 활용하기 어렵습니다.
                            </div>
                            <div className="ra-mission-caution text-muted">
                                - 검색키워드는 <red>4개 모두 필수</red>,
                                사례키워드와 관련키워드는 합해서{' '}
                                <red>4개 이상 </red>작성해주시기 바랍니다.
                            </div>
                            <div className="ra-mission-caution text-muted">
                                - '탐구과제'와 밀접하게 관련되는 도서를
                                태그해주세요. (
                                <bold>
                                    제목 또는 목차에서 탐구과제와 관련된 내용이
                                    확인되어야 합니다.
                                </bold>{' '}
                                '네이버 책(
                                <a
                                    href="https://book.naver.com/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    book.naver.com
                                </a>
                                )'을 활용해주세요.)
                            </div>
                            <div className="ra-mission-caution text-muted">
                                - ISBN 번호 13자리를 정확하게 입력해 주세요.
                                (네이버 책에서 검색이 되는지 확인 후 입력)
                            </div>
                        </div>
                        <div className="form-row mt-3">
                            <div className="col-2">
                                <div className="h-100 d-flex justify-content-center align-items-center">
                                    <h6 className="text-middle text-center mb-0">
                                        검색 키워드(필수)
                                    </h6>
                                    <div className="help-tip">
                                        <p>
                                            인터넷에 검색했을 때, 과제 또는
                                            이론과 관련된
                                            <br />
                                            내용이 나오는 키워드{' '}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {searchKeywords.fields.map(
                                (searchKeyword, index) => (
                                    <div
                                        key={searchKeyword.id}
                                        className="col-2"
                                    >
                                        <input
                                            key={searchKeyword.id}
                                            ref={form.register()}
                                            name={`searchKeywords[${index}].value`}
                                            defaultValue={searchKeyword.value}
                                            className="form-control rounded-0"
                                        />
                                    </div>
                                ),
                            )}
                            <div
                                className="study-subject-ex-btn ml-3"
                                onClick={() => handleGuide(3)}
                            >
                                예시 확인
                            </div>
                        </div>
                        <div className="form-row mt-3">
                            <div className="col-2">
                                <div className="h-100 d-flex justify-content-center align-items-center">
                                    <h6 className="text-middle text-center mb-0">
                                        사례 키워드(옵션)
                                    </h6>
                                    <div className="help-tip">
                                        <p className="long-p">
                                            과제 또는 이론과 관련된 과학적 원리
                                            혹은 사회적 현상의 실제 사례
                                            <br />
                                            (예: 제품명, 지역명, 기업명,
                                            프로그램명 등)
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {exampleKeywords.fields.map(
                                (exampleKeywordField, index) => (
                                    <div
                                        key={exampleKeywordField.id}
                                        className="col-2"
                                    >
                                        <input
                                            key={exampleKeywordField.id}
                                            ref={form.register()}
                                            name={`exampleKeywords[${index}].value`}
                                            defaultValue={
                                                exampleKeywordField.value
                                            }
                                            className="form-control rounded-0"
                                        />
                                    </div>
                                ),
                            )}
                        </div>
                        <div className="form-row mt-3">
                            <div className="col-2">
                                <div className="h-100 d-flex justify-content-center align-items-center">
                                    <h6 className="text-middle text-center mb-0">
                                        관련 키워드(옵션)
                                    </h6>
                                    <div className="help-tip">
                                        <p>
                                            과제 및 이론을 포괄하여 카테고리화
                                            할 수 있는
                                            <br />
                                            보다 넓은 범주의 키워드 (해시태그와
                                            같은 개념)
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {relevantKeywords.fields.map(
                                (relevantKeywordField, index) => (
                                    <div
                                        key={relevantKeywordField.id}
                                        className="col-2"
                                    >
                                        <input
                                            key={relevantKeywordField.id}
                                            ref={form.register()}
                                            name={`relevantKeywords[${index}].value`}
                                            defaultValue={
                                                relevantKeywordField.value
                                            }
                                            className="form-control rounded-0"
                                        />
                                    </div>
                                ),
                            )}
                        </div>
                        <div className="form-row mt-3">
                            <div className="col-2">
                                <div className="h-100 d-flex justify-content-center align-items-center">
                                    <h6 className="text-middle text-center mb-0">
                                        관련 도서(ISBN)
                                    </h6>
                                    <div className="help-tip">
                                        <p>
                                            <span>제목</span> 혹은{' '}
                                            <span>목차</span>에서 과제와 관련된
                                            내용이 확인되어야 함.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {recommendedBooks.fields.map(
                                (recommendedBookField, index) => (
                                    <div
                                        key={recommendedBookField.id}
                                        className="col-2"
                                    >
                                        <input
                                            type="number"
                                            key={recommendedBookField.id}
                                            ref={form.register()}
                                            name={`recommendedBooks[${index}].value`}
                                            defaultValue={
                                                recommendedBookField.value
                                            }
                                            className="form-control rounded-0"
                                        />
                                    </div>
                                ),
                            )}
                            <a
                                href="https://book.naver.com/"
                                target="_blank"
                                className="col-4 study-subject-naver-btn"
                                rel="noreferrer"
                            >
                                네이버 책 바로가기
                            </a>
                        </div>
                        <div className="mt-5 border-light-color p-4">
                            <h5 style={{ fontWeight: 800 }}>5. 학과 태깅</h5>
                            <div className="ra-mission-caution text-muted mt-3">
                                - '탐구과제'와 ① <bold>밀접하게 관련</bold>되는
                                학과를 ② <bold>모두</bold> 태그해주세요.
                            </div>
                        </div>
                        <hr className="border-dark mt-4 thick" />
                        <div className="mt-4">
                            <TagWizard
                                isLoaded={entity && tags.length > 0}
                                tags={tags}
                                setTags={setTags}
                                // onComplete={tags =>
                                //     form.setValue('recommendedMajors', tags)
                                // }
                                setFinished={setFinished}
                                setOnGoing={setOnGoing}
                            />
                        </div>
                        {finished && !allTagsChecked() && (
                            <div>
                                <div className="form-row mt-4 d-flex justify-content-end">
                                    <h6 className="text-muted">
                                        아직 태깅되지 않은 학과가 있어요!{' '}
                                        <span
                                            style={{
                                                color: 'red',
                                                fontWeight: 800,
                                            }}
                                        >
                                            8개 이상
                                        </span>
                                        작성
                                    </h6>
                                </div>
                                <div className="form-row d-flex justify-content-end">
                                    <h6 className="text-muted">
                                        다시 선택 버튼을 눌러서 학과 태깅을
                                        마무리해주세요!
                                    </h6>
                                </div>
                            </div>
                        )}
                        <hr className="border-dark mt-5 thick" />
                        {
                            profile.isSettlementIncluded &&
                            <div className="row mt-5 justify-content-end">
                                <div className="d-flex align-items-center mr-5">
                                    <h5>총 포인트</h5>
                                </div>
                                <div className="border-dark-color col-4 text-center p-2">
                                    <h5>0</h5>
                                </div>
                            </div>
                        }
                        <div className="form-row mt-5 mb-5 justify-content-end">
                            {finished && allTagsChecked() ? (
                                isAllSpellChecked() ? (
                                    <div className="offset-1 col-3 justify-content-center align-items-center d-flex">
                                        <button
                                            type="submit"
                                            className="btn btn-main-color btn-lg btn-block"
                                            ref={submitButtonRef}
                                        >
                                            제출하기
                                        </button>
                                    </div>
                                ) : (
                                    <div className="offset-1 col-3 justify-content-center align-items-center d-flex">
                                        <div
                                            className="btn border-main-color btn-lg btn-block"
                                            style={{ fontSize: 14 }}
                                        >
                                            맞춤법 검사를 완료해야
                                            <br />
                                            제출 버튼이
                                            <br />
                                            활성화됩니다
                                        </div>
                                    </div>
                                )
                            ) : (
                                <div className="offset-1 col-3 justify-content-center align-items-center d-flex">
                                    <div
                                        className="btn border-main-color btn-lg btn-block"
                                        style={{ fontSize: 14 }}
                                    >
                                        학과 태깅을 완료해야
                                        <br />
                                        제출버튼을
                                        <br />
                                        누를 수 있어요!
                                    </div>
                                </div>
                            )}
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    );
};
