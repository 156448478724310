import React from 'react';

export const Navigator = ({
    visible,
    back,
    next,
    currentIndex,
    setCurrentIndex,
    tagLength,
    currentStep,
    ...props
}) => {
    const handleCompleteButtonClick = () => {
        next();
    };
    return (
        <div className="mt-5 row">
            <div className="col-6 justify-content-start align-items-center d-flex pl-0">
                {currentStep == 'Complete' && (
                    <>
                        <div className="col-6 justify-content-center align-items-center d-flex">
                            <button
                                type="button"
                                className="btn-border-main btn-lg rounded-0 btn-block position-relative"
                                onClick={back}
                            >
                                이전
                            </button>
                        </div>
                    </>
                )}
            </div>
            <div className="col-6 justify-content-end align-items-center d-flex pr-0">
                {currentStep !== 'Complete' ?
                    <div className="col-6 justify-content-center align-items-center d-flex">
                        <button
                            type="button"
                            className="btn-main-color btn-lg rounded-0 btn-block position-relative"
                            onClick={handleCompleteButtonClick}
                        >
                            태깅 완료하기
                        </button>
                    </div>
                    : <></>}
            </div>
        </div>
    );
};
