import moment from 'Moment';

export const getRemainingTime = (createdAt, daysDelta = 3) => {
    const timeLimitMoment = moment(createdAt).add(daysDelta, 'days');
    const now = moment();
    const duration = moment.duration(timeLimitMoment.diff(now));

    const hours = Math.floor(duration.asHours());
    const minutes = Math.floor(duration.asMinutes() % 60);

    return {
        hours,
        minutes,
    };
};
