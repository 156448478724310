import React from 'react';

import { Route, Redirect } from 'react-router-dom';

export const AnonymousOnlyRoute = ({
    isAuthenticated,
    userType,
    render,
    routeComponent: RouteComponent = Route,
    component: Component,
    ...rest
}) => {
    const redirectUrl =
        userType === 'ADMIN'
            ? '/admin/'
            : userType === 'STAFF'
            ? '/staff/'
            : '/ra/missions/';
    return (
        <RouteComponent
            {...rest}
            render={routeProps =>
                !isAuthenticated ? (
                    render ? (
                        render(routeProps)
                    ) : (
                        <Component {...routeProps} />
                    )
                ) : (
                    <Redirect
                        to={{
                            pathname: redirectUrl,
                            state: {
                                from: routeProps.location,
                            },
                        }}
                    />
                )
            }
        />
    );
};
