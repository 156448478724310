import React, { useState, useEffect } from 'react';

import { useForm, useFieldArray } from 'react-hook-form';

import { useAPI, APIRoute } from 'Client';
import { formatMoney } from 'Utils';

import Moment from 'react-moment';
import moment from 'moment';
import { ko } from 'date-fns/esm/locale';
import DatePicker from 'react-datepicker';
import { BaseTable, Pagination, usePageBlock } from '../table/base';

import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';

const columnNames = [
    {
        key: 'created',
        name: '일자',
    },
    {
        key: 'mission',
        name: '미션',
    },
    {
        key: 'pointAmount',
        name: '포인트',
    },
];

const LIMIT_PER_PAGE = 10;
const BLOCK_SIZE = 10;

export const RAPointTable = props => {
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [status, setStatus] = useState('PENDING');

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const { pageBlock, pageParams, setTotal, before, next } = usePageBlock({
        blockSize: BLOCK_SIZE,
        limitPerPage: LIMIT_PER_PAGE,
        initialTotal: (data && data.total) || 0,
        page,
    });

    const getDefaultSelected = () =>
        [...Array(10).keys()].map(() => ({
            value: false,
        }));
    const form = useForm({
        defaultValues: {
            selected: getDefaultSelected(),
        },
    });
    const selected = useFieldArray({
        control: form.control,
        name: 'selected',
    });

    const pointsListAPI = useAPI(APIRoute.ResearchAssistant.PointLog.List, {
        callbacks: {
            onSuccess: ({ results, count }) => {
                results = results.map(result => {
                    result.created = result.createdTime;
                    return result;
                });
                setData(results);
                setTotal(count);
            },
        },
    });
    const pointsWithdrawalsSettleAPI = useAPI(
        APIRoute.Admin.PointsWithdrawal.Settle,
        {
            callbacks: {
                onSuccess: () => alert('성공'),
            },
        },
    );

    form.handleSubmit = form.handleSubmit(async data => {
        try {
            let date = {
                date:
                    moment(startDate).format('YYYY-MM-DD') +
                    ',' +
                    moment(endDate).format('YYYY-MM-DD'),
            };
            await pointsListAPI.send({
                ...data,
                ...pageParams,
                ...date,
                status,
            });
        } catch (e) {
            console.error(e);
        }
    });

    useEffect(() => {
        form.reset({
            ...form.watch(),
            selected: getDefaultSelected(),
        });
        form.handleSubmit(form.getValues());
    }, [page, status]);

    const settleSelectedPointsWithdrawals = () => {
        const selectedPointsWithdrawalIds = form
            .watch('selected')
            .map((row, index) => (row.value ? data[index].id : null))
            .filter(id => id);
        pointsWithdrawalsSettleAPI.send({
            pointsWithdrawals: selectedPointsWithdrawalIds,
        });
    };

    return (
        <>
            <div className="ra-point-container pt-5">
                <div className="mr-2">
                    <div className="mb-1 align-items-center">
                        <AccessAlarmIcon fontSize="small" /> 시작일
                    </div>
                    <DatePicker
                        locale={ko}
                        dateFormat="yyyy-MM-dd"
                        selected={startDate}
                        closeOnScroll={true}
                        minDate={new Date('2021-02-01')}
                        className="cursor-pointer"
                        onChange={date => {
                            setStartDate(date);
                            if (date > endDate) setEndDate(date);
                        }}
                    />
                </div>
                <div className="mr-2">
                    <div className="mb-1 align-items-center">
                        <AccessAlarmIcon fontSize="small" /> 종료일
                    </div>
                    <DatePicker
                        locale={ko}
                        dateFormat="yyyy-MM-dd"
                        selected={endDate}
                        closeOnScroll={true}
                        minDate={startDate}
                        onChange={date => setEndDate(date)}
                        className="cursor-pointer"
                    />
                </div>
                <button
                    type="submit"
                    onClick={() => form.handleSubmit(form.getValues())}
                    className="btn btn-main-color"
                >
                    <svg
                        width="1rem"
                        height="1rem"
                        viewBox="0 0 16 16"
                        className="bi bi-search"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fillRule="evenodd"
                            d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"
                        />
                        <path
                            fillRule="evenodd"
                            d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"
                        />
                    </svg>
                </button>
            </div>

            <div className="col-12 mt-3">
                <BaseTable columnNames={columnNames} data={data} />
                <Pagination
                    before={before}
                    next={next}
                    pageBlock={pageBlock}
                    page={page}
                    setPage={setPage}
                />
            </div>
        </>
    );
};
