import React, { useState, useEffect, useContext } from 'react';
import { Form } from 'react-bootstrap';
import { APIRoute } from 'Client';
import { useHistory } from 'react-router-dom';
import moment from 'Moment';

import { AppContext } from 'Contexts';

import { RemoteDataTable, useRemoteDataTable } from './base';

// const columnNames = ['카테고리', '작성자', '이론', '작성일자'];
const formatResult = results => {
    return (results = results.map(result => {
        return {
            ...result,
            inspectionStatus: result.previousVersion
                ? result.inspectionStatus === 'ACCEPTED'
                    ? '승인'
                    : result.inspectionStatus === 'REJECTED'
                    ? '반려'
                    : '재제출'
                : result.inspectionStatus === 'ACCEPTED'
                ? '승인'
                : result.inspectionStatus === 'REJECTED'
                ? '반려'
                : '-',
            tooltip: [
                {
                    key: 'inspectionStatus',
                    content: result.previousVersion,
                },
                {
                    key: 'username',
                    content: result.username,
                },
            ],
            created: moment(result.created).format('YYYY-MM-DD HH:mm'),
        };
    }));
};

const tableProperty = [
    {
        key: 'id',
        name: '번호',
    },
    {
        key: 'username',
        name: '작성자',
    },
    {
        key: 'conceptName',
        name: '이론명',
    },
    {
        key: 'inspectionStatus',
        name: '승인여부',
    },
    {
        key: 'created',
        name: '작성일자',
    },
];

export const MissionReportTable = props => {
    const history = useHistory();

    const { admin, staff } = useContext(AppContext);

    const apiRoute = admin.profile.id
        ? APIRoute.Admin.MissionReport.List
        : staff.profile.id
        ? APIRoute.Staff.MissionReport.List
        : null;

    const formatData = data => {
        return formatResult(data);
    };

    const { form, reload, ...remoteDataTable } = useRemoteDataTable({
        apiRoute,
        formatData,
        defaultValues: {
            inspectionStatus: 'PENDING,REJECTED,ACCEPTED',
        },
    });

    const clickHandler = (_, { missionType, id }) => {
        const baseUrl = admin.profile.id
            ? '/admin/main/mission-reports/'
            : staff.profile.id
            ? '/staff/main/mission-reports/'
            : null;

        const url =
            missionType === 'STUDY_SUBJECT' ||
            missionType === 'STUDY_SUBJECT_RFP'
                ? `${baseUrl}${id}/study-subject/`
                : missionType === 'CONCEPT' || missionType === 'CONCEPT_RFP'
                ? `${baseUrl}${id}/concept/`
                : `${baseUrl}${id}/study-subject/`;
        history.push({
            pathname: url,
        });
    };

    tableProperty.forEach(e => {
        e.clickHandler = clickHandler;
    });

    return (
        <>
            <Form onSubmit={form.handleSubmit}>
                <div className="row pt-3">
                    <div className="col-2 form-check d-flex justify-content-center align-items-center">
                        <input
                            name="inspectionStatus"
                            value="PENDING,REJECTED,ACCEPTED"
                            ref={form.register}
                            className="position-relative mr-2 form-check-input mt-0"
                            type="radio"
                            id="status_all"
                        />
                        <label
                            className="form-check-label"
                            htmlFor="status_all"
                        >
                            전체
                        </label>
                    </div>
                    <div className="col-2 form-check d-flex justify-content-center align-items-center">
                        <input
                            name="inspectionStatus"
                            value="PENDING"
                            ref={form.register}
                            className="position-relative mr-2 form-check-input mt-0"
                            type="radio"
                            id="status_null"
                        />
                        <label
                            className="form-check-label"
                            htmlFor="status_null"
                        >
                            미처리
                        </label>
                    </div>
                    <div className="col-2 form-check d-flex justify-content-center align-items-center">
                        <input
                            name="inspectionStatus"
                            value="REJECTED"
                            ref={form.register}
                            className="position-relative mr-2 form-check-input mt-0"
                            type="radio"
                            id="status_rejected"
                        />
                        <label
                            className="form-check-label"
                            htmlFor="status_rejected"
                        >
                            반려
                        </label>
                    </div>
                    <div className="col-2 form-check d-flex justify-content-center align-items-center">
                        <input
                            name="inspectionStatus"
                            value="ACCEPTED"
                            ref={form.register}
                            className="position-relative mr-2 form-check-input mt-0"
                            type="radio"
                            id="status_accepted"
                        />
                        <label
                            className="form-check-label"
                            htmlFor="status_accepted"
                        >
                            승인
                        </label>
                    </div>
                </div>

                <div className="row justify-content-between p-3">
                    <div className="row">
                        <div className="form-check d-flex justify-content-center align-items-center pr-5 pl-5">
                            <input
                                name="types"
                                value="CONCEPT"
                                ref={form.register}
                                className="position-relative mr-2 form-check-input mt-0"
                                type="checkbox"
                                id="mission_concept"
                            />
                            <label
                                className="form-check-label"
                                htmlFor="mission_concept"
                            >
                                이론
                            </label>
                        </div>
                        <div className="form-check d-flex justify-content-center align-items-center pr-5 pl-5">
                            <input
                                name="types"
                                value="STUDY_SUBJECT"
                                ref={form.register}
                                className="position-relative mr-2 form-check-input mt-0"
                                type="checkbox"
                                id="mission_study_subject"
                            />
                            <label
                                className="form-check-label"
                                htmlFor="mission_study_subject"
                            >
                                탐구콘텐츠 생성
                            </label>
                        </div>
                    </div>
                    <div className="input-group col-4 p-0">
                        <input
                            name="all"
                            ref={form.register}
                            type="text"
                            className="form-control border-right-0 h-100 rounded-0"
                        />
                        <div className="input-group-append rounded-0">
                            <button type="button" onClick={reload}>
                                <svg
                                    width="1rem"
                                    height="1rem"
                                    viewBox="0 0 16 16"
                                    className="bi bi-search"
                                    fill="currentColor"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"
                                    />
                                    <path
                                        fillRule="evenodd"
                                        d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </Form>

            <div className="col-12 mt-3">
                <RemoteDataTable
                    tableProperty={tableProperty}
                    {...remoteDataTable}
                />
            </div>
        </>
    );
};
