import React, { useContext } from 'react';
import { AppContext } from 'Contexts';

// copy and paste TagResultDisplay.js
// responsible during adding the tags
export const TagListDisplay = ({ tags, deleteLearningElements, index, currentStep, ...props }) => {
    const {
        getters: { getMajorCategory, getSchoolSubject, getSchoolSubjectUnit },
    } = useContext(AppContext);
    return (
        <>
            {tags.map(
                (
                    {
                        majorCategory: majorCategoryId,
                        schoolSubjects,
                        schoolSubjectUnits,
                        learningElements,
                    },
                    tagIndex,
                ) => {
                    const majorCategory = getMajorCategory(majorCategoryId);
                    if (index == tagIndex) {
                        return (
                            <div key={tagIndex} className="mt-4 pr-5 pl-5">
                                <div className="row">
                                    <div className="col-12 scroll border border-dark p-5">
                                        <h5>위의 주제는 위의 전공을 희망하는 학생들이</h5>
                                        <div>
                                            {schoolSubjects.length ? schoolSubjects.map(
                                                ({ schoolSubjectUuid, id }) => {
                                                    const schoolSubject = getSchoolSubject(
                                                        id,
                                                    );
                                                    return (
                                                        <div
                                                            key={schoolSubjectUuid}
                                                            className="pl-5 mt-3"
                                                        >
                                                            <h6>
                                                                <mark>{schoolSubject && schoolSubject.name.replace(/_/g, " ")}</mark> 과목
                                                        </h6>
                                                            <div className="pl-5">
                                                                {schoolSubjectUnits
                                                                    .filter(
                                                                        ({ schoolSubjectUuid: _, }) => _ === schoolSubjectUuid)
                                                                    .map(
                                                                        ({ schoolSubjectUnitUuid, name, }) => {
                                                                            return (
                                                                                <div
                                                                                    key={schoolSubjectUnitUuid}
                                                                                    className="row mt-2"
                                                                                >
                                                                                    <span className="mt-2">
                                                                                        <mark>{name && name.replace(/_/g, " ")}</mark> 단원에서
                                                                                </span>
                                                                                    <div className="pl-5">
                                                                                        {learningElements.filter(
                                                                                            ({
                                                                                                schoolSubjectUnitUuid: _schoolSubjectUnitUuid,
                                                                                                schoolSubjectUuid: _schoolSubjectUuid,
                                                                                            }) =>
                                                                                                _schoolSubjectUnitUuid ===
                                                                                                schoolSubjectUnitUuid &&
                                                                                                _schoolSubjectUuid ===
                                                                                                schoolSubjectUuid,
                                                                                        )
                                                                                            .map(({ name, learningElementUuid }) => {
                                                                                                return (
                                                                                                    <div
                                                                                                        key={name}
                                                                                                        className="mb-2"
                                                                                                    >
                                                                                                        <mark>{name && name.replace(/_/g, " ")}</mark>에 대해 발표할 수 있습니다.
                                                                                                        {currentStep !== 'Complete' &&
                                                                                                            <button type="button" onClick={() => deleteLearningElements(tagIndex, learningElementUuid)} className="btn btn-outline-secondary ml-3">X</button>}
                                                                                                    </div>
                                                                                                );
                                                                                            },
                                                                                            )}
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        },
                                                                    )}
                                                            </div>
                                                        </div>
                                                    );
                                                },
                                            ) : <h6 className="mt-3">(예시) <mark>✖︎✖︎과목</mark> <mark>☐☐단원</mark>에서 <mark>★★</mark>에 대해 발표할 수 있습니다.</h6>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    }
                },
            )}
        </>
    );
};