import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useAPI, APIRoute } from 'Client';
import { useHistory } from 'react-router-dom';

import { BaseTable, Pagination, usePageBlock } from 'Components/table/base';

// const columnNames = ['카테고리', '작성자', '이론', '작성일자'];

const columnNames = [
    {
        key: 'id',
        name: '번호',
    },
    {
        key: 'name',
        name: '작성자',
    },
    {
        key: 'username',
        name: '작성자ID',
    },
    {
        key: 'serialNumber',
        name: '시리얼 넘버',
    },
    {
        key: 'created',
        name: '작성일자',
    },
];

const LIMIT_PER_PAGE = 10;
const BLOCK_SIZE = 10;

export const DongaReportTable = props => {
    const history = useHistory();

    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);

    const { pageBlock, pageParams, setTotal, before, next } = usePageBlock({
        blockSize: BLOCK_SIZE,
        limitPerPage: LIMIT_PER_PAGE,
        initialTotal: (data && data.total) || 0,
        page,
    });

    const form = useForm();

    const dongaReportListAPI = useAPI(APIRoute.Admin.DongaReport.List, {
        callbacks: {
            onSuccess: ({ results, count }) => {
                setData(results);
                setTotal(count);
            },
        },
    });

    const handleOnClick = ({ id }) => {
        const url = `/admin/donga-reports/${id}/`;
        history.push(url);
    };

    const handleSubmit = form.handleSubmit(async data => {
        try {
            await dongaReportListAPI.send({
                ...data,
                ...pageParams,
            });
        } catch (e) {
            console.error(e);
        }
    });

    useEffect(() => {
        handleSubmit(form.getValues());
    }, [page]);

    return (
        <>
            <Form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="input-group col-4 p-0">
                        <input
                            name="name"
                            ref={form.register}
                            type="text"
                            className="form-control border-right-0 h-100 rounded-0"
                        />
                        <div className="input-group-append rounded-0">
                            <button type="submit">
                                <svg
                                    width="1rem"
                                    height="1rem"
                                    viewBox="0 0 16 16"
                                    className="bi bi-search"
                                    fill="currentColor"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"
                                    />
                                    <path
                                        fillRule="evenodd"
                                        d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </Form>

            <div className="col-12 mt-5">
                <BaseTable
                    columnNames={columnNames}
                    data={data}
                    onClick={handleOnClick}
                />
                <Pagination
                    before={before}
                    next={next}
                    pageBlock={pageBlock}
                    page={page}
                    setPage={setPage}
                />
            </div>
        </>
    );
};
