import React, { useState, useContext, useEffect } from 'react';

import { AppContext } from 'Contexts/';

export const RecommendSchoolSubject = ({ relatedUnits, clickRecommendUnit, ...props }) => {
    const [relatedUnitArr, setRelatedUnitArr] = useState([]);

    const {
        getters: { getSchoolSubjectUnit },
    } = useContext(AppContext);

    useEffect(() => {
        setRelatedUnitArr(relatedUnits.map(unit => getSchoolSubjectUnit(unit)))
    }, [])

    return (
        <>
            { relatedUnitArr &&
                <div className="pr-5 pl-5">
                {
                    relatedUnitArr.length > 0 &&
                        <h5 className="mt-3 mb-3 text-main-color">추천 단원</h5>
                }
                <div className="row">
                        {relatedUnitArr.map(unit =>
                            <div className="rounded btn btn-outline-info mr-2 mt-2" key={unit.id} onClick={() => clickRecommendUnit(unit)}>
                                {unit.name.replace(/_/g, " ")}
                            </div>
                        )}
                    </div>
                </div>
            }
        </>
    );
};
