import React from 'react';
import { generateUuid } from 'Utils';

export const LearningElementsSelector = ({
    learningElements,
    selectedLearningElements,
    setSelectedLearningElements,
    schoolSubjectUnit,
    ...props
}) => {
    const removeLearningElement = learningElementName => {
        setSelectedLearningElements([
            ...selectedLearningElements.filter(
                ({ name }) => name !== learningElementName,
            ),
        ]);
    };

    const addSelectedLearningElement = learningElementName => {
        setSelectedLearningElements([
            ...selectedLearningElements,
            { name: learningElementName, learningElementUuid: generateUuid() },
        ]);
    };

    const handlehandleLearningElementClick = learningElementName => {
        const handler = selectedLearningElements
            .map(({ name }) => name)
            .includes(learningElementName)
            ? removeLearningElement
            : addSelectedLearningElement;

        handler(learningElementName);
    };

    return (
        <div className="col-6 scroll border-left-0 border-dark-color p-5">
            {learningElements.map((learningElement, index) => {
                return (
                    <div
                        key={`${schoolSubjectUnit.id}-${learningElement}-${index}`}
                        onClick={() =>
                            handlehandleLearningElementClick(
                                learningElement.name,
                            )
                        }
                        className="mt-3 mb-3"
                    >
                        <span
                            className={`${
                                selectedLearningElements
                                    .map(({ name }) => name)
                                    .includes(learningElement.name)
                                    ? 'text-main-color cursor-pointer'
                                    : 'text-muted cursor-pointer'
                            }`}
                        >
                            <span
                                className={`${
                                    selectedLearningElements
                                        .map(({ name }) => name)
                                        .includes(learningElement.name)
                                        ? 'text-main-color cursor-pointer'
                                        : 'text-muted cursor-pointer'
                                }`}
                            >
                                {`${learningElement.name}`}
                            </span>
                        </span>
                    </div>
                );
            })}
        </div>
    );
};
