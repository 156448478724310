import React from 'react';

import { Pagination } from './Pagination';

export const RemoteDataBlockTable = ({
    blockProperty,
    data,
    loading,
    paginationData: { isPaginated = true, ...paginationData },
    ...props
}) => {
    return (
        <>
            <div className="row">
                {loading && (
                    <div className="col-xs-12 col-lg-12 col-md-12">
                        <div className="table-loading-text">
                            데이터를 불러오고 있습니다...
                        </div>
                        <div className="loader">Loading</div>
                    </div>
                )}
                {!loading && (
                    <>
                        {data.length === 0 && (
                            <div className="col-xs-12 col-lg-12 col-md-12">
                                <span>데이터가 없습니다.</span>
                            </div>
                        )}
                        {data.map((datum, index) => {
                            const {
                                key,
                                template,
                                clickHandler,
                            } = blockProperty;
                            return (
                                <div
                                    key={index}
                                    onClick={() =>
                                        typeof clickHandler === 'function'
                                            ? clickHandler(datum[key], datum)
                                            : ''
                                    }
                                    className="cursor-pointer direct-box"
                                >
                                    <div className="p-3 m-3 border direct-content">
                                        {template &&
                                            typeof template === 'function' ? (
                                            template(
                                                datum[key],
                                                datum,
                                                clickHandler,
                                            )
                                        ) : (
                                            <span>{datum[key]}</span>
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                    </>
                )}
            </div>
            {isPaginated && <Pagination {...paginationData} />}
        </>
    );
};
