import { useState } from 'react';

export * from './AdminDirectlyManagedConceptModal';
export * from './AdminDirectlyManagedStudySubjectModal';
export * from './AdminLearningElementModifyModal';
export * from './AdminSchoolSubjectCreatePatchDeleteModal';
export * from './AdminSchoolSubjectUnitCreateModal';

export * from './RegisterModal';
export * from './FindPasswordModal';
export * from './ModifyPasswordModal';
export * from './SearchConceptsModal';
export * from './ShowRewardsModal';
export * from './SearchRelatedMajorsModal';
export * from './SearchRelatedSchoolSubjectsModal';
export * from './SearchRelatedSchoolSubjectUnitsModal';
export * from './SearchStudentProfileModal';
export * from './ResearchAssistantProfileModal';
export * from './ResearchAssistantMyProfileModal';
export * from './StudentProfileModal';
export * from './TagAchievements';
export * from './TagAchievementsIssue';
export * from './FindIdModal';
export * from './PointsWithdrawalModal';
export * from './ResearchAssistantNoticeListModal';

export const useModal = () => {
    const [isOpen, setIsOpen] = useState(false);

    return {
        isOpen,
        open: () => setIsOpen(true),
        close: () => setIsOpen(false),
    };
};
