import React from 'react';
import { SearchRelatedSchoolSubjectsModal, useModal } from 'Components/modals';
import { generateUuid } from 'Utils';

export const AddSubjectUnitModalWrapper = ({
    first,
    tagIndex,
    handleCopy,
    addSchoolSubject,
    ...props
}) => {
    const searchRelatedSchoolSubjectsModal = useModal();
    const handleButtonClick = e => {
        if (first && tagIndex !== 0 && confirm('이전 단원을 불러오겠습니까?')) {
            handleCopy();
            return;
        }
        searchRelatedSchoolSubjectsModal.open();
    };

    const handleClick = schoolSubject => {
        const { name, id } = schoolSubject;
        addSchoolSubject({
            schoolSubjectUuid: generateUuid(),
            id,
        });
    };

    return (
        <>
            <button
                type="button"
                onClick={handleButtonClick}
                className="w-50 btn btn-outline-secondary rounded-0 major-add-btn ml-5"
            >
                <span>과목추가</span>
                <svg
                    width="0.65em"
                    height="0.65em"
                    viewBox="0 0 16 16"
                    className="display-4 bi bi-plus"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"
                    />
                </svg>
            </button>
            <SearchRelatedSchoolSubjectsModal
                handleClick={handleClick}
                {...searchRelatedSchoolSubjectsModal}
            />
        </>
    );
};
